import jwt_decode, {JwtPayload} from "jwt-decode";
import moment from 'moment';
import {Mutex, withTimeout} from 'async-mutex';

// import { error } from 'util';
import commonStore from '../../stores/common.store';
import memberStore from '../../stores/member.store';
import ErrorResp from '../../models/error.model';

import { ISearchResponse } from '../../models/search.model';
import IAccount, { IMemberRole, AuthKeyType } from '../../models/account.model';
import IApp from '../../models/app.model';
import IInvite from '../../models/invite.model';
import Json from '../json.service';
import environmentService from '../environment.service';
import IApiKeyCreate from '../../models/dto/apikey_create.model';
import authConnection from './auth.connection';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export class ConnectionError extends Error {
  status?: number;
  constructor(errorResp: ErrorResp) {
      super();
      this.name = errorResp.name;
      this.message = errorResp.message;
      this.status = errorResp.status;
      this.stack = errorResp.stack;
  }
}

const BASE_URL = environmentService.isProduction() ? 'https://api.shipbook.io/v1/' : 'http://localhost:8080/v1/';
class Connection {
  mutex = <Mutex> withTimeout(new Mutex(), 30000);

  public get appUrl(): string {
    return `apps/${memberStore.appId}`;
  } 

  public async sendValidateEmail(memberId: string): Promise<void> {
    return await this.fetch(`members/${memberId}/sendValidateEmail`, HttpMethod.PUT);
  }

  public async search(data: object): Promise<ISearchResponse> {
    return await this.fetch(`${this.appUrl}/search`, HttpMethod.GET, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async addMessageToSlack(data: object): Promise<any> {
    return await this.fetch(`${this.appUrl}/slack/message`, HttpMethod.POST, data);
  }

  public async getManufacturers(manufacturer: string): Promise<string[]> {
    return await this.fetch(`${this.appUrl}/devices/manufacturers`, HttpMethod.GET,  {q: manufacturer});
  }

  public async getDeviceModels(deviceModel: string): Promise<string[]> {
    return await this.fetch(`${this.appUrl}/devices/deviceModels`, HttpMethod.GET,  {q: deviceModel});
  }

  public async getUdids(udid: string): Promise<string[]> {
    return await this.fetch(`${this.appUrl}/devices/udids`, HttpMethod.GET,  {q: udid});
  }

  public async getUserIds(userId: string): Promise<string[]> {
    return await this.fetch(`${this.appUrl}/users/userIds`, HttpMethod.GET,  {q: userId});
  }

  public async getUserNames(userName: string): Promise<string[]> {
    return await this.fetch(`${this.appUrl}/users/userNames`, HttpMethod.GET,  {q: userName});
  }

  public async getFullNames(fullName: string): Promise<string[]> {
    return await this.fetch(`${this.appUrl}/users/fullNames`, HttpMethod.GET,  {q: fullName});
  }

  public async getEmails(emails: string): Promise<string[]> {
    return await this.fetch(`${this.appUrl}/users/emails`, HttpMethod.GET,  {q: emails});
  }

  public async getPhoneNumbers(phoneNumber: string): Promise<string[]> {
    return await this.fetch(`${this.appUrl}/users/phoneNumbers`, HttpMethod.GET,  {q: phoneNumber});
  }
  
  public async addAccountAuthKey(name: string, type: AuthKeyType): Promise<IApiKeyCreate> {
    return await this.fetch(`accounts/${memberStore.accountId}/authKey`,HttpMethod.POST,{
      name, type
    });
  }
  public async updateAccountAuthKey(key: string, name:string){
    return await this.fetch(`accounts/${memberStore.accountId}/authKey/${key}`,HttpMethod.PUT,{
      name
    });
  }

  public async updateSlackIntegration( integrationId: string, appId?: string){
    return await this.fetch(`accounts/${memberStore.accountId}/slackIntegration/${integrationId}`,HttpMethod.PUT, {appId});
  }

  public async removeSlackIntegration( integrationId: string){
    return await this.fetch(`accounts/${memberStore.accountId}/slackIntegration/${integrationId}`,HttpMethod.DELETE);
  }

  public async deleteAccountAuthKey(key: string): Promise<void> {
    await this.fetch(`accounts/${memberStore.accountId}/authKey/${key}`,HttpMethod.DELETE);
    await this.getCurrentAccount();
  } 

  public async getCurrentAccount(): Promise<IAccount> {
    return await this.fetch(`members/me/accounts/${memberStore.accountId}`);
  } 

  public async createApp(data: object): Promise<IApp> {
    return await this.fetch(`accounts/${memberStore.accountId}/apps`, HttpMethod.POST, data);
  }

  public async getMemberRoles(accountId: string): Promise<IMemberRole[]> {
    return await this.fetch(`accounts/${accountId}/membersRole`);
  }

  public async addMemberRole(accountId: string, data: object): Promise<void> {
    return await this.fetch(`accounts/${accountId}/membersRole`, HttpMethod.POST, data);
  }

  public async changeMemberRole(accountId: string, memberId: string, data: object): Promise<void> {
    return await this.fetch(`accounts/${accountId}/membersRole/${memberId}`, HttpMethod.PUT, data);
  }

  public async deleteMemberRole(accountId: string, memberId: string): Promise<void> {
    return await this.fetch(`accounts/${accountId}/membersRole/${memberId}`, HttpMethod.DELETE);
  }

  public async enableDevice(appId: string, udid: string): Promise<IApp> {
    const data = {udid};
    return await this.fetch(`apps/${appId}/enableDevice`, HttpMethod.POST, data);
  }

  public async disableDevice(appId: string, udid: string): Promise<IApp> {
    const data = {udid};
    return await this.fetch(`apps/${appId}/disableDevice`, HttpMethod.POST, data);
  }

  public async getInvites(accountId: string): Promise<IInvite[]> {
    return await this.fetch(`accounts/${accountId}/invites`);
  }

  public async deleteInvite(accountId: string, inviteId: string): Promise<void> {
    return await this.fetch(`accounts/${accountId}/invites/${inviteId}`, HttpMethod.DELETE);
  }

  public async resendInvite(accountId: string, inviteId: string): Promise<void> {
    return await this.fetch(`accounts/${accountId}/invites/${inviteId}/resend`, HttpMethod.PUT);
  }

  public async createBillingToken(accountId: string) {
    return await this.fetch(`accounts/${accountId}/billing/createToken`, HttpMethod.POST);
  }

  public async subscription(accountId: string, planId: string) {
    const data = { planId };
    return await this.fetch(`accounts/${accountId}/subscription`, HttpMethod.POST, data);
  }

  public async createCreditCard(accountId: string, data: object) {
    return await this.fetch(`accounts/${accountId}/billing/creditCard`, HttpMethod.POST, data);
  }

  public async getCreditCardInfo(accountId: string) {
    return await this.fetch(`accounts/${accountId}/billing/creditCard`);
  }

  public async getUsage(): Promise<{}[]> {
    return await this.fetch(`accounts/${memberStore.accountId}/billing/usage`);
  }

  public encodeQueryData(data: Object) {
    const urlParams = new URLSearchParams();
    for (const name in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(name)) {
        const value = data[name];
        if (Array.isArray(value)) {
          value.forEach(val => urlParams.append(name, val));
        }
        else urlParams.append(name, value);
      }
    }
    return urlParams.toString();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async fetch(url: string, method: HttpMethod = HttpMethod.GET, data?: object, headers?: Headers): Promise<any> {
    if (!headers) headers = new Headers();
    // checking has token
    const token = commonStore.token
    if (token) {
      console.log('has token ', token);
      if (!url.startsWith('auth')) { 
        // checking if need to refresh the token
        const {exp} = jwt_decode<JwtPayload>(token);
        const diff = moment.unix(exp!).diff(moment(), 'minutes');
        if (diff < 1) { // need to refresh token
          console.log('need to refresh token');
          await this.mutex.runExclusive(async () => {
            console.log('entered run exclusive');
            let token = commonStore.token;
            if (!token) {
              console.log('has no token after that entered ');
              return;
            }
            const {exp} = jwt_decode<JwtPayload>(token);
            console.log('current token ' + token);
            const diff = moment.unix(exp!).diff(moment(), 'minutes');
            if (diff < 1) { // can be that already got refreshed for the moment
              token = (await authConnection.refreshToken()).token;
            }
            headers!.set('Authorization', `Bearer ${token}`);
          });
          
        }
        else headers.set('Authorization', `Bearer ${commonStore.token}`);
      }
    } else {
      console.log('has no token: ', url);
    }

    // checking which data there is
    let body: BodyInit | undefined = undefined;
    if (data) {
      if (data instanceof File) {
        const formData = new FormData()
        formData.append('data', data)
        body = formData;
      }
      else if (method === HttpMethod.GET) {
        url = url + '?' + this.encodeQueryData(data);
      }
      else {
        headers.set('Content-Type', 'application/json');
        body = JSON.stringify(data);
      }
    }

    const fetchData: RequestInit = { 
      method: method, 
      body: body ?? null,
      headers: headers,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchReq = async function(): Promise<any> {
      try {
        console.log('the fetch: ' + BASE_URL + url);
        const resp = await fetch(BASE_URL + url, fetchData);
        console.log('the response:', resp);
        const contentType = resp.headers.get('content-type');
        let respData;
        if (contentType && contentType.includes('application/json')) {
          const jsonString = await resp.text(); // need to create Date out of json
          if (jsonString) {
            respData = Json.parse(jsonString);
            console.log('the json response:', respData);  
          }
        }
        if (contentType && contentType.includes('text/csv')) {
          respData = await resp.text(); 
          console.log('the csv response:', respData);
        }

        if (!resp.ok) {
          if (resp.status === 401 && respData.name === 'TokenExpired') {
            await authConnection.refreshToken();
            headers!.set('Authorization', `Bearer ${commonStore.token}`);
            // eslint-disable-next-line require-atomic-updates
            fetchData.headers = headers;
            console.log('the fetch: ' + BASE_URL + url);
            return await fetchReq();
          }
          throw new ConnectionError(respData);
        }

        return respData;
      } catch (err) {
        console.error(err);
        throw err;
      } 
    };
    return await fetchReq();
  }

}
const ConnectionService = new Connection();
export default ConnectionService;