import React, {useEffect, useRef} from 'react';

import styles from './logchat_sidebar.module.css';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList as List, ListChildComponentProps as ListProps} from 'react-window';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import memberStore from '../../stores/member.store';
import logChatStore from '../../stores/logchat.store';
import Time from '../../services/time.service';
import ILogChat from '../../models/logchat.model';
import Sidebar from '../common/sidebar.component';

import trash from '../../img/preferences/trash.svg';
import newChat from '../../img/logchat/new_chat.svg';



const LogChatRow = ({chat}: {chat: ILogChat}) => {
  const history = useHistory();
  const clickTrash = (event:React.FormEvent) => {
    event.stopPropagation();
    logChatStore.deleteChat(chat._id!);
  };

  const onClick = () => {
    const {accountId, appId} = memberStore;
    const path = `/${accountId}/${appId}/logchat/${chat._id}`;
    history.push(path)
  };

  const params = useParams<{chatId?: string}>();
  const selected = params.chatId === chat._id;
  
  return (
    <div className={`${styles.logChatRow} ${selected && styles.selected}`} onClick={onClick}>
      <div className={styles.chatName}>{chat.name ?? 'no name'}</div>
      {selected && <img alt="trash" className={styles.trash} src={trash} onClick={clickTrash}/> }
    </div>
  )
}

const LogChatDate = ({date}: {date: Date}) => {
  const time = new Time(date);
  return (
    <div className={styles.logChatDate}>{time.getLongDate()}</div>
  )
}

const Row = ({index, style}: ListProps) => {
  const item = logChatStore.list[index];
  return (
    <div style={style}>
      { item.type === 'date' ?
          <LogChatDate date={item.content as Date} /> :
          <LogChatRow chat={item.content as ILogChat} />
      }
    </div>
  )
}

const LogChatList = observer(() => {
  const listRef =  useRef<List | null>(null);
  const {list} = logChatStore;

  // reset list when list changes
  useEffect(() => {
    listRef?.current?.resetAfterIndex(0);
  }, [list]);

  return (
    <div className={styles.logChatList}>
      <AutoSizer >
        {({height, width}) => (
          console.log('autosizer height', height),
          <List
            ref={listRef}
            height={height}
            itemCount={list.length}
            itemSize={index => list[index].type === 'date' ? 61 : 35}
            width={width}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </div>
  )
});

const LogChatSidebar = observer(() => {
  const history = useHistory();
  const {appId, accountId } = memberStore;
  const path = `/${accountId}/${appId}/logchat`;

  useEffect(() => {
    console.log('logchat mounted');
    logChatStore.getChatList();
  }, []);

  function onNewChat() {
    history.push(path)
  }

  return (
    <Sidebar>
      <div className={styles.header}>
        <div className={styles.title}>LogChat </div>
        <img src={newChat} className={styles.addChat}  data-tip="add chat" onClick={onNewChat} />
      </div>
      <div className={styles.underline} /> 
      <LogChatList />  
    </Sidebar>
  );
});
export default LogChatSidebar;
