/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './loglytics.module.css';

import loglyticsStore from '../../stores/loglytics.store';
// import Select from '../common/select.component';
import Time from '../../services/time.service';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, TooltipProps, Tooltip } from 'recharts';
import { SeverityExc, Severity, Exception } from '../../models/log.model';

import numberService from '../../services/number.service';
import dashboardStore from '../../stores/dashboard.store';
import { LoglyticsStatus } from '../../models/loglytics.model';
import memberStore from '../../stores/member.store';
import { Platform } from '../../models/platform.model';
import { GraphTimeTick } from '../common/graph_time_tick.component';
import LoglyticsList from './loglytics_list.component';


import openUpperDrawer from '../../img/open-upper-drawer.svg';
import closeUpperDrawer from '../../img/close-upper-drawer.svg';
import blackFlag from '../../img/logs/black-flag.svg';
import wheel from '../../img/logs/wheel.svg';
import buoy from '../../img/logs/buoy.svg';
import anchor from '../../img/logs/anchor.svg';
import compass from '../../img/logs/compass.svg';
import sandClock from '../../img/logs/sand-clock.svg';

function getSeverityImage(severity: SeverityExc) {
  switch (severity) {
    case Exception.EXCEPTION:
      return blackFlag;
    case Severity.ERROR:
      return wheel;
    case Severity.WARNING:
      return buoy;
    case Severity.INFO:
      return anchor;
    case Severity.DEBUG:
      return compass;
    case Severity.VERBOSE:
      return sandClock;
    default:
      return '';
  } 
}

interface SeverityButtonProps {
  severity: SeverityExc;
}

const SeverityButton = observer(class SeverityButton extends React.Component<SeverityButtonProps> {
  constructor(props: SeverityButtonProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    console.log('temp ' + this.props.severity);
    loglyticsStore.setSeverity(this.props.severity);
  }

  render() {
    // let {severity, unreadCount} = this.props.loglyticsStore!;
    const {severity} = loglyticsStore;
    const severityOn =  severity === this.props.severity;
    const src = getSeverityImage(this.props.severity);
    // let unread = unreadCount[this.props.severity.toLowerCase()];
    const unread = 0;
    // tslint:disable-next-line:switch-default
    return (
      <div className={styles.severityButton} onClick={this.onClick}>
        <img src={src} className={`${styles.severityIcon} ${severityOn ? '': styles.severityOff}`} data-tip={this.props.severity} />
        {unread > 0 && <div />}
      </div>
    ); 
  }
});


class CustomTooltip extends React.Component<TooltipProps> {
  render() {
    const {payload} = this.props;
    const time = new Time(this.props.label!);
    return (
      <div className={styles.customTooltip}>
        <div className={styles.customTooltipTitle}>{time.getLongDate()}</div>
        {payload && payload.map((elem) => {      
          const value = Math.floor(elem.value as number * 1000) / 10 + '%';
          return (
          <React.Fragment key={elem.name}>
            <div className={styles.customTooltipText}>{elem.name}</div>
            <div className={styles.customTooltipColor} style={{backgroundColor: elem.color}}/>
            <div className={styles.customTooltipText}>{value}</div>
          </React.Fragment>);
        })}        
      </div>
    );
  }
}
interface MainGraphState {
  expanded: boolean;
}

const MainGraph = observer(class MainGraph extends React.Component<{}, MainGraphState> {
  constructor(props: {}) {
    super(props);
    this.state = {expanded: true};
    this.onExpansionClick = this.onExpansionClick.bind(this);
  }

  onExpansionClick() {
    const expanded = !this.state.expanded;
    this.setState({expanded});
  }

  render() {
    const { severity, deviceCount, unread } = loglyticsStore;
    const { affectedDevices, average } = dashboardStore;
    let color = '';
    let graphColor = '';
    let percentage = 0;
    let count = 0;
    switch (severity) {
      case Exception.EXCEPTION:
        color = "var(--black)";
        graphColor = "var(--brown-grey)"
        percentage = average.exception;
        count = deviceCount!.exception;
        break;
      case Severity.ERROR:
        color = "var(--lipstick)";
        graphColor = "var(--soft-pink)"
        percentage = average.error;
        count = deviceCount!.error;
        break;
      case Severity.WARNING:
        color = "var(--mango)";
        graphColor = "var(--light-salmon)"
        percentage = average.warning;
        count = deviceCount!.warning;
        break;
    }

    const axisTickStyle = {
      fill: '#062960',
      fontFamily: 'Heebo',
      fontSize: 12
    };
    const {expanded} = this.state;
    const expansionSrc = expanded ? openUpperDrawer: closeUpperDrawer;

    const src = getSeverityImage(severity);  
  
    return (
      <div className={styles.mainGraph}>
        <div className={`${styles.expandable} ${!expanded && styles.hidden}`}>
          <div className={styles.leftInfo}>
            <div className={styles.severity}>
              <img src={src}/>
              {unread > 0 && <div className={unread >= 100? styles.padding : ''}>{unread}</div>}
            </div>
            <div className={styles.infoMain}>
              <div className={styles.infoPercentage} style={{color}}>{Math.floor(percentage * 1000) / 10}%</div>
              <div className={styles.infoText}>
                <div>({numberService.abbreviateNumber(count)})</div>
                <div>devices</div>
              </div>
            </div>
          </div>
          <ResponsiveContainer width="99%" height={100} className={styles.chart}>
            <BarChart data={affectedDevices}>
              <Tooltip wrapperStyle={{zIndex: 100000}} content={<CustomTooltip />}/>  
              {/* <Tooltip wrapperStyle={{zIndex: 100000}} content={<CustomTooltip perc={true}/>}/> */}
              <Bar dataKey={severity.toLowerCase()} name={severity} fill={graphColor} barSize={8}/>
              <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
              <XAxis height={15} dataKey={(stat) => stat.time.toISOString()}
                tick={<GraphTimeTick />} stroke="#21a1b5"/>
              <YAxis axisLine={false} width={30} tickLine={false} tick={axisTickStyle}
                tickFormatter={(perc: number) => `${Math.floor(perc * 100)}%`} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.expansionWrapper}>
          <img src={expansionSrc} className={styles.expansion} onClick={this.onExpansionClick}/>
        </div>
      </div>
    );
  }
});

// const SeverityButton = withRouter(CSeverityButton);

// interface State {
// }

interface State {
  showMore: boolean;
}

class Loglytics extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showMore: false
    };
    this.changeStatus = this.changeStatus.bind(this);
    this.markAllAsRead = this.markAllAsRead.bind(this);
  }

  componentDidMount() {
    loglyticsStore.getLoglytics();
    dashboardStore.getStats();
  }
  
  changeStatus(status: string) {
    loglyticsStore.setLoglyticsStatus(status as LoglyticsStatus | "");
  }

  markAllAsRead() {
    this.setState({showMore: false});
    loglyticsStore.setAllRead();
  }

  render() {
    // const {  version, appVersions } = this.props.loglyticsStore!;
    // let versionNameValues = {'': 'All'};
    // appVersions.forEach((vers) => versionNameValues[vers] = vers);
    // const statusValues = {
    //   [LoglyticsStatus.OPEN]: LoglyticsStatus.OPEN,
    //   [LoglyticsStatus.FIXED]: LoglyticsStatus.FIXED,
    //   [LoglyticsStatus.IGNORED]: LoglyticsStatus.IGNORED,
    //   '': 'all',
    // };
    // let status = this.props.loglyticsStore!.loglyticsStatus;
    const { affectedDevices } = dashboardStore;
    let dateSpan = new Time().getLongDate();
    if (!!affectedDevices && affectedDevices.length > 0) dateSpan = new Time(affectedDevices[0].time).getLongDate() + ' - ' + new Time(affectedDevices[affectedDevices.length - 1].time).getLongDate();

    return (
      <div className={styles.component}>
        <div className={styles.header}>
          <div className={styles.key}>Severity: </div>
          {memberStore.app!.platform === Platform.ANDROID && <SeverityButton severity={Exception.EXCEPTION}/>}
          <SeverityButton severity={Severity.ERROR}/>
          <SeverityButton severity={Severity.WARNING}/>
          <div className={styles.dateSpan}>{dateSpan}</div>
          {/* <div className={styles.versionLabel}>Issue status:</div>
          <Select
            value={status}
            nameValues={statusValues} 
            onChange={this.changeStatus}/>
          <div className={styles.moreMenu}>
            <img src="/img/loglytics/more.svg" onClick={() => this.setState({showMore: !this.state.showMore})}/>
            {this.state.showMore && <div className={styles.moreMenuContent}>
              <div onClick={this.markAllAsRead}>Mark all as read</div>
            </div>}  
          </div> */}
        </div>
        <MainGraph />
        <div className={styles.loglyticsList}>
          <LoglyticsList />
        </div>
      </div>
    );
    //   // <div>
    //   // </div>
    // );
  }
}

export default observer(Loglytics)