import ConnectionService, { HttpMethod } from './connection.service';
// import { ILoglytics, LoglyticsStatus } from '../../models/loglytics.model';
import { ILoglytics } from '../../models/loglytics.model';

class LoglyticsConnection {
  public async get(data: object): Promise<ILoglytics[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/loglytics`, HttpMethod.GET, data);
  }

  public async getOne(key: string): Promise<ILoglytics> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/loglytics/${key}`);
  }

  // public async read(loglyticsIds: string[]): Promise<ILoglytics[]> {
  //   return await ConnectionService.fetch(`${ConnectionService.appUrl}/loglytics/read`, HttpMethod.POST, loglyticsIds);
  // }

  // public async unread(loglyticsIds: string[]): Promise<ILoglytics[]> {
  //   return await ConnectionService.fetch(`${ConnectionService.appUrl}/loglytics/unread`, HttpMethod.POST, loglyticsIds);
  // }

  // public async unreadCount(): Promise<{exception: number, error: number, warning: number}> {
  //   return await ConnectionService.fetch(`${ConnectionService.appUrl}/loglytics/countUnread`);
  // }


  // public async updateStatus(loglyticsId: string, status: LoglyticsStatus): Promise<ILoglytics> {
  //   const data = {status};
  //   return await ConnectionService.fetch(`${ConnectionService.appUrl}/loglytics/${loglyticsId}`, HttpMethod.PUT, data);
  // }

}

export default new LoglyticsConnection();