import ConnectionService, { HttpMethod } from './connection.service';
import memberStore from '../../stores/member.store';
import IPlan from '../../models/plan.model';
import { ISubscriptionGroup } from '../../models/subscription_group.model';
import IAccount from '../../models/account.model';

class AccountConnection {
  public async createAccount(name: string): Promise<IAccount> {
    const data = { name };
    return await ConnectionService.fetch('members/me/accounts',  HttpMethod.POST, data);
  }

  public async updateAccount(data: object): Promise<IAccount> {
    return await ConnectionService.fetch(`members/me/accounts/${memberStore.accountId}`, HttpMethod.PUT, data);
  }
  public async getSubscriptionGroups(): Promise<ISubscriptionGroup[]>{
    return await ConnectionService.fetch(`members/me/subscriptionGroups`);
  }
  public async suppressEmail(data: object): Promise<ISubscriptionGroup[]>{
    return await ConnectionService.fetch(`members/me/suppressEmail`, HttpMethod.POST, data);
  }

  public async getPlanOptions(): Promise<IPlan[]> {
    return await ConnectionService.fetch(`members/me/accounts/${memberStore.accountId}/planOptions`);
  } 

  public async getCurrentPlan(): Promise<IPlan> {
    return await ConnectionService.fetch(`members/me/accounts/${memberStore.accountId}/plan`);
  } 
}

export default new AccountConnection();