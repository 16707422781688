import React, { useEffect } from 'react';
import styles from './user_settings_refer_a_friend.module.css';
import { observer } from 'mobx-react';
import memberStore from '../../stores/member.store';
import IMember, { memberIcon } from '../../models/member.model';
import IReferral, { ReferralStatus } from '../../models/referral.model';
import CopyToClipboard from 'react-copy-to-clipboard';
import Time from '../../services/time.service';
import copy from '../../img/user_settings/copy.svg';
import email from '../../img/user_settings/email.svg';
import facebook from '../../img/user_settings/facebook.svg';
import linkedin from '../../img/user_settings/linkedin.svg';
import whatsapp from '../../img/user_settings/whatsapp.svg';
import x from '../../img/user_settings/x.svg';
import bubble from '../../img/user_settings/bubble.png';
import download from '../../img/user_settings/download.svg';

const ReferralRow = observer(({referral}: {referral: IReferral}) => {
  const member = memberStore.member!;
  const reward = member.rewards?.find(r => r.referral == referral._id);
  const referee = referral.referee as IMember;
  const time = new Time(referral.updatedAt);
  const statusClass = referral.status === ReferralStatus.PAID ? styles.paid : styles.registered;
  return (
    <div className={styles.referralRow}>
      <div className={styles.referralRowName}>{referee.fullName}</div>
      <div className={styles.referralRowEmail}>{referee.email}</div>
      <div className={`${styles.referralRowStatus} ${statusClass}`}>{referral.status}</div>
      <div className={styles.referralRowDate}>{time.getShortDate()}</div>
      {referral.status === ReferralStatus.PAID && !!reward &&
      <>
        <a href={reward.link} className={styles.referralRowAmount} target="_blank">
          <img src={download} />
          <div>${reward.value}</div>
        </a>
        
        <div className={styles.referralRowGift}>Gift Card</div>
      </>
      }
    </div>
  );
});

const UserSettingsReferAFriend = observer(() => {
  const member = memberStore.member!;
  const {referrals} = memberStore;
  useEffect(() => {
    memberStore.getReferrals();
  }, []); 

  const shipbookReferrerUrl = `https://console.shipbook.io/register?referral=${member.referralCode}`;
  const message = `Join Shipbook with my code ${member.referralCode} and get a $25 Gift Card! Dive into smart logging with us 📊. Sign up here: ${shipbookReferrerUrl}.`

  return (
    <div className={styles.component}>
      <div className={styles.title}>Refer A Friend</div>
      <div className={styles.referral}>
        <img className={styles.memberIcon} src={memberIcon(member)} alt="icon" />
        <div className={styles.referralCodeTitle}>Your referral code <span>*</span></div>
        <div className={styles.referralCode}>{member.referralCode}</div>
        <div className={styles.shareTitle}>Share Your Referral Code</div>
        <div className={styles.share}>
          <CopyToClipboard text={message}> 
            <div><img src={copy} alt="copy" /> </div>
          </CopyToClipboard>
          <a href={`mailto:?body=${encodeURIComponent(message)}`}>
            <img className={styles.email} src={email} alt="email" />
          </a>
          {/* linkedin only supports sharing urls, not text */}
          <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(message)}`} target="_blank">
            <img src={linkedin} alt="linkedin" />
          </a>
          <a href={`https://wa.me/?text=${encodeURIComponent(message)}`} target="_blank">
            <img src={whatsapp} alt="whatsapp" />
          </a>
          {/* facebook only supports sharing urls, not text */}
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shipbookReferrerUrl)}`} target="_blank">
            <img src={facebook} alt="facebook" />
          </a>
          <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`} target="_blank">
            <img src={x} alt="x" />
          </a>
        </div>
      </div>
      {referrals.length > 0 &&
        <div className={styles.referralList}>
          <div className={styles.referralListTitle}>Follow the friends you invited</div>
          {referrals.map(referral => <ReferralRow referral={referral} key={referral._id}/>)}
        </div>
      }
      <div className={styles.comment}>
        <div className={styles.commentStar}>* </div> 
        <div>
          <div className={styles.commentText}>Receive a gift card for each friend who signs up and completes a purchase. <br/>
              You’ll earn a $25 gift card <a href='https://www.tremendous.com/catalog/' target="_blank">(Tremendous)</a>,<br/>
              and your friends will also receive a $25 gift card. <br/>
              All this happens with your friend’s first payment in the Shipbook system. <br/>
              <br/>
              {/* <a href='https://shipbook.io'>Read More…</a> */}
          </div>
          <img className={styles.commentBubble} src={bubble}/>
        </div>
      </div>

    </div>
  );
});
export default UserSettingsReferAFriend;
