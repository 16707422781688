import * as React from 'react';
import styles from './user_settings_profile.module.css';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

//import Icon from '../common/icon.component';
import memberStore from './../../stores/member.store';
import Popup, { PopupInfo } from '../common/popup.component';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/agate';

import pen from '../../img/preferences/pen.svg';
import { icon, memberIcon, memberIconList } from '../../models/member.model';
import ResetSubmitButton from '../reset_submit_button.component';

const PASSWORD_NOT_CHANGED = "***********";

interface State {
  memberInfo:{
    icon: string;
    email: string;
    phone: string;
    password?: string;
    fullName: string;
  },
  iconSelected?: string;
  showIconSelector:boolean;

}

export default class UserSettingsProfile extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    const {member} = memberStore;
    if (member)
    {
      this.state = {
        memberInfo:{
          email: member.email,
          password: PASSWORD_NOT_CHANGED,
          fullName: member.fullName,
          phone: member.phone,
          icon: member.icon
        },
        showIconSelector:false
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditIcon = this.handleEditIcon.bind(this);
    this.handleSaveSelectIcon = this.handleSaveSelectIcon.bind(this);
    this.handleCancelSelectIcon = this.handleCancelSelectIcon.bind(this);
    
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleChange(event: any) {
    let value = event.target.value as string;
    
    const memberInfo  = { ...this.state.memberInfo};
    if (event.target.name === 'password' && memberInfo.password === PASSWORD_NOT_CHANGED) value = '';
    memberInfo[event.target.name] = value;
    this.setState({
      memberInfo
    });
  }

  private handlePhoneChange(phone: string) {
    const memberInfo  = { ...this.state.memberInfo};
    memberInfo.phone = phone;
    this.setState({
      memberInfo
    });
  }

  private handleReset() {
    const {member} = memberStore;
    this.setState({
      memberInfo:{
        icon: memberIcon(member!)!,
        email: member!.email,
        password: PASSWORD_NOT_CHANGED,
        fullName: member!.fullName,
        phone: member!.phone
      }
    })

  }
  private validateEmail(email: string) {
    //  eslint-disable-next-line no-control-regex
    const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    return re.test(email);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async handleSubmit(event: any) {
    event.preventDefault();
    if((this.state.memberInfo.fullName||'').length===0) {
      Popup.createError({msg: 'Username must be supplied'}); 
      return;
    }
    if(!this.state.memberInfo.email) {
      Popup.createError({msg: 'Email must be supplied'}); 
      return;
    }
    else if (!this.validateEmail(this.state.memberInfo.email)){
      Popup.createError({msg: 'Email is not valid'}); 
      return;
    }
    const updateInfo = {...this.state.memberInfo};
    if(updateInfo.password===PASSWORD_NOT_CHANGED){
      delete updateInfo.password;
    }
    await memberStore.updateMyMemberDetails(updateInfo);
    const info: PopupInfo = {
      msg: `Your details have been succesfully updated`,
    };
    Popup.create(info);
  }
  private handleEditIcon(){
    this.setState({
      iconSelected: this.state.memberInfo.icon,
      showIconSelector:true
    });
  }
  private handleSaveSelectIcon(){
    const memberInfo = this.state.memberInfo;
    memberInfo.icon = this.state.iconSelected!;
    this.setState({
      memberInfo,
      showIconSelector:false,
      iconSelected:undefined
    });
  }
  private handleCancelSelectIcon(){
    this.setState({
      showIconSelector:false,
      iconSelected:undefined
    });
  }
  render() {
    if (!this.state) return null;
    return (
      <div className={styles.component}>
        <form onSubmit={this.handleSubmit} onReset={this.handleReset}>
          {this.state.showIconSelector && 
            <div className={style.icon_selector_holder}>
             <div className={styles.icon_selector}>
               <div>
                 <div style={{float:'left',marginRight:10}}>
                    <img className={styles.userBorder} alt='icon' src={icon(this.state.iconSelected)}/>
                 </div>
                 <div className={styles.icon_selector_list} >
                  {
                    memberIconList.map((icon, index)=> {
                      return <img  className={`${styles.userBorder} ${styles.clickable}` } key={icon} onClick={()=>{
                        this.setState({iconSelected: `icon-${index + 1}.svg`});
                      }} alt='icon' src={icon}/>
                    })
                  }
                 </div>
               </div>
                <div className={styles.icon_selector_button_holder} >
                  <input className={styles.reset_small} type="reset" onClick={this.handleCancelSelectIcon} value="Cancel" />
                  <input className={styles.submit_small}  type="submit" onClick={this.handleSaveSelectIcon}  value="OK" />
                </div>
              </div>
            </div>}
          <div className={styles.title}>
            User Profile
          </div>
          <div>
            <div className={styles.icon_holder} onClick={this.handleEditIcon} >
              <img className={styles.userBorder} alt='icon' src={icon(this.state.memberInfo.icon)}/>
              <img className={styles.img_pen} alt="pen" src={pen}/>
            </div>
            <div style={{display:'inline-block',minWidth:450}}>
              <table  className={styles.table}>
                <tbody>
                  <tr>
                    <td style={{width:50}}>
                      User Name
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input  type="text" name="fullName" placeholder="User Name"
                        value={this.state.memberInfo.fullName} onChange={this.handleChange} />{(this.state.memberInfo.fullName)==='' && <span  className={styles.required_indicator}>* required</span>}
                    </td>
                  </tr>
                </tbody>
              </table> 
                {/* <div>User Name *</div>
                <div><input type="text" name="fullName" placeholder="User Name" 
                    value={this.state.fullName} onChange={this.handleChange} /></div> */}
            </div>
          </div>

          <hr className={styles.seperator}/>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>
                  Phone
                </td>
                <td>
                  <PhoneInput country={'us'} value={this.state.memberInfo.phone} onChange={this.handlePhoneChange} placeholder="Phone" 
                      inputStyle = {{width: 400, backgroundColor: "var(--light-grey)", borderColor: "transparent", borderRadius: 4 }}
                      buttonStyle= {{ backgroundColor: "var(--light-grey)", borderColor: "transparent", borderRadius: 4}}                 
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Email
                </td>
                <td>
                  <input type="email" name="email" placeholder="Email" disabled={true}
                    value={this.state.memberInfo.email} onChange={this.handleChange}/>{(this.state.memberInfo.email)==='' && 
                      <span  className={styles.required_indicator}>* required</span>}
                </td>
              </tr>
              <tr>
                <td>
                  Password
                </td>
                <td>
                  <input type="password" name="password"  
                    value={this.state.memberInfo.password} onChange={this.handleChange}/>{(this.state.memberInfo.password)==='' && <span  className={styles.required_indicator}>* required</span>}
                </td>
              </tr>
            </tbody>
          </table>
          <ResetSubmitButton />
        </form>
      </div>
    );
  }
}