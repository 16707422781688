import React from 'react';
import { ParamsObject } from "../../services/param.service";
import styles from './search.module.css';
import { Exception, Severity, SeverityExc, SeverityExcUtil } from '../../models/log.model';
import blackFlag from '../../img/preferences/black-flag-mini.svg';
import wheel from '../../img/preferences/wheel-mini.svg';
import buoy from '../../img/preferences/buoy-mini.svg';
import anchor from '../../img/preferences/anchor-mini.svg';
import compass from '../../img/preferences/compass-mini.svg';
import sandClock from '../../img/preferences/sand-clock-mini.svg';

const KeyValue = ({k, v}: {k: string, v: string}) => {
  return <div className={styles.keyValue}>{k}: {v}</div>
};

export const KeyValueList = ({search, className}: {search?:ParamsObject, className?: string}) => {
  if (!search) return null;
  const searchObj = {... search};
  delete searchObj['min_severity'];
  delete searchObj['severity'];
  delete searchObj['type'];
  delete searchObj['limit'];
  const keys = Object.keys(searchObj);
 
  const keyValueArray : {key:string, value:string}[] = [];
  Object.entries(searchObj).map((obj) => {
    if (Array.isArray(obj[1])) obj[1].forEach(value => keyValueArray.push({key: obj[0], value}));
    else keyValueArray.push({key: obj[0], value: obj[1]});
  });

  let amountDeleted = 0;
  if (keyValueArray.length > 2) {
    amountDeleted = keyValueArray.length - 3;
    keyValueArray.splice(3)
  }

  return (
    <div className={`${styles.keyValueList} ${className}`}>
      { keyValueArray.map(kv => <KeyValue key={kv.key} k={kv.key} v={String(kv.value)} />) }
      { amountDeleted > 0 && <div className={styles.keyValue}>+{amountDeleted}</div>}
    </div>
  )
}

const SeverityIcon = ({severity, severityOn}: {severity: SeverityExc, severityOn: boolean}) => {
  let src;
  switch (severity) {
    case Exception.EXCEPTION:
      src = blackFlag;
      break;
    case Severity.ERROR:
      src = wheel;
      break;
    case Severity.WARNING:
      src = buoy;
      break;
    case Severity.INFO:
      src = anchor;
      break;
    case Severity.DEBUG:
      src = compass;
      break;
    case Severity.VERBOSE:
      src = sandClock;
      break;
  } 

  return (      
    <img src={src} className={`${styles.severityIcon} ${severityOn ? '': styles.severityOff}`} />
  )
};

export const Severities = ({search, className}: {search?: ParamsObject, className?: string}) => {
  const severities = SeverityExcUtil.values() as SeverityExc[];
  const severitiesOn = Array(severities.length).fill(false);

  const setSeveritiesOn = (val:  string[] | string, index: number) => severitiesOn[index] = true;

  if (search?.severity) {
    if (typeof search.severity === 'string') {
      setSeveritiesOn(search.severity, severities.indexOf(search.severity as SeverityExc));
    } else {
      search.severity.forEach(s => setSeveritiesOn(s, severities.indexOf(s as SeverityExc)));
    }
  } else if (search?.min_severity) {
    severitiesOn.fill(true, severities.indexOf(search.min_severity as SeverityExc));
  } else {
    severitiesOn.fill(true);
  }

  return (
    <div className={`${styles.severityList} ${className}`}>
      {severities.map((s, i) => <SeverityIcon key={i} severity={s} severityOn={severitiesOn[i]}/>)}
    </div>
  );
}