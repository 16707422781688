import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './validation.module.css';
// import authStore from '../../stores/auth.store';
import memberStore from '../../stores/member.store';
import commonStore from '../../stores/common.store';
import Popup, { PopupInfo } from '../common/popup.component';
import AuthTemplate from './auth_template.component';
import trackAnalytic from '../analytics';

import logoAhoy from '../../img/logos/shipbook_ahoy.svg';

interface State {
  email?: string;
}

class Validation extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      email: memberStore.member?.email
    }
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
    document.getElementsByTagName('meta')['viewport'].content = 'width=device-width, initial-scale=1';
  }

  async componentDidMount() {
    this.setState({email: memberStore.member?.email})
  }
  componentDidUpdate() {
    const email = memberStore.member?.email;
    if (this.state.email === undefined && email) this.setState({email: memberStore.member?.email})
  }

  handleEmailChange(event: React.FormEvent<HTMLInputElement>) {
    this.setState({email: event.currentTarget.value});
  }

  async resendEmail() {
    commonStore.setLoading(true);
    if (this.state.email !== memberStore.member?.email) { // update email
      const data = {email: this.state.email};
      await memberStore.updateMyMemberDetails(data);
    }
    await memberStore.sendValidateEmail();
    commonStore.setLoading(false);
    const info: PopupInfo = {
      title: 'Resent',
      msg: `The verification email was resent to email ${this.state.email}.`,
      ok: "ok",
    };
    Popup.create(info);
  }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClickChat(event: any) {
      event.preventDefault();
      trackAnalytic.openChat();
    }  

  render() {
    return (
      <AuthTemplate type="bridge">
        <div className={styles.container}>
          <img className={styles.shipbookColorLogo} src={logoAhoy} alt="shipbook ahoy"/>
          <div className={styles.title}> Welcome to Shipbook</div>
            <div className={styles.body}>
              You are just one step away from becoming a member of Shipbook’s revolutionary logging platform. 
              Before you get started, we’ve just sent you a link to verify your account to the email below. 
            </div>
            <div className={styles.email}>
              <label>Work Email </label>
              <input className={styles.input} type="email" name="email"
                autoComplete="email" value={this.state.email} onChange={this.handleEmailChange} />
            </div>
            <div className={styles.resend}>
              <div>Email didn't arrive? Click and we will resend it.</div>
              <input className={styles.submit} type="submit" value="Resend Email" onClick={this.resendEmail} />
            </div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <div className={styles.problem}> If the problem persists, please <a onClick={this.onClickChat}>chat with us</a></div>
        </div>
      </AuthTemplate>
    );
  }

}

export default observer(Validation);
