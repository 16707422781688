import * as React from 'react';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react';
import styles from './forgot_password.module.css';
import authStore from '../../stores/auth.store';
import Popup, { PopupInfo } from '../common/popup.component';

interface State {
  email: string;
}

class ForgotPassword extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      email: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
    // tslint:disable-next-line:no-string-literal
    document.getElementsByTagName('meta')['viewport'].content = 'width=device-width, initial-scale=1';
  }
  
  validateForm() {
    return this.state.email.length > 0;
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    this.setState({email: event.currentTarget.value});
  }  

  async handleSubmit (event?: React.FormEvent<HTMLFormElement>) {
    if (event) event.preventDefault();
    console.debug('handle submit');
    try {
      await authStore.forgotPassword(this.state.email);
      const info: PopupInfo = {
        msg: `If a Shipbook account exists for ${this.state.email}, an e-mail will be sent with further instructions.`,
      };
      Popup.create(info);
    } catch (error) { 
      console.log(error);
    }
  }

  render() {
    const {error} = authStore;
    const errorMsg = 'There was an error with the email';
    return (
      <div className={styles.container}>
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <div className={styles.title}>Forgot your password?</div>
          <div className={styles.label}>Enter your email address to reset your password. </div>
          <input className={styles.input} type="email" name="email"  placeholder="Email" 
            autoComplete="email" value={this.state.email} onChange={this.handleChange} />
          {!!error && <label className={styles.error}>{errorMsg}</label>}
          <input className={styles.submit} type="submit" value="Send" disabled={!this.validateForm()} />
          <Link to="/login" className={styles.login}>Login</Link>
        </form>
      </div>
    );
  }
}

export default observer(ForgotPassword);