import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import Member from '../models/member.model';
import IApp from '../models/app.model';
import IReferral from '../models/referral.model';

import ConnectionService from '../services/connection/connection.service';
import commonStore from './common.store';
import IAccount, { IAuthKey, AuthKeyType } from '../models/account.model';
import trackAnalytic from '../components/analytics';
import IPlan from '../models/plan.model';
import loglyticsStore from './loglytics.store';
import IApiKeyCreate from '../models/dto/apikey_create.model';
import { ISubscriptionGroup } from '../models/subscription_group.model';
import AccountConnection from '../services/connection/account.connection';
import memberConnection from '../services/connection/member.connection';
import alertsStore from './alerts.store';
import { debug } from 'console';

export class MemberStore {
  member?: Member;
  referrals: IReferral[] = [];
  accountId?: string;
  appId?: string;
  loadingMember?: boolean;
  currentPlan?: IPlan;
  subscriptionGroups: ISubscriptionGroup[] = [];


  constructor() {
    makeObservable(this, {
      member: observable,
      referrals: observable,
      accountId: observable,
      appId: observable,
      loadingMember: observable,
      currentPlan: observable,
      subscriptionGroups: observable,
      account: computed,
      app: computed,
      pullMember: action,
      getSubscriptionGroups: action,
      suppressEmail: action,
      getCurrentPlan: action,
      addAccountAuthKey: action,
      deleteAccountAuthKey: action,
      updateAccountAuthKey: action,
      updateSlackIntegration: action,
      removeSlackIntegration: action,
      setMember: action,
      forgetMember: action,
      updateMyMemberDetails: action,
      updateAccount: action,
      updateApp: action,
      addApp: action,
      addAccount: action,
      deleteApp: action,
      setAppId: action,
      setAccountId: action
    });
  }


  get account() {
    return this.member?.accounts?.find(account => account._id === this.accountId);
  }

  get app() {
    if (!this.account) return;
    let app = this.account.apps.find(app => app._id === this.appId);
    if (!app && this.account.apps.length > 0) {
      app = this.account.apps[0];
      this.setAppId(app._id);
    }
    return  app;
  }

  async pullMember() {
    this.loadingMember = true;
    commonStore.setLoading(true);
    try {
      const member = await memberConnection.getMember();
      this.setMember(member);
    } catch (err) {
      console.log(err);
      commonStore.setToken(undefined);
      throw err;
    } finally {
      this.loadingMember = false; 
      commonStore.setLoading(false);
    }
  }

  async getSubscriptionGroups() {
    const subscriptionGroups = await AccountConnection.getSubscriptionGroups();
    runInAction(() => this.subscriptionGroups = subscriptionGroups);
  }

  async suppressEmail(data: object) {
    const subscriptionGroups = await AccountConnection.suppressEmail(data);
    runInAction(() => this.subscriptionGroups = subscriptionGroups);
  }

  async getCurrentPlan() {
    this.loadingMember = true;
    try {
      const currentPlan = await AccountConnection.getCurrentPlan();
      runInAction(() => { this.currentPlan = currentPlan; });
    } catch (err) {
      console.log(err);
      commonStore.setToken(undefined);
      throw err;
    } finally {
      this.loadingMember = false; 
    }
  }

  async addAccountAuthKey(name:string, type: AuthKeyType): Promise<IApiKeyCreate> {
    return await ConnectionService.addAccountAuthKey(name, type);
  }

  async deleteAccountAuthKey(keyToBeDeleted:IAuthKey) {
    await ConnectionService.deleteAccountAuthKey(keyToBeDeleted.key);
    return await this.pullMember();
  }

  async updateAccountAuthKey(keyToBeUpdated:IAuthKey, name:string): Promise<void> {
    return await ConnectionService.updateAccountAuthKey(keyToBeUpdated.key, name);
  }


  async updateSlackIntegration(integrationId:string, appId?: string): Promise<void> {
    await ConnectionService.updateSlackIntegration(integrationId, appId);
    return await this.pullMember();
  }
  async removeSlackIntegration(integrationId:string): Promise<void> {
    await ConnectionService.removeSlackIntegration(integrationId);
    return await this.pullMember();
    
  }

  setMember(member: Member) {
    this.member = member;
    trackAnalytic.user(this.member);
    if (member.accounts?.length > 0) {
      this.accountId = member.accounts[0]._id;
      this.setAppId(member.accounts[0].apps.length > 0 ? member.accounts[0].apps[0]._id : undefined)
      console.log(`set member app:${this.app?.name} `)
      this.getCurrentPlan();
    }
    
    loglyticsStore.clear();
    loglyticsStore.getUnreadCount();
  }

  forgetMember() {
    this.member = undefined;
    this.accountId = undefined;
    this.appId = undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async updateMyMemberDetails(updateInfo:any) {
    await memberConnection.updateMember(updateInfo);
    this.pullMember();
  }

  updateAccount(account: IAccount) {
    for (let i = 0; i < this.member!.accounts.length; i++) {
      if (this.member!.accounts[i]._id === account._id) {
        this.member!.accounts[i] = account;
        break;
      }
    }
  }

  updateApp(app: IApp) {
    if (!this.account) return;
    for (let i = 0; i < this.account.apps.length; i++) {
      if (this.account.apps[i]._id === app._id) {
        this.account.apps[i] = app;
        this.setAppId(app._id);
        return;
      }
    }
  }

  addApp(app: IApp) {
    this.account!.apps.push(app);
    this.setAppId(app._id);
  }

  addAccount(account: IAccount) {

    if (!this.member!.accounts) this.member!.accounts = [account];
    else this.member!.accounts.push(account);
    this.accountId = account._id;
  }

  deleteApp(appId: string) {
    if (!this.account) return;
    for (let i = 0; i < this.account.apps.length; i++) {
      if (this.account.apps[i]._id === appId) {
        this.account.apps.splice(i, 1);
        console.log('removed log', this.account.apps);
        return;
      }
    }
  }

  setAppId(appId?: string) {
    console.debug('set current app:', appId);
    if (!appId) {
      this.appId = undefined;
      return;
    }
    if (this.appId === appId) return;
    for (const account of this.member!.accounts) {
      const app = account.apps.find((app) => (app._id === appId));
      if (app) {
        this.accountId = account._id;
        this.appId = appId;
        loglyticsStore.clear();
        loglyticsStore.getUnreadCount();
        alertsStore.getUnreadCount();
        console.log(`set current app - appId: ${appId} app name:${app.name} `)
        return;
      }
    }
    this.appId = undefined; // in case that It didn't find the app.
  }

  setAccountId(accountId: string) {
    console.debug('set current account:', accountId);
    if (this.accountId === accountId) return;
    const account = this.member!.accounts.find(account => account._id === accountId);
    if (!account) { // doesn't have such account
      this.accountId = undefined;
      this.appId = undefined;
      return;
    }

    this.accountId = accountId;
    // this.setAppId(account.apps[0]._id); The app id will be set by the app route that will call setAppId
  }

  async sendValidateEmail() {
    if (!this.member) return;
    await ConnectionService.sendValidateEmail(this.member._id);
  }

  async getReferrals() {
    if (!this.member) return;
    const referrals = await memberConnection.getReferrals();
    runInAction(() => this.referrals = referrals);
  }
}
export default new MemberStore();