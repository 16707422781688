import { runInAction, observable, action, computed, toJS, makeObservable } from 'mobx';
import * as _ from 'lodash';

import IAccount from '../models/account.model';
import memberStore from './member.store';
import ConnectionService from '../services/connection/connection.service';
import { IMemberRole, Role } from '../models/account.model';
import IInvite from '../models/invite.model';
import commonStore from './common.store';
import IPlan from '../models/plan.model';
import AccountConnection from '../services/connection/account.connection';

export class PreferencesStore {
  get role() {
    if (!memberStore.member) return undefined;
    if (!memberStore.account) return undefined;
    const member = memberStore.account.members.find((obj) => obj.member === memberStore.member!._id);
    if (!member) return undefined;
    return member.role;
  }

  members: IMemberRole[] = [];
  invites: IInvite[] = [];

  constructor() {
    makeObservable(this, {
      role: computed,
      members: observable,
      invites: observable,
      updateAccount: action,
    });
  }

  /* tslint:disable:no-string-literal */
  async updateAccount(newAccount: IAccount) {
    commonStore.setLoading(true);
    const account = memberStore.account!;
    const data = {};
    if (newAccount.name !== account.name) data['name'] = newAccount.name;
    if (newAccount.companyName !== account.companyName) data['companyName'] = newAccount.companyName;
    if (!_.isEqual(newAccount.address, account.address)) data['address'] = newAccount.address;
    if (newAccount.phone !== account.phone) data['phone'] = newAccount.phone;
    if (newAccount.taxId !== account.taxId) data['taxId'] = newAccount.taxId;
    if (!_.isEqual(newAccount.invoiceEmails, toJS(account.invoiceEmails))) data['invoiceEmails'] = newAccount.invoiceEmails;
    if (Object.keys(data).length === 0) {
      commonStore.setLoading(false);
      console.log('there were no changes');
      return;
    }

    const retAccount: IAccount = await AccountConnection.updateAccount(data);

    // adding the information that wasn't updated. So that It will have more than id.
    retAccount.apps = memberStore.account!.apps; 
    retAccount.members = memberStore.account!.members;
    
    memberStore.updateAccount(retAccount);
    commonStore.setLoading(false);
  }
  

  async getMemberRoles() {
    if (!memberStore.account) return;
    const members = await ConnectionService.getMemberRoles(memberStore.account._id);
    runInAction(() => this.members = members);
  }

  async getInvites() {
    if (!memberStore.account) return;
    const invites = await ConnectionService.getInvites(memberStore.account._id);
    runInAction(() => this.invites = invites);
  }

  async deleteInvite(inviteId: string) {
    if (!memberStore.account) return;
    await ConnectionService.deleteInvite(memberStore.account._id, inviteId);
    this.getInvites();
  }
  
  async resendInvite(inviteId: string) {
    if (!memberStore.account) return;
    await ConnectionService.resendInvite(memberStore.account._id, inviteId);    
  }
  
  async addMemberRole(email: string, role: Role) {
    if (!memberStore.account) return;
    await ConnectionService.addMemberRole(memberStore.account._id, {email, role});
    this.getMemberRoles();
    this.getInvites();
  }

  async changeMemberRole(memberId: string, role: Role) {
    if (!memberStore.account) return;
    await ConnectionService.changeMemberRole(memberStore.account._id, memberId, {role});
  }

  async deleteMemberRole(memberId: string) {
    if (!memberStore.account) return;
    await ConnectionService.deleteMemberRole(memberStore.account._id, memberId);
    this.getMemberRoles();
  }

  async subscription(plan: IPlan) {
    commonStore.setLoading(true);
    try {
      const account = await ConnectionService.subscription(memberStore.accountId!, plan._id);
      runInAction(() => memberStore.account!.subscription = account.subscription);  
      return account;
    }
    catch (e) {
      return undefined;
    }
    finally {
      commonStore.setLoading(false);
    }
  }
}

export default new PreferencesStore();