import { observable, action, runInAction, makeObservable } from 'mobx';
import Cookies from 'js-cookie'

import ConnectionService, { ConnectionError } from '../services/connection/connection.service';

import commonStore from './common.store';
import memberStore from './member.store';
import trackAnalytic from '../components/analytics';
import {ITracking} from '../models/member.model';
import AuthConnection, { Provider } from '../services/connection/auth.connection';
import accountConnection from '../services/connection/account.connection';
import storageService from '../services/storage.service';
import { Role } from '../models/account.model';
import memberConnection from '../services/connection/member.connection';

export interface Invite {
  email: string;
  role: Role;
}

export class AuthStore {
  constructor() {
    makeObservable(this, {
      error: observable,
      values: observable,
      setTracking: action,
      setReferralCode: action, 
      setFullName: action,
      setEmail: action,
      setPassword: action,
      setPhone: action,
      setToken: action,
      setCompanyName: action,
      setAppName: action,
      clearError: action,
      reset: action,
      forgotPassword: action,
      changePassword: action,
      login: action,
      register: action,
      validate: action,
      logout: action


    });
  }
  error?: Error | ConnectionError = undefined;

  values: {
    fullName: string,
    email: string,
    password: string,
    phone: string,
    companyName: string,
    appName: string,
    referralCode?: string,
    tracking?: ITracking
  } = {
    fullName: '',
    email: '',
    password: '',
    phone: '',
    companyName: '',
    appName: ''
  };

  setTracking(tracking?: ITracking) {
    this.values.tracking = tracking;
  }
  
  setReferralCode(referralCode?: string) {
    this.values.referralCode = referralCode;
  }

  get inviteToken(): string | undefined {
    const inviteToken = storageService.getItem('inviteToken', '');
    if (inviteToken.length === 0) return undefined;
    return inviteToken;
  }

  set inviteToken(inviteToken: string | undefined) {
    if (!inviteToken) storageService.removeItem('inviteToken');
    else storageService.setItem('inviteToken', inviteToken);
  }

  setFullName(fullName: string) {
    this.values.fullName = fullName;
  }

  setEmail(email: string) {
    this.values.email = email;
  }

  setPassword(password: string) {
    this.values.password = password;
  }

  setPhone(phone: string) {
    this.values.phone = phone;
  }

  setToken(token: string) {
    this.inviteToken = token;
  }

  setCompanyName(companyName: string) {
    this.values.companyName = companyName;
  }
  
  setAppName(appName: string) {
    this.values.appName = appName;
  }

  clearError() {
    this.error = undefined;
  }

  reset() {
    this.values.email = '';
    this.values.password = '';
    this.values.phone = '';
  }

  async forgotPassword(email: string) {
    commonStore.setLoading(true);
    this.error = undefined;
    try {
      await AuthConnection.forgotPassword(email);
    } catch (error) {
      runInAction(() => this.error = <Error | ConnectionError> error);
      throw error;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  async changePassword(password: string, token: string) {
    commonStore.setLoading(true);
    this.error = undefined;
    try {
      await AuthConnection.changePassword(password, token);
    } catch (error) {
      runInAction(() => this.error = <Error | ConnectionError> error);
      throw error;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  async login() {
    this.error = undefined;
    commonStore.setLoading(true);
    try {
      const respLogin = await AuthConnection.login(this.values.email, this.values.password);
      commonStore.setToken(respLogin.token);
      await memberStore.pullMember();
    } catch (error) {
      runInAction(() => this.error = <Error | ConnectionError> error);
      throw error;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  // tslint:disable-next-line:no-empty
  async register() {
    this.error = undefined;
    commonStore.setLoading(true);
    try {
      const resp = await AuthConnection.register(this.values.fullName, this.values.email, this.values.password, 
        this.values.phone, this.inviteToken, this.values.referralCode, this.values.tracking);
      commonStore.setToken(resp.token);
      await memberStore.pullMember();
      trackAnalytic.register(memberStore.member!._id);
    } catch (error) {
      runInAction(() => this.error = <Error | ConnectionError> error);
      throw error;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  async token(token: string, provider: Provider) {
    commonStore.setLoading(true);
    try {
      const resp = await AuthConnection.token(token, provider, this.inviteToken, this.values.referralCode, this.values.tracking) ;
      commonStore.setToken(resp.token);
      await memberStore.pullMember();
      if (!memberStore.account) trackAnalytic.register(memberStore.member!._id);
    } catch (error) {
      runInAction(() => this.error = <Error | ConnectionError> error);
      throw error;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  async validate(token: string) {
    this.error = undefined;
    commonStore.setLoading(true);
    try {
      await AuthConnection.validate(token);
      await memberStore.pullMember();
    } catch (error) {
      runInAction(() => this.error = <Error | ConnectionError> error);
      throw error;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  async setAccount(companyName: string, invites: Invite[], phone: string) {
    this.error = undefined;
    commonStore.setLoading(true);
    try {
      if (phone.length > 0) {
        const member = await memberConnection.updateMember({phone});
        memberStore.setMember(member);
      }
      let {account} = memberStore;
      if (account) {
        if (account.name != companyName) {
          account.name = companyName;
          await accountConnection.updateAccount(account)
        }
      }
      else {
        account = await accountConnection.createAccount(companyName);
        memberStore.addAccount(account);
        await AuthConnection.refreshToken(); 
      }

      for (const invite of invites) {
        await ConnectionService.addMemberRole(account._id, invite);
      }
      
      return {account}
    } catch (error) {
      runInAction(() => this.error = <Error | ConnectionError> error);
      throw error;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  logout() {
    commonStore.reset();
    memberStore.forgetMember();
  }
}

export default new AuthStore();
