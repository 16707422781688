import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import trackAnalytic from '../analytics';

import { observer } from 'mobx-react';
import styles from './validate.module.css';
import authStore from '../../stores/auth.store';

import logo from '../../img/logos/shipbook_text.svg';

interface State {
  json: {};  
}

class Validate extends React.Component<RouteComponentProps<{}>, State> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);

    this.state = {
      json: {}
    };
    document.getElementsByTagName('meta')['viewport'].content = 'width=device-width, initial-scale=1';
  }

  async componentDidMount() {
    const search = this.props.location!.search;
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');
    if (token) {
      await authStore.validate(token!);
      this.props.history.replace('/');
    }
  }
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickChat(event: any) {
    event.preventDefault();
    trackAnalytic.openChat();
  }
  
  render() {
    const  { error } = authStore;
    if (!error) return null;
    return (
      <div className={styles.container}>
        <img className={styles.shipbookColorLogo} src={logo} alt="logo"/>
        <div className={styles.title}> This Token Has Expired </div>
        <div className={styles.body}>Please Log in to Shipbook and you'll get a new validation email.</div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <div className={styles.body}> If the problem persists, please <a onClick={this.onClickChat}>chat with us</a></div>
        <Link to="/" className={styles.login}>Go to Shipbook console &gt;</Link>
      </div>
    );
  }
}

export default withRouter(observer(Validate));
