import * as React from 'react';
import {  withRouter, RouteComponentProps } from 'react-router-dom';

import styles from './app_wizard.module.css';
import PrefAppWizard from '../pref_app/pref_app_wizard.component';
import PrefAppDoc from '../pref_app/pref_app_doc.component';
import logo from '../../img/shipbook-logo-circle.svg';

class AppWizard extends React.Component<RouteComponentProps<{}>> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    document.getElementsByTagName('meta')['viewport'].content = 'width=device-width, initial-scale=1';
  }

  render() {
    const newWiz = this.props.location.pathname.endsWith('/new')
    return (
      <div className={styles.container}>
        <img className={styles.shipbookColorLogo} src={logo} alt="logo"/>
        { newWiz ? <PrefAppWizard /> : <PrefAppDoc /> }
      </div>
    );
  }
}

export default withRouter(AppWizard);
