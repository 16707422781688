import * as React from 'react';
import styles from './sharebtn.module.css';
import { observer } from 'mobx-react';
import shareStore, { ShareType } from './../../stores/share.store';
import memberStore from '../../stores/member.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ConnectionService, { HttpMethod } from '../../services/connection/connection.service';

import share from '../../img/share.svg';
import jira from '../../img/logos/jira.svg';
import slack from '../../img/logos/slack.svg';
import email from '../../img/logos/email.svg';
import copyIcon from '../../img/copy-icon.svg';
import download from '../../img/logos/download.svg';

export enum ShareStyle {
  BIG,
  SMAll
}

interface Props  extends RouteComponentProps<{}> {
    summary: string;
    description: string;
    url: string;
    style?: ShareStyle;
    downloadUrl?: string;
}

class ShareBtn extends React.Component<Props, {show: boolean}> {
  constructor(props: Props) {
    super(props);
    this.state = {show: false};
    this.onClick = this.onClick.bind(this);
    this.shareCopyClipboard = this.shareCopyClipboard.bind(this);
  }

  componentDidUpdate(){
    const {summary, description, downloadUrl} = this.props;
    console.log('update: '+ downloadUrl);
    shareStore!.setJiraShareParams(summary, description, downloadUrl);
  }

  onClick() {
    this.setState({show: !this.state.show});
  }

  async showJiraAuthWindow(){
    if (shareStore.jiraAccessToken) {
      const resp = shareStore.showCreateJiraIssue();
      if (resp) return;
    }

    await shareStore.getAuthUrl();
    const {authUrl} = shareStore;
    const left= ((window.innerWidth -450)/2) ;
    window.open(authUrl,'_new','left=' +left+',resizable=no,height=600,width=450,menubar=no,status=no')          
  }

  async createSlackPost() {
    const {slackIntegration} = memberStore.account!;
    const {appId} = memberStore;
    if (!slackIntegration || slackIntegration.length === 0 || !slackIntegration.find(i => !i.app || i.app === appId )) {
      this.props.history.push(`/${memberStore.accountId}/preferences/integrations`);
      return;
    }
    shareStore.showCreateSlackPost();
  }

  shareEmail(){
    const mail = document.createElement("a");
    const email = memberStore.member?.email;
    mail.href = "mailto:" +email+ "?subject=" + encodeURIComponent(this.props.summary) 
        + '&body=' + encodeURIComponent(this.props.description) ;
    mail.target = '_blank';
    mail.click();
  }

  async shareDownload() {
    if (!this.props.downloadUrl) return;
    const headers = new Headers({Accept: 'text/csv'});
    const res = await ConnectionService.fetch(this.props.downloadUrl, HttpMethod.GET, undefined, headers);
    const blob = new Blob([res], {type: 'file'});
    const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
    a.href = url;
    a.download = 'shipbook.csv';
    a.click();
  }

  shareCopyClipboard() {
    const el = document.createElement('textarea');
    el.value = this.props.url;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  async onChange(value: string) {
    switch (value) {
      case ShareType.JIRA:
        this.showJiraAuthWindow();  
        break;
      case ShareType.SLACK:
        this.createSlackPost();
        break;
      case ShareType.EMAIL:
        this.shareEmail();
        break;

      case ShareType.DOWNLOAD:
        this.shareDownload();
        break;
      case ShareType.COPY:
        this.shareCopyClipboard();
        break;
      default:
        break;
    }
    this.setState({show: false});
  }
  
  render() {
      const nameValues = [ShareType.JIRA, ShareType.SLACK, ShareType.EMAIL, ShareType.COPY];
      const nameIcons = [jira, slack, email, copyIcon];
      if (this.props.downloadUrl) {
        nameValues.push(ShareType.DOWNLOAD);
        nameIcons.push(download);
      }
      let width = 14;
      let height = 14;
      if (this.props.style === ShareStyle.BIG) {
        width = 20;
        height = 20;
      }
      return (
      <div className={styles.dropdown} onClick={this.onClick} style={{height, width}}>
        <img className={styles.extraTriangle}  src={share} alt="share" /> 
          {this.state.show && <div className={styles.dropdownContent}>
          {
              (nameValues).map((name, index) => {
                  return <div key={name} onClick={() => this.onChange(name)}><img src={nameIcons[index]} alt={name}/>{name}</div>;
              })
          }
          </div>}
      </div>
      );
  }
}
export default withRouter(observer(ShareBtn));