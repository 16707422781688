/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import styles from './popup.module.css';

import shell from '../../img/shell.gif';
export interface PopupInfo {
  iconSrc?: string;
  title?: string;
  msg?: string | JSX.Element ;
  ok?: string;
  cancel?: string;
  okCallback?: Function;
  cancelCallback?: Function;  
  okAttr?: Object;
  cancelAttr?: Object;
  nonCancellable?: boolean;
}

interface State {
  info: PopupInfo;
  visible: boolean;
}

export default class Popup extends React.Component<{}, State> {
  private static instance: Popup;
  public static create(newInfo: PopupInfo) {
    const info = {
      ok: 'ok',
      nonCancellable: false,
      iconSrc: shell
    };
    Object.assign(info, newInfo); 
    Popup.instance.setState({info, visible: true});
  }

  public static createError(newInfo: PopupInfo) {
    const info = {
      ok: 'ok',
      nonCancellable: false,
      iconSrc: shell,
      title: 'error'
    };
    Object.assign(info, newInfo); 
    Popup.instance.setState({info, visible: true});
  }

  public static close() {
    Popup.instance.close();
  }

  constructor(props: {}) {
    super(props);
    this.state = { 
      visible: false,
      info: {
        ok: 'ok',
        nonCancellable: false,
        iconSrc: shell
      }
    };
    this.close = this.close.bind(this);
    this.ok = this.ok.bind(this);
    this.cancel = this.cancel.bind(this);
    Popup.instance = this;
  }

  private async ok() {
    if (this.state.info.okCallback) {
      const close = await this.state.info.okCallback();
      if (close === false) return;
    }
    if (this.state.info.nonCancellable) return;
    this.close();
    
  }

  private cancel() {
    if (this.state.info.cancelCallback) this.state.info.cancelCallback();
    this.close();
  }

  private close() {
    if (this.state.info.nonCancellable) return;
    this.setState({visible: false});
  }

  render() {
    if (!this.state.visible) return <div/>;
    const {info} = this.state;
    return (
      <div className={styles.wrapper} onClick={this.close}>
        <div className={styles.popup} onClick={(event) => event.stopPropagation()}>
          {info.iconSrc && 
            <div className={styles.icon}>
              <img src={info.iconSrc}/>
            </div>
          }
          <div className={styles.innerPopup}>
            <div className={styles.title}>{info.title}</div>
            {typeof info.msg === 'string' ? 
              <div className={styles.msg}>{info.msg}</div> : 
              <>{info.msg}</>
            }
            <div className={styles.buttons}>
              {!!info.cancel && 
                <button className={styles.buttonCancel} onClick={this.cancel}  {...info.cancelAttr}>{info.cancel}</button>}
               {!!info.ok &&
                <button className={styles.buttonOk} onClick={this.ok} {...info.okAttr}>{info.ok}</button>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}