import * as React from 'react';

import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import memberStore from '../stores/member.store';

import Logs from '../components/logs/logs.component';
import Dashboard from '../components/dashboard/dashboard.component';
import Loglytics from '../components/loglytics/loglytics.component';
import LoglyticsItem from '../components/loglytics/loglytics_item.component';
import LiveTail from '../components/live_tail/live_tail.component';
import PrefApp from '../components/pref_app/pref_app.component';
import Alerts from '../components/alerts/alerts.component';
import LogChat from '../components/logchat/logchat.component';

export const appRoutes = [
  "/bridge",
  "/logbook",
  "/loglytics",
  "/alerts",
  "/live_tail",
  "/logchat",
  "/preferences"
];

interface Params {
  appId: string;
}
interface Props extends RouteComponentProps<Params> {}
export default class AppRoutes extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const appId =  this.props.match!.params.appId;
    console.debug('AppRoutes - component did mount app: ' + appId);
    memberStore.setAppId(appId);
  }
  componentDidUpdate() {
    const appId =  this.props.match!.params.appId;
    console.debug('AppRoutes - component did update: ' + appId);
    memberStore.setAppId(appId);
  }

  render() {
    return (
      <Switch>
        <Route path="/:accountId/:appId/bridge" component={Dashboard} />
        <Route path="/:accountId/:appId/logbook" component={Logs} />
        <Route path="/:accountId/:appId/loglytics/:key/:sampleId" component={LoglyticsItem} />
        <Route path="/:accountId/:appId/loglytics/:key" component={LoglyticsItem} />
        <Route path="/:accountId/:appId/loglytics" component={Loglytics} />
        <Route path="/:accountId/:appId/alerts" component={Alerts} />
        <Route path="/:accountId/:appId/live_tail" component={LiveTail}/>
        <Route path="/:accountId/:appId/logchat/:chatId" component={LogChat}/>
        <Route path="/:accountId/:appId/logchat" component={LogChat}/>
        <Route path="/:accountId/:appId/preferences" component={PrefApp} />
        
      </Switch>
    );
  }
}