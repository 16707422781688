/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import styles from './log_message_popup.module.css';
import ILog, { IMessage, IException, StackTraceElement, LogType, Severity, IEvent, IScreenEvent, IAppEvent, IConfigEvent, IActionEvent, IActivityEvent, SeverityExcUtil } from '../../models/log.model';
import paramService from '../../services/param.service';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import logsStore, { ItemType } from '../../stores/logs.store';
import Time from '../../services/time.service';
import ISession from '../../models/session.model';
import { Platform } from '../../models/platform.model';
import LoglyticsItemGraph from '../loglytics/loglytics_item_graph.component';
import LoglyticsStore from '../../stores/loglytics.store';

import close from '../../img/close.svg';
import orientationIcon from '../../img/logs/orientation.svg'
import wrapIcon from '../../img/logs/wrap.svg';
interface State {
  platform: Platform | undefined;
  screen: string;
  state: string;
  activity: string;
  orientation: string;
  lastAction: string;
  wrap: boolean;
}
class LogMessagePopup extends React.Component<RouteComponentProps<{}>, State> {
  private readonly severityClasses = new Map<string, string>([
    [Severity.VERBOSE, styles.verbose],
    [Severity.DEBUG, styles.debug],
    [Severity.INFO, styles.info],
    [Severity.WARNING, styles.warning],
    [Severity.ERROR, styles.error]
  ]);

  constructor(props: RouteComponentProps<{}>) {
    super(props);

    this.close = this.close.bind(this);
    this.wrap = this.wrap.bind(this);
    this.add = this.add.bind(this);

    let platform: Platform | undefined;
    let screen = '';
    let state = '';
    let activity = '';
    let orientation = '';
    let lastAction = '';
    let wrap = true;

    const {itemList, messagePopupIndex} = logsStore;
    if (itemList[messagePopupIndex].itemType === ItemType.EXCEPTION ||
      (itemList[messagePopupIndex].data as IMessage).exception?.stackTrace?.length > 0) wrap = false;
    for (let i = messagePopupIndex - 1 ; i >= 0 ; --i ) {
      if (itemList[i].itemType === ItemType.SESSION) {
        platform = (itemList[i].data as ISession).platform;
        break;
      }
      if (itemList[i].itemType === ItemType.EVENT) {
        const event = itemList[i].data as IEvent;
        switch (event.type) {
          case LogType.SCREEN_EVENT: 
            if (screen.length === 0) screen = (event as IScreenEvent).name;
            break;
          case LogType.ACTIVITY_EVENT: 
            if (activity.length === 0) {
              const activityEvent = event as IActivityEvent;
              if (activityEvent.event === 'onActivityCreated') {
                const name = activityEvent.name.slice(activityEvent.name.indexOf('.') + 1);
                const title = activityEvent.title ? `(${activityEvent.title})` : '';
                activity = name + title;
              }
            }
            break;
          case LogType.APP_EVENT:
            if (state.length === 0) state = (event as IAppEvent).state;          
            if (orientation.length === 0) orientation = (event as IAppEvent | IConfigEvent).orientation ?? "";
            break;
          case LogType.CONFIG_EVENT:
            if (orientation.length === 0) orientation = (event as IAppEvent | IConfigEvent).orientation ?? "";
            break;
          case LogType.ACTION_EVENT: 
            if (lastAction.length === 0) {
              const actionEvent = event as IActionEvent;
              lastAction = `[${actionEvent.target} ${actionEvent.action}]\n 
              sender ${actionEvent.sender} (title: ${actionEvent.senderTitle})`;
            }
            break;
          default:
        }
      }
    }

    this.state = { 
      platform, screen, state, activity, orientation, lastAction, wrap
    };
    
    const log = itemList[messagePopupIndex].data as ILog;
    LoglyticsStore.setKey(log.key!);

  }


  private close() {
    logsStore.setMessagePopupIndex(0);
  }

  private wrap() {
    this.setState({wrap: !this.state.wrap});
  }

  private add(type: string, value: string) {
    paramService.setParam(this.props, type, value);
  }

  private stackTraceToString(stackTrace: [StackTraceElement]) {
    let stackTraceString = '';
    stackTrace.forEach((elem) => stackTraceString += `at ${elem.declaringClass}.${elem.methodName}(${elem.fileName}:${elem.lineNumber})\n` );
    return stackTraceString;
  }

  private callStackToString(exception: IException) {
    let str = '';
    if (exception.callStackAnalyzed) {
      for (const cur of exception.callStackAnalyzed) {
        if (cur.dsymFileName) str += `${String(cur.pos).padEnd(4, ' ')}${cur.moduleName.padEnd(36, ' ')}${cur.dsymFunction} (${cur.dsymFileName}:${cur.dsymLineNumber})\n`;
        else str += `${String(cur.pos).padEnd(4, ' ')}${cur.moduleName.padEnd(36, ' ')}${cur.stackAddressHex} ${cur.loadAddressHex} + ${cur.offset} \n`;
      }
      return str;
    }
    else return exception.callStackSymbols?.join('\n') ?? '';
  }

  render() {
    const index = logsStore.messagePopupIndex;
    const {itemList} = logsStore;
    const log = itemList[index].data as ILog;
    const time = new Time(log.time);
    let msgTxt: string; 

    const isException = log.type === LogType.EXCEPTION;
    const exception = log as IException;
    const message = log as IMessage;
    const severityClass = isException ? styles.exception : this.severityClasses.get(message.severity);

    let fileName = '';
    let lineNumber: string | number = 0;
    let functionName = '';
    let logClassName;
    if (isException) {  
      if (exception.stackTrace?.length > 0) {
        fileName = exception.stackTrace[0].fileName;
        lineNumber = exception.stackTrace[0].lineNumber;
        functionName = exception.stackTrace[0].methodName;
        logClassName = exception.stackTrace[0].declaringClass;
      }
      msgTxt = exception.name + ': ' + exception.reason + '\n';
      msgTxt += exception.stackTrace?.length > 0 ? this.stackTraceToString(exception.stackTrace) : 
        this.callStackToString(exception);

    } else {
      fileName = message.fileName.slice(message.fileName.lastIndexOf('/') + 1);
      lineNumber = message.lineNumber;
      functionName =  message.function;
      logClassName = message.className;
      msgTxt = message.messageHighlight ?? message.message;
      if (message.exception && message.exception.name) {
        msgTxt = message.exception.name + ': ' + message.exception.reason + '\n' + 
          this.stackTraceToString(message.exception.stackTrace);
      }
    }

    const {platform, screen, state, activity, orientation, lastAction, wrap} = this.state;
    let degrees = 0;
    switch (orientation) {
      case 'portrait': case 'Portrait': degrees = 0; break;
      case 'landscapeLeft': degrees = 270; break;
      case 'portraitUpsideDown': degrees = 180; break;
      case 'landscapeRight': case 'Landscape': degrees = 90; break;
      default:
    }
    const showGraph = SeverityExcUtil.number(message.severity) <= SeverityExcUtil.number(Severity.WARNING) && 
      !(platform === Platform.IOS && isException) &&
      !this.props.location.pathname.includes('/loglytics/');

    return (
      <div className={styles.component} onClick={this.close}>
        <div className={styles.popup} onClick={(event) => event.stopPropagation()}>
          <img src={close} className={styles.close} onClick={this.close} alt="close"/>
          <div className={styles.header}> 
            <div className={`${styles.severity} ${severityClass}`}/>
            <div className={`${styles.severityRound} ${severityClass}`} />
            <div className={styles.headerGrid}>
              <div className={styles.date} style={{gridRow: 1}}>{time.getLongDate()} </div>
              <div className={styles.time} style={{gridRow: 2}}>{time.getTime()} </div>
              <div  className={styles.location} style={{gridRow: 3}}>{fileName}: {lineNumber} ({functionName})</div>
              <div className={styles.gap} />
              <div className={styles.keyValue} style={{gridRow: 1}}>
                <div className={styles.key}>Tag: </div>
                <div className={styles.value}>{message.tag}</div>
              </div>
              <div className={styles.keyValue} style={{gridRow: 2}}>
                <div className={styles.key}>Thread: </div>
                <div className={styles.value}>{log.threadInfo?.queueLabel} {log.threadInfo?.threadId} {log.threadInfo?.threadName}</div>
              </div>
              {logClassName && <div className={styles.keyValue} style={{gridRow: 3}}>
                <div className={styles.key}>Class: </div>
                <div className={styles.value}>{logClassName}</div>
              </div>}
              <div className={styles.gap}/>
              <div className={styles.key} style={{gridRow: 1}}>Orientation</div>
              <img src={orientationIcon} className={styles.orientation} 
                style={{'transform': `rotate(${degrees}deg)`}} alt="orientation"/>

              {platform === Platform.IOS ?                 
                <div className={styles.keyValue} style={{gridRow: 1}}>
                  <div className={styles.key}>state: </div>
                  <div className={styles.value}>{state}</div>
                </div> :
                <div className={styles.keyValue} style={{gridRow: 1}}>
                  <div className={styles.key}>activity: </div>
                  <div className={styles.value}>{activity}</div>
                </div>
              }
              <div className={styles.keyValue} style={{gridRow: 2}}>
                <div className={styles.key}>screen: </div>
                <div className={styles.value}>{screen}</div>
              </div>

              <div className={styles.keyValue} style={{gridColumn: 3, gridRow: 3}}>
                <div className={styles.key}>Last Action: </div>
                <div className={styles.value}>{lastAction}</div>
              </div>
            </div>
          </div>
          <div className={`${styles.messageText} ${wrap ? styles.messageTextWrap : ''}`}>
            {
              message.messageHighlight ? 
                <span dangerouslySetInnerHTML={{__html : msgTxt}} />: 
                <span>{msgTxt}</span>
            }
          </div>
          { showGraph && <LoglyticsItemGraph popup={true} /> }
          <div className={styles.bottom}>
            <img src={wrapIcon} className={wrap? styles.wrapOn: styles.wrapOff} onClick={this.wrap} alt="wrap"/>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(LogMessagePopup))