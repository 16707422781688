import React, {useEffect} from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './live_tail.module.css';
import LogSearch from '../logs/log_search.component';
import LogBottom from '../logs/log_bottom.component';
import LiveTailView from './live_tail_view.component';
import searchStore from '../../stores/search.store';
import liveTailStore from '../../stores/live_tail.store';

interface Props extends RouteComponentProps<{}> {
}


function LiveTail(props: Props): JSX.Element {
  function params(search: string) {
    console.log('entered params with:', search);
    searchStore.setSearchParams(search);
    if (!liveTailStore.play) return;
    setTimeout(() => {
      liveTailStore.clear();
      liveTailStore!.getLogs();
    }, 0); //timeout so that the parameters will be changed.
  }

  useEffect(() =>{
    const {search} = props.location!;
    params(search);
    const historyListener = props.history!.listen((listener) => {
      if (listener.pathname.endsWith('/live_tail')) params(listener.search); 
    });
    return () => historyListener();
  }, [])

  return (
    <div className={styles.component}>
      <LogSearch liveTail={true}/>
      <LiveTailView />
      <LogBottom liveTail={true}/>
    </div>
  );

}

export default withRouter(LiveTail);