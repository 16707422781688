import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './pref_plan.module.css';
import preferencesStore, { PreferencesStore } from '../../../stores/preferences.store';
import IPlan, { planIcon } from '../../../models/plan.model';
import billingStore, { BillingStore } from '../../../stores/billing.store';
import { Role } from '../../../models/account.model';
import Time from '../../../services/time.service';
import Popup, { PopupInfo } from '../../common/popup.component';
import PrefCreditCard from './pref_credit_card.component';
import memberStore, { MemberStore } from '../../../stores/member.store';

class PrefPlan extends React.Component {
  async componentDidMount() {
    await billingStore.loadPlans();
    billingStore.setCurrentPlan(memberStore.account!.subscription.plan);
  }

  render() {
    const {currentPlan} = billingStore;
    
    const account = memberStore.account!;
    const hasPayablePlan = currentPlan && currentPlan.price > 0 ;
    const {role}  = preferencesStore;
    const disabled = role === Role.developer;

    if (!currentPlan) return null;
    return (
      <div className={styles.planContainer}>
        <Plan plan={currentPlan} selected={true}/>
        {hasPayablePlan && (!account.payments || account.payments.length === 0) &&
          <div className={styles.planTrial}>
            Trial Period Ends: {new Time(account.subscription.nextPayment).getLongDate()}</div>
        }
        <PrefChangePlan>
          <button className={styles.button} type="submit" disabled={disabled}>Change</button>
        </PrefChangePlan>
      </div>
    );
  }
}

export default observer(PrefPlan);
export class PrefChangePlan extends React.Component {
  prefCreditCardSubmit?: Function;

  constructor(props: {}) {
    super(props);
    this.handleUpdateCC = this.handleUpdateCC.bind(this);
    this.handleUpdatePlan = this.handleUpdatePlan.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  handleUpdateCC() {
    this.prefCreditCardSubmit!();
    return false;
  }

  async handleUpdatePlan() {
    const currentPlan = billingStore!.currentPlan!;
    const resp = await preferencesStore!.subscription(currentPlan!);
    if (resp) {
      const needBilling = currentPlan.price > 0 && !memberStore.account!.billing;
      if (needBilling) {
        const okAttr = {
          "data-bluesnap": "submitButton"
        };    
        const info: PopupInfo = {
          iconSrc: undefined,
          msg: ( 
            <PrefCreditCard backgroundWhite={true} 
              setHandleSubmit={(handle: Function) => this.prefCreditCardSubmit = handle}/> 
          ),
          okCallback: this.handleUpdateCC,
          okAttr, 
          cancel: 'Cancel'
        };
        Popup.create(info);
      }
      else {
        const info: PopupInfo = {
          msg: 'Succeeded to change plan to: ' + currentPlan!.name ,          
        };    
        Popup.create(info);
      }
    }
    return false;
  }

  onClick() {
    console.log('pressed change plan');
    const info: PopupInfo = {
      iconSrc: undefined,
      msg: <PlanList />,
      okCallback: this.handleUpdatePlan,
      cancel: 'Cancel'
    };

    Popup.create(info);

  }
  render() {
    return (<span onClick={this.onClick}>
      {this.props.children}
    </span>);
  }
}

interface PlanProps {
  plan: IPlan;
  selected: boolean;
  column?: number;
  choosePlan?: Function;
}

class Plan extends React.Component<PlanProps> {
 render() {
   let {column} = this.props;
   const { plan, selected, choosePlan } = this.props;
   if (column === undefined) column = 0;
   return (
     <>
       <div className={`${styles.planButton} ${selected ? styles.selected : ''}`}
         style={{gridColumn: column}}
         onClick={() => { if (choosePlan) choosePlan(); }}>
         <img alt={plan.name} src={planIcon[plan.icon]}/>
         <div>{plan.name}</div>
       </div>
       <div className={`${styles.planPrice} ${selected ? styles.selected : ''} `}
         style={{gridColumn: column}}>
         $<span>{plan.price}</span>/mo
       </div>
       <div className={`${styles.planDetails} ${selected ? styles.selected : ''}`} 
         dangerouslySetInnerHTML={{__html: plan.description}} style={{gridColumn: column}}/>
     </>
   );
 }
}


const PlanList = observer(class PlanList extends React.Component {
  choosePlan(plan: IPlan) {
    billingStore.setCurrentPlan(plan);
  }
  render() {
    const {plans, currentPlan} = billingStore;
    return (
      <div className={styles.planList}>
        { plans.map((plan, index) => 
            <Plan key={index} plan={plan} column={index + 1} selected={!!currentPlan && currentPlan._id === plan._id} 
              choosePlan={() => this.choosePlan(plan)} />
          )}
      </div>
    );
  }
});