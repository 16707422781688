import ConnectionService, { HttpMethod } from './connection.service';
import IMember, { ITracking } from '../../models/member.model';
import ILogin from '../../models/login.model';
import commonStore from '../../stores/common.store';

export enum Provider {
  GOOGLE = 'google',
  GITHUB = 'github'
}
class AuthConnection {
  public async forgotPassword(email: string) {
    const data = { email };
    return await ConnectionService.fetch('auth/forgotPassword', HttpMethod.POST, data);
  }

  public async changePassword(password: string, token: string) {
    const data = { token, password };
    return await ConnectionService.fetch('auth/changePassword', HttpMethod.POST, data);
  }

  public async login(email: string, password: string): Promise<ILogin> {
    const data = { email, password};

    return await ConnectionService.fetch('auth/login', HttpMethod.POST, data);
  }

  public async register(fullName: string, 
                        email: string, 
                        password: string,
                        phone: string, 
                        inviteToken?: string,
                        referralCode?: string,
                        tracking?: ITracking): Promise<ILogin> {
                          const data = { fullName, email, password, phone, inviteToken, referralCode, tracking};

    return await ConnectionService.fetch('auth/register', HttpMethod.POST, data);
  }

  public async validate(token: string): Promise<IMember> {
    const data = { token };
    return ConnectionService.fetch('auth/validateMemberToken', HttpMethod.POST, data);
  }

  public async refreshToken(): Promise<ILogin> {    
    const data = {
      token: commonStore.token
    };
    const response = await ConnectionService.fetch('auth/refreshToken', HttpMethod.POST, data);
    commonStore.setToken(response.token);
    console.log('answering all responses')
    return response;  
  }

  public async token(token: string, provider: Provider, inviteToken?: string, referralCode?: string, tracking?: ITracking): Promise<ILogin> {
    const data = { token, provider, tracking, inviteToken,  referralCode};
    return await ConnectionService.fetch('auth/token', HttpMethod.POST, data);
  }
}

export default new AuthConnection();