import * as React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import styles from './pref_general.module.css';
import preferencesStore from '../../stores/preferences.store';
import Icon from '../common/icon.component';
import IAccount, { Role, IAddress } from '../../models/account.model';
import Geosuggest from 'react-geosuggest';
import memberStore from '../../stores/member.store';
import PhoneInput from 'react-phone-input-2';
import IMember from '../../models/member.model';
import ResetSubmitButton from '../reset_submit_button.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Script: any = require('react-load-script');

interface State {
  account: IAccount;
  googleLoaded: boolean;
}

class PrefGeneral extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    const {account} = memberStore!;
    if (account) {
      this.state = {
        account: toJS(account),
        googleLoaded: false
      };
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvoiceEmails = this.handleInvoiceEmails.bind(this);
    this.onSuggest = this.onSuggest.bind(this);
    this.removeSlackChannel = this.removeSlackChannel.bind(this);
  }
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleChange(event: any) {
    const {account} = this.state;
    const value = event.target.value as string;
    account[event.target.name] = value;
    this.setState({account});
  }

  private handlePhoneChange(phone: string) {
    const {account} = this.state;
    account.phone = phone;
    this.setState({account})
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleInvoiceEmails(event: any) {
    const {account} = this.state;
    const value = event.target.value as string;
    account.invoiceEmails = value.trim().split(',');
    this.setState({account})
  }

  private handleReset() {
    const {account} = memberStore!;
    if (!account) return;
    this.setState({account:  toJS(account)});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleSubmit(event: any) {
    event.preventDefault();
    preferencesStore.updateAccount(this.state.account);
  }
  async removeSlackChannel(channelId: string){
    await memberStore!.removeSlackIntegration(channelId);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onSuggest(suggest: any) {
    if (!suggest) return;
    console.log('the suggest: ', suggest);
    const address: IAddress = {
      country: '',
      state: '',
      address: suggest.gmaps.name,
      city: '',
      zip: '',
      label: suggest.label
    };

    const addressComponents = suggest.gmaps.address_components;
    // let streetName = '';
    // let streetNumber = '';
    for (let i = 0; i < addressComponents.length; i++) {
      const addressType = addressComponents[i].types[0];
      switch (addressType) {
        // case 'street_number': streetNumber = addressComponents[i].short_name; break;
        // case 'route': streetName = addressComponents[i].short_name; break;
        case 'locality': address.city = addressComponents[i].short_name; break;
        case 'administrative_area_level_1': address.state = addressComponents[i].short_name; break;
        case 'postal_code': address.zip = addressComponents[i].short_name; break;
        case 'country': address.country = addressComponents[i].short_name; break;
        default:
      }
      // address.address = streetName + ' ' + streetNumber;
      const account = this.state.account;
      account.address = address;
      this.setState({account});
    }
  }

  render() {
    if (!this.state) return null;
    const account = this.state.account;
    const {role, members} = preferencesStore;
    const disabled = role === Role.developer;
    const address = this.state.account.address ? this.state.account.address.label : '';
    const emails = account.invoiceEmails?.join(',');

    return (
      <div className={styles.component}>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4vT7sCl39Z_7FTEV_06dUgGU6Tztgets&libraries=places"
          onLoad={() => this.setState({googleLoaded: true})}
        />
        <form onSubmit={this.handleSubmit} onReset={this.handleReset}>
          <div className={styles.title}>
            General
          </div>
          <div className={styles.subTitle}>
            One account to do it all. Remember in this one account you can add an unlimited amount of members and apps in the preferences menu. 
          </div>
          <div className={styles.accountNameWrapper}>
            <Icon className={styles.icon} name={account.name}/>
            <div>
              <div className={styles.accountName}>Account Name</div>
              <input className={styles.accountInput} type="text" name="name" placeholder="Account Name" 
                value={account.name} onChange={this.handleChange} disabled={disabled}/>
            </div>
          </div>
          <hr className={styles.seperator}/>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>
                  Company Name
                </td>
                <td>
                  <input type="text" name="companyName" placeholder="Company Name" 
                    value={account.companyName} onChange={this.handleChange} disabled={disabled}/>
                </td>
              </tr>
              <tr>
                <td>
                  Address
                </td>
                <td>
                  {this.state.googleLoaded && 
                    <Geosuggest initialValue={address} placeholder="Address" 
                      types={['address']} onSuggestSelect={this.onSuggest} autoComplete="true" disabled={disabled}/>
                      // ignoreEnter="true" 
                  }
                </td>
              </tr>
              <tr>
                <td>
                  Phone
                </td>
                <td>
                <PhoneInput country={'us'} value={account.phone} onChange={this.handlePhoneChange} placeholder="Phone" 
                  inputStyle = {{width: 400, backgroundColor: "var(--light-grey)", borderColor: "transparent", borderRadius: 4 }}
                  buttonStyle= {{ backgroundColor: "var(--light-grey)", borderColor: "transparent", borderRadius: 4}}                 
                />
                </td>
              </tr>
              <tr>
                <td>
                  Tax ID
                </td>
                <td>
                  <input type="text" name="taxId" placeholder="Tax ID" 
                    value={account.taxId} onChange={this.handleChange} disabled={disabled}/>
                </td>
              </tr>
              <tr>
                <td>
                  Accountant Emails
                </td>
                <td>
                  <input type="text" name="email" placeholder="Accountant Emails (comma seperated)" 
                    value={emails} onChange={this.handleInvoiceEmails} disabled={disabled}/>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={styles.note}>Please note that changing your address could impact the applicable tax rate as this information will be included in your invoice.</div>
          <ResetSubmitButton disabled={disabled}/>
        </form>
      </div>
    );
  }
}

export default observer(PrefGeneral);