import { observable, action, runInAction, computed, makeObservable } from 'mobx';

import ISession from '../models/session.model';
import ILog from '../models/log.model';
import { SearchParam } from '../models/search.model';
import SearchStore from './search.store';
import Popup from '../components/common/popup.component';
import logConnection from '../services/connection/log.connection';
import moment from 'moment';

export enum ItemType {
  SESSION = 0,
  MESSAGE,
  EXCEPTION,
  EVENT,
  LOADING
}

const LIMIT = 200;

export interface Item {
  itemType: ItemType;
  data?: ILog | ISession;
}

export class LiveTailStore {
  logs: ILog[] = [];
  play: boolean = false;
  
  isLoading = false;
  lastTime: Date = new Date();
  interval?: NodeJS.Timeout = undefined;

  constructor () {
    makeObservable(this, {
      logs: observable,
      play: observable,
      setPlay: action,
      clear: action
    });

    this.getLogs = this.getLogs.bind(this);
    this.setPlayInterval = this.setPlayInterval.bind(this);
    this.clearLiveTailInterval = this.clearLiveTailInterval.bind(this);
  }

  setPlay(play: boolean) {
    this.play = play;
  }
  
  setPlayInterval() {
    this.getLogs()
    this.interval = setInterval(() => {this.getLogs()}, 1000);
    console.log('entered setLiveTailInterval: ' + this.interval );
    this.setPlay(true);
  }

  clearLiveTailInterval() {
    
    console.log('entered clearLiveTailInterval: ' + this.interval )
    if (!this.interval) return;
    clearInterval(this.interval);
    this.setPlay(false);
  }

  
  async getLogs() {
    console.debug('getLogs');
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const data = SearchStore.logsParams;

      // limit
      const limit = LIMIT;
      data.limit = limit;
      data[SearchParam.FROM_TIME] = moment(this.lastTime).add(1, 'ms').toISOString();
      const logs = await logConnection.getLogs(data);
      console.log('the logs received', logs);
      if (logs.length > 0) this.lastTime = logs[logs.length -1].time;

      runInAction(() => {
        this.logs = [...this.logs, ...logs] //.push(...logs)
        if (this.logs.length > 10000) this.logs.splice(0, logs.length);
      });
    } catch (error) {
      Popup.createError({msg: 'Had a problem loading the logs'}); 
      console.log(error);
    } finally {
      this.isLoading = false;

    }
  }

  clear() {
    this.logs = []; // so that the references will continue
  }
}

export default new LiveTailStore();  