import IBase from "./base.model";
import ILog from "./log.model";

export type ParamsObject = {[key: string]: string | string[]};

export enum Role {
  System = 'system',
  User = 'user',
  Assistant = 'assistant',
  Function  = 'function',
  Tool = 'tool',
}

export interface ILogChatMessage {
  content: string;
  role: Role;
  search?: {
    query: ParamsObject;
    logs: ILog[];
  };
}

export default interface ILogChat extends IBase {
  app: string;
  name: string;
  messages: ILogChatMessage[];
}