import * as React from 'react';

import styles from './pref_billing.module.css';
import PrefPlan from './pref_plan.component';
import PrefUsage from './pref_usage.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Script: any = require('react-load-script');

interface Props {
}

interface State {
  name: string;
  error: string;
}

export default class PrefPlanPage extends React.Component<Props, State> {
  // childSubmit: (firstName: string, lastName: string, companyName: string, country: string) => void;
  prefCreditCardSubmit?: Function;
  constructor(props: Props) {
    super(props);
    this.onClick = this.onClick.bind(this);

  }

  async onClick() {
    this.prefCreditCardSubmit!();
  }

  render() {
    return (
      <div className={styles.component}>
        <div className={styles.title}>
          Usage
        </div>
        <div className={styles.subTitle}>
          Here we’ve displayed a summary of your account usage. 
        </div>
        <div className={styles.bodyHeader}>Hover your mouse over the graph to view:</div>
        <div className={styles.body}>How many logs are you retaining for each application relative to your account max?</div>


        <PrefUsage />
        <hr className={styles.seperator}/>
        <div className={styles.title}>
          Plan
        </div>
        <PrefPlan />
      </div>
    );
  }
}
