import moment from 'moment';

export default class Time {
  time: moment.Moment;
  constructor(date?: Date | string | number, utc?: boolean) {
    if (utc) this.time = moment.utc(date);
    else this.time = moment(date);
  }
  public getShortTime() {
    return this.time.format('LT');
  }
  
  public getSecondsTime() {
    return this.time.format('LTS');
  }

  public getTime() {
    return this.time.format('HH:mm:ss.SSS');
  }

  public getShortDateTime() {
    return this.time.format('YYYY-MM-DD HH:mm:ss');
  }

  public getLongDate() {
    return this.time.format('LL');
  }

  public getShortDate() {
    return this.time.format('L');
  }

  public getLongDateTime() {
    return this.time.format('LLL');
  }

  public getMonthDay() {
    return this.time.format('MMM DD');
  }
  
}
