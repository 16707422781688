import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './pref_app_alerts.module.css';
import { observer } from 'mobx-react';
import appStore from '../../stores/app.store';
import trash from '../../img/preferences/trash.svg';
import { ICustomAlert, comparisonValues } from '../../models/app.model';
import newAlert from '../../img/preferences/new-alert.svg';
import emptyAlerts from '../../img/preferences/empty-alerts.svg';
import { Exception, Severity, SeverityExc, SeverityExcUtil } from '../../models/log.model';
import Popup, { PopupInfo } from '../common/popup.component';
import { KeyValueList, Severities } from '../common/search.component';



const AlertRow = ({alert} : {alert:  ICustomAlert}) => {
  const history  = useHistory();

  function onTrash(e: React.MouseEvent<HTMLDivElement>, alert: ICustomAlert) {
    e.stopPropagation();

    const info: PopupInfo = {
      title: 'Delete',
      msg: `Are you sure that you want to delete the custom alert ${alert.name}?`,
      ok: 'Delete',
      okCallback: () => appStore.deleteAlert(alert._id),
      cancel: 'Cancel'
    };
    Popup.create(info);
  }

  function onEdit(id: string) {
    const path = `${history.location.pathname}/edit/${id}`;
    history.push(path)
  }

  return (
    <div key={alert._id} onClick={() => onEdit(alert._id)} className={styles.alertRow}>
      <div className={styles.alertNameCondition}>
        <div className={styles.alertName}>{alert.name}</div>
        <div className={styles.alertCondition}>logs for {alert.minutes} min. {comparisonValues[alert.comparison]} {alert.amount} times</div>
      </div>
      <KeyValueList search={alert.search} />
      <Severities search={alert.search} className={styles.alertSeverities} />
      <img alt="trash" className={styles.trash} src={trash} onClick={(e: React.MouseEvent<HTMLDivElement>) => onTrash(e, alert)} />
    </div>
  )
};

const PrefAppAlerts = observer(() => {
  const history  = useHistory();
  const alerts = appStore.app?.alerts;


  function onNewAlert() {
    const path = `${history.location.pathname}/new`;
    history.push(path)
  }

  return (
    <div className={styles.component}>      
      <div className={styles.header}>
        <div className={styles.addAlert}  onClick={() => onNewAlert()}>
          <div>add alert</div><img src={newAlert}/>
        </div>
      </div>
      {!alerts || alerts.length == 0 ? 
        <div className={styles.noAlerts}>
          <div className={styles.noAlertsTitle}>Add Alert </div>
          <div className={styles.noAlertsDescription}>Put on your snorkel and get ready to dive deep into the world of custom alerts. </div>
          <div className={styles.noAlertsImg}>
            <img src={emptyAlerts}/>
          </div>
        </div>
        :
        <div className={styles.alertList}>
          {alerts && alerts.map((alert )=> 
            <AlertRow key={alert._id} alert={alert} />
          )}
        </div>
      }
    </div>
  )
});

export default PrefAppAlerts;