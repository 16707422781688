import LogRocket from 'logrocket';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import storageService from '../services/storage.service';
import Member from '../models/member.model';
import IAccount from '../models/account.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let drift: any;

const dev = process.env.NODE_ENV !== 'production';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { dataLayer: any; }
}

if (!dev) {
  console.log('It is production');
  window.dataLayer = window.dataLayer || [];

  // initializing logrocket
  LogRocket.init('mcvb4w/shipbook-console', {
    network: {
      requestSanitizer: request => {
        const ignoredUrl = [
          'auth/register',
          'auth/login',
          'auth/changePassword'
        ];
        // if the url contains 'ignoredInfo'
        if (request.body && ignoredUrl.some(value => request.url.includes(value))) {
          try {
            const bodyObj = JSON.parse(request.body);
            if (bodyObj?.password) {
              bodyObj.password = '';
              request.body = JSON.stringify(bodyObj);
            }  
          }
          catch (e) {
            console.error("There was an error in parsing request body", e);
          }
        }
        
        // set empty authorization header
        if (request.headers["authorization"]) request.headers["authorization"] = '';
        return request;
      },
      responseSanitizer: response => {
        const ignoredUrl = [
          'auth/register',
          'auth/login',
          'auth/refreshToken'
        ];
        if (response.body && ignoredUrl.some(value => (response as unknown as {url: string}).url.includes(value))) {
          try {
            const bodyObj = JSON.parse(response.body);
            if (bodyObj?.token) {
              bodyObj.token = '';
              response.body = JSON.stringify(bodyObj);
            }  
          }
          catch (e) {
            console.error("There was an error in parsing reponse body", e);
          }          
        }
        return response;
      },
    },
  });
}

interface Props extends RouteComponentProps<{}> {
}

export class Analytics extends React.Component<Props> {  
  componentDidUpdate(prevProps: Props) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;
    if (currentPage !== prevPage) trackAnalytic.trackPage(currentPage);
  }

  render() {
    return null;
    // return <React.Component {...this.props} />;
  }
}

class TrackAnalytic {
  openChat() { // not the right place but didn't find a better place
    drift.api.openChat();
  }
  trackPage(pathname: string, search: string = '') {
    const page = pathname + search;
    console.log('called trackPage with page:' + page);
    if (!dev) {
      // TODO: add if needed track page to google analytics
    }
  }

  user(member: Member, account?: IAccount) {
    console.log('called trackUser with user:' + member._id);
    if (dev) return;
    // adding to google
    // sending user id
    window.dataLayer.push({
      userId: member._id,
      event: 'userId'
    });
    
    // adding info to drift
    drift.identify(member._id, {
      email: member.email, 
      name: member.fullName,
      phone: member.phone,
      company: account?.name,
      address: account?.address?.label
    });
    
    // adding info into Logrocket
    LogRocket.identify(member._id, {
      email: member.email, 
      name: member.fullName,
    });
    
  }
  
  register(userId: string) {
    console.log('called trackRegister with user:' + userId);
    if (dev) return;
    LogRocket.track('registration');
    // using user id as transaction id
    // sending user register
    window.dataLayer.push({
      userId,
      event: 'registration'
    });

    storageService.setItem('conversionTracking', true);
  }

  integration(userId: string) {
    console.log('called trackIntegration with user:' + userId);
    if (dev) return;

    if (!storageService.getItem('conversionTracking', false)) return;
    LogRocket.track('integration');
    // using user id as transaction id
    // sending user integration
    window.dataLayer.push({
      userId,
      event: 'integration'
    });

    storageService.removeItem('conversionTracking')
  }
   
  event(category: string, action: string, label: string) {
    if (dev) return;
    window.dataLayer.push({
      event: action,
      event_category: category,
      event_label: label 
    });
  }
}

const trackAnalytic = new TrackAnalytic();
export default trackAnalytic;
