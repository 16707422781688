import React, {useEffect} from 'react';


import styles from './logchat_item.module.css';

import logChatStore from '../../stores/logchat.store';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import searchStore from '../../stores/search.store';
import MessagesView from './logchat_messages_view.component';
import LogsView from './logchat_logs_view.component';
import memberStore from '../../stores/member.store';



const LogChatItem = () => {
  const location = useLocation();
  const history = useHistory();

  const params = useParams<{chatId?: string}>();

  const paramsSearch = (search: string) => {
    console.log('entered params with:', search);
    searchStore.setSearchParams(search);
  }

  useEffect(() =>{
    console.log('LogChatItem useEffect');  
    const {chatId} = params;
    if (chatId) {
      logChatStore.getChat(chatId);
    }
    else { // create new chat with the paremeters in the case that it came from logbook
      const {search} = location;
      if (search) { // search params meaning it is a chat from logbook
        paramsSearch(search);
        const startChat = async () => {
          try {
            await logChatStore.startLogChat();
            const {accountId, appId} = memberStore;
            history.replace(`/${accountId}/${appId}/logchat/${logChatStore.logChat?._id}`);
          } catch (error) {
            console.error('Error:', error);
          }
        };
        startChat();
      } else {
        logChatStore.clearChat();
      }
    }
  }, [params])

  return (
    <div className={styles.component}>
      <MessagesView />
      <LogsView />
    </div>
  );
}

export default LogChatItem;