import { History, Location } from 'history';

export type ParamsObjectArray = {[key: string]: string[]};
export type ParamsObject = {[key: string]: string[] | string};
class Param {
  public appendParam(props: {history?: History, location?: Location}, name: string,  value: string) {
    if (!props.history || !props.location) return;
    const location = props.location;
    const urlParams = new URLSearchParams(location.search);
    let exists = false;
    urlParams.getAll(name).some(param => {
      if (param === value) {
        exists = true;
        return true;
      }
      return false;
    });
    if (!exists) urlParams.append(name, value);
    location.search = urlParams.toString();
    props.history.push(location);
  }

  public removeParam(props: {history?: History, location?: Location}, name: string,  value?: string) {
    if (!props.history || !props.location) return;
    const location = props.location;
    const urlParams = new URLSearchParams(location.search);

    if (value !== undefined) {
      const paramValues = urlParams.getAll(name);
      if (!paramValues.includes(value)) return;
  
      urlParams.delete(name);    
      paramValues.forEach((elem) => {
        if (elem !== value) urlParams.append(name, elem)
      });  
    }
    else urlParams.delete(name);
    
    location.search = urlParams.toString();
    props.history.push(location);
  } 

  public setParam(props: {history?: History, location?: Location}, name: string,  value?: string | string[], 
    reset: boolean = false) {
    if (!props.history || !props.location) {
      console.error('there is no history or location props');
      return;
    } 
    const location = props.location;
    const search = reset ? undefined : location.search;
    const urlParams = new URLSearchParams(search);
    if (!value) urlParams.delete(name);
    else if (Array.isArray(value)) {
      urlParams.delete(name);
      value.forEach((elem) => urlParams.append(name, elem));
    }
    else urlParams.set(name, value);
    location.search = urlParams.toString();
    props.history.push(location);
  }

  public setParams(props: {history?: History, location?: Location}, params: ParamsObject, reset: boolean = false) {
    if (!props.history || !props.location) return;
    const location = props.location;

    const urlParams = this.createURLSearchParams(params);
    if (!reset) { 
      const search = new URLSearchParams(location.search);
      for (const [key, val] of search.entries()) {
        urlParams.append(key, val);
      }
    } 

    // tslint:disable-next-line:forin
    location.search = urlParams.toString();
    props.history.push(location);
  }

  public createURLSearchParams(params: ParamsObject) {
    const urlParams = new URLSearchParams();
    // tslint:disable-next-line:forin
    for (const name in params) {
      const value = params[name];
      console.log('the value is: ' + value);
      if (!value) urlParams.delete(name);
      else if (Array.isArray(value)) {
        urlParams.delete(name);
        value.forEach((elem) => urlParams.append(name, elem));
      }
      else urlParams.set(name, value);
    }

    return urlParams;
  }

  public createParamsObject(search: string)  {
    const searchParams = new URLSearchParams(search);
    const params: ParamsObjectArray = {};
    searchParams.forEach((value, key) => params[key] ? params[key].push(value) : params[key] = [value]);
    return params;
  }
}

export default new Param();