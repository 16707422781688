/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from 'react';

import { Link, withRouter, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import styles from './main.module.css';


import commonStore from '../stores/common.store';
import authStore from '../stores/auth.store';
import memberStore from '../stores/member.store';

import MainRoutes from '../routes/main.routes';
import { Percent } from '../models/account.model';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import loglyticsStore from '../stores/loglytics.store';
// import needsValidation from '../services/needs_validation.service';

import bridgeIcon from '../img/bridge-icon.svg';
import logbookIcon from '../img/logbook-icon.svg';
import logchatIcon from '../img/logchat-icon.svg';
import loglyticsIcon from '../img/loglytics-icon.svg';
import prefIcon from '../img/pref-icon.svg';
import liveTailIcon from '../img/livetail-icon.svg';
import alertsIcon from '../img/alerts-icon.svg';

import referAFriend from '../img/preferences/refer-a-friend.svg';

import logo from '../img/shipbook-circle-logo.svg';
import { memberIcon } from '../models/member.model';
import Select from './common/select.component';
import alertsStore from '../stores/alerts.store';

const AccountApp = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const {account, app, member} = memberStore!;
  const isAccPref = location.pathname.includes(`${account?._id}/preferences`);
  const isUserSettings = location.pathname.includes('/user-settings')

  function handleChangeAccount(accountId: string) {
    const account = member!.accounts.find((account) => account._id === accountId);
    history.push(`/${account!._id}/${account!.apps[0]._id}/bridge`);
  }
  function handleChangeApp(appId: string) {
    history.push(`/${account!._id}/${appId}/bridge`); 
  }

  const accounts: { [name: string]: string } = {};
  member?.accounts.forEach((account) => {
    accounts[account._id] = account.name; 
  });

  const apps: { [name: string]: string } = {};
  account?.apps.forEach((app) => {
    apps[app._id] = app.name;
  });
  return (
    <div className={styles.accountAppComponent}>
      {account && !isUserSettings &&  <>
        <Select
                value={account._id}
                nameValues={accounts} 
                onChange={handleChangeAccount}/>

        {app && !isAccPref &&         
          <Select
                  value={app._id}
                  nameValues={apps}
                  onChange={handleChangeApp}/>      
        }
        </>
      }
    </div>
  );
});

const Dropdown = observer(() => {
  const history = useHistory();
  
  function logout() {
    console.log('logout');
    authStore.logout();
    
    history.push('/login');
  }
  const {member} = memberStore;
  return (
    <div className={`${styles.dropdown} ${styles.user}`}>
      <img className={styles.memberIcon} src={memberIcon(member)}/>
      <div className={styles.dropdownContent} >
        <div className={styles.dropDownAccountWrapper}>
          <Link to={`/${memberStore.accountId}/preferences/general`} className={styles.dropDownAccount}>Account Preferences</Link>  
        </div>

        <div className={styles.dropDownMember}>
          <img className={styles.memberIcon} src={memberIcon(member)}/>
          <div className={styles.dropDownUserDetails}>
            <div className={styles.dropDownUserName}>{member?.fullName}</div>
            <div className={styles.dropDownUserEmail}>{member?.email}</div>
          </div>
        </div>
        <div className={styles.dropDownSeparator}/>
        <div className={styles.dropDownOptionRegular}><Link to="/user-settings/profile" >User Profile</Link></div>
        <div className={styles.dropDownOptionRegular}><Link to="/user-settings/notification">Email Notifications</Link></div> 
        <div className={styles.dropDownOptionRegular}><Link to="/user-settings/refer-a-friend">Refer A Friend</Link></div> 
        <div className={styles.dropDownSeparator}/>
        <a onClick={logout} className={styles.dropDownLogout}>Logout</a>
      </div>
    </div>

  )
});
const Notification = observer(class Notification extends React.Component {
  render() {
    const account = memberStore.account!;
    let percent; 
    if (account?.subscription.notification?.logLimit?.date &&  moment().utcOffset(0).isSame(account.subscription.notification.logLimit.date, 'day')) percent = account.subscription.notification.logLimit.percent;

    let colorRed: boolean = !!percent || !!account?.subscription.notification?.failedPayment?.date;
    let msg;
    let linkMsg;
    let url = `/${account._id}/preferences/billing`;
    if (account.notification) {
      msg = account.notification.msg;
      url = account.notification.url;
      linkMsg = account.notification.linkMsg;
      colorRed = true;
    } else if (percent) {
      if (percent === Percent.EIGHTY) {
        msg = "You have used 80% of the total storage available to you according to your current service package. No logs will be saved after the maximum capacity is reached.";
        linkMsg = "Upgrade now!"
      }
      else {
        let maxLogs = 100000;
        if (memberStore.currentPlan) maxLogs = memberStore.currentPlan.maxLogs;
  
        msg = `You have reached the maximum limit of ${maxLogs.toLocaleString()} daily logs! No additional logs will be saved beyond this point.`
        linkMsg = "Upgrade now to continue to receive coverage!"
      }  
    }
    else  if (account?.subscription.notification?.failedPayment?.date) {
      msg = "Your payment method has failed. Please update your payment method to continue receiving service. ";
      linkMsg = "Update now!"
    } 
    else {
      let maxLogs = 100000;
      if (memberStore.currentPlan) maxLogs = memberStore.currentPlan.maxLogs;

      msg = `With the free version logs will be saved for 24 hours ONLY with a limit of ${maxLogs.toLocaleString()} logs.     `
      linkMsg = "Upgrade now!"
    }

    return (
      <div className={`${styles.notification} ${colorRed ? '' : styles.free}`}>
        {msg} <Link to={url}>{linkMsg} </Link>
      </div>
    );
  }
});

enum MenuType {
  BRIDGE,
  LOGBOOK,
  LOGCHAT,
  LOGLYTICS,
  PREFERENCES,
  ALERTS,
  LIVE_TAIL
}

const MenuButton = withRouter(observer(class MenuButton extends React.Component<{type: MenuType} & RouteComponentProps> {
  render() {
    let src: string = '';
    let title: string = '';
    let to: string = '';
    let selectedType: MenuType = MenuType.BRIDGE;

    const {accountId, appId} = memberStore;
    const appsUrl = `/${accountId}/${appId}`;

    const {location} = this.props;
    if (location.pathname.includes(`/${appId}/preferences`)) selectedType = MenuType.PREFERENCES;
    else if (location.pathname.includes('/bridge')) selectedType = MenuType.BRIDGE;
    else if (location.pathname.includes('/logbook')) selectedType = MenuType.LOGBOOK;
    else if (location.pathname.includes('/loglytics')) selectedType = MenuType.LOGLYTICS;
    else if (location.pathname.includes('/alerts')) selectedType = MenuType.ALERTS;
    else if (location.pathname.includes('/live_tail')) selectedType = MenuType.LIVE_TAIL;
    else if (location.pathname.includes('/logchat')) selectedType = MenuType.LOGCHAT;

    let menuIconClassName = styles.menuIcon;
    let unread = 0;
    const selected =  this.props.type === selectedType;
    switch (this.props.type) {
      case MenuType.BRIDGE:
        src = bridgeIcon;
        title = 'Bridge';
        to = appsUrl + '/bridge';
        menuIconClassName = styles.bridgeIcon;
        break;
      case MenuType.LOGBOOK:
        src = logbookIcon;
        title = 'LogBook';
        to = appsUrl + '/logbook';
        break;
      case MenuType.LOGCHAT:
        src = logchatIcon;
        title = 'LogChat';
        to = appsUrl + '/logchat'
        break;  
      case MenuType.LOGLYTICS:
        src = loglyticsIcon;
        title = 'Loglytics';
        to = appsUrl + '/loglytics';
        unread = loglyticsStore.unreadSum;
        break;
      case MenuType.ALERTS:
        src = alertsIcon;
        title = 'Alerts';
        to = appsUrl + '/alerts';
        unread = alertsStore.unreadCount;
        break;
      case MenuType.LIVE_TAIL:
        src = liveTailIcon
        title = 'Live Tail';
        to = appsUrl + '/live_tail'
        break;
      case MenuType.PREFERENCES:
        src = prefIcon;
        title = 'App Preferences';
        to = appsUrl + '/preferences';
        break;

    } 
    
    return (
      <Link to={to} className={`${styles.menu} ${selected? styles.selected : ''}`} data-tip={title} >
        <img src={src} alt={title} className={menuIconClassName} />
        {unread > 0 && <div className={unread >= 100? styles.padding : ''}>{unread}</div>}
      </Link>
    ); 
  }
}));

// convert Header component to functional component
const Header = observer(() => {  
  const history = useHistory();
  useEffect(() => { 
    ReactTooltip.rebuild(); 
  });

  function home() {
    history.push('/');
  }

  const {accountId, appId} = memberStore;
  const appsUrl = `/${accountId}/${appId}`;
  const location = useLocation();
  const isApp = location.pathname.includes(appsUrl);

  return (
    <div className={styles.header}>
      <img src={logo} className={styles.logoShipbook} onClick={home} data-tip="Home"/>
      {isApp && <>
        <MenuButton type={MenuType.BRIDGE} />
        <MenuButton type={MenuType.LOGBOOK} />
        <MenuButton type={MenuType.LOGCHAT} />
        <MenuButton type={MenuType.LOGLYTICS} />
        <MenuButton type={MenuType.ALERTS} />
        <MenuButton type={MenuType.LIVE_TAIL} />
        <MenuButton type={MenuType.PREFERENCES} />
      </>}
        <Link to="/user-settings/refer-a-friend" className={styles.referAFriend}>
          <img src={referAFriend} alt='Refer A Friend'/>
        </Link>

      <AccountApp />
      <Dropdown />
    </div>
  );
});
  

class Main extends React.Component<RouteComponentProps<{}>> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    // tslint:disable-next-line:no-string-literal
    document.getElementsByTagName('meta')['viewport'].content = 'width=1920';
  }

  render() {
    const {account, currentPlan} = memberStore;
    let hasNotification =  account?.notification || 
      (account?.subscription.notification?.logLimit?.date &&  moment().utcOffset(0).isSame(account.subscription.notification.logLimit.date, 'day')) ||
      account?.subscription.notification?.failedPayment?.date;

    if (currentPlan?.retention === 1) hasNotification = true;

    const {appLoaded} = commonStore;
    if (!appLoaded) {
      return <div />;
    } 
    else {
      return (
        <div className={styles.container}>
          {hasNotification && <Notification />}
          <Header />
          <main className={hasNotification ? styles.mainNotification : styles.main}>
            <MainRoutes />
          </main>
        </div>
      );
    }
  }
}

export default withRouter(observer(Main));