import * as React from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './google_auth.module.css';

import { Provider } from '../../services/connection/auth.connection';
import environmentService from '../../services/environment.service';
import authStore from '../../stores/auth.store';

import google from '../../img/logos/google.svg';

interface Props extends RouteComponentProps<{}> {
  buttonText: string;
}

class GoogleAuth extends React.Component <Props> {
  constructor(props: Props) {
    super(props);
    this.googleSuccess = this.googleSuccess.bind(this);
    this.googleFailure = this.googleFailure.bind(this);
  }

  private async googleSuccess(response: GoogleLoginResponse | GoogleLoginResponseOffline) {
    const token = (response as GoogleLoginResponse).getAuthResponse().id_token;
    await authStore.token(token, Provider.GOOGLE);
    this.props.history!.push('/');
  }

  private googleFailure(response: {error:string}){
    console.log('there was an error with google loging', response);
  }

  render() {
    const clientId = environmentService.isProduction() ? 
      '764318139753-hvsiec8a2mn11h69ppls91a6bk3cjelq.apps.googleusercontent.com' :
      '764318139753-l8r130qsk1qnte9e16lqrihtdfg3njur.apps.googleusercontent.com';
    return (
      <GoogleLogin
        clientId={clientId}
        render={renderProps => (
          <div className={styles.container} onClick={renderProps.onClick}>
            <img src={google} alt="google"/>
            <div>{this.props.buttonText}</div>
          </div>  
        )}
        onSuccess={this.googleSuccess}
        onFailure={this.googleFailure}
      />
    );
  }
}

export default withRouter(GoogleAuth);
