import * as React from 'react';
import styles from './select.module.css';

import extraTriangle from '../../img/extra-triangle.svg';

interface Props {
  onChange: Function;
  value: string;
  nameValues: { [name: string]: string };
  centered?: boolean;
  className?: string;
}

export default class Select extends React.Component<Props, {show: boolean}> {
  constructor(props: Props) {
    super(props);
    this.state = {show: false};
    this.onClick = this.onClick.bind(this);
  }

  onChange(value: string) {
    this.setState({show: false});
    if (this.props.value === value) return;
    this.props.onChange(value);
  }

  onClick() {
    this.setState({show: !this.state.show});
  }

  render() {
    const {nameValues, centered, className} = this.props;
    const currentValue = this.props.nameValues[this.props.value];
    const dropdown = className ? `${styles.dropdown} ${className}` : styles.dropdown;
    return (
      <div className={dropdown} onClick={this.onClick}>
        <div className={styles.dropdownFlex}>
          <div className={`${styles.select} ${centered ? styles.centered : ''}`} >{currentValue}</div>
          <img className={styles.extraTriangle}  src={extraTriangle} alt="extra" /> 
        </div>
        {this.state.show && <div className={styles.dropdownContent}>
            {
              Object.keys(nameValues).map((name) => {
                return <div key={name} onClick={() => this.onChange(name)}>{this.props.nameValues[name]}</div>;
                }
              )
            }
          </div>}  

      </div>
    );
  }
}