import moment from "moment";

const dateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
class Json {  

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private reviver(key: string, value: any): Date {
      if (typeof value === 'string' && dateFormat.test(value)) {
          return moment(value).toDate(); // in safari new Date(value) is returning invalid value for dates with timezone
      }
      
      return value;
  }

  parse(text: string) {
    return JSON.parse(text, this.reviver);
  }
}

export default new Json();