import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import styles from './copy.module.css';

import copyIcon from '../../img/copy-icon.svg';
interface CopyProps {
  text: string;
  onCopy?: Function;
}

interface CopyState {
  copied: boolean;
}

export default class Copy extends React.Component <CopyProps, CopyState> {
  constructor(props: CopyProps) {
    super(props);
    this.handleOnCopy = this.handleOnCopy.bind(this);
    this.state = {copied: false};
  }

  private handleOnCopy() {
    if (this.props.onCopy) this.props.onCopy();
    this.setState({copied: true});
    setTimeout(() => {
      this.setState({copied: false});
    }, 2000);
  }

  render() {
    return (
      <CopyToClipboard text={this.props.text} onCopy={this.handleOnCopy}>
        <div className={styles.copy}>
          <img className={styles.copyIcon} src={copyIcon} alt="copy" />
          {this.state.copied && <div className={styles.copied}>Copied</div>}
        </div>
      </CopyToClipboard>
    );
  }
}
