import React, {useState} from 'react';
import styles from './sidebar.module.css';
import openDrawer from '../../img/open-drawer.svg';
import closeDrawer from '../../img/close-drawer.svg';

interface Props {
  children: React.ReactNode;
}

const Sidebar = ({children}: Props) => {
  const [expanded, setExpanded] = useState(true);

  const expansionSrc = expanded ? openDrawer: closeDrawer;
  return (
    <div className={`${styles.component} ${!expanded && styles.hidden}`}>
      <div className={`${styles.menu}  ${!expanded && styles.hidden}`}>
        {children}
      </div>
      <img src={expansionSrc} className={styles.expansion} onClick={() => setExpanded(!expanded)}/>
    </div>
  );
};

export default Sidebar;