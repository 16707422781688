import IUser from './user.model';

export interface ISearchResponse {
  deviceModels?: string[];
  manufacturers?: string[];
  udids?: string[];
  users?: IUser[];
  tags?: string[];
  appVersions?: string[];
}

export enum SearchParam {
  MESSAGE = 'msg',
  REGEX = 'regex',
  MANUFACTURER = 'manufacturer',
  DEVICE_MODEL = 'deviceModel',
  UDID = 'udid',
  UUID = "uuid",
  ANDROID_ID = "androidId",
  APP_VERSION = 'appVersion',
  TAG = 'tag',
  USER_ID = 'userId',
  USER_NAME = 'userName',
  FULL_NAME = 'fullName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  SESSION = 'session',
  FILE_NAME = 'fileName',
  BASE_FILE_NAME = 'baseFileName',
  LINE_NUMBER = 'lineNumber',
  LOG_ID = 'logId',
  HAS_ID = 'hasId',
  FROM_TIME = 'fromTime',
  TO_TIME = 'toTime',
  KEY = 'key'
}

export enum SearchMeta  {
  SEVERITY = 'severity',
  MIN_SEVERITY = 'min_severity',
  TYPE = 'type',
  INTERVAL = 'interval'
}

export type SearchType = SearchParam | string;

export function searchParamTitle(searchType: SearchType) {
  switch (searchType) {
      case SearchParam.MESSAGE:
        return 'Free text - Message';
      case SearchParam.REGEX:
        return 'RegEx - Message';
      case SearchParam.MANUFACTURER:
        return 'Manufacturer';
      case SearchParam.DEVICE_MODEL:
        return 'Device Model';
      case SearchParam.UDID:
        return 'UDID';
      case SearchParam.UUID:
        return 'UUID';
      case SearchParam.ANDROID_ID:
        return 'Android ID'
      case SearchParam.APP_VERSION:
        return 'App Version';
      case SearchParam.TAG:
        return 'Tag';
      case SearchParam.USER_ID:
        return 'User ID';
      case SearchParam.USER_NAME:
        return 'User name';
      case SearchParam.FULL_NAME: 
        return 'User Full Name';
      case SearchParam.EMAIL:
        return 'User Email';
      case SearchParam.PHONE_NUMBER:
        return 'User Phone Number';
      case SearchParam.FROM_TIME:
        return 'From Time';
      case SearchParam.TO_TIME: 
        return 'To Time';
      default:
        return searchType;
  }
}

export class CustomOption {
  label: string;
  value: string;
  type: SearchType;

  static createSearchParam(type: SearchType) {
    const label = searchParamTitle(type);
    return new CustomOption(type, `${label} (${type}:)`, '');
  }

  constructor(type: SearchType, label: string, value: string) {
    this.type = type;
    this.label = label;
    this.value = value;
  }

}

export enum DateType {
  TEN_MINUTES = '10 Minutes',
  HOUR = 'Hour',
  DAY = 'Day',
  MONTH = 'Month',
  NOW = 'Now',
  CUSTOM = 'Custom'
}


export class DateTypeUtil {
  static values() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(DateType).map(k => DateType[k as any]);
  }
}
