import React from 'react';
import { observer } from 'mobx-react';

import memberStore from '../../stores/member.store';
import appStore from '../../stores/app.store';
import PrefMenu from '../pref_menu.component';
import { Platform } from '../../models/platform.model';

const itemsOrig = [
  {name: 'General', url: 'general'},
  {name: 'Custom Alerts', url: 'alerts'},
  {name: 'Configuration', url: 'configuration'},
  {name: 'GDPR', url: 'gdpr'},
];

const PrefAppMenu = observer(() => {
  const {accountId} = memberStore;
  const app = appStore.app!;

  const prefUrl = `/${accountId}/${app._id}/preferences`;

  // const {role} = preferencesStore;
  // const disabled = role === Role.developer; // TODO: make disabled if developer
  let items = [... itemsOrig];

  if (app?.platform === Platform.IOS || app?.platform === Platform.TVOS) 
    items.splice(1,0, {name: 'DSym', url: 'dsym'});
  
  items = items.map((item) => {
    return {name: item.name, url: prefUrl + '/' + item.url};
  });

  const menuLink = {
    name: 'Account Preferences',
    url: `/${accountId}/preferences/general`,
  }

  return (
    <PrefMenu title="App Preferences" subTitle={app.name} items={items} menuLink={menuLink} referAndEarn={true}/>
  );
});



export default PrefAppMenu;