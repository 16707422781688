import * as React from 'react';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import UserSettingsProfile from '../components/user_settings/user_settings_profile.component';
import UserSettingsNotification from '../components/user_settings/user_settings_notification.component';
import UserSettingsReferAFriend from '../components/user_settings/user_settings_refer_a_friend.component';

interface Params {

}

interface Props extends RouteComponentProps<Params> {

}


class UserSettingsRoutes extends React.Component<Props> {
  render() {
    return (
        <Switch>
          <Route path="/user-settings/profile" render={() => <UserSettingsProfile/>} />
          <Route path="/user-settings/notification" render={() => <UserSettingsNotification/>} />
          <Route path="/user-settings/refer-a-friend" render={() => <UserSettingsReferAFriend/>} />
          <Redirect from="/user-settings" to="/user-settings/profile" />
        </Switch>
    );
  }
}
export default withRouter(UserSettingsRoutes);

