import ConnectionService, { HttpMethod } from './connection.service';
import ISession from '../../models/session.model';
import { IBucket } from '../../models/ bucket.model';
import ILog from '../../models/log.model';
import ILogChat from '../../models/logchat.model';

class LogConnection {  
  public async getSessionLogs(data: object): Promise<{sessions: ISession[], buckets: IBucket[]}> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/sessions/logs`, HttpMethod.GET, data);
  }

  public getSessionLogsUrl(data: object) {
    return `${ConnectionService.appUrl}/sessions/logs?${ConnectionService.encodeQueryData(data)}`;
  }

  public async getSessions(data: object): Promise<ISession[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/sessions`, HttpMethod.GET, data);
  }
  
  public async getAppVersions(appVersion: string): Promise<string[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/sessions/appVersions`, HttpMethod.GET,  {q: appVersion});
  }

  public async getTags(tag: string): Promise<string[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logs/tags`, HttpMethod.GET,  {q: tag});
  }

  public async getAdditionalParams(): Promise<string[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/sessions/additionalParams`, HttpMethod.GET);
  }

  public async getLogs(data: object): Promise<ILog[]>{
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logs`, HttpMethod.GET, data);
  }
}

export default new LogConnection();