import { observable, action, runInAction, computed, toJS, makeObservable } from 'mobx';
// import moment from 'moment';

import LoglyticsConnection from '../services/connection/loglytics.connection';
import { Severity, SeverityExc } from '../models/log.model';
import { ILoglytics, LoglyticsStatus } from '../models/loglytics.model';
import { StatDeviceNumber } from '../models/stat.model';
import statsConnection from '../services/connection/stats.connection';
import loglyticsConnection from '../services/connection/loglytics.connection';
// import searchStore from './search.store';
// import logsStore from './logs.store';
import CommonStore from './common.store';
import searchStore from './search.store';
import logsStore from './logs.store';
// import memberStore from './member.store';
// import { Platform } from '../models/platform.model';

class SearchParams {
  severity: Severity = Severity.ERROR;
}

export class LoglyticsStore {
  _loglytics: ILoglytics[] = [];
  version: string = '';
  appVersions: string[] = [];
  deviceCount?: StatDeviceNumber = {device: 0, error: 0, warning: 0, exception: 0};
  deviceSumHistogram?: {time: Date, device: number}[];
  severity: SeverityExc = Severity.ERROR;
  startDate?: Date = undefined;
  endDate?: Date = undefined;
  currentLoglytics?: ILoglytics;
  currentSampleIndex = -1;
  loading = false;

  unreadCount: {exception: number, error: number, warning: number} = {exception:0, error: 0, warning: 0};

  loglyticsStatus: LoglyticsStatus | '' = LoglyticsStatus.OPEN;

  // search params
  searchParams: SearchParams = new SearchParams();

  bridge: boolean = false;

  clear() {
    this._loglytics = [];
  }

  get loglytics(): (ILoglytics & {read?: boolean})[] {
    const loglytics:  (ILoglytics & {read?
      : boolean})[] = toJS(this._loglytics);
    // const memberId = memberStore.member!._id;
    // loglytics.forEach(l =>  l.read = l.membersRead && !!l.membersRead.find(id => id === memberId));
    return loglytics;
  }

  // @computed get sampleList() : ISample[] {
  //   if (!this.currentLoglytics) return [];
  //   let list: {session: string, log: string, _id:string}[] = [];
  //   this.currentLoglytics.daily.forEach(day => 
  //     day.sampleList?.forEach(sample => list.unshift(sample))
  //   );
  //   return list;
  // }

  // @computed get currentSample() {
  //   return this.sampleList[this.currentSampleIndex];
  // }

  get unreadSum() {
    // return (memberStore!.app!.platform === Platform.ANDROID ? this.unreadCount.exception : 0) + this.unreadCount.error + this.unreadCount.warning;
    return 0;
  }

  get unread() {
    let count = 0;
    this.loglytics?.forEach(l => {if (!l.read) ++count;});
    return count;
  }

  get currentDateHistogram() {
    if (!this.currentLoglytics) return [];

    const dateHistogram = this.currentLoglytics.dateHistogram!.map(bucket => {
      return {...bucket, percentage: bucket.deviceCount ? Math.min(bucket.devices / bucket.deviceCount, 1) : 0}
    });
    return dateHistogram;
  }

  constructor () {
    makeObservable(this, {
      _loglytics: observable,
      version: observable,
      appVersions: observable,
      deviceCount: observable,
      deviceSumHistogram: observable,
      severity: observable,
      startDate: observable,
      endDate: observable,
      currentLoglytics: observable,
      currentSampleIndex: observable,
      loading: observable,
      unreadCount: observable,
      loglyticsStatus: observable,
      searchParams: observable,
      bridge: observable,
      clear: action,
      loglytics: computed,
      unreadSum: computed,
      unread: computed,
      currentDateHistogram: computed,
      setVersion: action,
      setStatus: action,
      setLoglyticsStatus: action,
      setSeverity: action,
      getLoglytics: action,
      setKey: action,
      setCurrentLoglytics: action,
      nextPrevSample: action,
      setLoglyticsRead: action,
      setAllRead: action,
      getUnreadCount: action,
      setBridge: action,
      setLoading: action
    });

    this.getLoglytics = this.getLoglytics.bind(this);
    this.setSeverity = this.setSeverity.bind(this);
    this.nextPrevSample = this.nextPrevSample.bind(this);
  }

  // tslint:disable-next-line:no-shadowed-variable
  setVersion(version: string) {
    this.version = version;
    this.getLoglytics();
  }

  async setStatus(_status: LoglyticsStatus, _index?: number) {
    // if (typeof index !== 'number') index = this._loglytics.findIndex(l => l._id === this.currentLoglytics?._id);
    // if (index < 0) {
    //   console.error('didn\'t find a loglytic');
    //   return;
    // }
 
    // try {
    //   const loglyticResp = await LoglyticsConnection.updateStatus(this._loglytics[index]._id, status);
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   runInAction(() => {
    //     if (this.currentLoglytics) this.currentLoglytics.status = loglyticResp.status;
    //     if (this.loglyticsStatus !== loglyticResp.status) {
    //       this._loglytics.splice(index!, 1);
    //     }
    //     else {
    //       this._loglytics[index!].status = loglyticResp.status;
    //     }
    //   });
    // } catch (error) {
    //   console.log(error);
    // } 
  }

  async setLoglyticsStatus(status: LoglyticsStatus | '') {
    this.loglyticsStatus = status;
    await this.getLoglytics();
  }

  async setSeverity(severity: SeverityExc) {
    this.severity = severity;
    await this.getLoglytics();
  }

  async getLoglytics(all: boolean = false) {
    this.setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = {};
      if (this.version.length > 0) data.appVersion = this.version;
      
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const loglyticsData: any =  { severity: this.severity };
      if (!all) {
        if (this.version.length > 0) loglyticsData.appVersion = this.version;
        if (this.loglyticsStatus.length > 0) loglyticsData.status = this.loglyticsStatus;  
      }
      
      const [loglytics, appVersions, deviceCount, deviceSumHistogram] = await Promise.all([
        LoglyticsConnection.get(loglyticsData),
        statsConnection.getAppVersions(data),
        statsConnection.getDeviceCount(data),
        statsConnection.getDeviceSumHistogram(data),
      ]);

      console.log(loglytics);
      runInAction(() => {
        this.appVersions = appVersions
        this.deviceCount = deviceCount;
        this._loglytics = loglytics;
        this.deviceSumHistogram = deviceSumHistogram;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  }

  async setKey(key: string) {
    if (this.currentLoglytics?.key !== key) {
      try {
        const loglytics = await loglyticsConnection.getOne(key);
        console.log(loglytics);
        runInAction(async () => {
          this.currentLoglytics = loglytics;
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async setCurrentLoglytics(key: string, sessionId?: string, logId?: string) {
    await this.setKey(key);
    if (!this.currentLoglytics) return;
    if (!sessionId || !logId) {
      searchStore.setLoglyticsKey(this.currentLoglytics.key);  
    }
    else searchStore.setSessionLog(sessionId, logId);
    await logsStore.getLogs(true);
  }

  async nextPrevSample(_next: boolean) {
    // if (next) ++this.currentSampleIndex;
    // else --this.currentSampleIndex;
    // let sample = this.currentSample;
    // if (!sample) {
    //   console.error('no sample for current index ' + this.currentSampleIndex);
    //   return;
    // }
    // searchStore.setSessionLog(sample.session, sample.log);
    // await logsStore.getLogs(true);
  }

  async setLoglyticsRead(_read: boolean, _loglyticsId?: string) {
    // if (!loglyticsId) loglyticsId = this.currentLoglytics?._id;
    // if (!loglyticsId) return;
    // try {
    //   let loglyticsList: ILoglytics[];
    //   if (read) loglyticsList = await loglyticsConnection.read([loglyticsId!]);
    //   else loglyticsList = await loglyticsConnection.unread([loglyticsId!]);
    //   this.getUnreadCount();
    //   console.log(loglyticsList);
    //   runInAction(() => {
    //     if (this.currentLoglytics) {
    //       this.currentLoglytics.membersRead = loglyticsList[0].membersRead;
    //     }
    //     let loglytic = this._loglytics.find(l => l._id === loglyticsId);    
    //     if (loglytic) {
    //       loglytic.membersRead = loglyticsList[0].membersRead;
    //     }
    //   });
    // } catch (error) {
    //   console.log(error);
    // } 
  }

  async setAllRead() {
    // const ids = this.loglytics.filter(l => !l.read).map(l => l._id);
    // if (ids.length === 0) return;
    // try {
    //   let loglytics = await loglyticsConnection.read(ids);
    //   await this.getUnreadCount();
    //   runInAction(() => {
    //     loglytics.forEach((l) => {
    //       let index = this._loglytics.findIndex((value) => value._id === l._id);
    //       if (index < 0) {
    //         console.error('couldn\'t find the index of: ', l._id);
    //         return;
    //       }
    //       this._loglytics[index].membersRead = l.membersRead;
    //     });
    //   }); 
    // } catch (error) {
    //   console.log(error);
    // }
  }

  async getUnreadCount() {
    // try {
    //   const unreadCount = await loglyticsConnection.unreadCount();
    //   runInAction(() => {
    //     this.unreadCount = unreadCount;
    //   });
    // } catch (error) {
    //   console.log(error);
    // } 
  }

  // private fillMissingValues(dates: {date: Date, sessions: number}[]) {
  //   const currentDate = moment();
  //   let date: moment.Moment;
  //   date = moment().subtract(1, 'month').startOf('day');

  //   let newDates: {date: Date, sessions: number}[] = [];
  //   dates = dates.filter(d => date.isSameOrBefore(d.date));
  //   for (let i = 0; date.isBefore(currentDate) ; date = date.add(1, 'day')) {
  //     if (i < dates.length && date.isSame(dates[i].date, 'day')) {
  //       newDates.push(dates[i++]);
  //     }
  //     else {
  //       newDates.push({
  //         date: date.toDate(),
  //         sessions: 0,
  //       });
  //     }
  //   }
  //   return newDates;
  // }

  setBridge(bridge: boolean) {
    this.bridge = bridge;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }
}
export default new LoglyticsStore();