import * as React from 'react';
import styles from './user_settings.module.css';
import UserSettingsMenu from './user_settings_menu.component';
import UserSettingsRoutes from '../../routes/user_settings.routes';

interface Props {

}

export default class UserSettings extends React.Component<Props> {
  render() {

    return (
      <div className={styles.component}>
        <UserSettingsMenu />
        <div className={styles.main}>
          <UserSettingsRoutes />
        </div>
      </div>
    );
  }
}