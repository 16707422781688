import IAccount from './account.model';
import IBase from './base.model';


import memberIcon1 from '../img/members/icon-1.svg';
import memberIcon2 from '../img/members/icon-2.svg';
import memberIcon3 from '../img/members/icon-3.svg';
import memberIcon4 from '../img/members/icon-4.svg';
import memberIcon5 from '../img/members/icon-5.svg';
import memberIcon6 from '../img/members/icon-6.svg';
import memberIcon7 from '../img/members/icon-7.svg';
import memberIcon8 from '../img/members/icon-8.svg';
import memberIcon9 from '../img/members/icon-9.svg';
import memberIcon10 from '../img/members/icon-10.svg';
import memberIcon11 from '../img/members/icon-11.svg';
import memberIcon12 from '../img/members/icon-12.svg';
import memberIcon13 from '../img/members/icon-13.svg';
import memberIcon14 from '../img/members/icon-14.svg';
import memberIcon15 from '../img/members/icon-15.svg';
import memberIcon16 from '../img/members/icon-16.svg';

export const memberIconList = [
  memberIcon1,
  memberIcon2,
  memberIcon3,
  memberIcon4,
  memberIcon5,
  memberIcon6,
  memberIcon7,
  memberIcon8,
  memberIcon9,
  memberIcon10,
  memberIcon11,
  memberIcon12,
  memberIcon13,
  memberIcon14,
  memberIcon15,
  memberIcon16
];

export interface ITracking {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
    referrer?: string;
}

export default interface IMember extends IBase {
  fullName: string;
  email: string;
  isAdmin: boolean;
  accounts: IAccount[];
  icon: string;
  emailValidated: boolean;
  phone: string;
  referralCode: string; // when it comes to the console it is a must
  rewards?: {
    id: string;
    orderId: string;
    value: number;
    link: string;
    referral: string;
  }[];
  tracking?: ITracking;
}

export function memberIcon(member?: IMember) {
  return icon(member?.icon);
}

export function icon(iconName?: string) {
  if (!iconName) return;
  const match = iconName.match('icon-(.*).svg');
  const index = Number(match![1]);
  return memberIconList[index - 1];
}