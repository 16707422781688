import * as React from 'react';
import { Switch, Route, withRouter, RouteComponentProps, Redirect, match } from 'react-router-dom';

import PrefGeneral from '../components/pref_account/pref_general.component';
import PrefMembers from '../components/pref_account/pref_members.component';
import PrefAuthKeys from '../components/pref_account/pref_authkeys.component';
import PrefIntegrations from '../components/pref_account/pref_integrations.component';

import PrefBilling from '../components/pref_account/pref_billing/pref_billing.component';
import PrefPlanPage from '../components/pref_account/pref_billing/pref_plan_page.component';
import memberStore from '../stores/member.store';
import PrefApps from '../components/pref_account/pref_apps.component';
import PrefAppGeneral from '../components/pref_app/pref_app_general.component';


export const accountPreferencesRoutes = [
  "/preferences/global",
  "/preferences/apps",
  "/preferences/plan",
  "/preferences/billing",
  "/preferences/members",
  "/preferences/authkeys",
  "/preferences/integrations"
];

interface Params {
  accountId: string;
}
interface Props extends RouteComponentProps<Params> {}

class PreferencesRoutes extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const accountId =  this.props.match!.params.accountId;
    console.debug('PreferencesRoutes - component did mount app: ' + accountId);
    memberStore.setAccountId(accountId);
  }
  componentDidUpdate() {
    const accountId =  this.props.match!.params.accountId;
    console.debug('PreferencesRoutes - component did mount app: ' + accountId);
    memberStore.setAccountId(accountId);
  }

  render() {
    return (
        <Switch>
          <Route path="/:accountId/preferences/general" component={PrefGeneral} />
          <Route path="/:accountId/preferences/apps/new" component={PrefAppGeneral} />
          <Route path="/:accountId/preferences/apps" component={PrefApps} />
          <Route path="/:accountId/preferences/plan" component={PrefPlanPage} />
          <Route path="/:accountId/preferences/billing" component={PrefBilling} />
          <Route path="/:accountId/preferences/members" component={PrefMembers} />
          <Route path="/:accountId/preferences/authkeys" component={PrefAuthKeys} />
          <Route path="/:accountId/preferences/integrations" component={PrefIntegrations} />
          <Redirect from="/:accountId/preferences/" to="/:accountId/preferences/general" />
        </Switch>
    );
  }
}
export default withRouter(PreferencesRoutes);