import IMember from '../../models/member.model';
import IReferral from '../../models/referral.model';
import ConnectionService, { HttpMethod } from './connection.service';

class MemberConnection {
  public async getMember() :Promise<IMember>{
    return await ConnectionService.fetch('members/me');
  }

  public async updateMember(data:object):Promise<IMember>{
    return await ConnectionService.fetch(`members/me`,HttpMethod.PUT, data);
  }  

  public async getReferrals():Promise<IReferral[]>{
    return await ConnectionService.fetch('members/me/referral');
  }
}

export default new MemberConnection();