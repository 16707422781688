import * as React from 'react';
import environmentService from '../../services/environment.service';

import styles from './github_auth.module.css';
import github from '../../img/logos/github.svg';

interface Props {
  buttonText: string;
}

interface State {
}

export default class GithubAuth extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.loginGithub = this.loginGithub.bind(this);
  }

  private loginGithub() {
    const clientId = environmentService.isProduction()?  'f10469e22164e20abdf3' : '37b2fa9c1a61f268077f';
    const url =  `https://github.com/login/oauth/authorize?scope=user:email&client_id=${clientId}`;
    const left= ((window.innerWidth -450)/2) ;
    window.open(url,'_new','left=' +left+',resizable=no,height=600,width=450,menubar=no,status=no');
  }

  render() {
    return (
      <div className={styles.container} onClick={this.loginGithub}>
        <img src={github} alt="github"/>
        <div>{this.props.buttonText}</div>
      </div>
    );
  }
}
