import React, { useEffect } from 'react';
import styles from './alerts.module.css';
import captainRest from '../../img/loglytics/captain-rest.gif';
import alertsStore, { ItemType} from '../../stores/alerts.store';
import { observer } from 'mobx-react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList as List, ListChildComponentProps as ListProps} from 'react-window';
import Time from '../../services/time.service';
import  { ICustomAlert, ILoglyticsAlert } from '../../models/trig_alert.model';
import { comparisonValues } from '../../models/app.model';
import { KeyValueList, Severities } from '../common/search.component';
import { useHistory, useLocation } from 'react-router-dom';
import memberStore from '../../stores/member.store';
import appStore from '../../stores/app.store';
import searchStore from '../../stores/search.store';
import paramService from '../../services/param.service';
import commonStore from '../../stores/common.store';
import { LocalLoader } from '../common/loader.component';


const DateRow = ({date}: {date: Date}) => {
  const time = new Time(date);
  return (<div className={styles.dateRow}>{ time.getLongDate() }</div>);
};

const CustomAlertRow = ({alert}: {alert: ICustomAlert}) => {
  const time = new Time(alert.date);
  const customAlert = alert.alert;
  const history = useHistory();
  const {read} = alert;
  const highlight = alertsStore.alertId === alert._id;

  function onClick() {
    const {accountId} = memberStore;
    const appId = appStore.app?._id
    searchStore.setParams(customAlert.search);
    const urlSearch = paramService.createURLSearchParams(customAlert.search);
    history.push(`/${accountId}/${appId}/logbook?`+ urlSearch.toString());
    console.log('clicked');  
  }

  return (
    <div className={`${styles.customAlertRow} ${highlight && styles.highlight}`} onClick={onClick}>
      <div className={`${styles.alertDate} ${read && styles.read}`}>{time.getShortTime()}</div> 
      <div className={styles.alertCount}>{alert.count}</div>
      <div className={styles.customAlertNC}>
        <div className={styles.customAlertName}>{customAlert.name}</div>
        <div className={styles.customAlertCondition}>logs for {customAlert.minutes} min. {comparisonValues [customAlert.comparison]} {customAlert.amount} times</div>
      </div>
      <KeyValueList search={customAlert.search} />
      <Severities search={customAlert.search} className={styles.customAlertSeverities} />
    </div>
  )
};

const LoglyticsAlertRow = ({alert}: {alert: ILoglyticsAlert}) => {
  const time = new Time(alert.date);
  const loglyticsAlert = alert.alert;
  const history = useHistory();
  const {read} = alert;
  const highlight = alertsStore.alertId === alert._id;

  function onClick() {
    const {accountId} = memberStore;
    const appId = appStore.app?._id
    history.push(`/${accountId}/${appId}/loglytics/`+ loglyticsAlert.key);
    console.log('clicked');
  }

  return (
    <div className={`${styles.loglyticsAlertRow} ${highlight && styles.highlight}`} onClick={onClick}>
      <div className={`${styles.alertDate} ${read && styles.read}`}>{time.getShortTime()}</div> 
      <div className={styles.alertCount}>{alert.count}%</div>
      <div className={styles.loglyticsAlertMessage}>{loglyticsAlert.message}</div>
      <div className={styles.loglyticsAlertLocation}>{loglyticsAlert.baseFileName}:{loglyticsAlert.lineNumber}</div>
    </div>
  )
}

const Row = ({ index, style }: ListProps) => {
  const {itemList} = alertsStore; 
  const item = itemList[index];
  let element: JSX.Element;
  
  switch (item.itemType) {
    case ItemType.DATE:
      element = (<DateRow date={item.data as Date} />);
      break;
    case ItemType.CUSTOM_ALERT:
      element = (<CustomAlertRow alert={item.data as ICustomAlert} />);
      break;
    case ItemType.LOGLYTICS_ALERT:
      element = (<LoglyticsAlertRow alert={item.data as ILoglyticsAlert} />);
      break;
    default:
      element = (<div>Unknown alert</div>);
  }
  

  return (
    <div style={style}>
      {element}
    </div>
  );
};

function itemSize(index: number): number {
  const {itemList} = alertsStore; 
  const item = itemList[index];
  switch (item.itemType) {
    case ItemType.DATE:
      return 34;
    case ItemType.CUSTOM_ALERT:
      return 50;
    case ItemType.LOGLYTICS_ALERT:
      return 32;
    default:
      return 0;
  }
}

const AlertListView = observer(() => {
  useEffect(() => {
    console.log('AlertListView');
    alertsStore.getAlerts();
    return () => { alertsStore.read(); };
  }, []);

  
  // const alerts = mobx.toJS(alertsStore.alerts);
  const {itemList, loading} = alertsStore; 

  if (loading && !commonStore.loading) {
    return (
      <LocalLoader />
    )
  }

  console.log('the current item list', itemList);

  const emptyTitle = 'Hmmmm... we’re not registering any blips ... '
  const emptyText = 'Although we’re sure you are the master and commander of your ship. \n' + 
    'Give it a second glance, did you define error logs? Did you set up a custom alert?';  

  const location  = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const alertId = searchParams.get('alertId');
  alertsStore.alertId = alertId ?? undefined;

  return (
    <div className={styles.row}>
      {itemList.length > 0 ?
        <AutoSizer>
          {({height, width}) => (
            <List
              className='List'
              itemCount={itemList.length}
              itemSize={itemSize}
              height={height}
              width={width} 
              >
                {Row}
            </List>
          )}
        </AutoSizer>
        :
        !commonStore.loading && <div className={styles.emptyAlerts}>
          <img src={captainRest}/>
          <div>
            <div>{emptyTitle} </div>
            <div>{emptyText} </div>
          </div>
        </div>
      }
      </div>
  );
});

const Alerts = () => {
  return (
    <div className={styles.component}>
      <AlertListView />
    </div>
  );
}

export default Alerts;