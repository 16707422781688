
import { observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, ReferenceArea, ResponsiveContainer, Tooltip, TooltipPayload, TooltipProps, XAxis, YAxis } from 'recharts';
import { SearchParam } from '../../models/search.model';
import paramService from '../../services/param.service';
import Time from '../../services/time.service';
import logsStore from '../../stores/logs.store';
import { GraphTimeTick } from '../common/graph_time_tick.component';
import styles from './log_graph.module.css';
import openDrawer from '../../img/open-upper-drawer.svg';
import closeDrawer from '../../img/close-upper-drawer.svg';

const axisTickStyle = {
  fill: '#062960', 
  fontFamily: 'Heebo',
  fontSize: 12
};

const CustomTooltip: FunctionComponent <TooltipProps> = (props) => {
  // console.log('the payload', props)
  const time = new Time(props.label);
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className={styles.customTooltip}>
        <div className={styles.customTooltipDate}> {time.getLongDate()}</div>
        <div className={styles.customTooltipTime}> {time.getSecondsTime()} </div>
        <div className={styles.customTooltipText}>{`Logs : ${props.payload[0].value.toLocaleString()}`}</div>
      </div>
    );
  }

  return null;
}

const LogGraph = observer((props: RouteComponentProps<{}>) => {
  const {buckets, logCount} = logsStore;
  const [areaLeft, setAreaLeft] = useState(-1);
  const [areaRight, setAreaRight] = useState(-1);
  const [expanded, setExpanded] = useState(true);
  const [click, setClick] = useState(false);
  const expansionSrc = expanded ? openDrawer : closeDrawer;
  if (buckets.length == 0) return <div/>;
  return (
    <div className={styles.component}>      
      {expanded && <div className={styles.count}>
        About {logCount.toLocaleString()} log results 
      </div>}
      <div className={`${styles.graph} ${!expanded && styles.hidden}`}>
        <ResponsiveContainer width="99%" height={100} className={styles.chart}>
          <BarChart 
            data={buckets}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onMouseDown={(e: any) => setAreaLeft(e?.activeLabel)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onMouseMove={(e: any) => areaLeft != -1 && setAreaRight(e?.activeLabel)}
            onMouseUp={() => {
              if (areaLeft != -1 && areaRight != -1) {
                const fromTime = new Date(areaLeft < areaRight ? areaLeft : areaRight);
                const toTime = new Date(areaRight > areaLeft ? areaRight : areaLeft);
                console.log(`left: ${areaLeft} right: ${areaRight}`);
                paramService.setParam(props, SearchParam.FROM_TIME, fromTime.toISOString());
                paramService.setParam(props, SearchParam.TO_TIME, toTime.toISOString());
                setClick(true);  
              }
              setAreaLeft(-1);
              setAreaRight(-1);
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e:any) => {
              console.log('onclick:', e);
              if (click) {
                setClick(false);
                return;
              }
              if (!e) return;
              console.log('onclick bucket length:', buckets.length);
              const fromTime = new Date(e.activeLabel);
              paramService.setParam(props, SearchParam.FROM_TIME, fromTime.toISOString());

              let toTime: Date;
              if (buckets.length > e.activeTooltipIndex + 1) {
                toTime = new Date(buckets[e.activeTooltipIndex + 1].key)
                paramService.setParam(props, SearchParam.TO_TIME, toTime.toISOString());
              }
            }}>
            
            <Tooltip wrapperStyle={{zIndex:1}} content={<CustomTooltip />}/>  
            <Bar 
              dataKey="doc_count" 
              fill="var(--tealish)" className={styles.bar} />
            <XAxis dataKey="key" tick={<GraphTimeTick seconds={true} />} stroke="#21a1b5" />
  `         <YAxis axisLine={false} tickLine={false} tick={axisTickStyle} 
              tickFormatter={tick => tick.toLocaleString()} stroke="var(--brown-grey)"/>
  `         <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
            {areaLeft > -1 && areaRight > -1 ? (
              <ReferenceArea
                x1={areaLeft}
                x2={areaRight}
                strokeOpacity={0.3}/>
            ) : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.expansionWrapper}>
        {!expanded && <div className={styles.count}>
            About {logCount.toLocaleString()} log results 
        </div> }

        <img src={expansionSrc} className={styles.expansion} onClick={() => setExpanded(!expanded)}/> 
      </div>
    </div>

  )
});

export default withRouter(LogGraph);