import IBase from "./base.model";
import IMember from "./member.model";
import IAccount from "./account.model";

export enum ReferralStatus {
  REGISTERED = 'registered',
  PAID = 'paid',
  EXPIRED = 'expired'
}

export class ReferralStatusUtil {
  static values() {
    return Object.values(ReferralStatus);
  }
}

export default interface Referral extends IBase {
  referrer: string | IMember;
  referee: string | IMember;
  status: ReferralStatus;
  account?: string | IAccount;
  redeemedAt?: Date;
}

