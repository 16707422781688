import React, {useRef, useEffect, useState} from 'react';
import memberStore from '../../stores/member.store';
import styles from './pref_apps.module.css';
import { Link } from 'react-router-dom';
import preferencesStore from '../../stores/preferences.store';
import { Role } from '../../models/account.model';
import trash from '../../img/preferences/trash.svg';
import Popup, { PopupInfo } from '../common/popup.component';
import { observer } from 'mobx-react';
import appStore  from '../../stores/app.store';
import newApp from '../../img/preferences/new-app.svg';
import { Platform } from '../../models/platform.model';
import androidIcon from '../../img/android-icon-round.svg';
import iosIcon from '../../img/apple-icon-round.svg';
import reactIcon from '../../img/react-icon-round.svg';
import noneIcon from '../../img/none-icon-round.svg';

function deleteAppClick(appId: string) {
  if (preferencesStore.role === Role.developer) return;
  const info: PopupInfo = {
    title: 'Delete',
    msg: `Are you sure that you want to delete the app? 
      It will delete the app and all its' information. 
      You can't undo it.`,
    ok: 'Delete',
    okCallback: () => appStore.deleteApp(appId),
    cancel: 'Cancel'
  };
  Popup.create(info);    
}

const PrefApps = observer(() => {
  const {account} = memberStore;
  const {role} = preferencesStore;
  const disabled = role === Role.developer;
  
  function platformSrc(platform?: Platform) {
    switch (platform) {
      case Platform.ANDROID:
        return androidIcon;
      case Platform.IOS:
        return iosIcon;
      case Platform.REACT_NATIVE:
        return reactIcon;
      default:
        return noneIcon;
    }
  }


  return (
    <div className={styles.component}> 
      <div className={styles.subComponent}>
        <Link className={`${styles.newApp} ${disabled && styles.disabled}`} to={`/${account?._id}/preferences/apps/new`}>
          <img src={newApp} className={styles.newAppIcon} />
          Add new app
        </Link>
        <div className={styles.appList}>
        {account && account.apps.map((app) => 
          (<React.Fragment key={app._id}>
            <div  className={styles.baseApp}>
              <Link className={styles.app} to={`/${account?._id}/${app._id}/preferences`}>
                <img src={platformSrc(app.platform)}/>
                {app.name}
              </Link>
              <img src={trash} className={`${styles.trash} ${disabled && styles.disabled}`} 
                onClick={() => deleteAppClick(app._id)} />
              
            </div>
            <div className={styles.underlineApp} />  
          </ React.Fragment>

        ))}
        </div>
      </div>
    </div>
  );
});

export default PrefApps;