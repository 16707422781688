/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from 'react';
import { observer } from 'mobx-react';
import {
  CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, BarChart, Bar, TooltipProps
} from 'recharts';

import styles from './dashboard.module.css';
import dashboardStore from '../../stores/dashboard.store';
import memberStore from '../../stores/member.store';
import Time from '../../services/time.service';
import { StatDeviceSum, TimeSpan } from '../../models/stat.model';
import Select from '../common/select.component';
import { GraphTimeTick } from '../common/graph_time_tick.component';

import blackFlag from '../../img/logs/black-flag.svg';
import wheel from '../../img/logs/wheel.svg';
import buoy from '../../img/logs/buoy.svg';
import LoglyticsList from '../loglytics/loglytics_list.component';
import loglyticsStore from '../../stores/loglytics.store';
import { Severity } from '../../models/log.model';

interface PercentageProps {
  title: string; 
  src: string;
  percentage: number;
  color: string;
}

class Percentage extends React.Component <PercentageProps> {
  render() {
    const {title, src, percentage, color} = this.props;
    const percWidth = 170 * percentage;
    return (
      <div className={styles.percentage}>
        <div className={styles.percentageTitle}> {title} </div>
        <div className={styles.percentageCenter}>
          <img src={src} className={styles.percentageImage} />
          <div className={styles.percentageText} style={{color}}> {Math.floor(percentage * 1000) / 10}% </div>
        </div>        
        <div className={styles.percentageBar}>
          <div style={{backgroundColor: color, width: percWidth}}/>
        </div>   
      </div>
    );
  }
}

const CustomTooltip = observer(class CustomTooltip extends React.Component<{ perc?: boolean} & TooltipProps > {
  render() {
    const {payload} = this.props;
    const date = new Date(this.props.label!);
    const perc = !!this.props.perc;
    let total = 0;
    if (!perc && payload) payload.forEach(pay => total += pay.value as number);
    const time = new Time(date);
    return (
      <div className={styles.customTooltip}>
        <div className={styles.customTooltipTitle}>
          {time.getLongDate()} {time.getShortTime()} 
        </div>
        {payload && payload.map((elem) => {          
          const value = perc ? Math.floor(elem.value as number * 1000) / 10 + '%' : elem.value.toLocaleString();
          return (
          <React.Fragment key={elem.name}>
            <div className={styles.customTooltipText}>{elem.name}</div>
            <div className={styles.customTooltipColor} style={{backgroundColor: elem.color}}/>
            <div className={styles.customTooltipText}>{value}</div>
          </React.Fragment>);
        })}
        
        {!perc &&           
          <div className={styles.customTooltipTotal}>
            Total: {total.toLocaleString()}
          </div>
        }
      </div>
    );
  }
});

interface Coordinates { x: number; y: number; }

const deviceDataColors = ['#7becef','#21a1b5','#ffa7b6','#ffe38b','#e51a41','#b3e528','#ff7a06','#48f9a0','#11d472',
  '#bbbbbb','#ffc68d','#adf9fa','#ffdd34', '#ac40b2','#ebffb4','#ffc306', '#808080','#f45179','#ff8e1d']

// const timeSpanValues = {hour: TimeSpan.HOUR, day: TimeSpan.DAY, week: TimeSpan.WEEK, month: TimeSpan.MONTH};
const timeSpanValues = {day: TimeSpan.DAY, week: TimeSpan.WEEK, month: TimeSpan.MONTH};


const Dashboard = observer(() => {
  useEffect(() => {
    loglyticsStore.setSeverity(Severity.ERROR);
    loglyticsStore.setBridge(true);
    return () => {
      loglyticsStore.setBridge(false);
    }
  }, []);

  const { app } = memberStore;
  useEffect(() => {
    if (app == null) return;
    dashboardStore.getStats();
    loglyticsStore.getLoglytics();
  }, [app]);


  function changeVersion(version: string) {
    dashboardStore.setVersion(version);
  }

  function changeTimeSpan(timeSpan: TimeSpan) {
    dashboardStore.setTimeSpan(timeSpan);
  }

  const { logSums, affectedDevices, average, deviceSums, timeSpan, version, appVersions 
    } = dashboardStore;
  
  const logData = logSums.slice(); // linechart is checking if it is an array and it isn't because of mobx
  const affectedDevicesData = affectedDevices.slice(); 
    // linechart is checking if it is an array and it isn't because of mobx
  let deviceData = deviceSums.slice(); // linechart is checking if it is an array and it isn't because of mobx

  
  let dateSpan = new Time().getLongDate();
  if (!!logData && logData.length > 0) {
    const startTime = new Time(logData[0].time);
    const endTime = new Time(logData[logData.length - 1].time);
    dateSpan = `${startTime.getLongDate()} ${startTime.getShortTime()} - ${endTime.getLongDate()} ${endTime.getShortTime()}`;
    // if (timeSpan === TimeSpan.HOUR || timeSpan === TimeSpan.DAY) dateSpan = `${Time.getLongDate(startTime)} ${Time.getShortTime(startTime)} - ${Time.getLongDate(endTime)} ${Time.getShortTime(endTime)}`
    // else dateSpan = Time.getLongDate(startTime) + ' - ' + Time.getLongDate(endTime);
  }

  const axisTickStyle = {
    fill: '#062960', 
    fontFamily: 'Heebo',
    fontSize: 12
  };

  const versionNameValues = {'': 'All'};
  appVersions.forEach((vers) => versionNameValues[vers] = vers);
  const versions = version.length > 0 ? [version] : appVersions.slice();
  // keeping only the first keys
  // TODO: remove comment when there won't more be undefined
  // if (versions.length >= sessionDataColors.length) { 
    const showVersions = versions.slice(0, deviceDataColors.length - 1); 
    let hasOther = false;
    deviceData = deviceData.map(data => {
      const tempData: StatDeviceSum = {time: data.time};
      const keys = Object.keys(data).filter(elem => elem !== 'time');
      let other = 0;
      keys.forEach(key => 
        showVersions.includes(key) ? tempData[key] = data[key] : other += data[key] as number);

      if (other > 0) {
        tempData.other = other;
        hasOther = true;
      }
      return tempData;
    });
    if (hasOther) versions.push('other');
  // }
  
  return (
    <div className={styles.component}>
      <div className={styles.header}>
        <div className={styles.versionLabel}>Time span:</div>
        <Select 
          value={timeSpan} 
          nameValues={timeSpanValues} 
          onChange={changeTimeSpan} />

        <div className={styles.dateSpan}>{dateSpan}</div>
        <div className={styles.versionLabel}>Version:</div>

        <Select
          value={version}
          nameValues={versionNameValues} 
          onChange={changeVersion}/>
      </div>
      <div className={styles.body}>
        <div className={styles.firstRow}>
          <div> 
            <div className={styles.chartTitle}> Affected Devices:</div>
            <div className={styles.firstRowBody}>
              <Percentage src={blackFlag} title="Exception" percentage={average.exception}  color="#000000" />
              <Percentage src={wheel} title="Error" percentage={average.error} color="#e51a41" />
              <Percentage src={buoy} title="Warning" percentage={average.warning} color="#ff7336" />
            </div>
          </div>
          <div className={styles.loglytics}>
            <div className={styles.chartTitle}> Loglytics</div>
            <div className={styles.firstRowBody}>
              <LoglyticsList /> 
            </div>
          </div>
        </div>
        <div className={styles.graphs}>
          <div className={styles.chartTitle}>Devices:</div>
          <ResponsiveContainer width="100%" height={150}>
            <AreaChart data={deviceData} syncId="dashboard">
              <Tooltip wrapperStyle={{zIndex:1}} content={<CustomTooltip/>} position={{ y:-25 } as Coordinates} />
              {versions.map((vers, index)  => 
                <Area key={vers} type="monotone" dataKey={vers} name={vers}
                  stroke={deviceDataColors[index]} fill={deviceDataColors[index]} stackId="1" 
                  strokeWidth={3} />) }
              <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
              <XAxis dataKey={(stat) => stat.time.toISOString()}
                tick={<GraphTimeTick />} stroke="#21a1b5"/>
              <YAxis axisLine={false} tickLine={false} tick={axisTickStyle}
                tickFormatter={tick => tick.toLocaleString()}/>
            </AreaChart>
          </ResponsiveContainer>
          <div className={styles.chartTitle}>Affected Devices:</div>
          <ResponsiveContainer width="99%" height={165} className={styles.chart}>
            <BarChart data={affectedDevicesData} syncId="dashboard">
              <Tooltip wrapperStyle={{zIndex: 100000}} content={<CustomTooltip perc={true}/>} position={{ y:85 } as Coordinates}/>
              <Bar dataKey="exception" name="Exception" fill="var(--black)" barSize={8}/>
              <Bar dataKey="error" name="Error" fill="var(--lipstick)" barSize={8}/>
              <Bar dataKey="warning" name="Warning" fill="var(--mango)" barSize={8}/>
              <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
              <XAxis dataKey={(stat) => stat.time.toISOString()}
                tick={<GraphTimeTick />} stroke="#21a1b5"/>
              <YAxis axisLine={false} tickLine={false} tick={axisTickStyle}
                tickFormatter={(perc: number) => `${Math.floor(perc * 100)}%`} />
            </BarChart>
          </ResponsiveContainer>
          <div className={styles.chartTitle}> Logs: </div>
          <ResponsiveContainer width="100%" height={170} className={styles.chart}>
            <AreaChart data={logData} syncId="dashboard">
              <Tooltip wrapperStyle={{zIndex:1}} content={<CustomTooltip/>}/>
              <Area type="monotone" dataKey="exception"   name="Exception" 
                stackId="1" stroke="#000000" fill="#000000" />
              <Area type="monotone" dataKey="error" name="Error" 
                stackId="1" stroke="#e51a41" fill="#e51a41" />
              <Area type="monotone" dataKey="warning" name="Warning"
                stackId="1" stroke="#ff7336" fill="#ff7336" />
              <Area type="monotone" dataKey="info" name="Info" 
                stackId="1" stroke="#ffc306" fill="#ffc306" />
              <Area type="monotone" dataKey="debug" name="Debug"
                stackId="1" stroke="#7becef" fill="#7becef" />
              <Area type="monotone" dataKey="verbose" name="Verbose" 
                stackId="1" stroke="#b3e528" fill="#b3e528" />
              <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
              <XAxis dataKey={(stat) => stat.time.toISOString()} 
                tick={<GraphTimeTick />} stroke="#21a1b5"/>
              <YAxis axisLine={false} tickLine={false} tick={axisTickStyle} 
                tickFormatter={tick => tick.toLocaleString()}/>
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
});

export default Dashboard;