/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import {useEffect} from 'react';
import { withRouter, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import styles from './log_search.module.css';

import searchStore from '../../stores/search.store';

import { Severity, Exception, SeverityExc, SeverityExcUtil } from '../../models/log.model';
import ParamService from '../../services/param.service';
import LogSearchInput from './log_search_input.component';
import { SearchMeta } from '../../models/search.model';
import logsStore from '../../stores/logs.store';
import liveTailStore from '../../stores/live_tail.store';
import Switch from '../common/switch.component';
import ShareBtn, { ShareStyle } from './../common/sharebtn.component'

import refresh from '../../img/logs/refresh.svg';
import blackFlag from '../../img/preferences/black-flag-mini.svg';
import wheel from '../../img/preferences/wheel-mini.svg';
import buoy from '../../img/preferences/buoy-mini.svg';
import anchor from '../../img/preferences/anchor-mini.svg';
import compass from '../../img/preferences/compass-mini.svg';
import sandClock from '../../img/preferences/sand-clock-mini.svg';
import fromSeverity from '../../img/logs/from-severity.svg';
import specificSeverity from '../../img/logs/specific-severity.svg';
import play from '../../img/logs/play.svg';
import pause from '../../img/logs/pause.svg';
import logChatButton from '../../img/logs/logchat-button.svg'
import memberStore from '../../stores/member.store';
interface SeverityButtonProps extends RouteComponentProps<{}> {
  severity: SeverityExc;
}

const SeverityButton = withRouter(observer(class SeverityButton extends React.Component<SeverityButtonProps> {
  constructor(props: SeverityButtonProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (searchStore.fromSeverityMode) {
      if (searchStore.isAlert) searchStore.setSeverity(this.props.severity)
      else ParamService.setParam(this.props, SearchMeta.MIN_SEVERITY, this.props.severity);
    }
    else {
      if (searchStore!.hasSeverity(this.props.severity)) {
        let {severity} = searchStore;
        if (!severity || severity.length === 0) severity = SeverityExcUtil.values();
        severity = severity.filter(s => s !== this.props.severity);
        if (searchStore.isAlert) searchStore.setSeverity(severity)
        else ParamService.setParam(this.props, SearchMeta.SEVERITY, severity);
      }
      else {   
        if (searchStore.isAlert) {
          const {severity} = searchStore;
          severity?.push(this.props.severity);
          searchStore.setSeverity(severity)
        }
        else ParamService.appendParam(this.props, SearchMeta.SEVERITY, this.props.severity);
      }
    }
  }

  render() {
    const severityOn = searchStore.hasSeverity(this.props.severity);
    let src: string = '';

    // tslint:disable-next-line:switch-default
    switch (this.props.severity) {
      case Exception.EXCEPTION:
        src = blackFlag;
        break;
      case Severity.ERROR:
        src = wheel;
        break;
      case Severity.WARNING:
        src = buoy;
        break;
      case Severity.INFO:
        src = anchor;
        break;
      case Severity.DEBUG:
        src = compass;
        break;
      case Severity.VERBOSE:
        src = sandClock;
        break;
    } 
    return (
      <div className={styles.severityButton} onClick={this.onClick}>
        <img src={src} className={`${styles.severityIcon} ${severityOn ? '': styles.severityOff}`} data-tip={this.props.severity} />
      </div>
    ); 
  }
}));

interface Props extends RouteComponentProps<{}>  {
  liveTail?: boolean;
}

export const SeverityList = () => {
  const history = useHistory();
  const location = useLocation();
  function onSeveritySwitch(fromSeverityMode: boolean)  {
    if (fromSeverityMode === searchStore.fromSeverityMode) return;
    const props = {history, location};
    ParamService.removeParam(props, SearchMeta.SEVERITY);
    ParamService.removeParam(props, SearchMeta.MIN_SEVERITY);
    searchStore.setFromSeverityMode(fromSeverityMode);
  }

  const { fromSeverityMode } = searchStore;
  return (
    <>
      <div className={styles.severityList}>
        <SeverityButton severity={Severity.VERBOSE}/>
        <SeverityButton severity={Severity.DEBUG}/>
        <SeverityButton severity={Severity.INFO}/>
        <SeverityButton severity={Severity.WARNING}/>
        <SeverityButton severity={Severity.ERROR}/>
        <SeverityButton severity={Exception.EXCEPTION}/>
      </div>
      <Switch image1={fromSeverity} image2={specificSeverity} dataTip1="From severity" dataTip2="Specific severity" oneMode={fromSeverityMode} onClick={onSeveritySwitch}/>
    </>
  );
};

const LogSearch = observer((props: Props) => {
  const {liveTail} = props;

  if (props.liveTail) {
    useEffect(
      () => {
        liveTailStore!.clear();
        liveTailStore.setPlayInterval();
        
        return () => {
          liveTailStore.clearLiveTailInterval();
        }
      },
      [], // note empty dependencies
    )  
  }

  const shareUrl = window.location.href;
  const shareSummary = 'Shipbook > Logbook';
  const shareDescription = 'Search result: ' +shareUrl;
  const downloadUrl = searchStore.logsUrl;

  const goToAI = () => {
    const {accountId, appId} = memberStore;
    const {history, location} = props;
    history.push({pathname: `/${accountId}/${appId}/logchat`, search: location.search});
  }
  
  return (
    <div className={styles.component}>
      <LogSearchInput />
      {liveTail ?
        <div className={styles.play} onClick={()=> liveTailStore.play ? liveTailStore.clearLiveTailInterval() : liveTailStore.setPlayInterval()}>
          <img src={liveTailStore.play ? pause : play} data-tip={liveTailStore.play ? "Pause": "Play" } />
        </div>
      :
        <div className={styles.refresh} onClick={()=> logsStore!.getLogs(true)}>
          <img src={refresh} data-tip="Refresh logs" />
        </div>
      }
      <div className={styles.seperator}/> 
      <SeverityList/>
      { !liveTail &&
        <>
          <div className={styles.seperator}/>
          <input type="image" src={logChatButton} onClick={goToAI} className={styles.logChatButton} data-tip="LogChat" />
          <div className={styles.seperator}/>
          <ShareBtn summary={shareSummary} 
                    description={shareDescription} 
                    url={shareUrl}
                    style={ShareStyle.BIG}
                    downloadUrl={downloadUrl} />
        </>
      }
    </div>
  );
});

export default withRouter(LogSearch);