import { observer } from 'mobx-react';
import React, { CSSProperties, FormEvent, useEffect, useRef, useState, forwardRef,ForwardRefRenderFunction,  useImperativeHandle, HTMLInputTypeAttribute, EventHandler } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList as List, ListOnItemsRenderedProps, ListChildComponentProps as ListProps} from 'react-window';

import styles from './logchat_messages_view.module.css';
import logChatStore from '../../stores/logchat.store';

import { memberIcon } from '../../models/member.model';
import memberStore from '../../stores/member.store';
import bot from '../../img/logchat/bot.svg';
import chatSend from '../../img/logchat/chat_send.svg';
import { Role } from '../../models/logchat.model';
import Typewriter from '../common/typewriter.component';
import { useHistory } from 'react-router-dom';
import pen from '../../img/preferences/pen.svg';
import submit from '../../img/loglytics/fix.svg';
import ShareBtn, { ShareStyle } from '../common/sharebtn.component';

const MessageInput = observer(() => {
  const textInput = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const {prompt, setPrompt} = logChatStore;

  useEffect(() => {
    textInput.current?.focus();
  }, []);

  const sendMessage = async (event: FormEvent) => {
    event.preventDefault();
    if (prompt.trim() === "") return;
    console.log('clicked send');
    const hasLogChat = !!logChatStore.logChat;
    
    const tempPrompt = prompt.trim();
    setPrompt("");
    await logChatStore.chat(tempPrompt);
    
    if (!hasLogChat) {
      const {accountId, appId} = memberStore;
      history.replace(`/${accountId}/${appId}/logchat/${logChatStore.logChat!._id}`);  
      // needs new chatList because the there is a new logChat
      logChatStore.getChatList();
    }
  }

  return (
    <div className={styles.bottom}>
      <form className={styles.messageText} onSubmit={sendMessage}>
        <input ref={textInput} type="text"  className={styles.inputText} placeholder="enter the chat information" value={prompt} onChange={(text) => setPrompt(text.target.value) } />
        <input type="image" className={styles.inputButton} src={chatSend} alt="send" onClick={sendMessage} />
      </form>
    </div>
  );
});

const CursorView = ({style}:{style: CSSProperties}) => {
  return (
    <div style={style}>
      <div className={`${styles.message} ${styles.bot}`}>
         <img src={bot} alt="speaker icon" />
         <div className={styles.cursor} />
      </div>
    </div>
  );
}

const EmptyMessagesView = () => {
  const clickQuestion = (text: string) => {
    logChatStore.setPrompt(text);
  };

  const questions = [
    'What error was associated with the UUID ___',
    'Can you tell me about the error I encountered in the last hour?',
    'What caused the issue for the user john@doe.com',
    'Please conduct a detailed log analysis for the user john@doe.com'
  ];

  return (
    <div className={styles.emptyMessages}>
      <div className={styles.emptyTitle}>LogChat</div>
      <div className={styles.emptyDetail}>Logchat is an AI-powered chat tool that translates logs into human-readable language. 
        It offers summaries, analyses, and identifies errors within the log files.<br/><br/>
        LogChat can analyze logs and the problem in a simple way, presenting the source of the issue in the code. It can as well suggest solutions.
        If you have questions, here are some recommendations for first question: Feel free to ask!”
      </div>
      { questions.map((question) => 
        <div className={styles.emptyQuestion} onClick={()=>clickQuestion(question)}>{question}</div>)}
      
    </div>
  )
}

const MessagesView = observer(() => {
  const listRef = useRef<List>(null);
  const rowHeights = useRef({});
  const prevIndexSearch = useRef(0);
  const {messages, loading, logChat} = logChatStore;
  const [name, setName] = useState(logChat?.name ?? '');
  const [editName, setEditName] = useState(false);


  const [firstMessageLength, setFirstMessageLength] = useState(0);

  

  function setRowHeight(index: number, size: number) {
    if (listRef.current) listRef.current.resetAfterIndex(index);
    rowHeights.current[index] = size;
  }
  
  function getRowHeight(index: number) {
    return rowHeights.current[index] || 90;
  }

  useEffect(() => {
    console.log(`use effect messages.length: ${logChatStore.messages.length}, firstMessageLength: ${firstMessageLength}`);
    setFirstMessageLength(prev => prev != 0 ? prev : (logChatStore.messages.length ?? 0));
  }, [logChatStore.messages.length]);


  // message row is inside messages view because it needs to know the height of the row
  const MessageRow = ({index, style}: ListProps) => {
    if (index >= messages.length) return <CursorView style={style}/>;

    const rowRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
      if (rowRef.current) setRowHeight(index, rowRef.current.clientHeight);
    }, [rowRef]);
  
    const message = logChatStore.messages[index];
    const {msg, searchIndex} = message;
    let roleStyle: string;
    let icon: string;
    let scrolledUser = false;
    if (msg.role === Role.User) {
      roleStyle =  styles.user;
      icon = memberIcon(memberStore.member) ?? '';
      if (searchIndex >= 0 && searchIndex == logChatStore.scrollToIndex) scrolledUser = true;
    }
    else {
      roleStyle = styles.bot;
      icon = bot;
    }
  
    const typewriterEffect = index == logChatStore.messages.length - 1 && 
                             msg.role === Role.Assistant && 
                             messages.length > firstMessageLength;

    const clickMessage = () => {
      const {searchIndex} = message;
      console.log('clicked message search index' + searchIndex);
      if (searchIndex < 0) return;
      logChatStore.setScrollToIndex(searchIndex);
      listRef.current?.resetAfterIndex(index);
    }

    return (
      <div style={style}>
        <div className={`${styles.message} ${roleStyle} ${scrolledUser && styles.scrolledUser}`} ref={rowRef} onClick={clickMessage}>
          <img src={icon} alt="speaker icon" />
          <div >{typewriterEffect ? 
            <Typewriter text={msg.content} minSpeed={1} maxSpeed={300} onTextChange={() => {
              scrollToBottom();
              if (rowRef.current) setRowHeight(index, rowRef.current.clientHeight)
            }}/> :
            <>{msg.content}</>
          }</div> 
        </div>
      </div>
    )
  };

  useEffect(() => {
    if (loading || messages.length > 0) {
      scrollToBottom();
    }
    // eslint-disable-next-line
  }, [messages, loading]);

  const scrollToBottom = () => {
    if (listRef.current) listRef.current.scrollToItem(messages.length - 1, "start");
  };
  

  const handleItemsRendered = ({ visibleStartIndex, visibleStopIndex }: ListOnItemsRenderedProps) => {
    console.log(`handleItemsRendered visibleStartIndex: ${visibleStartIndex}, visibleStopIndex: ${visibleStopIndex}`);
    if (visibleStartIndex >= messages.length) return;
    
    const {searchIndex} = messages[visibleStartIndex];
    if (searchIndex !== prevIndexSearch.current && searchIndex >= 0) {  
      prevIndexSearch.current = searchIndex;
      logChatStore.setScrollToIndex(searchIndex);
    }
  };

  useEffect(() => {
    setName(logChat?.name ?? '');
  }, [logChat?.name]); 

  const handleChangeName = (event: FormEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  }
  const handleSubmitName = (event: FormEvent) => {
    event.preventDefault();
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }

    logChatStore.updateChatName(name!);
    setEditName(false);
  }


  const messagesLength = messages.length + (loading ? 1 : 0);
  const shareUrl = window.location.href;
  const shareSummary = name;
  const shareDescription = `LogChat: \n${name} \n${shareUrl}`;

  return (
    <div className={styles.chatView}>
      { messagesLength === 0 ? 
        <EmptyMessagesView/> :
        <>
          <div className={styles.logsHeader}>
            <form onSubmit={handleSubmitName} className={styles.nameForm}>
              <div className={styles.inputChatName}>
                <input type="text" name="name" placeholder="name"
                  value={name} onChange={handleChangeName}
                  onFocus={() => setEditName(true)} />
                <img alt="pen" src={pen} />
                <div className={styles.seperator}/>
                <ShareBtn summary={shareSummary} description={shareDescription} url={shareUrl} style={ShareStyle.SMAll}/>
              </div>
              {editName && <input type="image" className={styles.inputNameSubmit} src={submit} />}
            </form>
          </div>
          <div className={styles.messages}>
            <AutoSizer>
              {({ height, width }) => (
                console.log(`AutoSizer height: ${height}, width: ${width}`),
                <List
                  height={height}
                  width={width}
                  itemCount={messagesLength}
                  itemSize={getRowHeight}
                  ref={listRef}
                  onItemsRendered={handleItemsRendered}>
                  {MessageRow}
                </List>
              )}
            </AutoSizer>
          </div>
        </>
      }
      <MessageInput />
    </div>
  );
  
});

export default MessagesView;