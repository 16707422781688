import * as React from 'react';
import * as mobx from 'mobx';

import styles from './user_settings_notification.module.css';
//import Icon from '../common/icon.component';
import memberStore, { MemberStore } from '../../stores/member.store';
import Popup, { PopupInfo } from '../common/popup.component';
import { ISubscriptionGroup } from '../../models/subscription_group.model';
import Switch from '../common/switch.component';
import { observer } from 'mobx-react';
import ResetSubmitButton from '../reset_submit_button.component';
// import { FormEventHandler } from 'react';

interface State {
   subscriptionGroups: ISubscriptionGroup[];
}

class UserSettingsNotification extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = { subscriptionGroups: mobx.toJS(memberStore.subscriptionGroups) };
  }

  async componentDidMount(){
    await memberStore.getSubscriptionGroups();
    this.setState({ subscriptionGroups: mobx.toJS(memberStore.subscriptionGroups) });
  }

  private handleReset() {
    this.setState({ subscriptionGroups: mobx.toJS(memberStore.subscriptionGroups) });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async handleSubmit(event: any) {
    event.preventDefault();
    const length = this.state.subscriptionGroups.length;
    const suppressEmail = [];
    for (let i = 0; i < length; i++) {
      const stateSubs = this.state.subscriptionGroups[i];
      const storeSubs = memberStore.subscriptionGroups[i];
      if (stateSubs.id !== storeSubs.id) {
        console.error('had state and props subs not equal');
        return;
      }
      if (stateSubs.suppressed !== storeSubs.suppressed) {
        suppressEmail.push({
          id: stateSubs.id, 
          suppress: stateSubs.suppressed,
        });
      }
    }
    await memberStore.suppressEmail(suppressEmail);
    this.setState({ subscriptionGroups: mobx.toJS(memberStore.subscriptionGroups) });

    const info: PopupInfo = {
      msg: `Your changes have been succesfully updated`,
    };
    Popup.create(info);
  }

  private handleSwitch(groupId: number, suppressed:boolean){
    const {subscriptionGroups} = this.state;
    const changedItem = subscriptionGroups.find(group => group.id === groupId);
    if (changedItem) {
      changedItem.suppressed = suppressed;
      this.setState({ subscriptionGroups });
    }
  }

  render() {
    const subscriptionGroups = this.state.subscriptionGroups;
    if (subscriptionGroups.length === 0) return <div>Loading..</div>;

    return (
      <div className={styles.component}>
        <form onSubmit={this.handleSubmit} onReset={this.handleReset}>

          <div className={styles.title}>
            Email Subscription Groups
          </div>
          <div className={styles.subTitle}><b>{memberStore.member!.email}</b> will receive the following notifications:</div>
          {
            subscriptionGroups?.map(group => {
              return (
                <div className={styles.filterHolder} key={group.id}>
                  <div >{group.name}</div>
                  <Switch oneMode={group.suppressed} onClick={(one) => this.handleSwitch(group.id, one)}/>
                </div>
              )
            })
          }
          <ResetSubmitButton />
        </form>
      </div>
    );
  }
}

export default observer(UserSettingsNotification);