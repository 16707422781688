/* eslint-disable jsx-a11y/alt-text */

import * as React from 'react';
import { observer } from 'mobx-react';
import { ListChildComponentProps as ListProps } from 'react-window';
import styles from './loglytics_row.module.css';
import { LoglyticsType, ILoglytics, ILoglyticsMessage, LoglyticsStatus, ILoglyticsException } from '../../models/loglytics.model';
import arrayService from '../../services/array.service';
import memberStore from '../../stores/member.store';
import loglyticsStore from '../../stores/loglytics.store';
import { Exception, Severity } from '../../models/log.model';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import numberService from '../../services/number.service';
import ReactTooltip from 'react-tooltip';
// import ShareBtn, { ShareStyle } from '../common/sharebtn.component';

import appleIcon from '../../img/apple-icon.svg';
import androidIcon from '../../img/android-icon.svg';

interface Props extends ListProps, RouteComponentProps {
}

class LoglyticsRow extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeRead = this.changeRead.bind(this);
    this.showItem = this.showItem.bind(this);
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  showItem() {
    const {data, index}  = this.props;
    const {accountId, appId} = memberStore;
    const path = `/${accountId}/${appId}/loglytics/${data[index].key}`;

    this.props.history.push(path);
  }

  changeStatus(e: React.MouseEvent<HTMLElement>, status: LoglyticsStatus) {
    e.stopPropagation();
    loglyticsStore.setStatus(status, this.props.index)
  }

  changeRead(e: React.MouseEvent<HTMLElement>, read: boolean) {
    const {data, index}  = this.props;
    e.stopPropagation();
    loglyticsStore.setLoglyticsRead(read, data[index]._id);
  }

  render() {
    const {data, index}  = this.props;
    const {bridge, deviceCount} = loglyticsStore!
    const loglytic: ILoglytics & {read?: boolean} = data[index];

    const osVersions = arrayService.sortVersion(loglytic.osVersion!.slice());
    let osVersionString = ''; 
    if (osVersions.length > 0) {
      osVersionString = osVersions.length === 1 ? osVersions[0] : 
        `${osVersions[0]} - ${osVersions[osVersions.length-1]}`;
    }

    const appVersions = arrayService.sortVersion(loglytic.appVersion!.slice());
    let appVersionString = ''; 
    if (appVersions.length > 0) {
      appVersionString = appVersions.length === 1 ? appVersions[0] : 
        `${appVersions[0]} - ${appVersions[appVersions.length-1]}`;
    }

    const {platform} =  memberStore.app!
    const osIcon = platform === 'ios' ? appleIcon:  androidIcon;
    const statusIcon = undefined;
    // switch (loglytic.status) {
    //   case LoglyticsStatus.FIXED: statusIcon = '/img/loglytics/statusFixed.svg'; break;
    //   case LoglyticsStatus.IGNORED: statusIcon = '/img/loglytics/statusIgnored.svg'; break;
    // }

    let color = '';
    switch (loglytic.severity) {
      case Exception.EXCEPTION:
        color = "var(--black)"
        break;
      case Severity.ERROR:
        color = "var(--lipstick)"
        break;
      case Severity.WARNING:
        color = "var(--mango)"
        break;
    }

    
    const percentage = loglytic.devices! * 100 / deviceCount!.device;

    let logMessage = '';
    let fileName = '';
    let lineNumber: string | number = 0;
    if (loglytic.type === LoglyticsType.EXCEPTION) {  
      const loglyticsException = loglytic as ILoglyticsException;  
      logMessage = loglyticsException.name + ': ' + loglyticsException.reason;
      if (loglyticsException.stackTrace?.length > 0) {
        fileName = loglyticsException.stackTrace[0].fileName;
        lineNumber = loglyticsException.stackTrace[0].lineNumber;
      }
    } else {
      const loglyticsMessage = loglytic as ILoglyticsMessage;
      logMessage = loglyticsMessage.message;
      fileName = loglyticsMessage.fileName.slice(loglyticsMessage.fileName.lastIndexOf('/') + 1);
      lineNumber = loglyticsMessage.lineNumber;
    }

    // let ignoreIcon: string;
    // let ignoreChange: LoglyticsStatus;
    // let ignoreTip: string;
    // // if (loglytic.status !== LoglyticsStatus.IGNORED) {
    // //   ignoreIcon = "/img/loglytics/ignore.svg";
    // //   ignoreChange = LoglyticsStatus.IGNORED;
    // //   ignoreTip = "Ignore";
    // // }
    // // else {
    //   ignoreIcon = "/img/loglytics/unignore.svg";
    //   ignoreChange = LoglyticsStatus.OPEN;
    //   ignoreTip = "Unignore";
    // // }

    // let fixIcon: string;
    // let fixChange: LoglyticsStatus;
    // let fixTip: string;
    // // if (loglytic.status !== LoglyticsStatus.FIXED) {
    // //   fixIcon = "/img/loglytics/fix.svg";
    // //   fixChange = LoglyticsStatus.FIXED;
    // //   fixTip = "Fix";
    // // }  
    // // else {
    //   fixIcon = "/img/loglytics/unfix.svg";
    //   fixChange = LoglyticsStatus.OPEN;
    //   fixTip = "Unfix";
    // // }

    // const {pathname} = this.props.location;
    // const shareUrl = `${pathname}/${data[index].key}`;
    // const shareSummary = 'Shipbook > Loglytics';
    // const shareDescription = `Loglytics issue: \n${logMessage}\n ${shareUrl}`;

    // const hasRead = loglytic.read;
    // let readIcon; 
    // let readTip;
    // if (hasRead) {
    //   readIcon = "/img/loglytics/unread.svg";
    //   readTip = 'Mark as unread';
    // }
    // else {
    //   readIcon = "/img/loglytics/read.svg";
    //   readTip = 'Mark as read';
    // } 
    return (
      <div className={styles.rowBase} style={this.props.style} onClick={this.showItem}>
        {/* <div className={`${styles.row} ${hasRead ? '' : styles.unread}`}>  */}
        <div className={`${styles.row} ${styles.unread}`}> 
          <div className={styles.rowPercentage} style={{color}} >{Math.floor(percentage * 100) / 100}%</div>   
          { !bridge && <>
            <div className={styles.rowSum}>({numberService.abbreviateNumber(loglytic.devices > 0 ? loglytic.devices : 1)})</div>
            {(fileName.length > 0 || lineNumber !== 0) && <div className={styles.rowLocation}>{fileName}:{lineNumber}</div>}
          </>}
          <div className={styles.rowMessage}>{logMessage}</div>
          { !bridge && <>
            <img src={osIcon} className={styles.rowOsIcon}/>
            <div className={styles.rowOsVersion}>{osVersionString}</div>
            <div className={styles.rowAppVersion}>{appVersionString}</div>
            <div className={styles.rowLine}/>
            <img src={statusIcon} className={`${styles.rowStatusIcon} ${statusIcon ? '': styles.hidden}`}/>
          </>}
        </div>
        {/* <div className={styles.rowTools}>
          <ShareBtn summary={shareSummary} description={shareDescription} style={ShareStyle.SMAll}/>
        </div> */}
        {/* <div className={styles.rowTools}>
          <img src="/img/loglytics/share.svg" className={styles.rowToolIcon}/>
          <img src={readIcon} data-tip={readTip} className={styles.rowToolIcon} onClick={(e) => this.changeRead(e, !hasRead)}/>
          <img src={ignoreIcon} data-tip={ignoreTip} className={styles.rowToolIcon} onClick={(e) => this.changeStatus(e, ignoreChange)}/>
          <img src={fixIcon} data-tip={fixTip} className={styles.rowToolIcon} onClick={(e) => this.changeStatus(e, fixChange)}/>
          <img src="/img/loglytics/settings.svg" className={styles.rowToolIcon}/>
        </div> */}
      </div>
    );
  }    
}

export default withRouter(observer(LoglyticsRow));