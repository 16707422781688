import React from 'react';

import styles from './logchat.module.css';

import LogChatItem from './logchat_item.component';
import LogChatSidebar from './logchat_sidebar.component';

const LogChat = () => {
  return (
    <div className={styles.component}>
      <LogChatSidebar />
      <LogChatItem />
    </div>
  );
};

export default LogChat;