import * as React from 'react';
import styles from './pref_authkeys.module.css';

import memberStore from '../../stores/member.store';
import { observer } from 'mobx-react';

import Copy from '../common/copy.component';
import { IAuthKey, AuthKeyType } from '../../models/account.model';
import Popup, { PopupInfo } from '../common/popup.component';
import Time from '../../services/time.service';

import trash from '../../img/preferences/trash.svg';
import pen from '../../img/preferences/pen.svg';

interface State {
  APIValue?: string;
  DSymValue?: string;
}

class PrefAuthKeys extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      APIValue: undefined,
      DSymValue: undefined
    }
    this.handleChange = this.handleChange.bind(this);
    this.addKey = this.addKey.bind(this);
  }

  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  private async addKey(selectedKeyType: AuthKeyType) {
    const { APIValue, DSymValue} = this.state;
    const selectValue = selectedKeyType === AuthKeyType.API ? APIValue : DSymValue ;
    if (!selectValue) return;

    const newKeyData = await memberStore.addAccountAuthKey(selectValue, selectedKeyType);
    await memberStore.pullMember();
    const info: PopupInfo = {
      iconSrc: undefined,
      msg: ( 
        <KeyPopup name={selectValue} type={newKeyData.key.type} token={newKeyData.token!}/>
      ),
    };
    Popup.create(info);
    this.setState({
      APIValue: '',
      DSymValue: ''
    });
  }

  render() {
    const { authKeys } = memberStore.account!;
    const apiKeys = authKeys?.filter(key => key.type === AuthKeyType.API);
    const dsymKeys = authKeys?.filter(key => key.type === AuthKeyType.SYMBOLS);
    const keyTypes = {};
    keyTypes[''] = 'Key Type';
    keyTypes[AuthKeyType.API] = 'API';
    keyTypes[AuthKeyType.SYMBOLS] = 'DSym';

    return (
      <div className={styles.component}>
        <div className={styles.title}> Authentication Keys </div>
        <div className={styles.subTitle}> API Keys </div>
        <div className={styles.body}>Here you can get the token for the APIs that are open to the public and free for use with Shipbook
          for app programming and development.  See API document for more information <a href="https://www.shipbook.io/api-doc">here</a>.</div>

        <div className={styles.keyList}>
          {apiKeys && apiKeys.map((key) => {
            return (<KeyInfo key={key.key} authKey={key} keyType={key.type} />);
          })}
        </div>
        <div className={styles.underline} />        
        <div className={styles.addPanel}>
          <input type="text" className={styles.inputKeyName} placeholder="API key name"
            value={this.state.APIValue} name="APIValue" onChange={this.handleChange} />

          <div className={styles.buttonHolder}><button className={styles.addButton} onClick={()=>this.addKey(AuthKeyType.API)} >add</button></div>
        </div>          
          
        <div className={styles.subTitle}> DSYM Keys </div>
        <div className={styles.body}>Shipbook needs the dSYM file for the application to produce human-readable stack traces of the crash.
          For more info view <a href="https://docs.shipbook.io/ios#upload-the-dsym-file">upload dsym file</a>.</div>
        <div className={styles.keyList}>
          {dsymKeys && dsymKeys.map((key) => {
            return (<KeyInfo key={key.key} authKey={key} keyType={key.type} />);
          })}
        </div>
        <div className={styles.underline} />        
        <div className={styles.addPanel}>
          <input type="text" className={styles.inputKeyName} placeholder="DSYM key name"
            value={this.state.DSymValue} name="DSymValue" onChange={this.handleChange} />
          <div className={styles.buttonHolder}><button className={styles.addButton} onClick={()=>this.addKey(AuthKeyType.SYMBOLS)} >add</button></div>
        </div>         
      </div>
    );
  }
}

export default observer(PrefAuthKeys);

interface AuthKeyProps {
  authKey: IAuthKey;
  keyType: string;
}
interface ItemState {
  edit: boolean;
  keyName: string;
}
class KeyInfo extends React.Component<AuthKeyProps, ItemState> {
  constructor(props: AuthKeyProps) {
    super(props);
    this.state = {
      edit: false,
      keyName: props.authKey.name
    }
    this.onTrash = this.onTrash.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleUpdateName = this.handleUpdateName.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleChangeName(event: any) {
    const keyName = event.target.value as string;
    this.setState({keyName});
  }

  private async handleUpdateName() {
    //do update database
    await memberStore.updateAccountAuthKey(this.props.authKey, this.state.keyName);
    await memberStore.pullMember();

    this.setState({edit: false});
  }

  private onTrash() {
    console.log('pressed on trash');
    const authKey = this.props.authKey;
    const info: PopupInfo = {
      title: 'Delete',
      msg: `Are you sure that you want to delete  Key ${authKey.name} from the account?`,
      ok: 'Delete',
      okCallback: async () => {
        await memberStore.deleteAccountAuthKey(authKey);
      },
      cancel: 'Cancel'
    };
    Popup.create(info);
  }

  render() {
    const { authKey, keyType } = this.props;
    if (!authKey) {
      console.log('there was no key');
      return <div />;
    }
    const {keyName} = this.state;
    return (
      <div className={styles.keyInfo}>
        <div className={styles.inputName}>
          <input type="text" name="name" placeholder="name" 
                value={keyName} onChange={this.handleChangeName} 
                onFocus={() => this.setState({edit: true})} />
          <img alt="pen" src={pen}/>
        </div>
        <div className={styles.buttonName}>
        {this.state.edit && 
          <button onClick={this.handleUpdateName}>submit</button>}
        </div>  
        <div className={styles.keyType}>{keyType}</div>
        <div className={styles.keyType}>{new Time(authKey.expDate).getShortDate() }</div>
        <img alt="trash" className={`${styles.trash}`}
          src={trash} onClick={this.onTrash} />
      </div>
    );
  }
}

interface KeyPopupProps {
  type: string;
  name: string;
  token: string;
}

class KeyPopup extends React.Component<KeyPopupProps> {

  render() {
    const {type, name, token} = this.props;
    return (
      <div >
        <div>
          Type: {type}
        </div>
        <div>
          Name: {name}
        </div>
        <div>
          <div >
            <div className={styles.tokenTitleItem}>Token</div>
          </div>
          <textarea rows={4} value={token}
            readOnly className={styles.tokenText} name="newApiKeyToken" />
          <Copy text={token} />
          <div>
            {type === AuthKeyType.API ? 
              'Use the token when making API request. This token is provided once' :
              'Use the token when authenticating for to upload symbols file. This token is provided once'
            }
          </div>
        </div>
      </div>

    );
  }
}
