import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnregisterCallback } from 'history';

import styles from './logs.module.css';

import logsStore from '../../stores/logs.store';
import searchStore from '../../stores/search.store';

import LogSearch from './log_search.component';
import LogListView from './log_list_view.component';
import LogBottom from './log_bottom.component';
import LogGraph from './log_graph.component';

interface Props extends RouteComponentProps<{}> {
}

class Logs extends React.Component<Props> {
  historyListener: UnregisterCallback;
  constructor(props: Props) {
    super(props);
    const {search} = props.location!;
    this.params(search);
    this.historyListener = props.history!.listen((listener) => {
      if (listener.pathname.endsWith('/logbook')) this.params(listener.search); 
    });
  }

  private params(search: string) {
    console.log('entered params with:', search);
    searchStore.setSearchParams(search);
    setTimeout(() => {logsStore!.getLogs(true)}, 0); //timeout so that the parameters will be changed.
  }

  async componentDidMount() {
    await searchStore!.loadSearchParams();
    const {search} = this.props.location!;
    this.params(search);
  }

  componentWillUnmount() {
    this.historyListener();
  }

  render() {
    return (
      <div className={styles.component}>
        <LogSearch />
        <LogGraph />
        <LogListView />
        <LogBottom />
      </div>
    );
  }
}

export default withRouter(Logs);