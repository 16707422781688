
import  React from 'react';
import PrefMenu from '../pref_menu.component';
import memberStore from '../../stores/member.store';

const itemsOrig = [
  {name: 'User Profile', url: 'profile'},
  {name: 'Email Notifications', url: 'notification'},
  {name: 'Refer A Friend', url: 'refer-a-friend'},
];

const UserSettingsMenu  = () => {
  const member = memberStore.member!;
  const {accountId} = memberStore;
  const prefUrl = '/user-settings';
  const items = itemsOrig.map((item) => {
    return {name: item.name, url: prefUrl + '/' + item.url};
  });

  const menuLink = {
    name: 'Account Preferences',
    url: `/${accountId}/preferences/general`,
  }

  return (
    <PrefMenu title="User Settings" subTitle={member.email} items={items} menuLink={menuLink}/>
  );
};

export default UserSettingsMenu;