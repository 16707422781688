import IApp, { ICustomAlert } from "../../models/app.model";
import IAppVersionFile from "../../models/app_version_file.model";
import ConnectionService, { HttpMethod } from "./connection.service";

class AppConnection {
  public async updateApp(appId: string, data: object): Promise<IApp> {
    return await ConnectionService.fetch(`apps/${appId}`, HttpMethod.PUT, data);
  }
  public async deleteApp(appId: string) {
    return await ConnectionService.fetch(`apps/${appId}`, HttpMethod.DELETE);
  }

  public async getApp(appId: string): Promise<IApp> {
    return await ConnectionService.fetch(`apps/${appId}`);
  }

  public async uploadDsym(appId: string, version: string, build: string, file: File) {
    return await ConnectionService.fetch(`apps/${appId}/upload/dsym?version=${version}&build=${build}`, 
      HttpMethod.POST, file);
  }

  public async getAppVersionFiles(appId: string): Promise<IAppVersionFile[]> {
    return await ConnectionService.fetch(`apps/${appId}/appVersionFiles`);
  }

  public async deleteAppVersionFile(appId: string, appVersionFileId: string) {
    return await ConnectionService.fetch(`apps/${appId}/appVersionFiles/${appVersionFileId}`, HttpMethod.DELETE);
  }

  public async addAlert(appId: string, data:object): Promise<ICustomAlert> {
    return await ConnectionService.fetch(`apps/${appId}/alert`, HttpMethod.POST, data)
  }

  public async updateAlert(appId: string, alertId: string, data:object): Promise<ICustomAlert> {
    return await ConnectionService.fetch(`apps/${appId}/alert/${alertId}`, HttpMethod.PUT, data);
  }

  public async deleteAlert(appId: string, alertId: string) {
    await ConnectionService.fetch(`apps/${appId}/alert/${alertId}`, HttpMethod.DELETE);
    return;
  }
}

export default new AppConnection();