import React from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import styles from './pref_menu.module.css';

import Sidebar from './common/sidebar.component';

import referAFriend from '../img/preferences/refer-a-friend.svg';


const PrefLink = ({url, name}: {url: string, name: string}) => {
  const location = useLocation();
  const selected = location.pathname.includes(url);
  console.log('selected', selected, url, location.pathname);  
  return (  
    <Link className={`${styles.item} ${selected && styles.selected}`} to={url}>{name}</Link>
  );
}

interface PrefItem {
  name: string;
  url: string;
}

interface MenuLink {
  url: string;
  name: string;
}

interface PrefMenuProps {
  title: string;
  subTitle: string;
  items: PrefItem[];
  menuLink?: MenuLink;
  referAndEarn?: boolean;
}

const PrefMenu = ({title, subTitle, items, menuLink, referAndEarn}: PrefMenuProps) => { 
  return (
    
    <Sidebar>
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>
      {items.map((item, index) => { 
        return <PrefLink key={index} url={item.url} name={item.name}/>
      })}
      <div className={styles.space}/>
      {referAndEarn && 
        <Link to="/user-settings/refer-a-friend" className={styles.referAFriend}>
          <img src={referAFriend} alt='Refer A Friend'/>
          <div>Invite friends and earn a <b>$25 Gift Card</b> <br/>for both of you when they make a payment!</div>
        </Link>
      }
      {menuLink && <div className={styles.menuLinkWrapper}>
        <Link to={menuLink.url} className={styles.menuLink}>{menuLink.name}</Link>  
      </div>}
    </Sidebar>
  )
};

export default PrefMenu;