
import { observable, computed, action, reaction, makeObservable } from 'mobx';
import storageService from '../services/storage.service';
import memberStore from './member.store';

export class CommonStore {
  public token?: string = storageService.getItem('token', '').length > 0 ? storageService.getItem('token', '') : undefined;
  _loading = 0;
  get loading() {
    return this._loading > 0;
  }
  public origPath?: string = undefined;

  constructor() {
    makeObservable(this, {
      token: observable,
      _loading: observable,
      loading: computed,
      appLoaded: computed,
      reset: action,
      setToken: action,
      stopLoading: action,
      setLoading: action
    });

    reaction(
      () => this.token,
      token => {
        if (token) {
          storageService.setItem('token', token);
        } else {
          storageService.removeItem('token');
        }
      }
    );
  }

  get appLoaded() {
    return !!memberStore.member
  }

  reset() {
    this.token = undefined;
    this._loading = 0;
  }

  setToken(token?: string) {
    this.token = token;
  }

  stopLoading() {
    this._loading = 0;
  }

  setLoading(loading: boolean) {
    console.info('setLoading: ' + loading);
    if (loading) ++this._loading;
    else if (this._loading > 0) --this._loading;
  }
}

export default new CommonStore();