import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import SearchStore from './search.store';
import chatConnection from '../services/connection/chat.connection';
import ILogChat, { Role } from '../models/logchat.model';

import commonStore from './common.store';
import Popup from '../components/common/popup.component';
import moment from 'moment';

export interface IListItem {
  type: 'chat' | 'date';
  content: Date | ILogChat;
}
export class LogChatStore {
  logChat?: ILogChat;
  loading: boolean = false;
  chatList: ILogChat[] = [];
  scrollToIndex: number = -1;
  inputPrompt: string = '';
  prompt: string = '';

  constructor() {
    makeObservable(this, {
      logChat: observable,
      loading: observable,
      chatList: observable,
      scrollToIndex: observable,
      inputPrompt: observable,
      prompt: observable,
      setPrompt: action,
      setScrollToIndex: action,
      clearChat: action,
      startLogChat: action,
      chat: action,
      getChat: action,
      getChatList: action,
      deleteChat: action,
      updateChatName: action,
      messages: computed,
      searchArray: computed,
      list: computed
    });
  }

  setPrompt =(prompt: string) => {
    this.prompt = prompt;
  }

  setScrollToIndex(index: number) {
    console.log('setScrollToIndex: ' + index + ' original: ' + this.scrollToIndex);
    this.scrollToIndex = index;
  }

  get messages() {
    // empty chat
    if (!this.logChat?.messages) {
      if (this.inputPrompt.length > 0 )
        return [{msg: {role: Role.User, content: this.inputPrompt}, searchIndex: -1}];
      else return [];
    }

    // chat with messages
    let searchIndex = -1;
    const msgs = [];
    for (let i = 0; i < this.logChat.messages.length; i++) {
      const message = this.logChat.messages[i];
      const nextMessage = this.logChat.messages[i+2]; // there is a asistant message between user and function
      if (nextMessage?.role == Role.Tool || nextMessage?.role == Role.Function) searchIndex++;
  
      if (message.role !== Role.System && message.role !== Role.Tool && message.role !== Role.Function && message.content) {
        msgs.push({msg: message, searchIndex});
      }
    }

    return msgs;
  }

  get searchArray () {
    const messages = this.logChat?.messages;
    if (!messages) return [];
    const searchArray = [];
    for (let i = 0; i < messages.length; i++) {
      if ((messages[i].role == Role.Tool || messages[i].role == Role.Function) && messages[i].search) {
        const title = messages[i-2]?.content;
        searchArray.push({title, search: messages[i].search!, index: i });
      }
    } 
    // console.log('searchArray: ' + JSON.stringify(toJS(searchArray)));
    return searchArray;
  }

  get list () {
    const list: IListItem[] = [];
    let currentDate:Date;
    this.chatList.forEach(chat => {
      if (!currentDate || !moment(chat.createdAt).isSame(currentDate, 'day')) {
        currentDate = chat.createdAt;
        list.push({ type: 'date', content: chat.createdAt });
      }
      list.push({ type: 'chat', content: chat});
    });
    return list;
  }

  clearChat() { 
    this.logChat = undefined;
    this.inputPrompt = '';
  }

  async startLogChat() {
    this.loading = true;
    commonStore.setLoading(true);
    this.logChat = undefined;
    this.inputPrompt = '';
    try {
      const data = SearchStore.logsParams;
      const res = await chatConnection.startLogChat(data);
      runInAction(() => {
        this.logChat = res.chat;
      });
    }
    catch (error) {
      console.error(error);
      Popup.createError({msg: 'Had a problem starting chat, please try later again'}); 
    }
    finally {
      runInAction(() => {
        commonStore.setLoading(false);
        this.loading = false;
      });
    }
  }

  async chat(prompt: string) {
    const logChat = this.logChat;
    this.loading = true;

    try {
      let res: {chat: ILogChat};
      if (logChat) {
        logChat.messages.push({
          role: Role.User,
          content: prompt
        });
        res = await chatConnection.chat(logChat._id, prompt);
      }
      else { // new chat
        this.inputPrompt = prompt;
        res = await chatConnection.start(prompt);
      }
  
      runInAction(() => {
        this.logChat = res.chat;
      });
    }
    catch (error) {
      console.error(error);
      Popup.createError({msg: 'Had a problem posting message, please try later again.'});
    }
    finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async getChat(chatId: string) {
    this.loading = true;
    try {
      const res = await chatConnection.getChat(chatId);
      runInAction(() => {
        this.logChat = res.chat;
      });
    }
    catch (error) {
      console.error(error);
      Popup.createError({msg: 'Had a problem loading chat, please try later again'}); 
    }
    finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async getChatList() {
    this.loading = true;
    try {
      const res = await chatConnection.getChatList();
      runInAction(() => {
        this.chatList = res;
      });
    }
    catch (error) {
      console.error(error);
      Popup.createError({msg: 'Had a problem loading chat list, please try later again'}); 
    }
    finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async deleteChat(chatId: string) {
    this.loading = true;
    try {
      await chatConnection.deleteChat(chatId);
      runInAction(() => {
        this.chatList = this.chatList.filter(chat => chat._id !== chatId);
      });
    }
    catch (error) {
      console.error(error);
      Popup.createError({msg: 'Had a problem deleting chat, please try later again'}); 
    }
    finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async updateChatName(name: string) {
    this.loading = true;
    try {
      const res  = await chatConnection.updateChat(this.logChat!._id, {name});
      runInAction(() => {
        this.logChat = res.chat;
        this.chatList = this.chatList.map(chat => {
          if (chat._id === this.logChat!._id) {
            chat.name = name;
          }
          return chat;
        });
      });
    }
    catch (error) {
      console.error(error);
      Popup.createError({msg: 'Had a problem updating chat name, please try later again'}); 
    }
    finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new LogChatStore();