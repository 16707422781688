import * as React from 'react';
import { Switch, Route, withRouter, RouteComponentProps, Redirect, match } from 'react-router-dom';
import PrefAppGeneral from '../components/pref_app/pref_app_general.component';
import PrefAppDoc from '../components/pref_app/pref_app_doc.component';
import PrefAppWizard from '../components/pref_app/pref_app_wizard.component';
import PrefAppGdpr from '../components/pref_app/pref_app_gdpr.component';
import PrefAppConf from '../components/pref_app/pref_app_conf.component';
import PrefAppVersionFiles from '../components/pref_app/pref_app_version_files.component';
import PrefAppAlerts from '../components/pref_app/pref_app_alerts.component';
import PrefAppAlertsEdit from '../components/pref_app/pref_app_alerts_edit.component';

const PrefAppRoutes = withRouter(() => {

  return (
    <Switch>
      <Route path="/:accountId/:appId/preferences/general" render={() => <PrefAppGeneral/>} />
      <Route path="/:accountId/:appId/preferences/doc" render={() => <PrefAppDoc/>} />
      <Route path="/:accountId/:appId/preferences/new" render={() => <PrefAppWizard/>} />
      <Route path="/:accountId/:appId/preferences/alerts/edit/:alertId" render={() => <PrefAppAlertsEdit/>} />
      <Route path="/:accountId/:appId/preferences/alerts/new" render={() => <PrefAppAlertsEdit/>} />
      <Route path="/:accountId/:appId/preferences/alerts" render={() => <PrefAppAlerts/>} />
      <Route path="/:accountId/:appId/preferences/gdpr" render={() => <PrefAppGdpr/>} />
      <Route path="/:accountId/:appId/preferences/configuration" render={() => <PrefAppConf/>} />
      <Route path="/:accountId/:appId/preferences/dsym" render={() => <PrefAppVersionFiles/>} />
      <Redirect from="/:accountId/:appId/preferences/" to="/:accountId/:appId/preferences/general" />
    </Switch>
  );

});

export default PrefAppRoutes