import ConnectionService, { HttpMethod } from './connection.service';
import IJiraCloud from '../../models/dto/jira_cloud.model';
import IJiraIssueCreateResponse from '../../models/dto/jira_issue_create_response.model';

class JiraConnection {
  public async getJiraOptions(jiraToken:string): Promise<IJiraCloud>{
    return await ConnectionService.fetch(`integration/jira/options?jiraToken=${jiraToken}`);
  }
  
  public async createJiraIssue(jiraToken:string, data:object): Promise<IJiraIssueCreateResponse>{
    return await ConnectionService.fetch(`integration/jira/issue?jiraToken=${jiraToken}`, HttpMethod.POST, data);
  }

  public async getAutUrl() {
    return await ConnectionService.fetch('integration/jira/authUrl');
  }
}

export default new JiraConnection();