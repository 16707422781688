import IBase from './base.model';
import IApp from './app.model';
import IMember from './member.model';
import IPlan from './plan.model';

export enum Role {
  owner = 'OWNER',
  admin = 'ADMIN',
  developer = 'DEVELOPER'
}
export enum AuthKeyType {
  SYMBOLS = 'symbols',
  API = 'api'
}


export interface IAuthKey{
  type: AuthKeyType;
  key: string;
  name: string;
  expDate: Date;
}

export interface IMemberRole {
  member: IMember | string;
  role: Role;
}

export interface IAddress {
  country: string;
  state: string;
  address: string;
  city: string;
  zip: string;
  label: string;
}

export interface IBilling {
  vaultedShopperId: string;
  subscriptionId: string;
}

export interface IInvoice {
  id: string,
  number: number;
  url: {
      origin: string,
      he: string,
      en: string
  }
}

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER'
}

export interface IPayment {
  date: Date;
  amount: number;
  plan: IPlan | string;
  invoice?: IInvoice;
  paymentMethod?: PaymentMethod;
  month?: number;
  startDate?: Date;
}

export enum Percent {
  EIGHTY = 80, 
  HUNDRED = 100
}

export interface INotification {
  msg: string;
  url: string;
  linkMsg: string;
}

export default interface IAccount extends IBase {
  name: string;
  companyName?: string;
  taxId?: string;
  address?: IAddress;
  members: IMemberRole[];
  apps: (IApp)[];
  phone: string;
  subscription: {
    plan: IPlan | string,
    nextPayment?: Date,
    notification: {
      trialEnd?: number;
      logLimit?: {
        date: Date;
        percent: Percent;  
      },
      failedPayment?: {
        date: Date;
        count: number;
        reason: string;
      }
    },
    exceedLogLimit: Date[];
  };
  billing?: IBilling;
  invoiceEmails?: string[];
  payments: IPayment[];
  authKeys?: IAuthKey[];
  slackIntegration: ISlackIntegration[];
  notification?: INotification;
}

export interface ISlackIntegration {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _id: any,
  slack: {
    app_id: string,
    authed_user: {
      id: string,
      scope?: string,
      access_token?: string,
      token_type?: string,
    },
    scope: string,
    token_type: string,
    access_token: string,
    bot_user_id: string,
    team: {
      id: string,
      name: string
    },
    enterprise?: {
      id: string,
      name: string
    },
    incoming_webhook: {
      channel: string,
      channel_id: string,
      configuration_url: string,
      url: string
    }
  },
  app?: string;
}