import * as React from 'react';
import styles from './pref_integrations.module.css';

import memberStore from '../../stores/member.store';
import { observer } from 'mobx-react';
import commonStore from '../../stores/common.store';
import {ISlackIntegration} from '../../models/account.model'
import Popup, { PopupInfo } from '../common/popup.component';
import Select from '../common/select.component';
import environmentService from '../../services/environment.service';

import trash from '../../img/preferences/trash.svg';

interface SlackIntegrationProps {
  integration: ISlackIntegration;
}

const SlackIntegration = observer(class SlackIntegration extends React.Component<SlackIntegrationProps> {
  constructor(props: SlackIntegrationProps) {
    super(props);
    this.onTrash = this.onTrash.bind(this);
    this.changeApp = this.changeApp.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  private onTrash() {
    console.log('pressed on trash');
    const {integration} = this.props;
    const info: PopupInfo = {
      title: 'Delete',
      msg: `Are you sure that you want to delete slack channel ${integration.slack?.incoming_webhook?.channel} from the account?`,
      ok: 'Delete',
      okCallback: async () => {
        await memberStore.removeSlackIntegration(integration._id);
      },
      cancel: 'Cancel'
    };
    Popup.create(info);
  }

  private async changeApp(app: string) {
    console.log('new app', app);
    const {integration} = this.props;
    await memberStore.updateSlackIntegration(integration._id, app.length > 0 ? app : undefined);
  }
  render() {
    const { integration } = this.props;
    const apps = memberStore.account!.apps;
    let app = '';
    if (integration.app) app = apps.find(a => a._id === integration.app)?._id ?? '';
    const appValues = {'': 'All'};
    apps.forEach(a => appValues[a._id] = a.name);
    return (
      <div className={styles.integrationInfo}>
        <div className={styles.channel}> {integration.slack?.incoming_webhook.channel}</div>
        <img alt="trash" className={`${styles.trash}`}
          src={trash} onClick={this.onTrash} />
        <Select  
          value={app} 
          nameValues={appValues}
          onChange={this.changeApp} />
      </div>
    );
  }
});

class PrefIntegrations extends React.Component{
  constructor(props: {}) {
    super(props);
    this.removeSlackChannel = this.removeSlackChannel.bind(this);
    this.addSlack = this.addSlack.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async removeSlackChannel(event: any){
    event.preventDefault();
    const  {account} = memberStore;
    const {_id} = account!.slackIntegration![0];
    await memberStore.removeSlackIntegration(_id);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private addSlack(event: any) {
    const left= ((window.innerWidth -450)/2) ;
    const {token} = commonStore!;
    const {accountId} = memberStore!;

    const state = encodeURI(JSON.stringify({token, accountId}));

    const clientId = environmentService.isProduction()?  '1256080167442.1279949822288' : '1256080167442.1369499909330';
    const url =  `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=incoming-webhook,chat:write,chat:write.public,files:write&user_scope=&state=${state}`;
    window.open(url,'_new','left=' +left+',resizable=no,height=600,width=450,menubar=no,status=no');
  }

  render() {
    const {account} = memberStore;
    const slackIntegration = account?.slackIntegration;
 
    return (
      <div className={styles.component}>
        <div className={styles.title}>
          Slack Integration
        </div>
        <div className={styles.subTitle}>
          Shipbook works seamlessly with your dev kit. Integrate Slack with Shipbook and share alerts with your team at the press of a button. 
        </div>

        <div className={styles.section} >
          <div className={styles.slackList}> 
            {slackIntegration && slackIntegration.map((integration, index) => {
              return (<SlackIntegration key={index} integration={integration} />);
            })}
          </div>
        </div> 
        <div className={styles.underline} />
        {(!slackIntegration || slackIntegration.length === 0) &&  <div>Start send Shipbook alerts to Slack</div> }
        <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" data-srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" onClick={this.addSlack}/>
      </div>
    );
  }
}

export default observer(PrefIntegrations);