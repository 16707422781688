import React from 'react';
import { observer } from 'mobx-react';
import memberStore from '../../stores/member.store';
import PrefMenu from '../pref_menu.component';

const itemsOrig = [
  {name: 'General', url: 'general'}, 
  {name: 'Apps', url: 'apps'},
  {name: 'Plan & Usage', url: 'plan'},
  {name: 'Billing', url: 'billing'},
  {name: 'Members', url: 'members'},
  {name: 'Authentication Keys', url: 'authkeys'},
  {name: 'Slack Integration', url: 'integrations'},
];

const PrefAccountMenu = observer(() => {
  const account = memberStore.account!;
  const {appId} = memberStore;
  const prefUrl = '/' + account._id  + '/preferences' ;
  const items = itemsOrig.map((item) => {
    return {name: item.name, url: prefUrl + '/' + item.url};
  });

  const menuLink = {
    name: 'App Preferences',
    url: `/${account._id}/${appId}/preferences/general`,
  }

  return (
    <PrefMenu title="Account Preferences" subTitle={account.name} items={items} menuLink={menuLink}  referAndEarn={true}/>
  );
}); 

export default PrefAccountMenu;

