import * as React from 'react';

import moment from 'moment';

interface AxisProps {
  x?: number, 
  y?: number, 
  payload?: {
    value: string;
  },
  seconds?: boolean;
}

export const GraphTimeTick: React.FunctionComponent<AxisProps> = ({ x, y, payload, seconds}) => {
  const time = moment(payload!.value);
  const timeFormat = seconds ? 'hh:mm:ss a' : 'hh:mm a';
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={24} textAnchor="start" fill="#062960" fontSize={12}>
        <tspan x="0" dy="12" fontWeight="bold">{time.format('MMM DD')}</tspan>
        <tspan x="0" dy="12" fontSize={11}>{time.format(timeFormat)}</tspan>
        </text>
    </g>
  );
}