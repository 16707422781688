import * as React from 'react';
import styles from './switch.module.css';
import switchOff from '../../img/switch-off.svg';
import switchOn from '../../img/switch-on.svg';

interface Props{
  image1?: string;
  image2?: string;
  dataTip1?: string;
  dataTip2?: string;
  oneMode: boolean;
  onClick: (one: boolean) => void;
}

export default class Switch extends React.Component<Props> {
  render() {
    let {image1, image2} = this.props;
    const { dataTip1, dataTip2, oneMode} = this.props;
    let className = styles.switch;
    if (!image1 || !image2) {
      image1 = switchOff;
      image2 = switchOn;
      className = styles.switchSmall;
    }
    return (
      <div className={className}>
        <img src={image1} className={oneMode ?  styles.switchSelected : ''} onClick={() => this.props.onClick(true)} data-tip={dataTip1} alt={dataTip1}/>
        <img src={image2} className={oneMode ?  '' : styles.switchSelected} onClick={() => this.props.onClick(false)} data-tip={dataTip2} alt={dataTip1}/>
      </div>
    );
  }
}