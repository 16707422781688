export enum ConfigType {
  OFF = 'off',
  ALL = 'all',
  ACCUMULATE = 'accumulate',
  WARNING = 'warning',
  CUSTOM = 'custom'
}


export class ConfigTypeUtil {
  static values() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(ConfigType).map(k => ConfigType[k as any]);
  }
  
  static fromString(configType: string) {
    switch (configType) {
      case ConfigType.OFF: return ConfigType.OFF;
      case ConfigType.ALL: return ConfigType.ALL;
      case ConfigType.ACCUMULATE: return ConfigType.ACCUMULATE;
      case ConfigType.WARNING: return ConfigType.WARNING;
      case ConfigType.CUSTOM: return ConfigType.CUSTOM;
      default: return ConfigType.ALL;
    }
  }
}