import React, {useState} from 'react';
import { observer } from 'mobx-react';
import {toJS} from 'mobx';
import styles from './pref_app_conf.module.css';
import { ConfigType } from '../../models/config_type.model';
import Popup, { PopupInfo } from '../common/popup.component';
import appStore from '../../stores/app.store';
import preferencesStore from '../../stores/preferences.store';
import { Role } from '../../models/account.model';
import ReactJson, { InteractionProps } from 'react-json-view';
import ResetSubmitButton from '../reset_submit_button.component';

const PrefAppConf = observer(() => {
  const origApp = appStore.app; // needed to add it so that the component will be re-rendered
  const [app, setApp] = useState(toJS(origApp));
  if (!app) return null;
  const configType =  app.configType;
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    console.log('called submit conf');
    event.preventDefault();
    const info: PopupInfo = {
      title: 'Change',
      msg: `Changing the app info will influence all app users.
        Are you sure that you want to do the change`,
      ok: 'Yes',
      okCallback: () => appStore.updateApp(app),
      cancel: 'No'
    };
    Popup.create(info);
  };

  const handleReset = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setApp(toJS(appStore.app));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value;
    const { name } = event.currentTarget;
    const newApp = {...app, [name]: value};
    setApp(newApp);
  }

  const handleOnEdit = (edit: InteractionProps) => {
    console.log('the edit', edit);
    app.config = edit.updated_src;
    setApp(app);
    return true;
  };
  
  const {role}  = preferencesStore;
  const showCustom = configType === ConfigType.CUSTOM;
  const disabled = role === Role.developer;
  const config = app.config ?? {};
  return (
    <div className={styles.component}>
      <div className={styles.subTitle}>Configuration</div>
      <div className={styles.bodyHeader}><b>Information is power.</b> <br/> We recommend using the “all logs setting” for a more efficient way to fix issues. </div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div className={styles.configuration} >
          <input type="radio" name="configType" value={ConfigType.OFF} id={ConfigType.OFF} 
            checked={configType === ConfigType.OFF} onChange={handleChange} disabled={disabled}/>
          <label htmlFor={ConfigType.OFF}>Off</label>
          <input type="radio" name="configType" value={ConfigType.ALL} id={ConfigType.ALL} 
            checked={configType === ConfigType.ALL} onChange={handleChange} disabled={disabled}/>
          <label htmlFor={ConfigType.ALL}>All logs</label>
          <input type="radio" name="configType" value={ConfigType.ACCUMULATE} id={ConfigType.ACCUMULATE} 
            checked={configType === ConfigType.ACCUMULATE} onChange={handleChange} disabled={disabled}/>
          <label htmlFor={ConfigType.ACCUMULATE}>Accumulate</label>
          <input type="radio" name="configType" value={ConfigType.WARNING} id={ConfigType.WARNING} 
            checked={configType === ConfigType.WARNING} onChange={handleChange} disabled={disabled}/>
          <label htmlFor={ConfigType.WARNING}>Warning</label>
          <input type="radio" name="configType" value={ConfigType.CUSTOM} id={ConfigType.CUSTOM} 
            checked={configType === ConfigType.CUSTOM} onChange={handleChange} disabled={disabled}/>
          <label htmlFor={ConfigType.CUSTOM}>Custom</label>
        </div>
        { !showCustom ? 
          <div className={styles.showLogs}>
            <input name="configConsole" type="checkbox"
                checked={app.configConsole}
                onChange={handleChange} />

            <label className={styles.normal}> Show Logs in IDE console </label>
          </div>
        : <ReactJson src={config} 
            onEdit={disabled ? false : handleOnEdit} 
            onAdd={disabled ? false : handleOnEdit} 
            onDelete={disabled ? false : handleOnEdit}/>
        }
        <ResetSubmitButton disabled={disabled}/>
      </form>
    </div>
  );
});

export default PrefAppConf;