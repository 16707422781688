import * as React from 'react';
import styles from './pref_members.module.css';

import preferencesStore from '../../stores/preferences.store';
import { observer } from 'mobx-react';
import { IMemberRole } from '../../models/account.model';
import IInvite from '../../models/invite.model';
import { Role } from '../../models/account.model';
import Popup, { PopupInfo } from '../common/popup.component';
import commonStore from '../../stores/common.store';
import IMember, { memberIcon } from '../../models/member.model';

import trash from '../../img/preferences/trash.svg';

interface State {
  selectValue?: string;
  selectRole: Role;
}

class PrefMembers extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {selectRole: Role.developer};
    this.handleChange = this.handleChange.bind(this);
    this.addMember = this.addMember.bind(this);
    this.changeRole = this.changeRole.bind(this);
  }

  componentDidMount() {
    preferencesStore!.getMemberRoles();
    preferencesStore!.getInvites();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleChange(event: any) {
    const value = event.target.value as string;
    this.setState({
      selectValue: value
    });
  }

  private addMember() {
    if (!this.state.selectValue) return;
    console.log('add member pressed');
    const { selectValue, selectRole } = this.state;
    preferencesStore.addMemberRole(selectValue, selectRole);
    this.setState({selectValue: ''});
  }

  private changeRole(role: Role) {
    this.setState({selectRole: role});
  }

  render() {
    const {members, invites, role} = preferencesStore;
    const disabled = role === Role.developer;
    return (
      <div className={styles.component}>              
        <div className={styles.title}>
          Members
        </div>
        <div className={styles.subTitle}>Share Shipbook with your entire crew. Add an unlimited amount of members to your account.</div>
        <div className={styles.memberList}>
          {members.map((member) => {
            return (<MemberInfo key={(member.member as IMember)._id} member={member} />);
          })}
        </div>
        <div className={styles.underline}/>
        {invites.length > 0 && <div>
          <div className={styles.title}>
            Invited
          </div>
          <div className={styles.memberList}>
            {invites.map((invite) => { 
              return (<InviteInfo key={invite._id} invite={invite} />);
            })}
          </div>
          <div className={styles.underline}/>
        </div>}
        <div className={`${styles.search} ${disabled && styles.disabled}`}>
          <input type="email" className={styles.selectInvite} name="search" placeholder="New Member: Email" 
            value={this.state.selectValue} onChange={this.handleChange} disabled={disabled} />              

          <SelectRole role={this.state.selectRole} changeRole={this.changeRole}/>
          <button className={styles.addButton} onClick={this.addMember} disabled={disabled}>add</button>
        </div>
      </div>
    );
  }
}

export default observer(PrefMembers);

interface MemberProps {
  member: IMemberRole;
}

const MemberInfo = observer(class MemberInfo extends React.Component<MemberProps> {
  constructor(props: MemberProps) {
    super(props);
    this.onTrash = this.onTrash.bind(this);
    this.changeRole = this.changeRole.bind(this);
  }
  
  get disabled() {
    const owner = this.props.member.role === Role.owner;
    const disabled = preferencesStore.role === Role.developer;
    return disabled || owner;
  }

  private onTrash() {
    if (this.disabled) return;
    console.log('pressed on trash');
    const member = this.props.member.member as IMember;
    const info: PopupInfo = {
      title: 'Delete',
      msg: `Are you sure that you want to delete the member ${member.fullName} from the account?`,
      ok: 'Delete',
      okCallback: () => preferencesStore.deleteMemberRole(member._id),
      cancel: 'Cancel'
    };
    Popup.create(info);
  }

  private changeRole(role: Role) {
    if (this.disabled) return;
    const member = this.props.member.member as IMember;
    const info: PopupInfo = {
      title: 'Change Role',
      msg: `Are you sure that you want to change the role of ${member.fullName} to ${role}?`,
      ok: 'Yes',
      okCallback: () => preferencesStore.changeMemberRole(member._id, role),
      cancel: 'No'
    };
    Popup.create(info);
  }

  render() {
    const {member} = this.props;
    if (!member.member) {
      console.log('there was no member');
      return <div />;
    } 

    const memberObj = member.member as IMember;
    console.log('the member icon', memberObj.icon);
    return (
      <div className={styles.memberInfo}>
        <img alt={memberObj.fullName} className={styles.memberIcon} src={memberIcon(memberObj)} />
        <div className={styles.memberIdentity}>
          <div className={styles.fullName}>{memberObj.fullName}</div>
          <div className={styles.email}>{memberObj.email}</div>
        </div>
        <img alt="trash" className={`${styles.trash} ${this.disabled && styles.disabled}`} 
          src={trash} onClick={this.onTrash} />
        <SelectRole role={member.role} changeRole={this.changeRole}/>
      </div>
    );
  }
});

interface InviteProps {
  invite: IInvite;
}

const InviteInfo = observer(class InviteInfo extends React.Component<InviteProps> {
  constructor(props: InviteProps) {
    super(props);
    this.state = { 
      selectValue: this.props.invite.role
    };
    this.onTrash = this.onTrash.bind(this);
    this.onResend = this.onResend.bind(this);
  }

  get disabled() {
    const disabled = preferencesStore.role === Role.developer;
    return disabled ;
  }

  private async onTrash() {
    if (this.disabled) return;
    const info: PopupInfo = {
      title: 'Delete',
      msg: `Are you sure that you want to delete the invite to ${this.props.invite.email}?`,
      ok: 'Delete',
      okCallback: () => preferencesStore.deleteInvite(this.props.invite._id),
      cancel: 'Cancel'
    };
    Popup.create(info);
  }  

  private async onResend() {
    if (this.disabled) return;
    commonStore.setLoading(true);
    await preferencesStore.resendInvite(this.props.invite._id);
    commonStore.setLoading(false);
    const info: PopupInfo = {
      title: 'Resend',
      msg: `The invite for ${this.props.invite.email} was sent.`,
      ok: 'OK',
    };
    Popup.create(info);
  }

  render() {
    const disabled = this.disabled;
    const {invite} = this.props;
    return (
      <div className={styles.inviteInfo}>
        <div className={styles.email}>{invite.email}</div>
        <img alt="trash" className={`${styles.trash} ${disabled && styles.disabled}`} 
          src={trash} onClick={this.onTrash} />
        <div className={styles.role}>{invite.role}</div>
        <div className={`${styles.resend} ${disabled && styles.disabled}`} onClick={this.onResend}>resend</div>
      </div>
    );
  }
});

interface SelectRoleProps {
  role: Role;
  changeRole: (role: Role) => void;
}

export const SelectRole = observer(class SelectRole extends React.Component<SelectRoleProps, {show: boolean}> {
  constructor(props: SelectRoleProps) {
    super(props);
    this.state = {show: false};
    this.onClick = this.onClick.bind(this);
    this.changeRole = this.changeRole.bind(this);
  }

  get disabled() {
    const owner = this.props.role === Role.owner;
    const disabled = preferencesStore.role === Role.developer;
    return disabled || owner;
  }

  changeRole(role: Role) {
    this.setState({show: false});
    if (this.props.role === role) return;
    this.props.changeRole(role);
  }

  onClick() {
    if (this.disabled) return;
    this.setState({show: !this.state.show});
  }

  render() {
    const disabled = this.disabled;
    return (
      <div className={`${styles.dropdown} ${disabled && styles.disabled}`}>
        <div className={styles.role} onClick={this.onClick}>{this.props.role}</div>
        {this.state.show && <div className={styles.dropdownContent}>
          <div onClick={() => this.changeRole(Role.admin)}>{Role.admin}</div>
          <div onClick={() => this.changeRole(Role.developer)}>{Role.developer}</div>
        </div>}
      </div>
    );
  }
});