import * as React from 'react';

import styles from './icon.module.css';

interface Props {
  name: string;
  icon?: string; 
  className?: string;
}

export default class Icon extends React.Component<Props> {
  render() {
    const letter = this.props.name ? this.props.name[0] : '?';
    return (
      <div className={`${styles.icon} ${this.props.className ? this.props.className : ''}`}>{letter}</div>
    );
  }
}