import React, { useEffect } from 'react';
import styles from './app.module.css';
import ReactTooltip from 'react-tooltip';
import './tooltip.css';
import  ShareStore  from '../stores/share.store';
import memberStore from '../stores/member.store';
import Popup from './common/popup.component';
import GlobalRoutes from '../routes/global.routes';
import commonStore from '../stores/common.store';
import { Route, useHistory, useLocation } from 'react-router-dom';
import Loader from './common/loader.component';
import trackAnalytic, { Analytics } from './analytics';
import authStore from '../stores/auth.store';
import {Provider} from '../services/connection/auth.connection';
import { Integration } from '../models/app.model';
import { appRoutes } from '../routes/app.routes';
import { accountPreferencesRoutes } from '../routes/pref_account.routes';

const App = () => {
  const history = useHistory();
  const location = useLocation();

  const receiveMessage = async (event: MessageEvent) => {
    // if (event.origin.indexOf(window.location.hostname)<0)
    //   return;
    const { token, type } = event.data;
    // process jira
    switch (type) {
      case 'jiraauth':
        await ShareStore.showCreateJiraIssue(token);
        break;
      case 'slackauth':
        await memberStore.pullMember();
        break;
      case 'githubauth':
        await authStore.token(token, Provider.GITHUB);
        history.push('/');
        break;
      default:
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    const checkPathLocation = async () => {
      const { pathname } = location;
      console.debug('the pathname:' + pathname);
      if (pathname === '/register' || pathname === '/login' || pathname === '/forgot-password' || 
          pathname === '/reset-password' || pathname === '/validate') {
        // no login needed.
        return;
      }
  
      if (!commonStore!.token) {
        commonStore!.origPath = pathname;
        history.push('/login');
        return;
      }
  
      if (!memberStore.member) {
        try {
          await memberStore.pullMember();
        } catch (err) {
          console.log(err);
          return;
        }
      }
  
      if (!memberStore.member!.emailValidated) {
        if (pathname !== '/validation') history.replace('/validation');
        return;
      }
  
      console.log('the current account', memberStore.account);
      if (!memberStore.account) { 
        console.log('no current account');
        if (pathname !== '/create-account') history.replace(`/create-account`);
        return;
      }
  
      if (!memberStore.account!.apps.find(app => app.integration === Integration.FULL) ) {
        console.log('entered into the push');
        if (!pathname.includes('/app-wizard') && !pathname.includes('/create-account')) history.replace('/app-wizard/new');
          return;
      }
  
      // there is a full integration
      trackAnalytic.integration(memberStore.member!._id);
        
      console.log('url pathname ' + location.pathname);
      if (memberStore.app && 
        (location!.pathname === "/" || appRoutes.some(route => location!.pathname.startsWith(route)))) {
        console.log('pushing new url ' + location.pathname);

        // is an account preference route and therefor we need only the account id
        if (accountPreferencesRoutes.some(route => location!.pathname.startsWith(route))) {
          history.replace(`/${memberStore.accountId}${location!.pathname}`);  
          return;
        }
        const pathname = location!.pathname == "/" ? "/bridge" : location.pathname;
        history.replace(`/${memberStore.accountId}/${memberStore!.appId}${pathname}`);
      }
    };
    
    checkPathLocation();

  }, [location.pathname]);

  return (
    <div className={styles.container}>
      <Route component={Analytics} />
      <GlobalRoutes />
      <Popup />
      <ReactTooltip className="tooltip" effect="solid" multiline delayHide={1000} place="bottom" />
      <Loader />
    </div>
  );
};

export default App;
