import * as React from 'react';
import * as mobx from 'mobx';
import { observer } from 'mobx-react';

import styles from './pref_app_general.module.css';
import preferencesStore from '../../stores/preferences.store';
import IApp, { Integration } from '../../models/app.model';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import trackAnalytic from '../analytics';
import { Platform } from '../../models/platform.model';
import Copy from '../common/copy.component';
import { Role } from '../../models/account.model';
import { Link } from 'react-router-dom';
import memberStore from '../../stores/member.store';

import pen from '../../img/preferences/pen.svg';
import appleIcon from '../../img/apple-icon-white.svg';
import androidIcon from '../../img/android-icon-white.svg';
import reactIcon from '../../img/react-icon-white.svg';
import appStore from '../../stores/app.store';

interface State {
  app?: IApp;
  name: string;
  editName: boolean;
  platform?: Platform;
  udid: string;
}

class PrefApp extends React.Component<RouteComponentProps<{appId: string}>, State> {
  disposer?: mobx.Lambda ;

  constructor(props: RouteComponentProps<{appId: string}>) {
    super(props);
    let { app } = appStore;
    const {appId} = this.props.match!.params;
    app = app && appId && appId != 'new' ? mobx.toJS(app) : undefined;
    this.state = {
      app,
      name: app ? app.name : '',
      editName: false,
      platform: app?.platform,
      udid: ''
    };

    this.handleSubmitName = this.handleSubmitName.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);

    console.log('constructor PrefApp');
  }

  componentDidMount() {
    this.disposer = mobx.observe(memberStore, 'app', listener => {
      console.log('*** updated current app')
      const app = mobx.toJS(listener.newValue) as IApp;
      const name = app?.name ?? '';
      this.setState({app, name});
    });
  }
  componentWillUnmount() {
    if (this.disposer) this.disposer();
  }

  componentDidUpdate(prevProps: RouteComponentProps<{appId: string}>) { // this is for the case that it is a new app. I don't know why the disposer doesn't do the job.
    console.log('entered componentDidUpdate: ' + this.props.location.pathname);
    if (this.props.location.pathname === prevProps.location.pathname) return;
    console.log('changed pathname');
    let { app } = appStore;
    if (!app) return;
    console.log('has current app');
    app = mobx.toJS(app);
    const name = app?.name ?? '';
    this.setState({app, name});  
  }
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async handleSubmitName(event: any) {
    console.log('called submit name');
    event.preventDefault();
    event.target.blur();
    if (this.state.app) {
      const app = mobx.toJS(appStore.app!);
      app.name = this.state.name;
      appStore.updateApp(app);
    }
    else {
      const retApp = await appStore.createApp(this.state.name);
      const newUrl = `/${memberStore.accountId}/${retApp._id}/preferences`;
      this.props.history!.replace(newUrl);
    }
    this.setState({editName: false});
  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleChangeName(event: any) {
    const name = event.target.value as string;
    this.setState({name});
  }
    
  private trackIntegration(platform: string) {
    trackAnalytic.event('integration', 'integration ' + platform, 'integration ' + platform);
  }
  
  render() {
    const {name, app, udid} = this.state;
    const {role}  = preferencesStore;
    let appId = '';
    let appKey = '';
    let platformDoc = '';
    let platformIcon = '';
    let platformTitle = '';
    let udidTitle = '';
    
    if (app) {
      appId = app._id;
      appKey = app.key;
      if (app.integration === Integration.FULL) {
        switch (app.platform) {
          case Platform.IOS:
            platformDoc = 'https://docs.shipbook.io/ios-log-integration';
            platformIcon = appleIcon;
            platformTitle = 'iOS Doc';
            udidTitle = 'UUID';    
            break;
          case Platform.ANDROID:
            platformDoc = "https://docs.shipbook.io/android-log-integration";
            platformIcon = androidIcon;
            platformTitle = 'Android Doc';
            udidTitle = 'Android ID';
            break;
          case Platform.REACT_NATIVE:
            platformDoc = "https://docs.shipbook.io/react-native-log-integration";
            platformIcon = reactIcon;
            platformTitle = 'React-Native Doc';
            udidTitle = 'GUID'; 
            break;
          default:
            platformDoc = 'https://docs.shipbook.io/ios-log-integration';
            platformIcon = appleIcon;
            platformTitle = 'iOS Doc';
            udidTitle = 'UUID';    
            break;
        }
      }
    }

    const disabled = role === Role.developer;

    return (
      <div className={styles.component}>
        <div className={styles.title}>
          App Preferences {!app && '(New App)'}
        </div>
        <div className={styles.general}>
          <div>
            App Name
          </div>
          <form onSubmit={this.handleSubmitName}>
            <div className={styles.inputName}>
              <input type="text" name="name" placeholder="name" 
                    value={name} onChange={this.handleChangeName} 
                    onFocus={() => this.setState({editName: true})} disabled={disabled} />
              <img alt="pen" src={pen}/>
            </div>

          </form>
          <div>
            {this.state.editName && 
              <button className={styles.buttonName} onClick={this.handleSubmitName}>submit</button>}
          </div>    
          {app &&
            <>
              <div>
                App Id
              </div>
              <input value={appId} readOnly/>
              <Copy text={appId}/>

              <div>
                App Key
              </div>
              <input value={appKey} readOnly/>
              <Copy text={appKey}/>
            </>
          }
        </div>

        {app && <>
          <hr className={styles.seperator}/>
          <div className={styles.subTitle}>Integration</div>
          {app.integration !== Integration.FULL ? <>
              <div className={styles.bodyHeader}><b>What are you in the mood for today?</b> <br/> Quick and easy, or a detailed full integration of your app?</div>
              <div className={styles.docs}>
                <Link className={styles.openWizard} to={`/${memberStore.accountId}/${appId}/preferences/new`}>Open Quick Integration Wizard</Link>
                <a className={styles.openFull} 
                  href="https://www.shipbook.io/full-integration-doc" target="_blank" rel="noopener noreferrer">              
                  Full Integration Document
                </a>
              </div>
            </>
            : <>
            <div className={styles.bodyHeader}>View our detailed integration document</div>
            <a className={styles.platform} 
              href={platformDoc} target="_blank" rel="noopener noreferrer"
              onClick={() => this.trackIntegration('ios')}>
              <img alt="apple" src={platformIcon} className={styles.platformIcon}/>
              <div>{platformTitle}</div>
            </a>
          </>}
        </>}
      </div>
    );
  }
}

export default withRouter(observer(PrefApp));