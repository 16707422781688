
import * as React from 'react';
import styles from './reset_submit_button.module.css';

const ResetSubmitButton = ({disabled}: {disabled?:boolean}) => {
  return (
    <div className={styles.inputButton}>
      <input className={styles.reset} type="reset" value="Reset" disabled={disabled}/>
      <input className={styles.submit}  type="submit" value="Submit" disabled={disabled}/>
    </div>
  );
};

export default ResetSubmitButton;