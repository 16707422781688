
import React , {useState, useEffect} from 'react';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Comparison, ComparisonUtil, ICustomAlert, comparisonValues } from '../../models/app.model';
import appStore from '../../stores/app.store';
import searchStore from '../../stores/search.store';
import Select from '../common/select.component';
import LogSearch, { SeverityList } from '../logs/log_search.component';
import styles from './pref_app_alerts_edit.module.css';
import ResetSubmitButton from '../reset_submit_button.component';
import PopupScreen from '../common/popup_screen.component';
import memberStore from '../../stores/member.store';
import LogSearchInput from '../logs/log_search_input.component';
import Popup, { PopupInfo } from '../common/popup.component';

const PrefAppAlertsEdit = (props: RouteComponentProps<{alertId: string}>) => {  
  const history  = useHistory();
  const [alert, setAlert] = useState<ICustomAlert>({
    name:'',
    minutes: 5,
    comparison: Comparison.GREATER,
    amount: 1,
    search: {}
  });
  
  useEffect(() => {
    const {alertId} = props.match.params;
    searchStore.clear();
    if (alertId) {
      const alert = appStore.getAlert(alertId);
      if (alert) {
        setAlert(alert);
        if (alert.search) searchStore.setParams(alert.search);
        else searchStore.clear();
      }
    }
    searchStore.setIsAlert(true);
    return () => {
       searchStore.setIsAlert(false);
    }
  }, []);

  async function save(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let a;
    alert.search = searchStore.logsParams;
    if (alert._id) {
      console.log (`update alert: ${alert._id}`)
      a = await appStore.updateAlert(alert);
      setAlert(a!);
      const info: PopupInfo = {
        msg: 'Succeeded to update custom alert: ' + a!.name,
      };    
      Popup.create(info);

    } 
    else {
      console.log (`add alert`)
      a = await appStore.addAlert(alert);
      setAlert(a!);
      const info: PopupInfo = {
        msg: 'Succeeded to create custom alert: ' + a!.name,
      };
      Popup.create(info);

      const {accountId, appId} = memberStore;
      const path = `/${accountId}/${appId}/preferences/alerts/edit/${a!._id}`;
      history.push(path)
    }
  }

  function reset(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const {alertId} = props.match.params;
    if (alertId) {
      const alert = appStore.getAlert(alertId);
      if (alert) {
        setAlert(alert);
        searchStore.setParams(alert.search);
      }
    }
  }
  
  function changeComparison(value: string) {
    console.log (`change comparison value: ${value}`)
    setAlert(existingValues => ({
      // Retain the existing values
      ...existingValues,
      // update the current field
      comparison: ComparisonUtil.fromString(value)
    }));
  }
  
  function updateAlert(e: React.ChangeEvent<HTMLInputElement>){
    const fieldName = e.target.name;
    const value = e.target.value;
    console.log (`name: ${fieldName}, value: ${value}`)

    setAlert(existingValues => ({
      // Retain the existing values
      ...existingValues,
      // update the current field
      [fieldName]: value,
    }))
  }

  function onExit() {
    const {accountId} = memberStore;
    // get app id from url
    const {app} = appStore;
    props.history.push(`/${accountId}/${app?._id}/preferences/alerts`);
  }


  return (
    <PopupScreen title="Custom Alert" onExit={onExit}>
      <div className={styles.component}>
        <form onSubmit={save} onReset={reset}>
          <div className={styles.description}>
            Customize the alert for your application testing. Choose an alert name, severity level, parameters, and the Condition 
            that will help you identify any issues in the application.
          </div>
          <div className={styles.subTitle}>Alert Name</div>
          <input className={styles.inputName} type="text" name="name" value={alert.name} onChange={updateAlert} placeholder="Name" />
          <div className={styles.subTitle}>Parameters</div>
          <div className={styles.logSearch}>
            <LogSearchInput />
            <SeverityList />
          </div>
          <div className={styles.subTitle}>Trigger if...</div>
          <div className={styles.trigger}>
            <Select
                className={styles.select}
                value={alert.comparison} 
                nameValues={comparisonValues} 
                onChange={changeComparison} />
            <input className={styles.input} type="number"  min="0" name="amount" value={alert.amount} onChange={updateAlert} placeholder="Amount" />
            <div>logs in </div>
            <input className={styles.input} type="number"  min="1" name="minutes" value={alert.minutes} onChange={updateAlert} placeholder="Minutes" />
            <div>minutes</div>

          </div>
          <ResetSubmitButton />
        </form>
      </div>
    </PopupScreen>

  )
}

export default  withRouter(PrefAppAlertsEdit);