import * as React from 'react';
import { observer } from 'mobx-react';


import styles from './loglytics_list.module.css';
import LoglyticsRow from './loglytics_row.component';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList as List} from 'react-window';

import captainRest from '../../img/loglytics/captain-rest.gif';
import fishHungry from '../../img/loglytics/fish-hungry.gif';
import medal from '../../img/loglytics/medal.gif';
import { LoglyticsStatus } from '../../models/loglytics.model';
import { Exception } from '../../models/log.model';
import loglyticsStore from '../../stores/loglytics.store';
import commonStore from '../../stores/common.store';
import { LocalLoader } from '../common/loader.component';


class LoglyticsList extends React.Component {
  render() {
    const { loglytics, loglyticsStatus, severity, bridge, loading } = loglyticsStore;
    if (loading && !commonStore.loading) {
      return (
        <LocalLoader />
      )
    }
    let emptyIcon = '';
    let emptyTitle = '';
    let emptyText = '';
    switch(loglyticsStatus) {
      case LoglyticsStatus.OPEN:
      case '':
        emptyIcon = captainRest
        if (severity !== Exception.EXCEPTION) {
          emptyTitle = 'Hmmmm... we’re not registering any blips ... '
          emptyText = 'Although we’re sure you are the master and commander of your ship. \n' + 
            'Give it a second glance, did you define ' + severity.toUpperCase() + ' logs?';  
        }
        else {
          emptyTitle = 'All clear! No exceptions registered ...';
          emptyText = 'Keep it up!'  
        }
        break;
      case LoglyticsStatus.FIXED:
        emptyIcon = fishHungry;
        emptyTitle = 'The fish are hungry...';
        emptyText = 'Start tossing your issues overboard!'
        break;
      case LoglyticsStatus.IGNORED:
        emptyIcon = medal;
        emptyTitle = 'All clear! Not ignoring those issues...';
        emptyText = 'Keep it up!'  
      break;
    } 
    return (
      <div className={styles.loglyticsList}>
      { loglytics.length > 0 ? <>
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              width={width} // 14 for the padding of loglyticsList 
              height={height}
              itemData={loglytics}
              itemCount={loglytics.length}
              itemSize={41}
            >
              { LoglyticsRow }
            </List>)
          }
        </AutoSizer>
      </>: 
        !commonStore.loading && <div className={bridge ? styles.emptyBridge : styles.empty }>
          <img src={emptyIcon}/>
          <div>
            <div>{emptyTitle} </div>
            <div>{emptyText} </div>
          </div>
        </div>
        
      }
      </div>
    );
  }

}

export default observer(LoglyticsList);