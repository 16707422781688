/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { UnregisterCallback } from 'history';

import styles from './loglytics_item.module.css';

import loglyticsStore from '../../stores/loglytics.store';


import LogListView from '../logs/log_list_view.component';
import logsStore from '../../stores/logs.store';
import LogBottom from '../logs/log_bottom.component';
import Time from '../../services/time.service';
import LoglyticsItemGraph from './loglytics_item_graph.component';

interface Params {
  key: string;
}


class LoglyticsItem extends React.Component<RouteComponentProps<Params>> {
  historyListener: UnregisterCallback;
  constructor(props: RouteComponentProps<Params>) {
    super(props);
    this.state = {}

    logsStore.setIsLoglytics(true);
    logsStore.clear();
    const {search} = props.location!;
    this.params(search);
    this.historyListener = props.history!.listen((listener) => {
      this.params(listener.search)
    });  
  }

  private params(search: string) {
    console.log('entered params with:', search);
    const urlParams = new URLSearchParams(search);
    const sessionId = urlParams.get('session') ?? undefined;
    const hasId = urlParams.get('hasId') ?? undefined;

    const {key} = this.props.match!.params;
    loglyticsStore.setCurrentLoglytics(key, sessionId, hasId); 
  }


  async componentDidMount() {
    // const {key} = this.props.match!.params;
    // await this.props.loglyticsStore!.setLoglyticsRead(true, loglyticsId);
  }


  componentWillUnmount() {
    logsStore.setIsLoglytics(false);
    this.historyListener();  
  }

  render() {

    const {currentLoglytics} = loglyticsStore;
    if (!currentLoglytics) return null;
    const {dateHistogram} = currentLoglytics;
    let dateSpan = new Time().getLongDate();
    if (!!dateHistogram && dateHistogram.length > 0) dateSpan = new Time(dateHistogram[0].date).getLongDate() + ' - ' + new Time(dateHistogram[dateHistogram.length - 1].date).getLongDate();

    return (
      <div className={styles.component}>
        <div className={styles.header}>
          <div className={styles.dateSpan}>{dateSpan}</div>
        </div>

        <LoglyticsItemGraph />
        <LogListView />
        <LogBottom />
      </div>
    );
  }
}

export default withRouter(observer(LoglyticsItem));