import { computed, makeObservable, observable, runInAction } from "mobx";
import { ITrigAlert, AlertType, ILoglyticsAlert } from "../models/trig_alert.model";
import trigAlertConnection from "../services/connection/trig_alert.connection";
import moment from "moment";

export enum ItemType {
  DATE, 
  CUSTOM_ALERT,
  LOGLYTICS_ALERT
}

export interface Item {
  itemType: ItemType;
  data?: Date | ITrigAlert;
}

export class AlertsStore {
  alerts: ITrigAlert[] = [];
  loading = false;

  unreadCount = 0;
  alertId?: string = undefined;

  get itemList(): Item[] {
    const items: Item[] = [];
    if (this.alerts.length === 0) return items;

    let lastDate = moment(this.alerts[0].date).startOf('day').toDate();
    items.push({itemType: ItemType.DATE, data: lastDate});
    for (const alert of this.alerts) {
      if (moment(alert.date).startOf('day').toDate().getTime() !== lastDate.getTime()) {
        lastDate = moment(alert.date).startOf('day').toDate();
        items.push({itemType: ItemType.DATE, data: lastDate});
      }
      switch (alert.type) {
        case AlertType.CUSTOM:
          items.push({itemType: ItemType.CUSTOM_ALERT, data: alert});
          break;
        case AlertType.LOGLYTICS:
          items.push({itemType: ItemType.LOGLYTICS_ALERT, data: alert});
          break;
      }
    }
    return items;
  }

  constructor() {
    makeObservable(this,{
      alerts: observable,
      loading: observable,
      unreadCount: observable,
      itemList: computed
    });
  }

  getLoglyticsAlerts(key: string) {
    if (!this.alerts) return [];
    return this.alerts.filter(alert => 
      alert.type == AlertType.LOGLYTICS && (alert as ILoglyticsAlert).alert.key == key);  
  }

  async getAlerts() {
    this.setLoading(true);
    try {
      const alerts = await trigAlertConnection.getTrigAlerts();
      runInAction(() => this.alerts = alerts);  
    }
    catch (error) {
      console.log(error);
    } 
    finally {
      this.setLoading(false);
    }
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  async read() {
    try {
      const alerts = await trigAlertConnection.read();
      runInAction(() => {
        this.alerts = alerts;
        this.unreadCount = 0;
      });  
    }
    catch (error) {
      console.log(error);
    } 
  }

  async getUnreadCount() {
    try {
      const unreadCount = await trigAlertConnection.unreadCount();
      runInAction(() => {
        this.unreadCount = unreadCount.count;
      });
    } catch (error) {
      console.log(error);
    } 
  }


}

export default new AlertsStore();