class ArrayService {
  public sortVersion(arr: string[]) {
    return arr.sort( (a, b) => a.localeCompare(b, undefined, { numeric: true }) );
  }

  public remove<T>(arr: T[] , elem: T) {
    return arr.filter((value) => value !== elem);
  }
}

export default new ArrayService();