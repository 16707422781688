import ConnectionService, { HttpMethod } from './connection.service';
import { StatDeviceSum, StatNumber, StatDateNumbers } from '../../models/stat.model';

class StatsConnection {
  public async getAppVersions(data: object): Promise<string[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/stats/appVersions`, HttpMethod.GET, data);
  }

  public async getAverages(data: object): Promise<StatNumber> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/stats/averages`, HttpMethod.GET, data);
  }

  public async getLogSums(data: object): Promise<StatDateNumbers[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/stats/logSums`, HttpMethod.GET, data);
  }

  public async getDeviceCount(data: object): Promise<{exception: number, error: number, warning:number, device: number}> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/stats/deviceCount`, HttpMethod.GET, data);
  }

  public async getDeviceSumHistogram(data: object): Promise<{time: Date, device: number}[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/stats/deviceSumHistogram`, HttpMethod.GET, data);
  }
  
  public async getDeviceSums(data: object): Promise<StatDeviceSum[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/stats/deviceSums`, HttpMethod.GET, data);
  }

  public async getAffectedDevices(data: object): Promise<StatDateNumbers[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/stats/effectedDevices`, HttpMethod.GET, data);
  }
}

export default new StatsConnection();