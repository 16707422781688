import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './pref_usage.module.css';
import { ComposedChart, Tooltip, Line, Bar, CartesianGrid, XAxis, YAxis, TooltipProps } from 'recharts';
import billingStore from '../../../stores/billing.store';
import memberStore from '../../../stores/member.store';
import Time from '../../../services/time.service';
import numberService from '../../../services/number.service';
import { PrefChangePlan } from './pref_plan.component';
import moment from 'moment';

class CustomTooltip extends React.Component<TooltipProps > {
  render() {
    const {payload} = this.props;
    const date = new Date(this.props.label!);
    let total = 0;
    let limit = 0;
    payload?.forEach(pay => {
      if (pay.name === 'limit') {
        limit = pay.value as number;
        return;
      }
      total += pay.value as number;
    });
    
    return (
      <div className={styles.customTooltip}>
        <div className={styles.customTooltipTitle}>
          {new Time(date).getLongDate()} 
        </div>
        {payload && payload.map((elem) => {
          if (elem.name === 'limit') return null;
          return (
          <React.Fragment key={elem.name}>
            <div className={styles.customTooltipText}>{elem.name}</div>
            <div className={styles.customTooltipColor} style={{backgroundColor: elem.color}}/>
            <div className={styles.customTooltipText}>{elem.value.toLocaleString()}</div>
          </React.Fragment>);
        })}        
        <div className={styles.customTooltipTotal}>
          Total: {total.toLocaleString()}
        </div>
        <div className={styles.customTooltipTotal}>
          Limit: {limit.toLocaleString()}
        </div>
      </div>
    );
  }
}

const axisTickStyle = {
  fill: '#062960', 
  fontFamily: 'Heebo',
  fontSize: 12
};

const appColors = ['#7becef','#21a1b5','#ffa7b6','#ffe38b','#e51a41','#b3e528','#ff7a06','#48f9a0','#11d472',
    '#bbbbbb','#ffc68d','#adf9fa','#ffdd34', '#ac40b2','#ebffb4','#ffc306', '#808080','#f45179','#ff8e1d']

class PrefUsage extends React.Component {
  constructor(props: {}) {
    super(props);
    billingStore.loadUsage();
  }
  render() {
    let {usage} = billingStore;
    const {currentPlan} = billingStore;
    const limit = currentPlan?.maxLogs ?? 0;
    usage = usage.map(u => { return {...u, limit}});
    const retention = currentPlan?.retention ?? 1;
    let width = 100 + retention * 30;
    width = Math.min(width, 820);


    const {apps, subscription} = memberStore.account!;
    const passedLimit = subscription.exceedLogLimit.find(date => moment().subtract(retention, 'd').isBefore(date))
    return (
      <div className={styles.component}>
        <ComposedChart width={width} height={170} data={usage} syncId="dashboard">
          <Tooltip wrapperStyle={{zIndex: 1000}} position={{y: 170, x: undefined as unknown as number}} content={<CustomTooltip/>}/>
          {apps.map((app, index)  => 
                  <Bar key={index} dataKey={app._id} name={app.name}
                    stroke={appColors[index]} fill={appColors[index]} stackId="1" />) }
          <Line type="monotone" dataKey="limit" stroke="var(--lipstick)" dot={false} />
          <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
          <XAxis dataKey={(u) => u.time.toISOString()}
            tick={axisTickStyle} tickFormatter={(tick) => new Time(tick, true).getMonthDay()} stroke="#21a1b5"/>
          <YAxis axisLine={false} tickLine={false} tick={axisTickStyle} tickFormatter={tick => numberService.abbreviateNumber(tick)}/>
        </ComposedChart>
        {passedLimit && <div className={styles.limit}>Please Note: The daily account limit was reached. All logs past the limit are not saved. <PrefChangePlan><span>Upgrade Now</span></PrefChangePlan> and keep logging. </div>}
      </div>
    );
  }
}

export default observer(PrefUsage);