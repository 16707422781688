import { observable, action, runInAction, computed, makeObservable } from 'mobx';

// import { OptionsType } from 'react-select/lib/types';
import ConnectionService, { ConnectionError } from '../services/connection/connection.service';
import { SearchType, CustomOption, SearchParam, SearchMeta } from '../models/search.model';
import { LogType, Severity, SeverityExc, SeverityExcUtil } from '../models/log.model';
import Time from '../services/time.service';
import logsStore from './logs.store';
import storageService from '../services/storage.service';
import logConnection from '../services/connection/log.connection';
import paramService, { ParamsObject } from '../services/param.service';
import { PropTypes } from 'mobx-react';

export class SearchStore {
  inProgress = false;
  error?: Error | ConnectionError = undefined;


  severity?: SeverityExc[] = undefined;
  minSeverity?: SeverityExc = undefined;
  selectedOptions: CustomOption[] = [];
  showEvents: boolean = storageService.getItem('showEvents', false);
  fromSeverityMode = true;
  additionalParams: string[] = [];

  isAlert: boolean = false;

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      error: observable,
      severity: observable,
      minSeverity: observable,
      selectedOptions: observable,
      showEvents: observable,
      fromSeverityMode: observable,
      additionalParams: observable,
      isAlert: observable,
      logId: computed,
      types: computed,
      logsParams: computed,
      logsUrl: computed,
      searchParams: computed,
      clear: action,
      setLoglyticsKey: action,
      setSessionLog: action,
      setParams: action,
      search: action,
      setSeverity: action,
      setShowEvents: action,
      setFromSeverityMode: action,
      setIsAlert: action
    });
  }

  get logId() {
    const logIdOption = this.selectedOptions.find(option => option.type === SearchParam.HAS_ID);
    if (!logIdOption) return undefined;
    return logIdOption.value;
  }

  get types() {
    if (this.showEvents) return undefined;
    else return [LogType.MESSAGE, LogType.EXCEPTION];
  }

  get logsParams() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data:  { [key: string]: any} = {};
    if (this.types?.length) data.type = this.types;
  
    this.selectedOptions.forEach((option) => {
      const {type} = option;
      if (data[type]) data[type].push(option.value);
      else data[type] = [option.value];
    });

    if (this.severity?.length) {
      if (this.severity.length < SeverityExcUtil.values().length) data.severity = this.severity; // so that if everyting is pressed it is like nothing is pressed.
    }
    else if (this.minSeverity && this.minSeverity !== Severity.VERBOSE) data.min_severity = this.minSeverity;

    if (data[SearchParam.ANDROID_ID]) {
      data[SearchParam.UDID] = data[SearchParam.ANDROID_ID];
      delete data[SearchParam.ANDROID_ID];
    }

    if (data[SearchParam.UUID]) {
      data[SearchParam.UDID] = data[SearchParam.UUID];
      delete data[SearchParam.UUID];
    }

    return data;
  }

  get logsUrl() {
    const data = this.logsParams;
    data.limit = 10000;
    return logConnection.getSessionLogsUrl(data);
  }


  get searchParams() {
    const searchParameters = [
      SearchParam.MESSAGE,
      SearchParam.REGEX,
      SearchParam.MANUFACTURER,
      SearchParam.DEVICE_MODEL,
      SearchParam.UDID,
      SearchParam.ANDROID_ID,
      SearchParam.UUID,
      SearchParam.APP_VERSION,
      SearchParam.TAG,
      SearchParam.USER_ID,
      SearchParam.USER_NAME,
      SearchParam.FULL_NAME,
      SearchParam.EMAIL,
      SearchParam.PHONE_NUMBER,
      SearchParam.FROM_TIME,
      SearchParam.TO_TIME
    ];

    return [...searchParameters, ...this.additionalParams].map(p => CustomOption.createSearchParam(p));
  }

  async loadSearchParams() {    
    const additionalParams = await logConnection.getAdditionalParams();
    runInAction(() => this.additionalParams = additionalParams);
  }

  clear() {
    this.selectedOptions = [];
    this.severity = undefined;
    this.minSeverity = undefined;
  }

  setLoglyticsKey(key: string) {
    this.clear();
    const selectedOptions = [new CustomOption(SearchParam.KEY, `key: ${key}`, key)];
    this.selectedOptions = selectedOptions;
  }

  setSessionLog(sessionId: string, logId: string) {
    this.clear();
    const selectedOptions: CustomOption[] = [];
    let option = new CustomOption(SearchParam.SESSION, `logId: ${sessionId}`, sessionId);
    selectedOptions.push(option);
    option = new CustomOption(SearchParam.HAS_ID, `hasId: ${logId}`, logId);
    selectedOptions.push(option);
    this.selectedOptions = selectedOptions;
  }

  setSearchParams(search: string) {
    const paramsObj = paramService.createParamsObject(search);
    this.setParams(paramsObj);
  }

  setParams(params: ParamsObject) {
    this.clear();
    // logsStore.setTypes(urlParams.getAll('types'));
    
    let severity: SeverityExc[] | SeverityExc | undefined = undefined;
    
    if (params[SearchMeta.MIN_SEVERITY]) {
      
      severity = (Array.isArray(params[SearchMeta.MIN_SEVERITY]) ? params[SearchMeta.MIN_SEVERITY][0] : params[SearchMeta.MIN_SEVERITY]) as SeverityExc;
    }
    else if (params[SearchMeta.SEVERITY]) {
      severity = params[SearchMeta.SEVERITY] as SeverityExc[];
    }

    if (Array.isArray(severity) && severity.length === SeverityExcUtil.values.length) severity = undefined;
    
    this.setSeverity(severity);

    // adding selected options
    const searchTypeKeys = new Set([
      ...Object.keys(SearchParam).map(key => SearchParam[key]),
      ...this.additionalParams
    ]);
    const selectedOptions: CustomOption[] = [];
    const addSelected = (prop: string, value: string) => {
      let label = `${prop}: ${value}`;
      if (prop === SearchParam.TO_TIME || prop === SearchParam.FROM_TIME) {
        const time = new Time(value);
        label = `${prop}: ${time.getShortDateTime()}`;
      }
      const option = new CustomOption(prop as SearchType, label, value);
      selectedOptions.push(option);
    };

    for (const prop in params) {
      if (searchTypeKeys.has(prop)) {
        if (Array.isArray( params[prop])) (params[prop] as string[]).forEach((value) => addSelected(prop, value));
        else addSelected(prop, params[prop] as string);
      }
    }
    this.selectedOptions = selectedOptions;
  }

  async search(type: SearchType, query: string) {
    let res: string[];
    if (query.length < 3) return [query];
    this.inProgress = true;
    try {
      switch (type) {
        case SearchParam.MANUFACTURER:
          res = await ConnectionService.getManufacturers(query);
          break;
        case SearchParam.DEVICE_MODEL:
          res = await ConnectionService.getDeviceModels(query);
          break;
        case SearchParam.ANDROID_ID:
        case SearchParam.UUID:
        case SearchParam.UDID:
          res = await ConnectionService.getUdids(query);
          break;
        case SearchParam.APP_VERSION:
          res = await logConnection.getAppVersions(query);
          break;
        case SearchParam.TAG:
          res = await logConnection.getTags(query);
          break;
        case SearchParam.USER_ID:
          res = await ConnectionService.getUserIds(query);
          break;
        case SearchParam.USER_NAME:
          res = await ConnectionService.getUserNames(query);
          break;
        case SearchParam.FULL_NAME:
          res = await ConnectionService.getFullNames(query);
          break;
        case SearchParam.EMAIL:
          res = await ConnectionService.getEmails(query);
          break;
        case SearchParam.PHONE_NUMBER:
          res = await ConnectionService.getPhoneNumbers(query);
          break;
        default:
          res = [];
      }
      return [query, ...res];
    } catch (error) {
      console.log(error);
      runInAction(() => this.error = error as Error);
    } finally {
      runInAction(() => this.inProgress = false);
    }
    return [];
  }

  setSeverity(severity?: SeverityExc[] | SeverityExc) {
    this.severity = undefined;
    this.minSeverity = undefined;
    if (!severity) return;

    if (Array.isArray(severity)) {
      this.severity = severity;
      this.minSeverity = undefined;
      this.fromSeverityMode = false;
    }
    else {
      this.severity = undefined;
      this.minSeverity = severity;
      this.fromSeverityMode = true;
    }
  }

  hasSeverity(severity: SeverityExc) {
    if (this.fromSeverityMode) {
      if (!this.minSeverity) return true;
      return SeverityExcUtil.number(severity) <= SeverityExcUtil.number(this.minSeverity);
    }
    else {
      if (!this.severity || this.severity.length === 0) return true;
      return this.severity.find((s) => severity === s )
    }
  }

  setShowEvents(showEvents: boolean) {
    this.showEvents = showEvents;
    storageService.setItem('showEvents', showEvents);
    logsStore.getLogs(true);
  }

  setFromSeverityMode(fromSeverityMode: boolean) {
    this.fromSeverityMode = fromSeverityMode;
  }

  setIsAlert(isAlert: boolean) {
    this.isAlert = isAlert;
  }
}

export default new SearchStore();