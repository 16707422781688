
import * as React from 'react';
import styles from './auth_template.module.css';
import topBubbles from '../../img/auth/top-bubbles.svg';
import bottomBubbles from '../../img/auth/bottom-bubbles.svg';
import octypus from '../../img/auth/octopus.png';
import bridge from '../../img/auth/bridge.png';
import logbook from '../../img/auth/logbook.png';
import loglytics from '../../img/auth/loglytics.png';
const types = {
  octopus: {
    img: octypus,
    title: "Fix Your App Issues Before Users Complain",
    body: "A smart & simple search tool with powerful capabilities. Did you know? In one search box, You can mix and match different parameters to find the exact logs you need to fix your app. "
  },
  bridge: {
    img: bridge,
    title:"View App Quality in Real-Time",
    body: "The heartbeat monitor of your app. In depth graphs of system metrics including log errors and usage per app version over time. For an extensive overview of actual app stability.",
  },
  logbook: {
    img: logbook,
    title: "A better way to analyze your app issues",
    body: "Combine search params such as User ID, device type, app version, log message text and more. With the error log at your fingertips there’s no more need to try and reproduce bugs. "
  },
  loglytics: {
    img: loglytics, 
    title: "Detect Issues Before Your Users Do",
    body: "Shipbook’s unique algorithm detects, ranks and then compiles recurring issues into one comprehensive list. Logs are listed according to frequency. The more urgent the log, the higher up it will be."
  }
};

interface Props{
  type: string;
}

export default class AuthTemplate extends React.Component<Props> {

  render() {
    const {img, title, body} = types[this.props.type];
    return (
      <div className={styles.component}>
        <div className={styles.main}>
          {this.props.children}
        </div>
        <div className={styles.sideComponent}>
          <img className={styles.topBubbles} src={topBubbles} alt="background bubbles"/>
          <img className={styles.bottomBubbles} src={bottomBubbles} alt="background bubbles"/>
          <div className={styles.sideMain}>
            <img className={styles.sideImage} src={img} alt="main imag"/>
            <div className={styles.sideTitle}>{title}</div>
            <div className={styles.sideBody}>{body}</div>
          </div>
        </div>
      </div>
    );
  }
}
