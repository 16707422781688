/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import styles from './popup_share.module.css';
import { observer } from 'mobx-react';
import { ShareStore, ShareType } from '../../stores/share.store'
import Select from './select.component';

interface Props {
  shareStore?: ShareStore;
}

const PopupShare = observer(class PopupShare extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleChangeProject = this.handleChangeProject.bind(this);
    this.handleChangeIssue = this.handleChangeIssue.bind(this);
    this.handleSummaryChange = this.handleSummaryChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
  }

  private handleChangeProject(value:string) {
    this.props.shareStore!.setJiraProject(value);
  }

  private handleChangeIssue(value:string) {
    this.props.shareStore!.setJiraIssueType(value);
  }
  
  private handleSummaryChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.shareStore!.setSummary(event.target.value);
  }

  private handleDescriptionChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.props.shareStore!.setDescription(event.target.value);
  }

  render() {
    const {summary, description, jiraProject, jiraIssueType, jiraCloud, shareType} = this.props.shareStore!;
    
    const projectNameValues: { [name: string]: string } = {'':'Project'};
    const issueNameValues: { [name: string]: string } = {'':'Issue'};

    if (shareType === ShareType.JIRA) {
      const {projects} = jiraCloud!;
      projects.forEach((po)=>{
        projectNameValues[po.id] = po.name;
      });
  
      if (jiraProject.length > 0) delete projectNameValues[''];
  
      if(jiraProject.length > 0 && projects.length > 0){
        const curProject = projects.find(d=>d.id===jiraProject);
        curProject?.issueTypes.forEach((issue)=>{
          issueNameValues[issue.id] = issue.name;
        })
      }
      if(jiraIssueType.length > 0) delete issueNameValues[''];  
    }
    return (
      <div>  
        {
          shareType === ShareType.JIRA && <>
            <div className={styles.title}>
              <Select
                value={jiraProject}
                nameValues={projectNameValues} 
                onChange={this.handleChangeProject}/>
            </div>
            <div className={styles.title}>
              <Select 
                value={jiraIssueType}
                nameValues={issueNameValues} 
                onChange={this.handleChangeIssue}/>
            </div>
          </>
        }         
        <div className={styles.title}>
          <input type="text" value={summary} className={styles.popupiInput} onChange={this.handleSummaryChange} placeholder="Summary"/>
        </div>    
        <div className={styles.msg}>
          <textarea className={styles.popupInputArea} rows={3} onChange={this.handleDescriptionChange} value={description}></textarea>
        </div> 
      </div>
    );
  }
});

export const popupShareComponent = <PopupShare /> // for to pass it into the store