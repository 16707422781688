import * as React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import PrefAccount from '../components/pref_account/pref_account.component';
import UserSettings from '../components/user_settings/user_settings.component';
import AppRoutes from './app.routes';

export default class MainRoutes extends React.Component {
  render() {
    return (
      <Switch>
         <Route path="/user-settings/refer-and-earn"> {/* redirect the old url */}
          <Redirect to="/user-settings/refer-a-friend" />
        </Route>
        <Route path="/user-settings/" component={UserSettings}/>
        <Route path="/:accountId/preferences/" component={PrefAccount} />
        <Route path="/:accountId/:appId/" component={AppRoutes}/>
      </Switch>
    );
  }
}