import React, { ReactElement, ReactText } from 'react';
import styles from  './popup_screen.module.css';
import xIcon from '../../img/x.svg';

interface Props {
  children: ReactElement | ReactText;
  title: string;
  titleCenter?: ReactElement | ReactText;
  onExit: Function;
}

const PopupScreen = ({children, title, titleCenter, onExit} : Props) => { 
  return (
    <div className={styles.component}> 
      <div className={styles.popup}>
        <div className={styles.header} >
          <div className={styles.title}>{title}</div> 
          {titleCenter && <div className={styles.titleCenter}>{titleCenter}</div>}
          <img className={styles.xIcon} src={xIcon} alt="exit" onClick={() => onExit!()}/>
        </div>
        <div className={styles.body}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PopupScreen;