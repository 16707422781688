import { observable, action, runInAction, makeObservable } from 'mobx';

// import { OptionsType } from 'react-select/lib/types';
import { ConnectionError } from '../services/connection/connection.service';
import IJiraCloud from '../models/dto/jira_cloud.model';
import JiraConnection from '../services/connection/jira.connection';
import Popup, {PopupInfo} from '../components/common/popup.component';
import { popupShareComponent } from '../components/common/popup_share.component';
import slackConnection from '../services/connection/slack.connection';
import memberStore from './member.store';
import commonStore  from './common.store';

export enum ShareType {
  EMAIL = 'Email',
  JIRA = 'Jira',
  SLACK = 'Slack',
  DOWNLOAD = 'Download',
  COPY = 'Copy URL'
}

export class ShareStore {
  summary: string = '';
  description: string = '';
  downloadUrl?: string;
  jiraProject: string = '';
  jiraIssueType: string = '';

  jiraAccessToken?: string;
  jiraCloud?: IJiraCloud;
  authUrl?: string;
  error?: Error | ConnectionError = undefined;

  shareType?: ShareType;

  constructor() {
    makeObservable(this, {
      summary: observable,
      description: observable,
      downloadUrl: observable,
      jiraProject: observable,
      jiraIssueType: observable,
      jiraAccessToken: observable,
      jiraCloud: observable,
      authUrl: observable,
      error: observable,
      shareType: observable,
      getJiraProjects: action,
      showCreateJiraIssue: action,
      showCreateSlackPost: action,
      getAuthUrl: action,
      setJiraShareParams: action,
      setSummary: action,
      setDescription: action,
      setJiraProject: action,
      setJiraIssueType: action
    });

    this.showCreateJiraIssue = this.showCreateJiraIssue.bind(this);
    this.showCreateSlackPost = this.showCreateSlackPost.bind(this);
    this.jiraOk = this.jiraOk.bind(this);
    this.slackOk = this.slackOk.bind(this);
  }

  async getJiraProjects(jiratoken:string) {
    let res: IJiraCloud;
    try {
      //fetch projects and issues
      res = await JiraConnection.getJiraOptions(jiratoken);
      runInAction(() => this.jiraCloud = res);

    } catch (error) {
      console.log(error);
      runInAction(() => this.error = error);
    } 
  }
  private async jiraOk() {
    if (this.jiraProject.length === 0){
      console.error('Must specify project for the ticket'); 
      return false;
    }
    if (this.jiraIssueType.length === 0){
      console.error('Must specify issue type for the ticket'); 
      return false;
    }

    commonStore.setLoading(true);
    const resp =  await JiraConnection.createJiraIssue(this.jiraAccessToken!,{
      projectId: this.jiraProject,
      cloudId: this.jiraCloud!.cloudId,
      issueTypeId: this.jiraIssueType,
      summary: this.summary,
      description: this.description,
      attachmentUrl: this.downloadUrl        
    });
    commonStore.setLoading(false);

    if (!resp?.key) {
      Popup.createError({msg:'Failed to create ticket'});
      return false;
    }

    const ticketUrl = `https://${this.jiraCloud!.cloudName ||''}.atlassian.net/browse/${resp.key}`;
    window.open(ticketUrl,'_jira');
    return true;
  }

  private  async slackOk() {
    try {
      await slackConnection.postMessage({
        accountId: memberStore.accountId,
        appId: memberStore.appId,
        summary: this.summary,
        description: this.description
      });
      Popup.create({msg: 'Succeeded to send to slack'});
    }
    catch(e) {
      Popup.createError({msg: 'Had a problem posting message to slack'});
    }
    return false;
  }

  async showCreateJiraIssue(jiraAccessToken?:string) {
    this.shareType = ShareType.JIRA;
    if (jiraAccessToken) this.jiraAccessToken = jiraAccessToken;
    if (!this.jiraAccessToken) return false;    
    await this.getJiraProjects(this.jiraAccessToken);
    if (!this.jiraCloud) return false;

    const info: PopupInfo = {
      iconSrc: undefined,
      title: 'Jira Share',
      okCallback: this.jiraOk,
      cancel: 'Cancel',
      msg: popupShareComponent
    };
    Popup.create(info);
    return true;
  }

  async showCreateSlackPost() {
    this.shareType = ShareType.SLACK;
    const info: PopupInfo = {
      iconSrc: undefined,
      title: 'Slack Share',
      okCallback: this.slackOk,
      cancel: 'Cancel',
      msg: popupShareComponent
    };
    Popup.create(info);

  }

  async getAuthUrl() {
    try {
     if(!this.authUrl){
        //fetch clientid
        const res = await JiraConnection.getAutUrl();
        runInAction(() => {
          this.authUrl = res.jiraUrl;
        });
      }
    } catch (error) {
      console.log(error);
      runInAction(() => this.error = error);
    } 
  } 

  setJiraShareParams(summary:string, description:string, downloadUrl?: string) {
    this.summary = summary;
    this.description = description;
    this.downloadUrl = downloadUrl;
  }

  setSummary(summary: string) {
    this.summary = summary;
  }

  setDescription(description: string) {
    this.description = description;
  }

  setJiraProject(jiraProject: string) {
    this.jiraProject = jiraProject;
    this.jiraIssueType = '';
  }

  setJiraIssueType(jiraIssueType: string) {
    this.jiraIssueType = jiraIssueType;
  }
}

export default new ShareStore();