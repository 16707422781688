import React, {useEffect, useState, FormEvent, useRef} from 'react';
import { observer } from 'mobx-react';
import logChatStore from '../../stores/logchat.store';

import styles from './logchat_logs_view.module.css';
import { KeyValueList, Severities } from '../common/search.component';
import { IMessage, LogType } from '../../models/log.model';
import Time from '../../services/time.service';
import { toJS } from 'mobx';
import bot from '../../img/logchat/bot.svg';
import toLogBook from '../../img/logs/tosession-dark.svg';
import paramService, { ParamsObject } from '../../services/param.service';
import memberStore from '../../stores/member.store';
import { useHistory } from 'react-router-dom';

const EmptyLogsView = () => {
  return (
    <div className={styles.emptyLogsView}>
      <img src={bot} alt="bot" className={styles.emptyBot}/>
      <div className={styles.emptyText}>
        Timestamp: {new Time().getShortDateTime()} <br/>
        Log Type: DEBUG <br/>
        Message: User Request for Bug Assistance <br/>
        Description: The user is seeking help in identifying a bug in the code. <br/>
        <br/>
        Questions: <br/>
        1. Help me find a bug in the code. <br/>
        2. Assist me in locating an error in the logs. <br/>
        3. Can you translate this log into human language? <br/>
        4. What’s the meaning of this message? <br/>
        5. Find the line of code causing the issue. <br/>
      </div>
    </div>
  );
}

const LogsView = observer(() => {
  const {searchArray} = logChatStore;
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const history = useHistory();
  // const refLogView = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const {scrollToIndex} = logChatStore;
    console.log('entered useEffect scrollToIndex ' + scrollToIndex);
  
    if (scrollToIndex < 0) return;
    refs.current[scrollToIndex]?.scrollIntoView({behavior: 'smooth', block: 'start'});
  }, [logChatStore.scrollToIndex]);

  const showLogbook = (params: ParamsObject) => {
    const {accountId, appId} = memberStore;
    const search = paramService.createURLSearchParams(params);
    history.push({pathname: `/${accountId}/${appId}/logbook`, search: search.toString()});
  }

  let height = 0;
  if (searchArray.length > 0) {
    // height = refLogView.current?.clientHeight ?? 0;
    height = 500;
  }

  return (
    <div className={styles.logsView}>
      { searchArray.length == 0 ? 
        <EmptyLogsView /> : 
        searchArray.map((_search, index) => {
          const search = _search!;
          console.log('search query: ' + JSON.stringify(toJS(search.search.query)));
          console.log('search length: ' + search.search.logs.length);
          const logsArray = search.search.logs.filter(item => item.type == LogType.MESSAGE)
            .map(item => {
              switch(item.type) {
                case LogType.MESSAGE: {
                  const message = item as IMessage;
                  return `${new Time(message.time).getTime() }  ${message.severity.padEnd(7)}  ${message.message}`
                }
                default: return '';
              }
            });
          return (
            <div key={index}> 
              <div className={styles.logsHeader} ref={(element) => refs.current[index] = element}>
                <div className={styles.logsTitle}>{search.title ?? 'LogBook Query'}</div>
                <img src={toLogBook} className={styles.toLogBook} data-tip="go to logbook" alt="to logbook"
                  onClick={() => showLogbook(search.search.query)}/>

              </div>
      
              <div className={styles.logsBody}>
                <Severities search={search.search.query ?? {}} />
                <KeyValueList search={search.search.query ?? {}} className={styles.keyValueList} />
                <div className={styles.logsWarning}>Last {logsArray?.length} logs from search</div>
                <div className={styles.logs}>{logsArray?.join('\n')}</div>
              </div>
            </div>
          );

        })}
      <div style={{height}} > </div> {/* to make sure the last item can scroll to the top */}
    </div>
  )
});

export default LogsView;