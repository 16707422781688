import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { configure } from 'mobx';

import App from './components/app.component';
import * as serviceWorker from './serviceWorker';


configure({ enforceActions: 'observed' });

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root') as HTMLElement);

serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: registration => {
//     // alert('New version available!  Ready to update?');
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//     }
//     window.location.reload();
//   }
// });