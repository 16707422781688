import { observer } from 'mobx-react';
import * as React from 'react';
import * as mobx from 'mobx';
import PhoneInput from 'react-phone-input-2';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IAccount, { Role } from '../../models/account.model';
import authStore, { Invite } from '../../stores/auth.store';
import memberStore from '../../stores/member.store';
import {SelectRole} from '../pref_account/pref_members.component';
import AuthTemplate from './auth_template.component';

import styles from './create_account.module.css';

import logo from '../../img/shipbook-logo-circle.svg';
import addIcon from '../../img/add-icon.svg';


interface State {
  errorName: string;
  errorPhone: string;
  invites: Invite[];
}

class CreateAccount extends React.Component<RouteComponentProps<{}>, State> {
  disposer?: mobx.Lambda ;
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    this.state = {
      errorName: '',
      errorPhone: '',
      invites: [{email: "", role: Role.developer}, {email: "", role: Role.developer}]
    };
    this.changeName = this.changeName.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.changePhone = this.changePhone.bind(this);
    document.getElementsByTagName('meta')['viewport'].content = 'width=device-width, initial-scale=1';

    const {account} = memberStore;
    if (account) authStore.setCompanyName(account.name);

  }

  componentDidMount() {
    this.disposer = mobx.observe(memberStore, 'accountId', listener => {
      if (!listener.newValue) return;
      const {account} = memberStore;
      if (account) authStore.setCompanyName(account.name);
    });

  }

  componentWillUnmount() {
    if (this.disposer) this.disposer();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private changeName(event: any) {
    authStore.setCompanyName(event.target.value);
  }
  
  private changePhone(phone: string) {
    authStore.setPhone(phone);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onClick(event: any) {
    event.preventDefault();
    const {invites} = this.state;
    invites.push({email: "", role: Role.developer});
    this.setState({invites});
  }

  private changeEmail(email: string, index: number) {
    const {invites} = this.state;
    invites[index].email = email;
    this.setState({invites});
  }

  private changeRole(role: Role, index: number) {
    const {invites} = this.state;
    invites[index].role = role;
    this.setState({invites});
  }


  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let {invites} = this.state;
    const {companyName, phone} = authStore.values;
    let errorName = '';
    let errorPhone = '';
    if (companyName.length < 1) errorName = 'Company name is required';
    if (!memberStore.member?.phone && phone.length < 7) errorPhone = 'Need a valid phone number';
    this.setState({errorName, errorPhone});
    if (errorName.length > 0 ||
      errorPhone.length > 0) {
      return;
    }

    invites = invites.filter(invite =>  invite.email.length > 0);
    try {
      await authStore.setAccount(companyName, invites, phone);
      this.props.history!.replace(`/app-wizard/new`);
    } catch (error) { 
      console.log(error);
    } 
  }

  render() {
    const {invites, errorName, errorPhone} = this.state;
    const {companyName, phone} = authStore.values;
    const { member } = memberStore;
    return (
      <AuthTemplate type="loglytics">
        <div className={styles.container}>
          
          <img className={styles.shipbookColorLogo} src={logo} alt="logo"/>
          <div className={styles.title}>Create a New Account</div>
          <div className={styles.body}>This is where you create your new account.
            Invite an unlimited amount of members and monitor an unlimited number of apps! One account to do it all. </div>
          <div className={styles.fill}>Fill out your company details get started:</div>
          <form className={styles.form} onSubmit={this.handleSubmit}>
            <label className={styles.label}>Company Name </label>
            <input className={styles.input} type="text" name="companyName" value={companyName} onChange={this.changeName} />
            <div className={styles.error}>{errorName}</div>
            { !member?.phone && 
              <>
                <label className={styles.label}>User Phone Number</label>
                <PhoneInput country={'us'} value={phone} onChange={this.changePhone}
                  inputStyle = {{backgroundColor: "var(--light-grey)", width:"100%", border: 0, borderRadius: 5 }}
                  containerStyle = {{marginTop : 3, marginBottom: 0, width:"100%"}}
                  buttonStyle= {{ backgroundColor: "var(-light-grey)", border: 0, borderRadius: 5}}/>
                <div className={styles.error}>{errorPhone}</div>
              </>
            }

            <div className={styles.space}/>
            <div className={styles.title}>Invite collaborators</div>
            <div className={styles.body}>You can also always add additional members by going to preferences &gt; members in the console.</div>
            {
              invites.map((invite, index) => (
                <div  key={index} className={styles.invite}>
                  <input className={styles.inputEdit} type="email" placeholder="Email" autoComplete="email" value={invite.email}  onChange={(event) => this.changeEmail(event.target.value, index)} />
                  <SelectRole role={invite.role} changeRole={(role) => this.changeRole(role, index)}/>
                </div>
                )
              )
            }
            <input className={styles.add} type="image" src={addIcon} onClick={this.onClick} alt="add email"/>

            <input className={styles.submit} type="submit" value="Continue" />
          </form>
        </div>
      </AuthTemplate>
    );    
  }
}


export default withRouter(observer(CreateAccount));
