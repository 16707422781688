import IApp, { ICustomAlert as ICustomAlertBase } from "./app.model";
import IBase from "./base.model";
import { SeverityExc } from "./log.model";
import { ILoglytics, LoglyticsType } from "./loglytics.model";
import IMember from "./member.model";

export enum AlertType {
  CUSTOM = "custom-alert",
  LOGLYTICS = "loglytics-alert"
}

export class AlertTypeUtil {
  public static values() {
    return Object.keys(AlertType).map(key => AlertType[key as keyof typeof AlertType]);
  }
}

export interface ITrigAlert extends IBase {
  app: IApp  | string;
  type: AlertType;
  count: number;
  date: Date;
  read: boolean;
}
export interface ICustomAlert  extends ITrigAlert{
  alert: ICustomAlertBase ;
}

export interface ILoglyticsAlert extends ITrigAlert {
  alert: {
    loglytics: ILoglytics | string;
    key: string;
    type: LoglyticsType;
    severity: SeverityExc;
    baseFileName: string;
    lineNumber: number;
    function: string;
    className: string;
    message: string;
  }
}
