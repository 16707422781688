import { ParamsObject } from '../services/param.service';
import IBase from './base.model';
import { ConfigType } from './config_type.model';
import { Platform } from './platform.model';

export enum Integration {
  NONE = 'none',
  SESSION = 'session',
  FULL = 'full'
}

export enum Comparison {
  GREATER = ">",
  LESS = "<",
  EQUAL = "=",
  NOT_EQUAL = "≠"
}

export enum ComparisonName {
  GREATER = "> (more than)",
  LESS = "< (less than)",
  EQUAL = "= (equal to)",
  NOT_EQUAL = "≠ (not equal to)"
}

export const comparisonValues = {
  ">": ComparisonName.GREATER, 
  "<": ComparisonName.LESS,
  "=": ComparisonName.EQUAL, 
  "≠": ComparisonName.NOT_EQUAL
};

export class ComparisonUtil {
  static values() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(Comparison).map(k => Comparison[k as any]);
  }

  static fromString(comparison: string) {
    switch (comparison) {
      case Comparison.GREATER: return Comparison.GREATER;
      case Comparison.LESS: return Comparison.LESS;
      case Comparison.EQUAL: return Comparison.EQUAL;
      case Comparison.NOT_EQUAL: return Comparison.NOT_EQUAL;
      default: return Comparison.GREATER;
    }
  }
}

export interface ICustomAlert {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _id?:any;
  name: string;
  search: ParamsObject;
  minutes: number;
  comparison: Comparison;
  amount: number;
}

export default interface IApp extends IBase {
  name: string;
  key: string;
  config?: object;
  configType: ConfigType;
  configConsole: boolean;
  activationDate?: Date;
  platform?: Platform;
  integration: Integration;
  disabledDevices: string[];
  alerts?: ICustomAlert[];
}