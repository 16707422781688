/* eslint-disable jsx-a11y/alt-text */
import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import styles from './log_bottom.module.css';
import logsStore from '../../stores/logs.store';
import Switch from '../common/switch.component';
import searchStore from '../../stores/search.store';

import maxModeIcon from '../../img/logs/max-mode.svg';
import minModeIcon from '../../img/logs/min-mode.svg';
import trash from '../../img/logs/trash.svg';
import liveTailStore from '../../stores/live_tail.store';

const LogBottom: FunctionComponent <{liveTail?: boolean}> = ({liveTail}) => {
  function onShowEventsClick(status: boolean) {
    searchStore.setShowEvents(!status);
  }

  function onMinMaxClick(max: boolean) {
    logsStore.setMinMode(!max);
  }

  function clickTrash() {
    liveTailStore.clear();
  }

  const { minMode } = logsStore;
  const { showEvents } = searchStore;
  return (
    <div className={styles.component}>
      {liveTail ?
        <>
          <img alt="trash" className={styles.trash} src={trash} onClick={clickTrash} />
        </>:
        <>
          <Switch oneMode={!showEvents} onClick={onShowEventsClick} />
          <div className={styles.showEvents}>Events</div>
          <div className={styles.seperator}/>
          <Switch image1={maxModeIcon} image2={minModeIcon} dataTip1="all message info" dataTip2="short message info" oneMode={!minMode} onClick={onMinMaxClick}/>
        </>
      }
    </div>
  );
}

export default observer(LogBottom);