import styles from  './loader.module.css';
import * as React from 'react';
import { observer } from 'mobx-react';
import commonStore from '../../stores/common.store';

import radar from '../../img/radar.gif';
class Loader extends React.Component<{}> {
  constructor(props: {}) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    commonStore.stopLoading();
  }
  render() {
    if (!commonStore.loading) return null;
    return (
      <div className={styles.loader} onClick={this.onClick}>
        <img alt="loader" src={radar}/>
      </div>
    );
  }
}

export default observer(Loader);

export class LocalLoader  extends React.Component {
  render() {
    return (      
      <div className={styles.localLoader}>
        <img alt="loader" src={radar}/>
      </div>
    );
  }
}
