import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { observer } from 'mobx-react';
import styles from './register.module.css';
import authStore from '../../stores/auth.store';

// import Popup, { PopupInfo } from '../common/popup.component';
import GithubAuth from './github_auth.component';
import GoogleAuth from './google_auth.component';
import emailService from '../../services/email.service';
// import environmentService from '../../services/environment.service';
import commonStore from '../../stores/common.store';
import AuthTemplate from './auth_template.component';

import logo from '../../img/logos/shipbook_text.svg';
import { ITracking } from '../../models/member.model';

interface State {
  errorName: string;
  errorEmail: string;
  errorPhone: string;
  errorPassword: string;
  disabled: boolean;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { opera: any; }
}

class Register extends React.Component<RouteComponentProps<{}>, State> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);

    this.state = {
      errorName: '',
      errorEmail: '',
      errorPhone: '',
      errorPassword: '',
      disabled: false,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleReferralCodeChange = this.handleReferralCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const search = this.props.location!.search;
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    if (token) authStore.setToken(token);
    if (email) authStore.setEmail(email);

    const referralCode = urlParams.get('referral') ?? undefined;
    if (referralCode) authStore.setReferralCode(referralCode);

    const utm_source = urlParams.get('utm_source') ?? undefined;
    const utm_medium = urlParams.get('utm_medium') ?? undefined;
    const utm_campaign = urlParams.get('utm_campaign') ?? undefined;
    const utm_term = urlParams.get('utm_term') ?? undefined;
    const utm_content = urlParams.get('utm_content') ?? undefined;
    const referrer = urlParams.get('referrer') ?? undefined;
  
    if (utm_source || utm_medium || utm_campaign || utm_term || utm_content || referrer) {
      const tracking: ITracking = {utm_source, utm_medium, utm_campaign, utm_term, utm_content, referrer};
      authStore.setTracking(tracking);
    }

    // tslint:disable-next-line:no-string-literal
    document.getElementsByTagName('meta')['viewport'].content = 'width=device-width, initial-scale=1';
  }

  handleNameChange =
    (event: React.FormEvent<HTMLInputElement>) =>
      authStore.setFullName(event.currentTarget.value);

  handleEmailChange =
    (event: React.FormEvent<HTMLInputElement>) =>
      authStore.setEmail(event.currentTarget.value);

  handlePhoneChange = (phone: string) => authStore.setPhone(phone);
  
  handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) =>
    authStore.setPassword(event.currentTarget.value);
  
  handleReferralCodeChange = (event: React.FormEvent<HTMLInputElement>) => 
    authStore.setReferralCode(event.currentTarget.value);

  async handleSubmit (event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    commonStore.reset();
    console.debug('handle submit');

    const {values} = authStore;
    

    let errorName = '';
    let errorEmail = '';
    let errorPhone = '';
    let errorPassword = '';
    
    if (values.fullName.length < 2) errorName = 'need at least 2 characters' 
    if (!emailService.validateEmail(values.email)) errorEmail = 'need a valid email'
    if (values.phone.length < 8) errorPhone = 'need a valid phone number' 
    if (values.password.length < 8) errorPassword = 'need at least 8 characters' 

    this.setState({errorName, errorEmail, errorPhone, errorPassword});
    authStore.clearError();
    if (errorName.length > 0 ||
        errorEmail.length > 0 ||
        errorPhone.length > 0 ||
        errorPassword.length > 0) {
      return;
    }

    this.setState({disabled: true});
    try {
      await authStore.register();
      // if (environmentService.isMobile()) {
      //   const info: PopupInfo = {
      //     msg: `Thank you for registering. To continue setup please login to your account on the Desktop.`,
      //     nonCancellable: true,
      //     ok: undefined
      //   };
      //   Popup.create(info);
      //   return;
      // }
      this.props.history!.push('/');
    } catch (error) { 
      console.log(error);
      this.setState({disabled: false});
    }
  }
  

  render() {
    const {fullName, email, phone, password, referralCode} = authStore.values;
    const { error } = authStore;
    const { errorName, errorEmail, errorPhone, errorPassword, disabled} = this.state;

    const mailto = `mailto:support@shipbook.io?subject=${encodeURIComponent('Sign up error - ' + email)}`;
    return (
      <AuthTemplate type="logbook">
        <div className={styles.container}>
          <img className={styles.shipbookColorLogo} src={logo} alt="shipbook"/>
          <div className={styles.sso}>
            <div><GoogleAuth buttonText="SIGN UP WITH GOOGLE"/></div>
            <div className={styles.space}/>
            <div><GithubAuth buttonText="SIGN UP WITH GITHUB"/></div>
          </div>
          <div className={styles.or}>
            <div className={styles.line}/>
            <div className={styles.space}>Or</div>
            <div className={styles.line}/>
          </div>
          <form className={styles.form} onSubmit={this.handleSubmit}>
            <label className={styles.label}>Full Name</label>
            <input value={fullName} className={styles.input} type="text" name="fullName" onChange={this.handleNameChange} />
            <div className={styles.error}>{errorName}</div>
            <label className={styles.label}>Work Email</label>
            <input value={email} className={styles.input} type="email" name="email"
              autoComplete="email" onChange={this.handleEmailChange} />
            <div className={styles.error}>{errorEmail}</div>
            <label className={styles.label}>Phone</label>
            <PhoneInput country={'us'} value={phone} onChange={this.handlePhoneChange}
              inputStyle = {{backgroundColor: "var(--light-grey)", width:"100%", border: 0, borderRadius: 5 }}
              containerStyle = {{marginTop : 3, marginBottom: 0, width:"100%"}}
              buttonStyle= {{ backgroundColor: "var(-light-grey)", border: 0, borderRadius: 5}}/>
            <div className={styles.error}>{errorPhone}</div>
            <label className={styles.label}>Password</label>
            <input value={password} className={styles.input} type="password" name="password"
              autoComplete="current-password" onChange={this.handlePasswordChange} />
            <div className={styles.error}>{errorPassword}</div>

            <label className={styles.label}>Referral Code</label>
            <input value={referralCode} className={styles.input} type="text" name="referralCode" onChange={this.handlePasswordChange} />

            {!!error && 
              <div className={styles.error}>Sorry, we weren't able to create a user for you. 
                It could be that you already have one, if so, please <Link to="/login">log in</Link>. 
                If not, please contact <a href={mailto}>support</a>.
              </div>}
            <div className={styles.terms}>By signing up, you are agreeing to our <br/>
              <Link
                to="route" 
                onClick={(event) => { 
                  event.preventDefault(); 
                  window.open(`https://www.shipbook.io/shipbook-terms-of-service`);
                }
              }>Terms of Service</Link>
              &nbsp;&amp;&nbsp;
              <Link
                to="route" 
                onClick={(event) => { 
                  event.preventDefault(); 
                  window.open(`https://www.shipbook.io/shipbook-privacy-policy`);
                }
              }>Privacy Policy</Link>
            </div>
            <input className={styles.submit} type="submit" value="Sign up" disabled={disabled}/> 
            <div className={styles.login}>Already a member? <Link to="/login" className={styles.register}>Log in</Link></div>
          </form>
        </div>
      </AuthTemplate>
    );
  }
}

export default withRouter(observer(Register));