import * as React from 'react';
import { observer } from 'mobx-react';
import { autorun, IReactionDisposer } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList as List, ListChildComponentProps as ListProps, ListOnItemsRenderedProps} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from 'react-tooltip';

import styles from './log_list_view.module.css';
import logsStore, {Item, ItemType, PagingType } from '../../stores/logs.store';
import { IMessage, IException, Severity, LogType, 
  IEvent, IActionEvent, IActivityEvent, IConfigEvent, IAppEvent, IScreenEvent } from '../../models/log.model';
import ISession from '../../models/session.model';
import Time from '../../services/time.service';
import paramService from '../../services/param.service';
import commonStore from '../../stores/common.store';
import searchStore from '../../stores/search.store';
import { SearchParam } from '../../models/search.model';
import LogMessagePopup from './log_message_popup.component';

import toSession from '../../img/logs/tosession-dark.svg';
import appleIcon from '../../img/apple-icon.svg';
import androidIcon from '../../img/android-icon.svg';
import empty from '../../img/empty.gif';
import lost from '../../img/lost.gif';
import can from '../../img/loglytics/can.gif';
interface LogState {
}

interface LogProps extends RouteComponentProps<{}> {
  log: IMessage | IException;
  index: number;
}

const LogComponent = withRouter(observer(class LogComponent extends React.Component<LogProps, LogState> {

  private readonly severityClasses = new Map<string, string>([
    [Severity.VERBOSE, styles.verbose],
    [Severity.DEBUG, styles.debug],
    [Severity.INFO, styles.info],
    [Severity.WARNING, styles.warning],
    [Severity.ERROR, styles.error]
  ]);

  constructor(props: LogProps) {
    super(props);
    this.showSession = this.showSession.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  private showMessage(e: React.MouseEvent<HTMLElement>) {
    console.log('pressed show message');
    e.stopPropagation();
    logsStore.setMessagePopupIndex(this.props.index);
  }

  private showSession(e: React.MouseEvent<HTMLElement>) {
    console.log('pressed show session');
    e.stopPropagation();
    const sessionId = this.props.log.sessionId;
    const hasId = this.props.log._id;
    if (e.metaKey) {
      const win = window.open(`${this.props.history.location.pathname}?${SearchParam.SESSION}=${sessionId}&${SearchParam.HAS_ID}=${hasId}`, "_blank");
      win?.focus();
      return;
    }
    const params = {};
    params[SearchParam.SESSION] = sessionId;
    params[SearchParam.HAS_ID] = hasId;
    paramService.setParams(this.props, params, true);  
  }

  private add(e: React.MouseEvent<HTMLElement>, type: string, value: string) {
    e.stopPropagation();
    paramService.setParam(this.props, type, value);
  }

  // private highlightMessage (message:string) {
  //   let messageArray = message.replaceAll("</shipbook>", "<shipbook>").split("<shipbook>");
  //   return (
  //     <>
  //       {messageArray.map((value, index) => (index % 2 === 1) ? <em> {value} </em> : <> {value} </>)}
  //     </>
  //   )
  // }
  
  render() {
    const {log, history} = this.props;
    const isException = log.type === LogType.EXCEPTION;
    const exception = log as IException;
    const message = log as IMessage;
    const severityClass = isException ? styles.exception : this.severityClasses.get(message.severity);
    const { minMode } = logsStore;
    const { logId, logsParams } = searchStore;
    const time = new Time(log.time);
    let logMessage = '';
    let fileName = '';
    let lineNumber: string | number = 0;
    let functionName = '';
    let logClassName;
    if (isException) {  
      logMessage = exception.name + ': ' + exception.reason;
      if (exception.stackTrace?.length > 0) {
        fileName = exception.stackTrace[0].fileName;
        lineNumber = exception.stackTrace[0].lineNumber;
        functionName = exception.stackTrace[0].methodName;
        logClassName = exception.stackTrace[0].declaringClass;
      }
    } else {
      logMessage = message.messageHighlight ?? message.message;
      fileName = message.fileName.slice(message.fileName.lastIndexOf('/') + 1);
      lineNumber = message.lineNumber;
      functionName =  message.function;
      logClassName = message.className;
    }

    return (
      <div className={`${styles.log} ${minMode ? styles.minMode : ''} ${logId === log._id ? styles.selected : ''}`} onClick={this.showMessage}>
        <div className={`${styles.severity} ${severityClass}`}/>
        <div className={`${styles.logRow} ${styles.header}`}>
          <div className={styles.time}>
            {time.getTime()} </div>
          <div className={styles.msg}>
            <span> &gt; </span>
            {
              message.messageHighlight ? 
                <span dangerouslySetInnerHTML={{__html : logMessage}} />: 
                <span>{logMessage}</span>
            }
          </div> 
          <div className={styles.space}/>
          <img src={toSession} className={styles.tosession} data-tip="go to session" alt="to session"
            onClick={this.showSession}/>
        </div>
        {!minMode && <div className={`${styles.logRow} ${styles.parameters}`}> 
          {(fileName.length > 0 || lineNumber !== 0)  && <>
            <div className={styles.logKey}>{fileName}:</div>
            <div className={styles.logValue}>{lineNumber} ({functionName})</div>
          </>}
          {!isException && <>
            <div className={styles.seperator}>|</div>
            <div className={styles.logKey}>tag: </div>
            <div className={`${styles.logValue} ${logsParams[SearchParam.TAG] ? styles.highlight: ''}`}>{message.tag.slice(message.tag.indexOf('.') + 1)}</div>
            <div className={styles.plus} 
              onClick={(e) => this.add(e, 'tag', message.tag)} data-tip="add tag to search">+</div>
          </>}

          {!!logClassName && <>
            <div className={styles.seperator}>|</div>
            <div className={styles.logKey}>class: </div>
            <div className={styles.logValue}>{logClassName}</div>
          </>}          
        </div>}
      </div>

    );
  }
}));


interface SessionProps extends RouteComponentProps<{}> {
  session: ISession;
}

const SessionComponent = withRouter(observer(class SessionComponent extends React.Component<SessionProps> {
  constructor(props: SessionProps) {
    super(props);
    this.add = this.add.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  private add(type: string, value: string) {
    paramService.setParam(this.props, type, value);
  }

  private getUserInfo() {
    const user = this.props.session.userInfo ;
    const { logsParams } = searchStore;
    if (!user) return null;
    const additionalInfoKeys = user.additionalInfo ? Object.keys(user.additionalInfo): [];
    return (
      <>
        {!!user.userId && <> 
          <div className={styles.sessionTitle}> User ID </div>
          <div className={styles.sessionValue}> 
            <div className={logsParams[SearchParam.USER_ID] ? styles.highlight: ''}>{user.userId} </div>
            <div className={styles.sessionPlus} onClick={() => this.add(SearchParam.USER_ID, user.userId)}
            data-tip="add User Id to search">+</div>
          </div>
        </>}
        
        {!!user.userName && <>
          <div className={styles.sessionTitle}> User Name </div>
          <div className={styles.sessionValue}> 
            <div className={logsParams[SearchParam.USER_NAME] ? styles.highlight: ''}>{user.userName} </div>
            <div className={styles.sessionPlus} onClick={() => this.add(SearchParam.USER_NAME, user.userName)}
            data-tip="add User name to search">+</div>
          </div>
        </>}

        {!!user.fullName && <>
          <div className={styles.sessionTitle}> Full User Name </div>
          <div className={styles.sessionValue}> 
            <div className={logsParams[SearchParam.FULL_NAME] ? styles.highlight: ''}>{user.fullName} </div>
            <div className={styles.sessionPlus} onClick={() => this.add(SearchParam.FULL_NAME, user.fullName)}
            data-tip="add full name to search">+</div>
          </div>
        </>}

        {!!user.phoneNumber && <>
          <div className={styles.sessionTitle}> Phone Number </div>
          <div className={styles.sessionValue}> 
            <div className={logsParams[SearchParam.PHONE_NUMBER] ? styles.highlight: ''}> {user.phoneNumber} </div>
            <div className={styles.plus} onClick={() => this.add(SearchParam.PHONE_NUMBER, user.phoneNumber)}
            data-tip="add phone number to search">+</div>
          </div>
        </>}

        {!!user.email && <>
          <div className={styles.sessionTitle}> email </div>
          <div className={styles.sessionValue}> 
            <div className={logsParams[SearchParam.EMAIL] ? styles.highlight: ''}> {user.email} </div>
            <div className={styles.plus} onClick={() => this.add(SearchParam.EMAIL, user.email)}
              data-tip="add email to search">+</div>
          </div>
        </>}

        {additionalInfoKeys.map((key) => (
          <React.Fragment key={key}>
            <div className={styles.sessionTitle}> {key} </div>
            <div className={styles.sessionValue}> 
              <div className={logsParams[key] ? styles.highlight: ''}> {user.additionalInfo[key]} </div>
              <div className={styles.plus} onClick={() => this.add(key, user.additionalInfo[key])}
                data-tip={`add ${key} to search`}>+</div>
            </div>
          </ React.Fragment>
        ))}
      </>
    )
  }

  render() {
    const { session } = this.props;
    if (!session) return null;

    const { logsParams } = searchStore;
    const device = session.deviceInfo;
    let osIcon;
    let udid;
    let udidParam: SearchParam;
    if (device.os === 'ios') {
      osIcon = appleIcon;
      udid = 'UUID';
      udidParam = SearchParam.UUID;
    }
    else {
      osIcon = androidIcon;
      udid = 'Android ID';
      udidParam = SearchParam.ANDROID_ID;
    }

    const time = new Time(session.time ?? session.deviceTime);
    const build = session.appInfo.build ? session.appInfo.build : session.appInfo.versionCode;
    const user = this.getUserInfo();
    const udidHighlight = logsParams[SearchParam.UDID] || logsParams[SearchParam.UUID] || logsParams[SearchParam.ANDROID_ID] ? styles.highlight : '';
    return (
      <div className={styles.session}>
        <div className={styles.sessionDate}>
          {time.getLongDate()}
        </div> 
        <div className={styles.sessionTime}>
          {time.getShortTime()} 
        </div>
        <div className={styles.sessionCenter}>
          <img src={osIcon} className={styles.sessionOsIcon} alt={device.os}/>
          <div className={styles.sessionOsText}>{session.os.version}</div>
        </div>
        <div className={`${styles.sessionTitle} ${styles.appLeft}`}>App Version</div>
        <div className={styles.sessionLanguage} data-tip={session.language}>{session.language.split(/-|_/)[0]}</div>
        <div className={styles.sessionValue}>
          <div className={`${styles.defaultValue} ${styles.appLeft} ${logsParams[SearchParam.APP_VERSION] ? styles.highlight: ''}`}>
            {`${session.appInfo.version} (${build})`}</div>
          <div className={styles.sessionPlus} onClick={() => this.add(SearchParam.APP_VERSION, session.appInfo.version)}
            data-tip="add App Version to search">+</div>
        </div>
        <div className={styles.sessionTitle}>Manufacturer</div>
        <div className={styles.sessionValue}>
          <div className={`${styles.defaultValue} ${styles.deviceValue} ${logsParams[SearchParam.MANUFACTURER] ? styles.highlight: ''}`}>{device.manufacturer}</div>
          <div className={styles.sessionPlus} onClick={() => this.add(SearchParam.MANUFACTURER, device.manufacturer)} 
            data-tip="add Manufacturer to search">+</div>
        </div>
        <div className={styles.sessionTitle}>Device Model</div>
        <div className={styles.sessionValue}>
          <div className={`${styles.defaultValue} ${styles.deviceValue} ${logsParams[SearchParam.DEVICE_MODEL] ? styles.highlight: ''}`}>{device.deviceModel}</div>
          <div className={styles.sessionPlus} onClick={() => this.add(SearchParam.DEVICE_MODEL, device.deviceModel)}
            data-tip="add Device Model to search">+</div>
        </div>
        <div className={styles.sessionTitle}>{udid}</div>
        <div className={styles.sessionValue}>
          <div className={`${styles.sessionValue} ${udidHighlight}`}>{device.udid}</div>
          <div className={styles.sessionPlus} onClick={() => this.add(udidParam, device.udid)}
            data-tip={`add ${udid} to search`}>+</div>
        </div>
        {user}
      </div>
    );
  }
}));

interface EventProps {
  event: IEvent; 
}

const EventComponent = observer(class EventComponent extends React.Component<EventProps> {
  render() {
    const { event } = this.props;
    const { minMode } = logsStore;
    let eventName = '';
    let msg = '';
    const rowValues = [];
    switch (event.type) {
      case LogType.ACTION_EVENT: 
        eventName = 'Action Event';
        // eslint-disable-next-line no-case-declarations
        const actionEvent = event as IActionEvent;
        msg = actionEvent.action;
        rowValues.push({key: 'sender', value: actionEvent.sender});
        rowValues.push({key: 'senderTitle', value: actionEvent.senderTitle});
        rowValues.push({key: 'target', value: actionEvent.target});
        break;
      case LogType.SCREEN_EVENT: 
        eventName = 'Screen Event';
        // eslint-disable-next-line no-case-declarations
        const screenEvent = event as IScreenEvent;
        msg = screenEvent.name;
        break;      
      case LogType.ACTIVITY_EVENT: 
        eventName = 'Activity Event';
        // eslint-disable-next-line no-case-declarations
        const activityEvent = event as IActivityEvent;
        msg = activityEvent.name;
        rowValues.push({key: 'event', value: activityEvent.event});
        rowValues.push({key: 'title', value: activityEvent.title});
        break;
      case LogType.CONFIG_EVENT: 
        eventName = 'Config Event';
        // eslint-disable-next-line no-case-declarations
        const configEvent = event as IConfigEvent;
        msg = configEvent.orientation;
        break;
      case LogType.APP_EVENT: 
        eventName = 'App Event';
        // eslint-disable-next-line no-case-declarations
        const appEvent = event as IAppEvent;
        msg = appEvent.event;
        rowValues.push({key: 'state', value: appEvent.state});
        if (appEvent.orientation) rowValues.push({key: 'orientation', value: appEvent.orientation}); // if tvos there is no orientation
        break;
      default: 
    }
    
    return (
      <div className={`${styles.event} ${minMode?styles.minMode:''}`}>
        <div className={styles.eventRow}>
          <div className={styles.time}>{new Time(event.time).getTime()} </div>
          <div className={styles.eventArrow}> &gt; </div>
          <div className={styles.eventArrow}> {eventName} </div>
          <div className={styles.eventArrow}> &gt; </div>
          <div className={styles.eventInfo}> {msg} </div>  
        </div>
        {!minMode && <div className={styles.eventRow}> 
          {rowValues.map((value, index) => 
            <div key={value.key}>
              <div className={styles.logKey}>{index > 0 && '\u00A0|'} {value.key}:</div>
              <div className={styles.logValue}>{value.value}</div>
            </div>
          )}
        </div>}
      </div>
    );
  }
});

export class ItemComponent extends React.Component<{ item: Item, 
  index: number}> {
  render() {
    const { item, ...other } = this.props;
    const data = item.data;
    switch (this.props.item.itemType) {
      case ItemType.MESSAGE: 
      case ItemType.EXCEPTION:
        return <LogComponent log={data as IMessage | IException} {...other} />;
      case ItemType.EVENT:
        return <EventComponent event={data as IEvent} {...other} />;
      case ItemType.LOADING:
        return <div className={styles.loading}> loading ...</div>;
      case ItemType.SESSION:
        return <SessionComponent session={data as ISession} />;
      default:
        return <div> this type doesn't exist ({this.props.item.itemType})</div>;
    }
  }
}

const Row = observer(class Row extends React.PureComponent<ListProps> {
  render() {
    const { index, style } = this.props;
    const { itemList } = logsStore;
    const item = itemList[index];
    ReactTooltip.rebuild();
    return (
      <div
        className={styles.row}
        style={style} > 
        <ItemComponent item={item} index={index}/>
      </div>
    );
  }    
});

interface State {
  session?: ISession;
  showSession: boolean;
}

export default observer(class LogListView extends React.Component<{}, State> {
  private list?: List;
  private prevMinMode = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private infiniteOnItemsRendered?:(props: ListOnItemsRenderedProps) => any;

  private handler: IReactionDisposer;
  private prevLogId?: string;
  private prevLogsCounter = 0;
  constructor(props: {}) {
    super(props);
    this.isItemLoaded = this.isItemLoaded.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
    this.itemHeight = this.itemHeight.bind(this);
    this.mobxAutorun = this.mobxAutorun.bind(this);
    this.onItemsRendered = this.onItemsRendered.bind(this);
    this.scrollToId = this.scrollToId.bind(this);

    this.state = {
      showSession: true,
    }

    this.handler = autorun(this.mobxAutorun);
    const {minMode} = logsStore!;
    this.prevMinMode = minMode;
  }
  mobxAutorun() {
    console.debug('mobxAutorun');
    const {minMode, itemList} = logsStore;
    if (this.prevMinMode !== minMode) {
      if (this.list) this.list.resetAfterIndex(0, true)
      this.prevMinMode = minMode;
    }
    if (!this.list && itemList.length > 0) {
      this.setState({session: itemList[0].data as ISession})
    }
  }

  componentDidUpdate() {
    console.debug('LogListView componentDidUpdate');
    if (!this.list) return;
    const {logId} = searchStore;
    const {logsCounter} = logsStore;
    if (logId && logId !== this.prevLogId && this.list && logsCounter !== this.prevLogsCounter) {
      console.debug('call scroll to id');
      this.scrollToId(logId);
      this.prevLogId = logId;
    }
    this.prevLogsCounter = logsCounter;
  }

  componentWillUnmount() {
    this.handler();
  }

  private isItemLoaded( index: number): boolean {
    return index > 0 && index + 1 < logsStore.itemList.length;
  }

  private async loadMoreItems(startIndex: number, stopIndex: number) {
    console.log('the loadMoreRows indexes :' + startIndex + ':' + stopIndex);
    if (startIndex == 0 && stopIndex == 0) return;
    if (!logsStore.hasNext) return;
    await logsStore.getLogs(false, PagingType.NEXT);
    this.list!.resetAfterIndex(stopIndex, true);
  }

  private itemHeight(index: number): number {
    const { minMode, itemList } = logsStore;
    const heights = new Map<number, number>([
      [ItemType.SESSION, 52],
      [ItemType.MESSAGE, minMode ? 29 : 58],
      [ItemType.EXCEPTION, minMode ? 29 : 58],
      [ItemType.EVENT, minMode ? 29 : 58],
      [ItemType.LOADING, 20]
    ]);
    const itemType = itemList[index].itemType;
    return heights.get(itemType) as number;
  }

  private onItemsRendered(props: ListOnItemsRenderedProps) {
    console.debug('the start index:' + props.visibleStartIndex)
    const {itemList} = logsStore
    const index = Math.min(props.visibleStartIndex + 1, itemList.length - 1);
    for (let i = index; i >= 0 ; i--) {
      if (itemList[i].itemType === ItemType.SESSION) {
        this.setState({session: itemList[i].data as ISession, showSession: i !== index})
        break;
      }
    }
    this.infiniteOnItemsRendered!(props);
  }

  scrollToId(id: string) {
    console.debug('scrollToId - ' + id);
    const { itemList } = logsStore;
    for (let i = 0; i < itemList.length; i++) {
      const item = itemList[i];
      if (item.data?._id === id) {
        console.debug('called scrollToItem')
        this.list!.scrollToItem(i, 'center');
        break;
      }
    }
  }

  render() {
    const { itemList, minMode, hasNext, messagePopupIndex, isLoglytics } = logsStore;
    const { loading } = commonStore;

    console.log('then min mode: ' + minMode);
    const hasParams = searchStore.selectedOptions.length > 0;
    let logsImg = hasParams ? empty: lost;
    let noLogsTitle = hasParams ? 'Oops, There are no results for this search ...' : 'Oops, There are no logs ...';
    let noLogsBody = hasParams ? 'Please change your search' : 'Please upload logs from your devices';
    if (isLoglytics) {
      logsImg = can;
      noLogsTitle = 'Pew! this session has expired.';
      noLogsBody = 'But wait! You have the details of the log above- just work with them :)'
    }
    return (
      <div className={`${styles.component} ${isLoglytics ? styles.isLoglytics : ''}`}>
        {itemList.length > 0 ? <>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={this.isItemLoaded}
                loadMoreItems={this.loadMoreItems}
                itemCount={hasNext ? 100000 : itemList.length}>
                {({ onItemsRendered, ref }) => {
                  this.infiniteOnItemsRendered = onItemsRendered;
                  return (
                    <List
                      className="List"
                      width={width}
                      height={height}
                      ref={el => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (ref as (instance: any) => void)(el);
                        this.list = el!;
                      }}
                      itemCount={itemList.length}
                      itemSize={this.itemHeight}
                      onItemsRendered={this.onItemsRendered}
                    >
                      {Row}
                    </List>);
                }}
              </InfiniteLoader>
            )}
          </AutoSizer>
          <div className={`${styles.sessionWrapper} ${this.state.showSession ? '' : styles.hidden}`}>
            <SessionComponent session={this.state.session!}/>
          </div>
          {messagePopupIndex > 0 && <LogMessagePopup/>}
        </>: 
          !loading && <div className={styles.noLogs}>
          <img src={logsImg} alt="logs"/>
          <div className={styles.noLogsText}>
            <div>{noLogsTitle}</div>
            <div className={styles.noLogsTextBody}>{noLogsBody}</div>
          </div>
        </div>}
      </div>
    );
  }
})
