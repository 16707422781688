import IBase from './base.model';

import goldfish from '../img/preferences/goldfish.gif';
import mermaid from '../img/preferences/mermaid.gif';
import shark from '../img/preferences/shark.gif';
import worm from '../img/preferences/worm.gif';


export enum DefaultPlan {
  FREE = 'Free', STANDARD = 'Standard', PRO = 'Pro'
}
export default interface IPlan extends IBase {
  name: string;
  description: string;
  retention: number;
  maxLogs: number;
  price: number;
  icon: string;
  order?: number;
}


export const planIcon = {
  goldfish, mermaid, shark, worm
}