import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './log_search_input.module.css';

import 'react-datepicker/dist/react-datepicker.css';
// TODO: this file is not working
import './custom-react-datepicker.css';

import searchStore from '../../stores/search.store';
import { observer } from 'mobx-react';
import { Async, components } from 'react-select';
import { SearchType, CustomOption, searchParamTitle, SearchParam } from '../../models/search.model';
import ParamService, { ParamsObjectArray } from '../../services/param.service';
import { CommonProps, ActionMeta, ValueType } from 'react-select/lib/types';
import { MenuListProps } from 'react-select/lib/components/Menu';
import { StylesConfig } from 'react-select/lib/styles';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/qtcreator_dark';
import DatePicker from 'react-datepicker';

const Highlighter  = require('react-highlight-words');

interface State {
  inputValue: string;
  value: CustomOption[];
  currentType?: SearchType;
  open: boolean;
  selectedDate: Date; 
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class MenuList extends React.Component<CommonProps<any> & MenuListProps & {  isMulti: boolean; maxHeight: number; }> {
  render() {
    const {props} = this ;
    const {currentParam} = props.selectProps;
    console.log('the value', props.getValue());
    let title = 'Search ';
    title += currentParam ? searchParamTitle(currentParam) :  'Parameter';
    return (
      <>
        <components.MenuList {...props}>
          <div className={styles.menuTitle}>{title}</div>
          {props.children}
        </components.MenuList>
      </>
    );
  }
}

const customStyles: StylesConfig = {
  control: (base, _state) => ({
    ...base,
    '&:hover': { borderColor: 'gray' }, // border style on hover
    border: '1px solid var(--bb-grey)', // default border color
    'border-radius': 0
    // You can also use state.isFocused to conditionally style based on the focus state
  }),
  placeholder: (base, _state) => ({
    ...base,
    color: 'var(--pinkish-grey)',
  }),
  option: (base, state) =>  ({
    ...base,
    color: 'var(--marine)',
    padding: '4px 14px',
    fontSize: 15,
    fontWeight: 300,
    backgroundColor: state.isFocused ? 'rgba(33, 161, 181, .1)' : style.background,
    ':active': {
      backgroundColor: 'rgba(33, 161, 181, .2)',
    }
  }),
  menu: (base, state) => ({
    ...base,
    boxShadow: '0 0 7px rgba(0, 0, 0, .2)',
    marginTop: 15
  }),
  multiValue: (base, state) => ({
    ...base,
    backgroundColor: 'var(--fully-white)',
    border: '1px solid var(--marigold)',
    borderRadius: 4,
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: 'var(--marine)',
    fontSize: 12,
    padding: 4
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    color: 'var(--marine)',
    '&:hover': { 
      backgroundColor: 'var(--marigold)',
      color: 'var(--marine)'
    }
  })
};
class LogSearchInput extends React.Component<RouteComponentProps<{}>, State> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    
    this.state = {
      inputValue: '',
      value: [],
      open: false, 
      selectedDate: new Date()
    };
    this.getOptions = this.getOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.formatOptionLabel = this.formatOptionLabel.bind(this);
    this.split = this.split.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }
  
  private split(value: string) {
    let split = value.split(':');
    split = [split.shift()!!, split.join(':')];
    return split;
  }

  private handleChange(value: ValueType<CustomOption>, _action: ActionMeta) {
    const selectedOptions = value! as CustomOption[];
    console.log('the selectedOptions:' , selectedOptions);
    let intermediate = false;
    const params: ParamsObjectArray = {};

    if (selectedOptions.length === 0 && searchStore.selectedOptions.length === 0) return;

    selectedOptions.forEach(option => {
      const type = (option as CustomOption).type;
      if (option.value.length > 0) {
        const value = option.value.trim();
        if (params[type]) params[type].push(value);
        else params[type] = [value];
      }
      else {
        intermediate = true;
        console.debug('intermediate');
        const open = type === SearchParam.FROM_TIME || type === SearchParam.TO_TIME;
        this.setState({inputValue: `${type}:`, currentType: type, open});
      }
    });

    if (searchStore.isAlert) searchStore.setParams(params)
    else ParamService.setParams(this.props, params, true);
  }

  private formatOptionLabel(option: CustomOption) {
    console.log('option', option);
    const word = option.value.length > 0 ? this.split(this.state.inputValue)[1] : this.state.inputValue;
    return (
      <div>
        <Highlighter highlightTag="strong"
          autoEscape
          searchWords={[word]} 
          textToHighlight={option.label}/>
      </div>
    );

  }
  private async getOptions(input: string ) {
    console.debug('getOptions');
    let options: CustomOption[];
    if (this.state.currentType) {
      const [, value] = this.split(input);
      const values = await searchStore.search(this.state.currentType, value.trim());
      options = values.map(val => {
        return {value: val, label: val, type: this.state.currentType!}; 
      });
    }
    else {
      options = searchStore.searchParams.filter(param => 
        param.label.toLocaleLowerCase().includes(input.toLocaleLowerCase()));
    }
    return options;
  }

  onInputChange(inputValue: string) {
    if (this.state.open) return;
    console.debug('onInputChange input value', inputValue); 
    const [type, value] = this.split(inputValue);
    const currentType = value.length > 0 ? type as SearchType : undefined;
    console.debug('onInputChange', currentType);

    // only if closed checking if should open
    const open = currentType === SearchParam.FROM_TIME || currentType === SearchParam.TO_TIME;
    this.setState({inputValue, currentType, open});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDateChange(date: Date, event?: React.SyntheticEvent<any>) {
    if (event) {
      this.setState({selectedDate: date});
      return;
    }
    console.log('date change', event);
    const { currentType } = this.state;
    ParamService.setParam(this.props, currentType!, date.toISOString());
    this.setState({open: false, inputValue: '', selectedDate: date});
    console.log('date change', date);
  }

  render() {
    const {selectedOptions, searchParams: searchParameters} = searchStore;
    const {open, selectedDate} = this.state;
    return (
      <div className={styles.component}>
        <Async
          styles={customStyles}
          onInputChange={this.onInputChange}
          inputValue={this.state.inputValue}
          value={selectedOptions}
          placeholder="Search - case sensitive"
          onChange={this.handleChange}
          formatOptionLabel={this.formatOptionLabel}
          components={{ 
            DropdownIndicator: null,
            MenuList: MenuList
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any}
          loadOptions={this.getOptions}
          defaultOptions={searchParameters}
          isMulti
          currentParam={this.state.currentType} // passing it for the menu list
          />
          <DatePicker
            selected={selectedDate}
            onChange={this.onDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            maxDate={new Date()}
            open={open}
            onClickOutside={() => this.setState({open: false})}
            customInput={<React.Fragment />} 
            />
      </div>
    );
  }
}

export default withRouter(observer(LogSearchInput));