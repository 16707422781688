import React, {useEffect, useState} from 'react';

import styles from './pref_app_version_files.module.css';
import Time from '../../services/time.service';
import IAppVersionFile from '../../models/app_version_file.model';
import Dropzone from 'react-dropzone'
import { observer } from 'mobx-react';
import Popup, { PopupInfo } from '../common/popup.component';
import trash from '../../img/preferences/trash.svg';
import appStore from '../../stores/app.store';

interface PrefAppVersionFilesState {
  file?: File;
  appVersion: string;
  build: string;
}

const PrefAppVersionFiles = observer(() => {
  const [file, setFile] = useState<File>();
  const [appVersion, setAppVersion] = useState("");
  const [build, setBuild] = useState("");
  useEffect(() => {
    appStore.getAppVersionFiles();
  });
  const fileChangeHandler  = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : undefined;
    setFile(file);
  }

  const handleSubmission = async (event?: React.FormEvent<HTMLButtonElement>) => {
    if (event) event.preventDefault();
    await appStore.uploadDsym(file!, appVersion, build);
    setFile(undefined);
    setAppVersion("");
    setBuild("")
  }
  
  const onTrash = (appVersionFile: IAppVersionFile) => {
    const info: PopupInfo = {
      title: 'Delete',
      msg: `Are you sure that you want to delete the version file ${appVersionFile.versionInfo.version} (${appVersionFile.versionInfo.build})?`,
      ok: 'Delete',
      okCallback: () => appStore.deleteAppVersionFile(appVersionFile._id),
      cancel: 'Cancel'
    };
    Popup.create(info);
  }  

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {name, value} = event.currentTarget;
    switch (name) {
      case 'build': 
        setBuild(value);
        break;
      case 'appVersion':
        setAppVersion(value)
        break;
      default:
    } 
  }

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);
  }

  const {appVersionFiles} = appStore
  const disabled = !(file && (appVersion.length > 0) && (build.length > 0)); 

  return (
    <div className={styles.component}>
      <div className={styles.subTitle}>Dsym Files</div>
      <div className={styles.bodyHeader}>Shipbook needs the dSYM file for the application to produce human-readable stack traces of the crash.
      For more info view <a href="https://docs.shipbook.io/ios-log-integration#upload-the-dsym-file">upload dsym file</a>
      </div>

      {appVersionFiles.map((appVersionFile) => 
        <div key={appVersionFile._id} className={styles.dsym}>
          <div> {new Time(appVersionFile.createdAt).getShortDate()} </div>
          <div> {appVersionFile.versionInfo.version} ({appVersionFile.versionInfo.build})</div>
          <img alt="trash" className={styles.trash} src={trash} onClick={() => onTrash(appVersionFile)} />
        </div>
      )}
      <div className={styles.breakLine} />
      <div className={styles.addFile}>
        <Dropzone onDrop={onDrop} >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps({className: styles.dragDrop})}>
              <input {...getInputProps()} />
              {file ? <div>{file.name}</div> : <div>Drag &amp; Drop or <span>Upload File </span></div> }
            </div>
          )}
        </Dropzone>
        <input className={styles.input} type="text" name="appVersion" value={appVersion} onChange={handleChange} placeholder="App version" />
        <input className={styles.input} type="text" name="build" value={build} onChange={handleChange} placeholder="build" />
        <div className={styles.space}/>
        <button onClick={handleSubmission} disabled={disabled} >Add</button>
      </div>
    </div>
  )
});


export default PrefAppVersionFiles;