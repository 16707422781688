import React, {useRef, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import styles from './live_tail_view.module.css';
import { IMessage, LogType } from '../../models/log.model';
import Time from '../../services/time.service';
import liveTailStore from '../../stores/live_tail.store';


const NO_LOGS = "There are no new logs. Please send logs from your app ..."; 
const EMPTY = "Press play to start getting logs";
let interval: NodeJS.Timeout | undefined = undefined;
const LiveTailView = observer(() => {
  const logsEndRef = useRef<null | HTMLDivElement>(null)
  // eslint-disable-next-line prefer-const
  let [info, setInfo] = useState(""); //info let because need to update it in useEffect

  useEffect(() => {
    logsEndRef.current?.scrollIntoView({ behavior: "smooth" })
  });

  const {logs} = liveTailStore;
  useEffect(() => {
    if (liveTailStore.logs.length == 0 && !interval) {
      interval = setInterval(()=> {
        const staticInfo = (liveTailStore.play ? NO_LOGS : EMPTY) + "          ";
        const length = staticInfo.length == info.length ? 1 : info.length + 1;
        info = staticInfo.substring(0, length);
        setInfo(info);
      }, 100);
    }

    return () => {
      if (interval) {
        clearInterval(interval)
        interval = undefined;
      }
    };
  }, [logs]);
  
  const logsArray = logs.filter(item => item.type == LogType.MESSAGE)
    .map(item => {
      switch(item.type) {
        case LogType.MESSAGE: {
          const message = item as IMessage;
          return `${message.udid}  ${new Time(message.time).getTime() }  ${message.severity.padEnd(7)}  ${message.message}`

        }
        default: return '';
      }
    });

  return (
    <div className={styles.component}>
      <div className={styles.logs}>{logsArray.length > 0 ? logsArray.join('\n') : info}</div>
      <div ref={logsEndRef} />
    </div>
  )
});

export default LiveTailView;