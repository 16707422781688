import IBase from './base.model';
import { Platform } from './platform.model';

export enum LogType {
  MESSAGE = 'message',
  EXCEPTION = 'exception', 
  ACTION_EVENT = 'actionEvent',
  SCREEN_EVENT = 'screenEvent', 
  VIEW_CONTROLLER_EVENT = 'viewControllerEvent',
  ACTIVITY_EVENT = 'activityEvent',
  FRAGMENT_EVENT = 'fragmentEvent',
  CONFIG_EVENT = 'configEvent',
  APP_EVENT = 'appEvent',
  CUSTOM_EVENT = 'customEvent',
  TIME_EVENT = 'timeEvent'
}

export default interface ILog extends IBase {
  type: LogType;
  app:  string;
  platform: Platform;
  sessionId: string;
  sessionTime: Date;
  orderId: number;
  time: Date;
  udid: string;
  threadInfo?: {
    queueLabel: string,
    threadName: string,
    threadId: number
  };
  key?: string; // for loglytics
}

export interface IException extends ILog  {
  name: string;
  reason: string;
  stackTrace: [StackTraceElement];
  callStackSymbols: [string];
  callStackAnalyzed?: ICallStackAnalyzed[];
  severity: Exception;
}

export interface IMessage extends ILog  {
  tag: string;
  severity: Severity;
  message: string;
  messageHighlight?: string; //when there is a search 
  function: string;
  fileName: string;
  lineNumber: number;
  className: string;
  stackTrace: [StackTraceElement];
  callStackSymbols: [string];
  exception: {
    name: string;
    reason: string;
    stackTrace: [StackTraceElement];
  };
}
export enum Exception {
  EXCEPTION = 'Exception'
}
export enum Severity {
  ERROR = 'Error',
  WARNING = 'Warning',
  INFO = 'Info',
  DEBUG = 'Debug',
  VERBOSE = 'Verbose'
}

export class SeverityUtil {
  static values() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(Severity).map(k => Severity[k as any]).reverse();
  }
}

export type SeverityExc = Exception | Severity;

export class SeverityExcUtil {
  static number(severity: SeverityExc) {
    switch (severity) {
      case Exception.EXCEPTION: return 0;
      case Severity.ERROR: return 1; 
      case Severity.WARNING: return 2; 
      case Severity.INFO: return 3; 
      case Severity.DEBUG: return 4; 
      case Severity.VERBOSE: return 5; 

      default: return -1;
    }
  }

  static min(severity: SeverityExc) {
    const num = this.number(severity);
    const severities = this.values().filter(elem => 
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      this.number(<SeverityExc> elem) <= num);
    return severities;
  }


  static values(): SeverityExc[] {  
    return [...SeverityUtil.values(), Exception.EXCEPTION];
  }
}
export interface IEvent extends ILog {
  
}

export interface IActionEvent extends IEvent {
  action: string;
  sender: string;
  senderTitle: string;
  target: string;
}

export interface IScreenEvent extends IEvent {
  name: string;
}

// iOS events 
export interface IViewControllerEvent extends IEvent {
  name: string;
  event: string;
  title: string;
}

export interface IAppEvent extends IEvent {
  event: string;
  state: string;
  orientation: string;
}

// Android events
export interface IActivityEvent extends IEvent {
  name: string;
  event: string;
  title: string;
}

export interface IFragmentEvent extends IEvent {
  name: string;
  event: string;
}

export interface IConfigEvent extends IEvent {
  orientation: string;
}

export interface StackTraceElement {
  declaringClass: string;
  methodName: string;
  fileName: string;
  lineNumber: number;
}

export interface ICallStackAnalyzed {
  pos: number;
  moduleName: string;
  stackAddressHex?: string;
  loadAddressHex?: string;
  offset?: number;
  dsymLineNumber?: number;
  dsymFileName?: string;
  dsymFunction?: string;
}