import { observable, action, runInAction, makeObservable } from 'mobx';

import ConnectionService from '../services/connection/connection.service';
import IPlan from '../models/plan.model';
import commonStore from './common.store';
import memberStore from './member.store';
import AccountConnection from '../services/connection/account.connection';

export class BillingStore {
  bluesnapLoaded: boolean = false;
  error?: string = undefined;
  token?: string;
  currentPlan?: IPlan;
  creditCard?: {
    cardLastFourDigits: string;
    cardType: string
    expirationMonth: string;
    expirationYear: string;
    issuingCountryCode: string;
  };

  plans: IPlan[] = [];
  usage: object[] = [];

  constructor() {
    makeObservable(this, {
      bluesnapLoaded: observable,
      error: observable,
      token: observable,
      currentPlan: observable,
      creditCard: observable,
      plans: observable,
      usage: observable,
      setError: action,
      setCurrentPlan: action,
      setBluesnapLoaded: action,
      createToken: action,
      createCreditCard: action
    });

    this.creditCardInfo = this.creditCardInfo.bind(this);
  }

  async loadUsage() {
    try {
      const usage = await ConnectionService.getUsage();
      runInAction(() => this.usage = usage);
    } catch (error) {
      runInAction(() => this.error = error.message);
      throw error;
    }
  }

  async loadPlans() {
    try {
      const plans = await AccountConnection.getPlanOptions();
      runInAction(() => this.plans = plans);
    } catch (error) {
      runInAction(() => this.error = error.message);
      throw error;
    }
  }

  setError(error?: string) {
    this.error = error;
  }

  setCurrentPlan(currentPlan?: IPlan | string) {
    if (typeof currentPlan === 'string') {
      this.currentPlan = this.plans.find(plan => plan._id.toString() === currentPlan);
    }
    else this.currentPlan = currentPlan;
  }

  setBluesnapLoaded(bluesnapLoaded: boolean) {
    this.bluesnapLoaded = bluesnapLoaded;
  }
  
  async createToken() {
    this.error = undefined;
    try {
      const resp = await ConnectionService.createBillingToken(memberStore.accountId!);
      runInAction(() => this.token = resp.token);
    } catch (error) {
      runInAction(() => this.error = error.message);
      throw error;
    }
  }

  async createCreditCard(firstName: string, lastName: string, companyName: string, country?: string) {

    const data = {
        firstName, lastName, companyName, country,
        pfToken: this.token    
    };
    
    this.error = undefined;
    commonStore.setLoading(true);
    try {
      const origAccount = memberStore.account!;
      const account = await ConnectionService.createCreditCard(origAccount!._id, data);
      
      runInAction(() => origAccount.billing = account.billing);

      // reload creditcard so that the information will be reloaded doesn't need to be async can be done in parallel
      this.creditCardInfo(true);
      return account;
    } catch (error) {
      runInAction(() => this.error = error.message);
      return undefined;
    }
    finally {
      commonStore.setLoading(false);
    }
  } 

  async creditCardInfo(force?: boolean) {
    if (!force && this.creditCard) return this.creditCard;
    try {
      const resp = await ConnectionService.getCreditCardInfo(memberStore.accountId!);
      runInAction(() => this.creditCard = resp);
      return resp;
    } catch (error) {
      return undefined;
    }
  }
}

export default new BillingStore();
