import ConnectionService, { HttpMethod } from './connection.service';
import ILogChat from '../../models/logchat.model';

class ChatConnection {  
  public async startLogChat(search: object): Promise<{chat: ILogChat}> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logChat/startLogChat`, HttpMethod.POST, {search});
  }

  public async start(prompt: string): Promise<{chat: ILogChat}> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logChat/`, HttpMethod.POST, {prompt});
  }

  public async chat(chatId: string, prompt: string): Promise<{chat: ILogChat}> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logChat/${chatId}`, HttpMethod.POST, {prompt});
  }

  public async getChat(chatId: string): Promise<{chat: ILogChat}> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logChat/${chatId}`, HttpMethod.GET);
  }

  public async getChatList(): Promise<ILogChat[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logChat`, HttpMethod.GET);
  }

  public async deleteChat(chatId: string): Promise<void> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logChat/${chatId}`, HttpMethod.DELETE);
  }

  public async updateChat(chatId: string, chat: {}): Promise<{chat: ILogChat}> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/logChat/${chatId}`, HttpMethod.PUT, chat);
  }
}

export default new ChatConnection();