import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { observer} from 'mobx-react';
import styles from './reset_password.module.css';
import authStore  from '../../stores/auth.store';
import Popup, { PopupInfo } from '../common/popup.component';

interface State {
  password: string;
  email: string;
}

class ResetPassword extends React.Component<RouteComponentProps<{}>, State> {
  private token: string = '';
  constructor(props: RouteComponentProps<{}>) {
    super(props);

    this.state = {
      password: '',
      email: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // tslint:disable-next-line:no-string-literal
    document.getElementsByTagName('meta')['viewport'].content = 'width=device-width, initial-scale=1';
  }

  componentDidMount() {
    const search = this.props.location!.search;
    const urlParams = new URLSearchParams(search);

    const token = urlParams.get('token');
    if (token) this.token = token;
    const email = urlParams.get('email');
    if (email) this.setState({ email });

    console.log(`token: ${token}, email: ${email}`);
  }

  validateForm() {
    return this.state.password.length > 0;
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    this.setState({password: event.currentTarget.value});
  }

  async handleSubmit (event?: React.FormEvent<HTMLFormElement>) {
    if (event) event.preventDefault();
    console.debug('handle submit');
    try {
      await authStore.changePassword(this.state.password, this.token);
      const info: PopupInfo = {
        msg: `Your password was changed`,
        okCallback: () => {
          this.props.history!.replace('/login');
        }
      };
      Popup.create(info);

    } catch (error) { 
      console.log(error);
    }
  }

  render() {
    const {error} = authStore;
    const errorMsg = 'There was an error with the password change';
    return (
      <div className={styles.container}>
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <div className={styles.title}>Forgot your password?</div>
          <div className={styles.label}>Enter a new password for your {this.state.email} account.</div>

          <input className={styles.input} type="email" name="email"  placeholder="Email" 
            autoComplete="email" value={this.state.email} hidden readOnly/>

          <input className={styles.input} type="password" name="password" placeholder="Password" 
            autoComplete="new-password" value={this.state.password} onChange={this.handleChange} />

          {!!error && <label className={styles.error}>{errorMsg}</label>}
          <input className={styles.submit} type="submit" value="Submit" disabled={!this.validateForm()} />
          <Link to="/login" className={styles.login}>Login</Link>
        </form>
      </div>
    );
  }
}

export default withRouter(observer(ResetPassword));