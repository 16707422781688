import * as React from 'react';
import * as mobx from 'mobx';

import styles from './pref_app_wizard.module.css';
import trackAnalytic from '../analytics';
import { Platform } from '../../models/platform.model';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IApp from '../../models/app.model';

import appleBig from '../../img/preferences/apple-big-button.svg';
import androidBig from '../../img/preferences/android-big-button.svg';
import reactBig from '../../img/preferences/react-big-button.svg';
import appStore from '../../stores/app.store';
import PopupScreen from '../common/popup_screen.component';

interface State {
  name: string;
  error: string;
}

class PrefAppWizard extends React.Component<RouteComponentProps<{}>, State> {
  disposer?: mobx.Lambda ;
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    const { app } = appStore;
    this.state = {
      name: app?.name ?? '',
      error: ''
    };
    this.choosePlatform = this.choosePlatform.bind(this);
    this.trackIntegration = this.trackIntegration.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onExit = this.onExit.bind(this);
    console.log('constructor PrefApp');
  }

  componentDidMount() {
    this.disposer = mobx.observe(appStore, 'app', listener => {
      if (!listener.newValue) return;
      this.setState({name: (listener.newValue as IApp).name});    
    });
  }

  componentWillUnmount() {
    if (this.disposer) this.disposer();
  }

  private async choosePlatform(event: React.MouseEvent<HTMLImageElement, MouseEvent>, platform: Platform) {
    event.preventDefault();
    if (this.state.name.length === 0) {
      this.setState({error: 'app name required'})
      return;
    }

    this.trackIntegration(platform);
    const {name} = this.state;
    if (!appStore.app) {
      await appStore.createApp(name);
    }
    else if (appStore.app.name !== name) {
      const app = mobx.toJS(appStore.app!);
      app.name = name;
      await appStore.updateApp(app);
    }

    let path = this.props.location.pathname;
    path = path.replace('/new', '/doc');
    path += `?platform=${platform}`;
    this.props.history.push(path);
  }

  private trackIntegration(platform: string) {
    trackAnalytic.event('integration', 'integration ' + platform, 'integration ' + platform);
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.value as string;
    this.setState({name});
  }

  onExit() {
    const {pathname} = this.props.location!;
    if (pathname.includes('/app-wizard')) this.props.history.replace('/create-account');
    else this.props.history.goBack();
  }


  render() {
    const {name, error} = this.state;
    return (
      <PopupScreen title="App Integration" onExit={this.onExit}>
        <form className={styles.form}>
          <label>
            App Name
          </label>
          <input className={styles.input} type="text" 
                value={name} 
                onChange={this.onChange}/>
          <div className={styles.error}>{error}</div>
          <div className={styles.integration}>
            <div className={styles.subTitle}>Integration</div>
            <div className={styles.choosePlatform}>Please choose the platform that you want to integrate </div>
            <div>
              <img src={appleBig} onClick={(event) => this.choosePlatform(event, Platform.IOS)} alt="ios"/>
              <img src={androidBig} onClick={(event) => this.choosePlatform(event, Platform.ANDROID)} alt="android"/>
              <img src={reactBig} onClick={(event) => this.choosePlatform(event, Platform.REACT_NATIVE)} alt="react-native"/>
            </div>
          </div>
        </form>
      </PopupScreen>
    );
  }
}

export default withRouter(PrefAppWizard);