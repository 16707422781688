import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import Login from '../components/auth/login.component';
import Register from '../components/auth/register.component';
import Main from '../components/main.component';
import CreateAccount from '../components/auth/create_account.component';
import ForgotPassword from '../components/auth/forgot_password.component';
import ResetPassword from '../components/auth/reset_password.component';
import Validation from '../components/auth/validation.component';
import Validate from '../components/auth/validate.component';
import AppWizard from '../components/auth/app_wizard.component';

export default class GlobalRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword}/>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/validation" component={Validation} />
        <Route path="/validate" component={Validate} />
        <Route path="/create-account" component={CreateAccount} />
        <Route path="/app-wizard" component={AppWizard}/>
        <Route path="/" component={Main}/>
      </Switch>
    );
  }
}