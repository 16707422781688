/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { observer } from 'mobx-react';
import styles from './login.module.css';
import authStore from '../../stores/auth.store';

import commonStore from '../../stores/common.store';
import GithubAuth from './github_auth.component';
import GoogleAuth from './google_auth.component';
import emailService from '../../services/email.service';
import AuthTemplate from './auth_template.component';

import logo from '../../img/logos/shipbook_text.svg';

interface State {
  errorEmail: string,
  errorPassword: string
}

class Login extends React.Component<RouteComponentProps<{}>, State> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);

    this.state = {
      errorEmail: '',
      errorPassword: ''
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);

    // tslint:disable-next-line:no-string-literal
    document.getElementsByTagName('meta')['viewport'].content = 'width=device-width, initial-scale=1';
  }

  componentDidMount() {
    const search = this.props.location!.search;
    const urlParams = new URLSearchParams(search);

    const email = urlParams.get('email');
    const password = urlParams.get('password');
    console.log(`email: ${email} password: ${password}`);
    if (email) authStore.setEmail(email);
    if (password) authStore.setPassword(password);
    if (email && password) this.handleSubmit();
  }

  validateForm() {
    
  }

  handleEmailChange =
    (event: React.FormEvent<HTMLInputElement>) => authStore.setEmail(event.currentTarget.value)

  handlePasswordChange = (event: React.FormEvent<HTMLInputElement>) => authStore.setPassword(event.currentTarget.value)

  async handleSubmit  (event?: React.FormEvent<HTMLFormElement>) {
    if (event) event.preventDefault();
    commonStore.reset();
    console.debug('handle submit');

    const {values} = authStore;
    let errorEmail = '';
    let errorPassword = '';
    
    if (!emailService.validateEmail(values.email)) errorEmail = 'need a valid email'
    if (values.password.length ===  0) errorPassword = 'password required' 

    this.setState({errorEmail, errorPassword});
    authStore.clearError();
    if (errorEmail.length > 0 ||
        errorPassword.length > 0) {
      return;
    }

    try {
      await authStore.login();
      if (commonStore.origPath) {
        console.debug('entered orig url: ' + commonStore.origPath);
        this.props.history!.push(commonStore.origPath);
        commonStore.origPath = undefined;
      }
      else this.props.history!.push('/');
    } catch (error) { 
      console.log(error);
    }
  }

  closeAlert() {
    authStore.clearError();
  }

  render() {
    const { error } = authStore;
    const errorMsg = 'There was an error with the email or password';
    const { email, password } = authStore.values;
    const { errorEmail, errorPassword } = this.state;
    return (
      <AuthTemplate type="octopus">
        <div className={styles.container}>
          <img className={styles.shipbookColorLogo} src={logo} alt="shipbook"/>
          <div className={styles.title}>
            Nice to see you again!
          </div>
          <div className={styles.sso}>
            <div><GoogleAuth buttonText="LOG IN WITH GOOGLE"/></div>
            <div className={styles.space}/>
            <div><GithubAuth buttonText="LOG IN WITH GITHUB"/></div>
          </div>
          <div className={styles.or}>
            <div className={styles.line}/>
            <div className={styles.space}>Or</div>
            <div className={styles.line}/>
          </div>
          <form className={styles.form} onSubmit={this.handleSubmit}>
            <label className={styles.label} >Work Email</label>
            <input autoFocus className={styles.input} type="email" name="email" autoComplete="email" 
              value={email} onChange={this.handleEmailChange} />
            <div className={styles.error}>{errorEmail}</div>
            <label className={styles.label}>Password</label>
            <input className={styles.input} type="password" name="password"
              autoComplete="current-password" value={password} onChange={this.handlePasswordChange} />
            <div className={styles.error}>{errorPassword}</div>
            <Link to="/forgot-password" className={styles.forgot}>forgot password?</Link>
            {!!error && <div className={styles.error}>{errorMsg}</div>}
            <input className={styles.submit} type="submit" value="Log in" />
            <div className={styles.register}>New member? <Link to="/register" className={styles.register}>Sign up</Link></div>
          </form>
        </div>
      </AuthTemplate>
    );
  }
}

export default withRouter(observer(Login));