import * as React from 'react';
import { observe } from 'mobx';
import { observer } from 'mobx-react';
import styles from './pref_credit_card.module.css';

import Select from 'react-select';
import memberStore from '../../../stores/member.store';
import billingStore from '../../../stores/billing.store';
import Popup, { PopupInfo } from '../../common/popup.component';
import { ValueType } from 'react-select/lib/types';
import trackAnalytic from '../../analytics';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let bluesnap: any;

interface State {
  firstName: string;
  lastName: string;
  selectedOption?: { label: string; value: string };
}

interface Props {
  setHandleSubmit: Function;
  backgroundWhite?: boolean;
}

const countryOptions: { label: string; value: string }[] = [
  {value: 'AF', label: 'Afghanistan'},
  {value: 'AX', label: 'Åland Islands'},
  {value: 'AL', label: 'Albania'},
  {value: 'DZ', label: 'Algeria'},
  {value: 'AS', label: 'American Samoa'},
  {value: 'AD', label: 'Andorra'},
  {value: 'AO', label: 'Angola'},
  {value: 'AI', label: 'Anguilla'},
  {value: 'AQ', label: 'Antarctica'},
  {value: 'AG', label: 'Antigua and Barbuda'},
  {value: 'AR', label: 'Argentina'},
  {value: 'AM', label: 'Armenia'},
  {value: 'AW', label: 'Aruba'},
  {value: 'AU', label: 'Australia'},
  {value: 'AT', label: 'Austria'},
  {value: 'AZ', label: 'Azerbaijan'},
  {value: 'BS', label: 'Bahamas'},
  {value: 'BH', label: 'Bahrain'},
  {value: 'BD', label: 'Bangladesh'},
  {value: 'BB', label: 'Barbados'},
  {value: 'BY', label: 'Belarus'},
  {value: 'BE', label: 'Belgium'},
  {value: 'BZ', label: 'Belize'},
  {value: 'BJ', label: 'Benin'},
  {value: 'BM', label: 'Bermuda'},
  {value: 'BT', label: 'Bhutan'},
  {value: 'BO', label: 'Bolivia, Plurinational State of'},
  {value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba'},
  {value: 'BA', label: 'Bosnia and Herzegovina'},
  {value: 'BW', label: 'Botswana'},
  {value: 'BV', label: 'Bouvet Island'},
  {value: 'BR', label: 'Brazil'},
  {value: 'IO', label: 'British Indian Ocean Territory'},
  {value: 'BN', label: 'Brunei Darussalam'},
  {value: 'BG', label: 'Bulgaria'},
  {value: 'BF', label: 'Burkina Faso'},
  {value: 'BI', label: 'Burundi'},
  {value: 'KH', label: 'Cambodia'},
  {value: 'CM', label: 'Cameroon'},
  {value: 'CA', label: 'Canada'},
  {value: 'CV', label: 'Cape Verde'},
  {value: 'KY', label: 'Cayman Islands'},
  {value: 'CF', label: 'Central African Republic'},
  {value: 'TD', label: 'Chad'},
  {value: 'CL', label: 'Chile'},
  {value: 'CN', label: 'China'},
  {value: 'CX', label: 'Christmas Island'},
  {value: 'CC', label: 'Cocos (Keeling) Islands'},
  {value: 'CO', label: 'Colombia'},
  {value: 'KM', label: 'Comoros'},
  {value: 'CG', label: 'Congo'},
  {value: 'CD', label: 'Congo, the Democratic Republic of the'},
  {value: 'CK', label: 'Cook Islands'},
  {value: 'CR', label: 'Costa Rica'},
  {value: 'CI', label: 'Côte d\'Ivoire'},
  {value: 'HR', label: 'Croatia'},
  {value: 'CU', label: 'Cuba'},
  {value: 'CW', label: 'Curaçao'},
  {value: 'CY', label: 'Cyprus'},
  {value: 'CZ', label: 'Czech Republic'},
  {value: 'DK', label: 'Denmark'},
  {value: 'DJ', label: 'Djibouti'},
  {value: 'DM', label: 'Dominica'},
  {value: 'DO', label: 'Dominican Republic'},
  {value: 'EC', label: 'Ecuador'},
  {value: 'EG', label: 'Egypt'},
  {value: 'SV', label: 'El Salvador'},
  {value: 'GQ', label: 'Equatorial Guinea'},
  {value: 'ER', label: 'Eritrea'},
  {value: 'EE', label: 'Estonia'},
  {value: 'ET', label: 'Ethiopia'},
  {value: 'FK', label: 'Falkland Islands (Malvinas)'},
  {value: 'FO', label: 'Faroe Islands'},
  {value: 'FJ', label: 'Fiji'},
  {value: 'FI', label: 'Finland'},
  {value: 'FR', label: 'France'},
  {value: 'GF', label: 'French Guiana'},
  {value: 'PF', label: 'French Polynesia'},
  {value: 'TF', label: 'French Southern Territories'},
  {value: 'GA', label: 'Gabon'},
  {value: 'GM', label: 'Gambia'},
  {value: 'GE', label: 'Georgia'},
  {value: 'DE', label: 'Germany'},
  {value: 'GH', label: 'Ghana'},
  {value: 'GI', label: 'Gibraltar'},
  {value: 'GR', label: 'Greece'},
  {value: 'GL', label: 'Greenland'},
  {value: 'GD', label: 'Grenada'},
  {value: 'GP', label: 'Guadeloupe'},
  {value: 'GU', label: 'Guam'},
  {value: 'GT', label: 'Guatemala'},
  {value: 'GG', label: 'Guernsey'},
  {value: 'GN', label: 'Guinea'},
  {value: 'GW', label: 'Guinea-Bissau'},
  {value: 'GY', label: 'Guyana'},
  {value: 'HT', label: 'Haiti'},
  {value: 'HM', label: 'Heard Island and McDonald Islands'},
  {value: 'VA', label: 'Holy See (Vatican City State)'},
  {value: 'HN', label: 'Honduras'},
  {value: 'HK', label: 'Hong Kong'},
  {value: 'HU', label: 'Hungary'},
  {value: 'IS', label: 'Iceland'},
  {value: 'IN', label: 'India'},
  {value: 'ID', label: 'Indonesia'},
  {value: 'IR', label: 'Iran, Islamic Republic of'},
  {value: 'IQ', label: 'Iraq'},
  {value: 'IE', label: 'Ireland'},
  {value: 'IM', label: 'Isle of Man'},
  {value: 'IL', label: 'Israel'},
  {value: 'IT', label: 'Italy'},
  {value: 'JM', label: 'Jamaica'},
  {value: 'JP', label: 'Japan'},
  {value: 'JE', label: 'Jersey'},
  {value: 'JO', label: 'Jordan'},
  {value: 'KZ', label: 'Kazakhstan'},
  {value: 'KE', label: 'Kenya'},
  {value: 'KI', label: 'Kiribati'},
  {value: 'KP', label: 'Korea, Democratic People\'s Republic of'},
  {value: 'KR', label: 'Korea, Republic of'},
  {value: 'KW', label: 'Kuwait'},
  {value: 'KG', label: 'Kyrgyzstan'},
  {value: 'LA', label: 'Lao People\'s Democratic Republic'},
  {value: 'LV', label: 'Latvia'},
  {value: 'LB', label: 'Lebanon'},
  {value: 'LS', label: 'Lesotho'},
  {value: 'LR', label: 'Liberia'},
  {value: 'LY', label: 'Libya'},
  {value: 'LI', label: 'Liechtenstein'},
  {value: 'LT', label: 'Lithuania'},
  {value: 'LU', label: 'Luxembourg'},
  {value: 'MO', label: 'Macao'},
  {value: 'MK', label: 'Macedonia, the former Yugoslav Republic of'},
  {value: 'MG', label: 'Madagascar'},
  {value: 'MW', label: 'Malawi'},
  {value: 'MY', label: 'Malaysia'},
  {value: 'MV', label: 'Maldives'},
  {value: 'ML', label: 'Mali'},
  {value: 'MT', label: 'Malta'},
  {value: 'MH', label: 'Marshall Islands'},
  {value: 'MQ', label: 'Martinique'},
  {value: 'MR', label: 'Mauritania'},
  {value: 'MU', label: 'Mauritius'},
  {value: 'YT', label: 'Mayotte'},
  {value: 'MX', label: 'Mexico'},
  {value: 'FM', label: 'Micronesia, Federated States of'},
  {value: 'MD', label: 'Moldova, Republic of'},
  {value: 'MC', label: 'Monaco'},
  {value: 'MN', label: 'Mongolia'},
  {value: 'ME', label: 'Montenegro'},
  {value: 'MS', label: 'Montserrat'},
  {value: 'MA', label: 'Morocco'},
  {value: 'MZ', label: 'Mozambique'},
  {value: 'MM', label: 'Myanmar'},
  {value: 'NA', label: 'Namibia'},
  {value: 'NR', label: 'Nauru'},
  {value: 'NP', label: 'Nepal'},
  {value: 'NL', label: 'Netherlands'},
  {value: 'NC', label: 'New Caledonia'},
  {value: 'NZ', label: 'New Zealand'},
  {value: 'NI', label: 'Nicaragua'},
  {value: 'NE', label: 'Niger'},
  {value: 'NG', label: 'Nigeria'},
  {value: 'NU', label: 'Niue'},
  {value: 'NF', label: 'Norfolk Island'},
  {value: 'MP', label: 'Northern Mariana Islands'},
  {value: 'NO', label: 'Norway'},
  {value: 'OM', label: 'Oman'},
  {value: 'PK', label: 'Pakistan'},
  {value: 'PW', label: 'Palau'},
  {value: 'PS', label: 'Palestinian Territory, Occupied'},
  {value: 'PA', label: 'Panama'},
  {value: 'PG', label: 'Papua New Guinea'},
  {value: 'PY', label: 'Paraguay'},
  {value: 'PE', label: 'Peru'},
  {value: 'PH', label: 'Philippines'},
  {value: 'PN', label: 'Pitcairn'},
  {value: 'PL', label: 'Poland'},
  {value: 'PT', label: 'Portugal'},
  {value: 'PR', label: 'Puerto Rico'},
  {value: 'QA', label: 'Qatar'},
  {value: 'RE', label: 'Réunion'},
  {value: 'RO', label: 'Romania'},
  {value: 'RU', label: 'Russian Federation'},
  {value: 'RW', label: 'Rwanda'},
  {value: 'BL', label: 'Saint Barthélemy'},
  {value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha'},
  {value: 'KN', label: 'Saint Kitts and Nevis'},
  {value: 'LC', label: 'Saint Lucia'},
  {value: 'MF', label: 'Saint Martin (French part)'},
  {value: 'PM', label: 'Saint Pierre and Miquelon'},
  {value: 'VC', label: 'Saint Vincent and the Grenadines'},
  {value: 'WS', label: 'Samoa'},
  {value: 'SM', label: 'San Marino'},
  {value: 'ST', label: 'Sao Tome and Principe'},
  {value: 'SA', label: 'Saudi Arabia'},
  {value: 'SN', label: 'Senegal'},
  {value: 'RS', label: 'Serbia'},
  {value: 'SC', label: 'Seychelles'},
  {value: 'SL', label: 'Sierra Leone'},
  {value: 'SG', label: 'Singapore'},
  {value: 'SX', label: 'Sint Maarten (Dutch part)'},
  {value: 'SK', label: 'Slovakia'},
  {value: 'SI', label: 'Slovenia'},
  {value: 'SB', label: 'Solomon Islands'},
  {value: 'SO', label: 'Somalia'},
  {value: 'ZA', label: 'South Africa'},
  {value: 'GS', label: 'South Georgia and the South Sandwich Islands'},
  {value: 'SS', label: 'South Sudan'},
  {value: 'ES', label: 'Spain'},
  {value: 'LK', label: 'Sri Lanka'},
  {value: 'SD', label: 'Sudan'},
  {value: 'SR', label: 'Suriname'},
  {value: 'SJ', label: 'Svalbard and Jan Mayen'},
  {value: 'SZ', label: 'Swaziland'},
  {value: 'SE', label: 'Sweden'},
  {value: 'CH', label: 'Switzerland'},
  {value: 'SY', label: 'Syrian Arab Republic'},
  {value: 'TW', label: 'Taiwan, Province of China'},
  {value: 'TJ', label: 'Tajikistan'},
  {value: 'TZ', label: 'Tanzania, United Republic of'},
  {value: 'TH', label: 'Thailand'},
  {value: 'TL', label: 'Timor-Leste'},
  {value: 'TG', label: 'Togo'},
  {value: 'TK', label: 'Tokelau'},
  {value: 'TO', label: 'Tonga'},
  {value: 'TT', label: 'Trinidad and Tobago'},
  {value: 'TN', label: 'Tunisia'},
  {value: 'TR', label: 'Turkey'},
  {value: 'TM', label: 'Turkmenistan'},
  {value: 'TC', label: 'Turks and Caicos Islands'},
  {value: 'TV', label: 'Tuvalu'},
  {value: 'UG', label: 'Uganda'},
  {value: 'UA', label: 'Ukraine'},
  {value: 'AE', label: 'United Arab Emirates'},
  {value: 'GB', label: 'United Kingdom'},
  {value: 'US', label: 'United States'},
  {value: 'UM', label: 'United States Minor Outlying Islands'},
  {value: 'UY', label: 'Uruguay'},
  {value: 'UZ', label: 'Uzbekistan'},
  {value: 'VU', label: 'Vanuatu'},
  {value: 'VE', label: 'Venezuela, Bolivarian Republic of'},
  {value: 'VN', label: 'Viet Nam'},
  {value: 'VG', label: 'Virgin Islands, British'},
  {value: 'VI', label: 'Virgin Islands, U.S.'},
  {value: 'WF', label: 'Wallis and Futuna'},
  {value: 'EH', label: 'Western Sahara'},
  {value: 'YE', label: 'Yemen'},
  {value: 'ZM', label: 'Zambia'},
  {value: 'ZW', label: 'Zimbabwe'},
];

class PrefCreditCard extends React.Component<Props, State> {
  // disposer: Lambda;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bluesnapObj: any;
  mounted = true;

  constructor(props: Props) {
    super(props);
    const names = memberStore.member!.fullName.split(' ');
    this.state = {
      firstName: names[0],
      lastName: names[1] ?? "",
      selectedOption: undefined
    };
    billingStore.setError(undefined);
    console.log(`the name is: ${this.state.firstName}, ${this.state.lastName} `);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);

    props.setHandleSubmit(this.handleSubmit);

    const self = this;
    this.bluesnapObj = {
      onFieldEventHandler: {
          // tagId returns: "ccn", "cvv", "exp" 
          // tslint:disable-next-line:no-empty
          onFocus: function(_tagId: string) {
            billingStore.setError(undefined);
          },
          // tslint:disable-next-line:no-empty
          onBlur: function(_tagId: string) {}, // Handle blur 
          
          onError: function(tagId: string, errorCode: string , errorDescription: string) {
            console.log(`blue snap error: ${tagId}, ${errorCode}, ${errorDescription} `);
            billingStore.setError( `${tagId} ${errorDescription}`);
          }, 
          /*errorCode returns:
              "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
              "22013" --> "CC type is not supported by the merchant"; 
              "14040" --> " Token is expired";
              "14041" --> " Could not find token";
              "14042" --> " Token is not associated with a payment method, please verify your client integration 
                            or contact BlueSnap support";
              "400" --> "Session expired please refresh page to continue";
              "403", "404", "500" --> "Internal server error please try again later"; 
          */          /* errorDescription is optional. Returns BlueSnap's standard error description */ 
          
          // tslint:disable-next-line:no-empty
          onType: function(tagId: string, cardType: string) {}, /* cardType will give card type, 
          and only applies to ccn: CarteBleue, Visa, MasterCard, AmericanExpress, Discover, 
          DinersClub, JCB, Solo, MaestroUK, ChinaUnionPay */
          // tslint:disable-next-line:no-empty
          onValid: function(tagId: string) {}, // Handle a change in validation
      }, 
      /* example:
          style: {
          Style elements 
          (Selectors: "#ccn", "#cvv", "#year", "#month", "input", "::placeholder", ":focus", ".valid", ".invalid",
           "span", "select", "option")
    (Properties: "color", "font", "font-family", "font-size", "font-style", "font-weight", "line-height", "opacity", 
    "outline", "text-shadow", "transition",
    "left", "margin-right", "width", "height", "background-color)
          "Selector": {
          "Property": "Value",
          "Property2": "Value2"
          },
          "Selector2": {
          "Property": "Value"
          } 
      }, */
      style: {
          ':focus': {
            // style for all input elements on focus event
              color: '#062960'
          },
          input: {
              // style for all input elements 
              color: '#062960'
          },
          '.invalid': {
              // style for all input elements when invalid
              'color': '#e51a41'
          }
      },
      ccnPlaceHolder: '1234 5678 9012 3456', // for example
      cvvPlaceHolder: '123', // for example
      expPlaceHolder: '', // for example
      expDropDownSelector: false, // set to true for exp. date dropdown
      '3DS': true
    };
    
  }

  componentDidMount() {
    // this.disposer = Loader.observeVisible(this.props.preferencesStore!, 'accountUpdating');
    billingStore.createToken();

    const disposer = observe(billingStore, 'token', (change) => {
      console.log('got token', change.newValue);
      this.bluesnapObj.token = change.newValue;
      if (billingStore.bluesnapLoaded) bluesnap.hostedPaymentFieldsCreate(this.bluesnapObj);
      disposer();
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    // Loader.stop();
    // this.disposer();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleChange(event: any) {
    const value = event.target.value as string;
    const state = {};
    state[event.target.name] = value;
    this.setState(state);
    billingStore.setError(undefined);
  }

  public handleSubmit() {
    // event.preventDefault();
    const self = this;

    if (this.state.firstName.length < 1) {
      billingStore.setError('please enter first name');
      return;
    }

    if (this.state.lastName.length < 1) {
      billingStore.setError('please enter last name');
      return;
    }

    if (!this.state.selectedOption && !memberStore.account!.address?.country) {
      billingStore!.setError('please select country');
      return;
    }

    const threeDSecureObj = {
      amount: 0, 
      currency: 'USD'
    }; 

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bluesnap.hostedPaymentFieldsSubmitData(async (callback: any) => {
      if (null != callback.cardData) {
        console.log('Card type is ' + callback.cardData.ccType +                                      
          ', Last 4 digits are ' + callback.cardData.last4Digits +
          ', Exp is ' + callback.cardData.exp + 
          ', Issuing Country is ' + callback.cardData.issuingCountry + 
          ', 3D Secure result is ' + callback.threeDSecure?.authResult +
          ', 3D Secure reference id ' + callback.threeDSecure?.threeDSecureReferenceId);

        const resp = await billingStore.createCreditCard(
            self.state.firstName, self.state.lastName, memberStore.account!.name, self.state.selectedOption?.value);
          if (resp) {
            const info: PopupInfo = {
              msg: 'Succeeded to insert credit card information.'
            };    
            Popup.create(info);
          }
  
      } else {  
        const errorArray: [{tagId: string, errorCode: string, errorDescription: string}] = callback.error;
        for (const i in errorArray) {
          if (!errorArray[i]) continue;
            console.log('Received error: tagId= ' + 
              errorArray[i].tagId + ', errorCode= ' + 
              errorArray[i].errorCode + ', errorDescription= ' + 
              errorArray[i].errorDescription);
        }
        const error = `${errorArray[0].tagId} ${errorArray[0].errorDescription}`; 
        billingStore.setError(error);
      }             
  }, threeDSecureObj);
   
    // this.props.preferencesStore!.updateAccount(this.state.account);
  }

  private handleChangeCountry(value: ValueType<{ label: string; value: string }>) {
    const selectedOption = value! as { label: string; value: string };
  
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    billingStore.setError(undefined);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onClickChat(event: any) {
    event.preventDefault();
    trackAnalytic.openChat();
  }
  
  render() {
    if (!this.state) return null;
    const account = memberStore.account!;
    const country = account!.address?.country;


    const backgroundColor = `var(${this.props.backgroundWhite ? '--fully-white' : '--light-grey'})`;
    return (
      <div className={styles.component}>
        <div className={styles.title}>
          Credit Card
        </div>

        <form className={[styles.form,  this.props.backgroundWhite ? styles.backgroundWhite : ''].join(' ')}>
          <div className={styles.name}>
            <label>Cardholder Name</label>
            <div className={styles.nameInput}>
              <input type="name" name="firstName" placeholder="First Name" autoComplete="firstName" 
                value={this.state.firstName} onChange={this.handleChange} />
              <input type="name" name="lastName" placeholder="Last Name" autoComplete="lastName" 
                value={this.state.lastName} onChange={this.handleChange} />
            </div>
          </div>
          <div className={styles.cardNumber}>
            <label>Card Number</label>
            <div data-bluesnap="ccn"/>
          </div>
          <div className={styles.experation}>
            <label>Exp. (MM/YY)</label>
            <div data-bluesnap="exp"/>
          </div>
          <div className={styles.securityCode}>
            <label>Security Code</label>
            <div data-bluesnap="cvv"/>
          </div>
          { !country &&
            <div >
              <label>Country</label>
              <Select value={this.state.selectedOption} 
                      multi={false} 
                      onChange={this.handleChangeCountry}
                      options={countryOptions}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: 'var(--marine)',
                          // primary25: 'var(--marine)',
                          // primary50: 'var(--marine)',
                          // primary75: 'var(--marine)',
                          neutral0: backgroundColor,
                          neutral5: 'var(--marine)',
                          neutral10: 'var(--marine)',
                          neutral20: 'var(--marine)',
                          neutral30: 'var(--marine)',
                          neutral40: 'var(--marine)',
                          neutral50: 'var(--marine)',
                          neutral60: 'var(--marine)',
                          neutral70: 'var(--marine)',
                          neutral80: 'var(--marine)',
                          neutral90: 'var(--marine)',
                        },
                      })}/>
            </div>        
          }
        </form>
        { !country &&
          <div className={styles.note}>Please note that changing your billing location could impact your applicable tax rate</div>
        }
        <div className={styles.error}>{billingStore.error}</div>
        <div className={styles.chatWithUs}> If you encounter any issue with entering your credit card, we are here to help and would truly be happy to assist you. Please <a onClick={this.onClickChat}>chat with us</a></div>
      </div>
    );
  }
}

export default observer(PrefCreditCard);