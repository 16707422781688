import { observable, action, runInAction, computed, makeObservable } from 'mobx';

import storageService from '../services/storage.service';
import ISession from '../models/session.model';
import ILog, { LogType } from '../models/log.model';
import {SearchParam, SearchType} from '../models/search.model';
import SearchStore from './search.store';
import CommonStore from './common.store';
import Popup from '../components/common/popup.component';
import logConnection from '../services/connection/log.connection';
import { IBucket } from '../models/ bucket.model';
import moment from 'moment';
import memberStore from './member.store';

export enum PagingType {
  NONE,
  NEXT, 
  PREV
}

export enum ItemType {
  SESSION = 0,
  MESSAGE,
  EXCEPTION,
  EVENT,
  LOADING
}

const LIMIT = 200;

export interface Item {
  itemType: ItemType;
  data?: ILog | ISession;
}

export class LogsStore {
  hasNext = true;
  sessions: ISession[] = [];
  buckets: IBucket[] = [];
  get logCount() {
    return this.buckets.map(bucket => bucket.doc_count).reduce((a, b) => a + b, 0);
  }

  messagePopupIndex = 0;

  isLoglytics = false;

  logsCounter = 0;
  
  get itemList() {
    const itemList: Item[] = [];
    for (const session of this.sessions) {
      itemList.push({ itemType: ItemType.SESSION, data: session });
      for (const log of (session.logs as ILog[])) {
        let type: ItemType;
        switch (log.type) {
          case LogType.MESSAGE:
            type = ItemType.MESSAGE;
            break;
          case LogType.EXCEPTION:
            type = ItemType.EXCEPTION;
            break;
          case LogType.ACTION_EVENT:
          case LogType.SCREEN_EVENT: 
          case LogType.ACTIVITY_EVENT:
          case LogType.APP_EVENT:
          case LogType.CONFIG_EVENT:
            type = ItemType.EVENT;
            break;
          default:
            continue;
        }
        itemList.push({ itemType: type, data: log });
      }
    }
    console.log('the item list length ' + itemList.length);
    if (this.hasNext && itemList.length > 0 && itemList.length > LIMIT) itemList.push({ itemType: ItemType.LOADING });
    return itemList;
  }

  minMode: boolean = storageService.getItem('minMode', false);
  

  isLoading = false;
  from = 0;

  constructor () {
    makeObservable(this, {
      hasNext: observable,
      sessions: observable,
      buckets: observable,
      logCount: computed,
      messagePopupIndex: observable,
      isLoglytics: observable,
      logsCounter: observable,
      itemList: computed,
      minMode: observable,
      getLogs: action,
      setMinMode: action,
      setMessagePopupIndex: action,
      setIsLoglytics: action,
      clear: action
    });

    this.getLogs = this.getLogs.bind(this);
  }

  async getLogs(hasLoader: boolean, pagingType: PagingType = PagingType.NONE) {
    console.debug('getLogs');
    if (this.isLoading) return;
    this.isLoading = true;
    if (hasLoader) CommonStore.setLoading(true);
    if (pagingType === PagingType.NONE) this.sessions = [];
    try {
      const data = SearchStore.logsParams;

      // checking interval for time
      let retention = memberStore.currentPlan?.retention;
      if (!retention) retention = 7
      console.log(`param from time: ${data[SearchParam.FROM_TIME]} to time: ${data[SearchParam.TO_TIME]}`);
      const fromTime = data[SearchParam.FROM_TIME] ? moment(new Date(data[SearchParam.FROM_TIME])) : moment().subtract(retention, 'day');
      const toTime = data[SearchParam.TO_TIME] ? moment(new Date(data[SearchParam.TO_TIME])) : moment();
      console.log(`from time: ${fromTime} to time: ${toTime}`);

      const duration = moment.duration(toTime.diff(fromTime));
      console.log(`days: ${duration.asDays()}`);
      console.log(`hours: ${duration.asHours()}`);
      console.log(`minutes: ${duration.asMinutes()}`);

      let interval = "1d";
      if (duration.asDays() > 8) interval = "3h";
      else if (duration.asHours() > 50) interval = "1h";
      else if (duration.asHours() > 10) interval = "30m";
      else if (duration.asHours() > 3) interval = "10m";
      else if (duration.asMinutes() > 40) interval = "1m";
      else if (duration.asMinutes() > 10) interval = "30s";
      else interval = "1s";
      console.log(`interval: ${interval}`);
      data.interval = interval;
      data.histogram = true;

      // limit
      const limit = LIMIT;
      data.limit = limit;
      if (pagingType === PagingType.NEXT) {
        this.from += LIMIT;
        data.from = this.from;
      }
      else {
        this.from = 0;
      }
      
      const {sessions, buckets} = await logConnection.getSessionLogs(data);
      console.log('the session received', sessions);

      runInAction(() => {
        this.hasNext = sessions.length > 0;
        this.buckets = buckets ?? [];
        switch (pagingType) {
          case PagingType.NONE: 
            this.sessions = sessions;
            this.logsCounter++;
            break;
          case PagingType.NEXT: {
            if (sessions.length <= 0) break;
            const lastIndex = this.sessions.length - 1;
            if (sessions[0]._id === this.sessions[lastIndex]._id) {
              const firstSession = sessions.shift();
              (this.sessions[lastIndex].logs as ILog[]).push(...(firstSession!.logs as ILog[]));
            }
            this.sessions.push(...sessions);
            break;
          }
          default:
            break;

        }
      });
    } catch (error) {
      Popup.createError({msg: 'Had a problem loading the logs'}); 
      console.log(error);
    } finally {
      this.isLoading = false;
      if (hasLoader) CommonStore.setLoading(false);
    }
  }

  setMinMode(minMode: boolean) {
    this.minMode = minMode;
    storageService.setItem('minMode', minMode);
  }

  setMessagePopupIndex(messagePopupIndex: number) {
    this.messagePopupIndex = messagePopupIndex;
  }

  setIsLoglytics(isLoglytics: boolean) {
    this.isLoglytics = isLoglytics;
  }

  clear() {
    this.sessions = [];
    this.hasNext = true;
  }
}

export default new LogsStore();  