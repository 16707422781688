type Primitive = string | number | boolean;
class StorageService {
  setItem<T extends Primitive>(key: string, value: T) {
    const stringValue = typeof value === 'string' ? value : value.toString();
    window.localStorage.setItem(key, stringValue);
  }

  getItem<T extends Primitive>(key: string, defaultValue: T): T {
    const stringValue = window.localStorage.getItem(key);
    if (!stringValue) return defaultValue;

    if (typeof defaultValue === 'string') return stringValue as T;

    switch(typeof defaultValue) {
      case 'string': return stringValue as T;
      case 'boolean': return (stringValue === 'true') as  T;
      case 'number': return Number(stringValue) as T;
    }
    return defaultValue;
  }

  removeItem(key: string) {
    window.localStorage.removeItem(key);
  }
}

export default new StorageService();