
import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './pref_has_credit_card.module.css';
import billingStore from '../../../stores/billing.store';
import Popup, { PopupInfo } from '../../common/popup.component';
import PrefCreditCard from './pref_credit_card.component';

import creditCardIcon from '../../../img/preferences/credit-card.svg'
class PrefHasCreditCard extends React.Component {
  prefCreditCardSubmit?: Function;

  constructor(props: {}) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.handleUpdateCC = this.handleUpdateCC.bind(this);
  }

  componentDidMount() {
    billingStore.creditCardInfo();
  }

  handleUpdateCC() {
    this.prefCreditCardSubmit!();
    return false;
  }

  onClick() {
    console.log('pressed update credit card');
    const okAttr = {
      "data-bluesnap": "submitButton"
    };
    const info: PopupInfo = {
      iconSrc: undefined,
      msg: ( 
        <PrefCreditCard backgroundWhite={true} 
          setHandleSubmit={(handle: Function) => this.prefCreditCardSubmit = handle}/> 
      ),
      okCallback: this.handleUpdateCC,
      okAttr,
      cancel: 'Cancel'
    };

    Popup.create(info);
  }

  render() {
    const { creditCard } = billingStore;    
    return (
      <div className={styles.component}>
        <div className={styles.title}>Credit Card</div>
        {creditCard && 
          <div className={styles.creditCard}>
            <img src={creditCardIcon} alt="credit card" />
            <div>
              <div>{creditCard.cardType} **** {creditCard.cardLastFourDigits}</div>
              <div className={styles.expires}>Expires {creditCard.expirationMonth}/{creditCard.expirationYear}</div>
            </div>
          </div> 
        }

        <div className={styles.buttonWrapper}>
          <button type="submit" onClick={this.onClick}>Change</button>
        </div>
      </div>
    );
  }
}

export default observer(PrefHasCreditCard);
