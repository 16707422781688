import IApp from './app.model';
import { SeverityExc, StackTraceElement } from './log.model';

export enum LoglyticsType {
  MESSAGE = 'loglyticsMessage',
  EXCEPTION = 'loglyticsException', 
}

export class LoglyticsTypeUtil {
  static values() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(LoglyticsType).map(k => LoglyticsType[k as any]);
  }
}

export enum LoglyticsStatus {
  OPEN = 'open',
  FIXED = 'fixed',
  IGNORED = 'ignored'
}

export class LoglyticsStatusUtil {
  static values() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(LoglyticsStatus).map(k => LoglyticsStatus[k as any]);
  }
}

export interface ILoglytics {
  key: string;
  app: IApp | string;
  type: LoglyticsType;
  severity: SeverityExc;
  // isObfuscated: boolean;
  // daily?: {
  //   date: Date,
  //   count: number;
  //   sessions: number;
  //   devices: number;
  //   users: number;
  //   appVersion: string[];
  //   osVersion: string[]; 
  //   sampleList: {
  //     session: ISession | Types.ObjectId;
  //     log: string;
  //   } [];
  // }[],
  // status: LoglyticsStatus;
  // membersRead: IMember[] | Types.ObjectId[] | string[];
  lastAlert?: Date;

  // children?: ILoglyticsBase[];
  count: number;
  appVersion: string[];
  osVersion: string[];
  devices: number;
  sessions?: number;
  // users?: number;
  dateHistogram?: {
    date: Date,
    count: number;
    sessions: number;
    deviceCount: number;
    devices: number;
    // users: number;
  }[];
}

export interface ILoglyticsMessage extends ILoglytics {
  tag: string;
  message: string;
  function: string;
  fileName: string;
  lineNumber: number;
  className: string; // only in android 
  stackTrace?: StackTraceElement[]; // for future adding maybe also to normal log messages
  callStackSymbols?: string[]; // for future adding maybe also to normal log messages
  exception?: { // in the case that a developer added a log message
    name: string;
    reason: string;
    stackTrace: StackTraceElement[];
  };
}

export interface ILoglyticsException extends ILoglytics {
  name: string;
  reason: string;
  stackTrace: StackTraceElement[];
  callStackSymbols: string[];
}