
import { observable, action, runInAction, makeObservable } from 'mobx';

import { StatNumber, StatDeviceSum, StatDateNumbers, TimeSpan } from '../models/stat.model';
import Popup from '../components/common/popup.component';
import statsConnection from '../services/connection/stats.connection';
import commonStore from './common.store';
import moment from 'moment';

enum TimeInterval {
  HOUR = '1h',
  TEN_MINUTE = '10m',
  MINUTE = '1m',
  TEN_SECONDS = '10s'
}

export class DashboardStore {
  inProgress = false;
  query: string = '';
  
  appVersions: string[] = [];
  version: string = '';
  timeSpan: TimeSpan = TimeSpan.DAY;

  average: StatNumber = {exception: 0, error: 0, warning: 0};
  deviceSums: StatDeviceSum[] = [];
  logSums: StatDateNumbers[] = [];
  affectedDevices: StatDateNumbers[] = [];

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      query: observable,
      appVersions: observable,
      version: observable,
      timeSpan: observable,
      average: observable,
      deviceSums: observable,
      logSums: observable,
      affectedDevices: observable,
      getStats: action,
      setVersion: action,
      setTimeSpan: action
    });

    this.fillMissingValues = this.fillMissingValues.bind(this);
  }
  // @computed getAf
  async getStats() {
    this.inProgress = true;
    commonStore.setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = {};
      if (this.version.length > 0) data.appVersion = this.version;
      let startDate: Date;
      let timeInterval = TimeInterval.HOUR;
      switch(this.timeSpan) {
        
        // case TimeSpan.HOUR: 
        //   startDate = moment().subtract(1, 'h').startOf('m').toDate();
        //   timeInterval = TimeInterval.MINUTE;
        //   break;
        // case TimeSpan.DAY:
        //   startDate = moment().subtract(1, 'd').startOf('h').toDate();
        //   timeInterval = TimeInterval.TEN_MINUTE;
        //   break;
        default:
        case TimeSpan.DAY:
          startDate = moment().subtract(1, 'd').startOf('h').toDate();
          timeInterval = TimeInterval.HOUR;
          break;

        case TimeSpan.WEEK:
          startDate = moment().subtract(7, 'd').startOf('h').toDate();
          timeInterval = TimeInterval.HOUR;
          break;
        case TimeSpan.MONTH:
          startDate = moment().subtract(30, 'd').startOf('h').toDate();
          timeInterval = TimeInterval.HOUR;
          break;
      }
      data.startDate = startDate.toISOString();
      data.timeInterval = timeInterval;
      const [appVersions, average, deviceSums, logSums, affectedDevices] = await Promise.all([
        statsConnection.getAppVersions(data),
        statsConnection.getAverages(data),
        statsConnection.getDeviceSums(data),
        statsConnection.getLogSums(data),
        statsConnection.getAffectedDevices(data),
      ]);
      runInAction(() => {
        this.appVersions = appVersions;
        this.average = average;
        this.deviceSums = this.fillMissingValues(deviceSums);
        this.logSums = this.fillMissingValues(logSums) as StatDateNumbers[];
        this.affectedDevices = this.fillMissingValues(affectedDevices) as StatDateNumbers[];
      });
    } catch (error) {
      console.error('got the following error', error);
      Popup.createError({msg: 'Had a problem loading the dashboard info, please try later again'}); 
    } finally {
      commonStore.setLoading(false);
      runInAction(() => this.inProgress = false);
    }
  }

  // tslint:disable-next-line:no-shadowed-variable
  setVersion(version: string) {
    this.version = version;
    this.getStats();
  }

  setTimeSpan(timeSpan: TimeSpan) {
    this.timeSpan = timeSpan;
    this.getStats();
  }


  private fillMissingValues(dates: {time: Date}[]) {
  //   const currentDate = moment();
  //   let date: moment.Moment;
  //   let unit: moment.DurationInputArg2;
  //   if (this.dateUnit === DateUnit.MONTH) {
  //     date = moment().subtract(1, 'month').startOf('day');
  //     unit = 'day';
  //   }
  //   else {
  //     date = moment().subtract(1, 'day').startOf('hour');
  //     unit = 'hour';
  //   }

  //   let newDates: {time: Date}[] = [];
  //   for (let i = 0; date.isBefore(currentDate) ; date = date.add(1, unit)) {
  //     if (i < dates.length && date.isSame(dates[i].time, unit)) newDates.push(dates[i++]);
  //     else {
  //       newDates.push({
  //         time: date.toDate()
  //       });
  //     }
  //   }
  //   return newDates;
  // }
    return dates;
  }
}

export default new DashboardStore();
