import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import * as _ from 'lodash';
import memberStore from './member.store';
import IApp, { ICustomAlert, Integration } from '../models/app.model';
import trackAnalytic from '../components/analytics';
import AuthConnection from '../services/connection/auth.connection';
import AppConnection from '../services/connection/app.connection';
import commonStore from './common.store';
import ConnectionService from '../services/connection/connection.service';
import IAppVersionFile from '../models/app_version_file.model';

export class AppStore {
  get app() {
    const app = memberStore.app;
    return app;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private intervalHandle?: any;
  appVersionFiles: IAppVersionFile[] = [];

  constructor() {
    makeObservable(this, {
      app: computed,
      appVersionFiles: observable
    });

    this.startCheckIntegrated = this.startCheckIntegrated.bind(this);
    this.checkIntegrated = this.checkIntegrated.bind(this);
    this.stopCheckIntegrated = this.stopCheckIntegrated.bind(this);
  }

  async updateApp(newApp: IApp) {
    commonStore.setLoading(true);
    const app = this.app!;
    const data = {};
    if (newApp.name !== app.name) data['name'] = newApp.name;
    if (!_.isEqual(newApp.config,app.config)) data['config'] = newApp.config;
    if (newApp.configType !== app.configType) data['configType'] = newApp.configType;
    if (newApp.configConsole !== app.configConsole) data['configConsole'] = newApp.configConsole;

    if (Object.keys(data).length === 0) {
      commonStore.setLoading(false);
      console.log('there were no changes');
      return;
    }

    console.log('the data', data);
    const retApp: IApp = await AppConnection.updateApp(app._id, data);
    
    memberStore.updateApp(retApp);
    commonStore.setLoading(false);
    console.log('the app that was returned', retApp);
  }

  async createApp(name: string) {
    commonStore.setLoading(true);
    const retApp: IApp = await ConnectionService.createApp({name});
    memberStore.addApp(retApp);
    await AuthConnection.refreshToken();
    console.log('the app that was returned', retApp);
    commonStore.setLoading(false);
    return retApp;
  }

  async deleteApp(appId: string) {
    const res = await AppConnection.deleteApp(appId);
    memberStore.deleteApp(appId);
    console.log('delete res', res);
  }

  startCheckIntegrated() {
    if (this.intervalHandle) return; // already started
    if (this.app?.integration === Integration.FULL) return;
    this.intervalHandle = setInterval(this.checkIntegrated, 1000);
    console.log('the intervalHandle', this.intervalHandle);
  }

  stopCheckIntegrated() {
    if (!this.intervalHandle) return;
    clearInterval(this.intervalHandle);
    this.intervalHandle = undefined;
  }

  async checkIntegrated() {
    console.log('checkIntegrated', this.app);

    if (!this.app) return;
    const app = await AppConnection.getApp(this.app!._id);
    
    if (app.integration === Integration.FULL) {
      trackAnalytic.integration(memberStore.member!._id);
      this.stopCheckIntegrated();
    }
    memberStore.updateApp(app);
  
  }
  async uploadDsym(file: File, appVersion: string, build: string) {
    if (!this.app) return;
    commonStore.setLoading(true);
    try {
      await AppConnection.uploadDsym(this.app._id, appVersion, build, file);
      this.getAppVersionFiles();
    }
    catch (e) {
      return undefined;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  async getAppVersionFiles() {
    if (!this.app) return;
    const appVersionFiles = await AppConnection.getAppVersionFiles(this.app._id);
    runInAction(() => this.appVersionFiles = appVersionFiles);
  }

  async deleteAppVersionFile(appVersionFileId: string) {
    if (!this.app) return;
    commonStore.setLoading(true);
    try {
      await AppConnection.deleteAppVersionFile(this.app._id, appVersionFileId);
      this.getAppVersionFiles();
    }
    catch (e) {
      return;
    }
    finally {
      commonStore.setLoading(false);
    }
  }

  async disableDevice(udid: string) {
    if (!this.app) return;
    const app = await ConnectionService.disableDevice(this.app._id, udid);
    memberStore.updateApp(app);
  }

  async enableDevice(udid: string) {
    if (!this.app) return;
    const app = await ConnectionService.enableDevice(this.app._id, udid);
    memberStore.updateApp(app);
  }

  getAlert(id: string) {
    if (!this.app) return undefined;
    return this.app.alerts?.find(alert => alert._id === id);
  }

  async addAlert(alert: ICustomAlert) {
    if (!this.app) return;
    const a = await AppConnection.addAlert(this.app._id, alert);
    if (!this.app.alerts) this.app.alerts = [];
    runInAction(() => this.app!.alerts!.push(a));
    return a;
  }

  async updateAlert(alert: ICustomAlert) {
    if (!this.app) return;
    const a = await AppConnection.updateAlert(this.app._id, alert._id, alert);
    const i = this.app.alerts?.findIndex(a => alert._id === a._id);
    if (typeof i == 'number')
    runInAction(() =>  this.app!.alerts![i] = a);
    return a;
  }

  async deleteAlert(id: string) {
    if (!this.app) return;
    await AppConnection.deleteAlert(this.app._id, id);
    const i = this.app.alerts?.findIndex(a => a._id == id);
    if (typeof i != 'undefined') runInAction (() => this.app!.alerts?.splice(i,1));
  }
}

export default new AppStore();