import React, {useRef, useEffect, useState} from 'react';
import styles from './pref_app.module.css';
import PrefAppMenu from './pref_app_menu.component';
import PrefAppRoutes from '../../routes/pref_app.routes';

const PrefApp = () => {
  return (
    <div className={styles.component}>
      <PrefAppMenu />
      <div className={styles.main}>
        <PrefAppRoutes />
      </div>
    </div>
  )
}

export default PrefApp;