import { on } from 'process';
import React, { useState, useEffect } from 'react';

interface Props {
  text?: string;
  minSpeed?: number;
  maxSpeed?: number;
  onTextChange?: (text: string) => void; // Function to call when the text changes
}
const Typewriter = ({ text = '', minSpeed = 50, maxSpeed = 150 , onTextChange}: Props) => {
  const [typewriterText, setTypewriterText] = useState('');
  let index = 0;

  // This function returns a random speed with lower values being more probable.
  const getRandomSpeed = () => {
    const speed = Math.pow(Math.random(), 100) * (maxSpeed - minSpeed) + minSpeed;
    return speed;
  };

  useEffect(() => {
    let timeoutId:  NodeJS.Timeout;

    const typeNextCharacter = () => {
      if (index < text.length) {
        setTypewriterText((prev) => {
          const newText = prev + text[index];
          if (onTextChange) onTextChange(newText);
          return newText;
        });
        index++;

        const speed = getRandomSpeed();
        timeoutId = setTimeout(typeNextCharacter, speed);
      }
    }

    typeNextCharacter();

    // Clear the timeout if the component is unmounted
    return () => clearTimeout(timeoutId);
  }, []);

  return <span>{typewriterText}</span>;
};

export default Typewriter;
