import * as React from 'react';

import styles from './pref_account.module.css';
import PrefAccountMenu from './pref_account_menu.component';
import PrefAccountRoutes from '../../routes/pref_account.routes';

const PrefAccount = () => {
  return (
    <div className={styles.component}>
      <PrefAccountMenu />
      <div className={styles.main}>
        <PrefAccountRoutes />
      </div>
    </div>
  );
};

export default PrefAccount;