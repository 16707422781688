import {ITrigAlert} from '../../models/trig_alert.model';
import ConnectionService, { HttpMethod } from './connection.service';

class TrigAlertConnection {
  public async getTrigAlerts(): Promise<ITrigAlert[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/trigAlerts`);
  }

  public async read():  Promise<ITrigAlert[]> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/trigAlerts/read`, HttpMethod.POST);
  }

  public async unreadCount(): Promise<{count: number}> {
    return await ConnectionService.fetch(`${ConnectionService.appUrl}/trigAlerts/unreadCount`);
  }
}

export default new TrigAlertConnection();