export enum TimeSpan {
  HOUR = 'hour',
  DAY = 'day', 
  WEEK = 'week',
  MONTH = 'month'
}

export enum StatType {
  LOG = 'log',
  SESSION = 'session'
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export class StatTypeUtil {
  static values() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(StatType).map(k => StatType[k as any]);
  }
}  
export interface StatNumber {
  exception: number; 
  error: number; 
  warning: number;
}

export interface StatDeviceNumber extends StatNumber {
  device: number;
}

export interface StatDeviceSum {
  time: Date; 
  [name: string]: Date | number;
}

export interface StatDateNumbers {
  time: Date; 
  exception: number;
  error: number;
  warning: number;
  info: number;
  debug: number;
  verbose: number;
}
