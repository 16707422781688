/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';

import styles from './loglytics_item_graph.module.css';
import Time from '../../services/time.service';

import loglyticsStore from '../../stores/loglytics.store';
import { ILoglyticsMessage, LoglyticsType, ILoglyticsException } from '../../models/loglytics.model';
// import { ILoglyticsMessage, LoglyticsType, ILoglyticsException, LoglyticsStatus } from '../../models/loglytics.model';
import { ResponsiveContainer, XAxis, Tooltip, TooltipProps, BarChart, Bar, ReferenceLine, Rectangle } from 'recharts';
import { Exception, Severity } from '../../models/log.model';
import numberService from '../../services/number.service';
import memberStore from '../../stores/member.store';
import arrayService from '../../services/array.service';
import { GraphTimeTick } from '../common/graph_time_tick.component';
import ShareBtn, { ShareStyle } from '../common/sharebtn.component';
import logsStore from '../../stores/logs.store';

import appleIcon from '../../img/apple-icon.svg';  
import androidIcon from '../../img/android-icon.svg';
import blackFlag from '../../img/logs/black-flag.svg';
import wheel from '../../img/logs/wheel.svg';
import buoy from '../../img/logs/buoy.svg';
import moment from 'moment';
import alertsStore from '../../stores/alerts.store';

const CustomTooltip = (props: TooltipProps) => {
  const {payload} = props;
  const time = new Time(props.label!);
  return (
    <div className={styles.customTooltip}>
      <div className={styles.customTooltipTitle}>{time.getLongDateTime()}</div>
      {payload && payload.map((elem) => {
        const alert = isAlert(elem.payload.date);
        return (
        <React.Fragment key={elem.name}>
          <div className={`${styles.customTooltipText} ${alert && styles.customTooltipAlert} `}>{elem.name}</div>
          <div className={styles.customTooltipColor} style={{backgroundColor: alert ? 'var(--lipstick)': elem.color}}/>
          <div className={`${styles.customTooltipText} ${alert && styles.customTooltipAlert}`}>{Math.floor(elem.value as number * 10000) / 100}% 
            ({numberService.abbreviateNumber(elem.payload.devices)})</div>
        </React.Fragment>);
      })}        
    </div>
  );
}

interface CustomBarProps {
  fill?: string;
  payload?: {date: Date};
}

const CustomBar = (props: CustomBarProps) => {
  const { fill, payload } = props;

  let newFill = fill;

  if (isAlert(payload!.date)) {
    newFill = 'var(--lipstick)';
  }

  return <Rectangle {...props} fill={newFill} />;
}

const isAlert = (date: Date) => {
  const currentLoglytics = loglyticsStore.currentLoglytics!;
  if (currentLoglytics.severity !== Severity.ERROR) return false;
  const alerts = alertsStore.getLoglyticsAlerts(currentLoglytics.key);
  const alert = alerts.find(a => moment(a.date).isSame(moment(date), 'hour'));
  return !!alert;
}

interface LoglyticsItemGraphProps extends RouteComponentProps<{}> {
  popup?: boolean;
}

class LoglyticsItemGraph extends React.Component<LoglyticsItemGraphProps> {
  constructor(props: LoglyticsItemGraphProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    loglyticsStore.getLoglytics(true);
  }

  // next() {
  //   this.props.loglyticsStore!.nextPrevSample(true);
  // }

  // prev() {
  //   this.props.loglyticsStore!.nextPrevSample(false);
  // }

  // changeStatus(status: LoglyticsStatus) {
  //   this.props.loglyticsStore!.setStatus(status)
  // }

  // changeToUnread() {
  //   this.props.loglyticsStore!.setLoglyticsRead(false);
  // }

  onClick() {
    if (!this.props.popup) return;
    logsStore.setMessagePopupIndex(0);
    const {appId, accountId} = memberStore;

    const appsUrl = `/${accountId}/${appId}`;
    this.props.history!.push(`${appsUrl}/loglytics/${loglyticsStore.currentLoglytics!.key}`);
  }

  render() {
    const {popup} = this.props;
    if (!loglyticsStore.currentLoglytics) return null;
    const currentLoglytics = loglyticsStore.currentLoglytics!;
    
    const {currentDateHistogram} = loglyticsStore;
    // const {currentSampleIndex} = loglyticsStore;
    const {severity} = currentLoglytics;
    let color;
    let graphColor;
    const percentage = loglyticsStore.deviceCount?  currentLoglytics.devices / loglyticsStore.deviceCount.device : 0;
    let icon;
    switch (severity) {
      case Exception.EXCEPTION:
        color = "var(--black)";
        graphColor = "var(--brown-grey)";
        icon = blackFlag;
        break;
      case Severity.ERROR:
        color = "var(--lipstick)";
        graphColor = "var(--soft-pink)";
        icon = wheel;
        break;
      case Severity.WARNING:
        color = "var(--mango)"
        graphColor = "var(--light-salmon)"
        icon = buoy;
        break;      
    }
    const {platform} =  memberStore.app!
    const osIcon = platform === 'ios' ? appleIcon: androidIcon;
    const osVersions = arrayService.sortVersion(currentLoglytics.osVersion.slice());
    let osVersionString = ''; 
    if (osVersions.length > 0) {
      osVersionString = osVersions.length === 1 ? osVersions[0] : 
        `${osVersions[0]} - ${osVersions[osVersions.length-1]}`;
    }
    const dataTipOsVersions = osVersions.reverse().join('<br/>')

    const appVersions = arrayService.sortVersion(currentLoglytics.appVersion.slice());
    let appVersionString = ''; 
    if (appVersions.length > 0) {
      appVersionString = appVersions.length === 1 ? appVersions[0] : 
        `${appVersions[0]} - ${appVersions[appVersions.length-1]}`;
    }

    const dataTipAppVersions = appVersions.reverse().join('<br/>')

    let logMessage = '';
    let fileName = '';
    let lineNumber: string | number = 0;
    if (currentLoglytics.type === LoglyticsType.EXCEPTION) {  
      const loglyticsException = currentLoglytics as ILoglyticsException;  
      logMessage = loglyticsException.name + ': ' + loglyticsException.reason;
      if (loglyticsException.stackTrace?.length > 0) {
        fileName = loglyticsException.stackTrace[0].fileName;
        lineNumber = loglyticsException.stackTrace[0].lineNumber;
      }
    } else {
      const loglyticsMessage = currentLoglytics as ILoglyticsMessage;
      logMessage = loglyticsMessage.message;
      fileName = loglyticsMessage.fileName.slice(loglyticsMessage.fileName.lastIndexOf('/') + 1);
      lineNumber = loglyticsMessage.lineNumber;
    }

    const shareUrl = window.location.href;
    const shareSummary = 'Shipbook > Loglytics';
    const shareDescription = `Loglytics issue: \n${logMessage} \n${shareUrl}`;

    // const hasPrev = currentSampleIndex > 0;
    // const hasNext = false; // currentSampleIndex < sampleList.length - 1;

    // let ignoreIcon: string;
    // let ignoreChange: LoglyticsStatus;
    // let ignoreTip: string;
    // // if (currentLoglytics.status !== LoglyticsStatus.IGNORED) {
    // //   ignoreIcon = "/img/loglytics/ignore.svg";
    // //   ignoreChange = LoglyticsStatus.IGNORED;
    // //   ignoreTip = "Ignore";
    // // }
    // // else {
    //   ignoreIcon = "/img/loglytics/unignore.svg";
    //   ignoreChange = LoglyticsStatus.OPEN;
    //   ignoreTip = "Unignore";
    // // }

    // let fixIcon: string;
    // let fixChange: LoglyticsStatus;
    // let fixTip: string;
    // // if (currentLoglytics.status !== LoglyticsStatus.FIXED) {
    // //   fixIcon = "/img/loglytics/fix.svg";
    // //   fixChange = LoglyticsStatus.FIXED;
    // //   fixTip = "Fix";
    // // }  
    // // else {
    //   fixIcon = "/img/loglytics/unfix.svg";
    //   fixChange = LoglyticsStatus.OPEN;
    //   fixTip = "Unfix";
    // // }

    // let statusIcon = undefined;
    // // switch (currentLoglytics.status) {
    // //   case LoglyticsStatus.FIXED: statusIcon = '/img/loglytics/statusFixed.svg'; break;
    // //   case LoglyticsStatus.IGNORED: statusIcon = '/img/loglytics/statusIgnored.svg'; break;
    // // }

    // const hasPrevNext = currentSampleIndex > -1;
    // let histogram = dateHistogram?.map(h => { 
    //   return {...h, percentage: h.sessionCount? h.sessions / h.sessionCount : 0}
    // });

    return (
      <div className={`${styles.mainInformation} ${popup ? styles.popupContainer: '' }`} onClick={this.onClick}>
        <div className={styles.severity}>
          {/* {hasPrevNext && <input type="image" className={styles.up} src="/img/extra-triangle.svg" alt="prev sample" data-tip="Previous Sample" onClick={this.prev} disabled={!hasPrev}/>} */}
          <img src={icon} className={styles.severityIcon}/>
          {/* {hasPrevNext && <input type="image" className={styles.down}  src="/img/extra-triangle.svg" alt="next sample" data-tip="Next Sample" onClick={this.next} disabled={!hasNext}/> } */}
        </div>
        <div className={styles.line} />
        <div className={styles.infoMain}>
          <div className={styles.infoPercentage} style={{color}}>{Math.floor(percentage * 10000) / 100}%</div>
          <div className={styles.infoSeverity} style={{color}}> {severity} </div>
          <div className={styles.infoLocation} >{fileName}:{lineNumber}</div>
        </div>
        <div className={styles.infoMiddle}>
          <ResponsiveContainer width="99%" height={83}>
            <BarChart data={currentDateHistogram}>
              <Tooltip wrapperStyle={{zIndex: 100000}} content={<CustomTooltip />}/> 
              <Bar dataKey={(day) => day.percentage} name="devices"  fill={graphColor} shape={<CustomBar/>}/>
              <XAxis height={30} dataKey={(stat) => stat.date.toISOString()} 
                tick={<GraphTimeTick />} stroke="#21a1b5"/>
            </BarChart> 
          </ResponsiveContainer>
          <div className={styles.infoMessage}>{logMessage}</div>
        </div>
        <div className={styles.line} />
        <div className={styles.rightInfo}>
          <div className={styles.infoName}>devices</div>
          <div className={styles.infoValue} style={{color}}>{numberService.abbreviateNumber(currentLoglytics.devices!)}</div>
          <div className={styles.infoName}>sessions</div>
          <div className={styles.infoValue} style={{color}}>{numberService.abbreviateNumber(currentLoglytics.sessions!)}</div>
          {/* <div className={styles.infoName}>users</div>
          <div className={styles.infoValue} style={{color}}>{numberService.abbreviateNumber(currentLoglytics.users!)}</div> */}
          <div className={styles.infoName}></div> {/* to keep the space */}
          <div className={styles.infoValue}></div>

          <div className={styles.osVersion}><img src={osIcon} className={styles.osIcon}/><div data-tip={dataTipOsVersions}>{osVersionString}</div></div>
          <div className={styles.appVersion}><div>App version</div><div data-tip={dataTipAppVersions}>{appVersionString}</div></div>
        </div>
        {!popup &&
          <>
            <div className={styles.line} />
            <div className={styles.status}>
              <ShareBtn summary={shareSummary} description={shareDescription} url={shareUrl} style={ShareStyle.SMAll}/>
              {/* <div className={styles.currentStatus}>
                <img src="/img/menu.svg" className={styles.menuIcon}/>
                <div className={styles.space}/>
                <img src={statusIcon} className={`${styles.statusIcon} ${statusIcon ? '': styles.hidden}`}/>
              </div>
              <div className= {styles.actions}>
                <img src="/img/loglytics/unread.svg" data-tip="Mark as unread"  onClick={this.changeToUnread}/>
                <img src={ignoreIcon} data-tip={ignoreTip} onClick={() => this.changeStatus(ignoreChange)}/>
                <img src={fixIcon} data-tip={fixTip} onClick={() => this.changeStatus(fixChange)}/>
              </div> */}
            </div>
          </>
        }
      </div>
    );
  }
}

export default withRouter(observer(LoglyticsItemGraph));